import { Button } from "@fluentui/react-components";
import { AddSquare16Regular, Delete16Regular, Edit16Regular } from "@fluentui/react-icons";
import React, { useEffect, useState } from "react";

interface props {
  item: any;
  index: number;
  inEditMode: string[];
  emptyObj: any[];
  setEmptyObj: (value: any) => void;
  setEditModeTab: (value: any) => void;
  setEditMode: (value: any) => void;
  handleDeleteTab: (tileId: string) => void;
  addNewTab: (tab: number) => void;
  handleChangeTileText: (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
  handleChangeReportUrl: (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
}

const AdminItem: React.FC<props> = ({
  item,
  index,
  emptyObj,
  setEditModeTab,
  setEmptyObj,
  inEditMode,
  setEditMode,
  handleDeleteTab,
  addNewTab,
  handleChangeTileText,
  handleChangeReportUrl,
}) => {
  const [editTab, setEditTab] = useState<boolean>(true);

  // const checkIfEditDisabled = () => {
  //     if (editTab != "" && editTab!= item.tileId) setEditTab(item.tileId)
  //     console.log("Edit Tab", item.tileId, editTab)
  // }

  const setInEditMode = () => {
    console.log("Index", inEditMode.indexOf(item.Uid), inEditMode, item.Uid);
    if (inEditMode.indexOf(item.Uid) == -1) {
      console.log("In Edit Mode", inEditMode, item.Uid);
      setEditMode([...inEditMode, item.Uid]);
    }
  };

  const deleteFromEmptyObj = (index: number) => {
    const newEmptyObj = emptyObj.filter((obj) => obj != index);
    setEmptyObj(newEmptyObj);
  };

  useEffect(() => {
    // console.log("Edit Mode change hua", inEditMode)
    if (inEditMode.length > 0) {
      let check = false;
      inEditMode.map((id: string) => {
        if (id == item.Uid) {
          setEditTab(false);
          check = true;
        }
      });

      if (!check) {
        setEditTab(true);
      }
    } else {
      setEditTab(true);
    }
    // console.log("Edit Mode change hua", inEditMode, item.tileId, editTab)
  }, [inEditMode]);

  useEffect(() => {
    if (item.tileText === "" || item.powerBiLink === "") {
      //add item.Uid if not already present
      if (inEditMode.indexOf(item.Uid) === -1) {
        setEditMode([...inEditMode, item.Uid]);
      }
    } else if (inEditMode.length > 0) {
      inEditMode.map((id: string) => {
        if (id == item.Uid) setEditTab(false);
      });
    }
    console.log("item change hua", index, item.Uid, inEditMode, item);
  }, [item]);

  return (
    <tr className="navListTableRow">
      <td className="horizontalListTableRowTileText">
        <input
          className={`${editTab ? "inputStyle" : "inputStyleEdit"}`}
          required
          name="tileText"
          type="text"
          value={item.tileText}
          disabled={editTab}
          onChange={(e) => handleChangeTileText(e, index)}
        />
      </td>
      <td className="horizontalListTableRowLink">
        <input
          name="reportUrl"
          type="text"
          required
          value={item.powerBiLink}
          disabled={editTab}
          onChange={(e) => handleChangeReportUrl(e, index)}
          style={{ width: "100%" }}
          className={`${editTab ? "inputStyle" : "inputStyleEdit"}`}
        />
      </td>
      <td className="horizontalListTableRowActions">
        <div className="actions">
          <Button 
            onClick={() => {
              setEditModeTab("horizontalList");
              addNewTab(index);
            }}
            title="Add new item below this"
            icon={< AddSquare16Regular />} 
            appearance="subtle"
            as="a"
          > Add </Button>
          <Button 
            onClick={() => {
              setEditModeTab("horizontalList");
              setInEditMode();
            }}
            title="Edit this item"
            icon={<  Edit16Regular />} 
            appearance="subtle"
            as="a"
          > Edit </Button>
          <Button 
            onClick={() => {
              setEditModeTab("horizontalList");
              deleteFromEmptyObj(index);
              handleDeleteTab(item.tileId);
            }}
            title="Delete this item"
            icon={<Delete16Regular />} 
            appearance="subtle"
            as="a"
          > Delete </Button>
        </div>
      </td>
    </tr>
  );
};

export default AdminItem;
