import React,{useState, useEffect} from 'react'
import { Constants } from './helpers/Constants'
import { IPaginationConfiguration } from './Interfaces/Interfaces'
import axios from 'axios'
import protectedAxiosInstance from "./helpers/api";

const DataRefresh = () => {
    const pageSize = 5;
    const [showFilter, setShowFilter] = useState<string>("")
    const [gridDetails, setGridDetails] = useState<any[]>([])
    const [paginatedData, setPaginatedData] = useState<any[]>([])
    const [paginationConfig, setPaginationConfig] = useState<IPaginationConfiguration>()
    const [jumpToPage, setJumpToPage] = useState<number>(1)
    const [isGridLoading, setIsGridLoading] = useState<Boolean>(true)
    const [DataRefresh, setDataRefresh] = useState<Boolean>(false);

    const toggleOptions = () => {
        if (showFilter !== Constants.REFRESH_DETAILS_POPUP_ID)
            setShowFilter(Constants.REFRESH_DETAILS_POPUP_ID)
        else
            setShowFilter("")
    }

    const toggleSubOptions = () => {
        if (showFilter !== Constants.SUB_FUNCTIONAL_AREA_POPUP)
            setShowFilter(Constants.SUB_FUNCTIONAL_AREA_POPUP)
        else
            setShowFilter("")
    }

    const formatAndTrimData = (content: string, len: number): string => {
        let formattedData = "";
        if (content.length > len) {
            formattedData = content.slice(0, len - 3) + "...";
        } else {
            formattedData = content;
        }
        return formattedData;
    }

    const formatDate = (inputDate: string) => {
        const date = new Date(inputDate);
    
        // Get month, day, year
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();
    
        // Get hours and minutes
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
    
        // Construct formatted date string
        const formattedDate = `${month}/${day}/${year} ${hours}:${minutes} ${date.getHours() >= 12 ? "PM" : "AM"}`;
    
        return formattedDate;
    }


    const getPageConfig = (totalItems: number, currentPage: number, pageSize: number = 10): IPaginationConfiguration => {
        const paginationSize = Constants.PAGINATION_SIZE_DEFAULT;

        // Default to first page
        let newCurrentPage = currentPage || 1;

        // Default page size is 10
        pageSize = pageSize || 10;

        // Calculate total pages
        const totalPages = Math.ceil(totalItems / pageSize);

        let startPage: any, endPage;
        if (totalPages <= paginationSize) {
            // Less than 5 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // More than 5 total pages so calculate start and end pages
            if (newCurrentPage <= Math.floor(paginationSize / 2) + 1) {
                startPage = 1;
                endPage = paginationSize;
            } else if (newCurrentPage + Math.ceil(paginationSize / 2) - 1 >= totalPages) {
                startPage = totalPages - paginationSize + 1;
                endPage = totalPages;
            } else {
                startPage = newCurrentPage - Math.ceil(paginationSize / 2) + 1;
                endPage = newCurrentPage + Math.floor(paginationSize / 2);
            }
        }

        // Calculate start and end item indexes
        const startIndex = (newCurrentPage - 1) * pageSize;
        const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // Create an array of pages
        const pages = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

        // const totalPagesFormatted = Common.FormatNumber(totalPages, 3);

        // Return pagination config object
        return {
            totalItems,
            currentPage: newCurrentPage,
            pageSize,
            totalPages,
            totalPagesFormatted: totalPages.toString(),
            startPage,
            endPage,
            startIndex,
            endIndex,
            pages,
        };
    }

    const setPage = (page: number, tmpGridDetails?: any[]) => {
        if (!tmpGridDetails)
            tmpGridDetails = gridDetails
        if (page < 1 || page > Math.ceil(tmpGridDetails.length / pageSize)) {
            setPaginatedData(tmpGridDetails)
            return;
        }
        //br get pager object from common.ts
        const tmppaginationConfig = getPageConfig(tmpGridDetails.length, page, pageSize);
        setPaginationConfig(tmppaginationConfig)

        // get current page of items
        const tmpPaginatedData = tmpGridDetails!.slice(tmppaginationConfig.startIndex, tmppaginationConfig.endIndex + 1)
        // this.paginatedData = ;
        setPaginatedData(tmpPaginatedData)
        ////console.log("tmpPaginatedData", tmpPaginatedData)
    }

    const LoadDataRefreshData = async () => {
        try {
            const response = await protectedAxiosInstance.get(`/api/Service/GetRefreshDetails`, {
                withCredentials: true,
            })

            const tmpgridDetails = typeof response.data === "string" ? JSON.parse(response.data) : response.data
            // const tmpGridDetails = data
            setGridDetails(tmpgridDetails.Table)
            setPage(1,tmpgridDetails.Table)
            // setGridDetails(tmpGridDetails)
            // setPage(1,tmpGridDetails)
            setIsGridLoading(false)

        } catch (error) {
            console.error("Error while fetcing Data", error)
        }
    }

    useEffect(() => {
        setIsGridLoading(true)
        LoadDataRefreshData()
    }, [])
  return <div className="DataRefresh" title="Refresh Details">
  <div className="pull-left refreshIcon refreshWaitingIcon RefreshDetailsGreenAlertShow"
      onClick={() => setDataRefresh(!DataRefresh)}></div>
  {
      DataRefresh && <div>
      <div className="Overlay"></div>
      <div className="OverlayFrontDropOverView" >
          <div className="RefreshDetailsPopupContainer">
              <div >
                  <div className="col-lg-12 pull-left RefreshDetailsPopupHead">
                      <span>Data Refresh Details</span>
                      <i className="ms-Icon ms-Icon--Cancel" aria-hidden="true"  onClick={() => setDataRefresh(!DataRefresh)}></i>
                  </div>
                  <div className="FunctionalAreaSelect">
                      <span className="FunctionAreaText">Functional Area: </span>
                      <button id="refreshDetailsBtn" className="popup" onClick={() => toggleOptions()}>All</button>
                      <div className="dropdownimage popup" onClick={() => toggleOptions()}></div>
                      {showFilter == "RefreshDetailsPopup" && <div className="RefreshDetailsOptions popup">
                          <div className="OptionType">
                              <span></span>
                          </div>
                      </div>}
                  </div>
                  <div className="SubFunctionalAreaSelect">
                      <span className="FunctionAreaText">Sub Functional Area: </span>
                      <button id="refreshDetailsBtn" className="popup" onClick={() => toggleSubOptions()}>All</button>
                      <div className="dropdownimage popup" onClick={() => toggleSubOptions()}></div>
                      {showFilter === 'SubFunctionaAreaPopup' && <div className="SubRefreshDetailsOptions popup"></div>}
                  </div>
                  <div className="col-lg-12 pull-left RefreshDetailsPopupGridSection">
                      {isGridLoading ? <div className="LoadingImage ImageLoad"></div>
                      : <div className="RefreshDetailsPopupGridContainer">
    
    
                          <div className="pull-left PopupGrid">
                              <div className="PopupGridHeader">
                                  <div className="PopupGridRow">
                                      <div className="PopupCell SourceName">
                                          <span>Source</span>
                                      </div>
                                      <div className="PopupCell RefreshFrequency">
                                          <span>Refresh Frequency</span>
                                      </div>
                                      <div className="PopupCell DataLatency">
                                          <span>Data Latency</span>
                                      </div>
                                      <div className="PopupCell SourceLastRefreshDate">
                                          <span>Last Refresh Date</span>
                                      </div>
                                      <div className="PopupCell DataThrough">
                                          <span>Data Through</span>
                                      </div>
                                      <div className="PopupCell SLA">
                                          <span>SLA</span>
                                      </div>
                                      <div className="PopupCell NotesnAlerts">
                                          <span>Notes</span>
                                      </div>
                                  </div>
                              </div>
                              <div className="PopupGridBody">
                                  {paginatedData && paginatedData.map((row, index) => (
                                      <div key={index} className="PopupGridRow">
                                          <div className="PopupCell SourceName">
                                              <span title={row.Source}>{formatAndTrimData(row.Source, 56)}</span>
                                          </div>
                                          <div className="PopupCell RefreshFrequency">
                                              <span>{row.RefreshFrequency}</span>
                                          </div>
                                          <div className="PopupCell DataLatency">
                                              <span>{row.DataLatency}</span>
                                          </div>
                                          <div className="PopupCell SourceLastRefreshDate">
                                              <span>{formatDate(row.SourceLastRefreshDate)}</span>
                                          </div>
                                          <div className="PopupCell DataThrough">
                                              <span>{formatDate(row["Data through"])}</span>
                                          </div>
                                          <div className="PopupCell SLA SLAAlign">
                                              {row.SelectType === 1.0 && (
                                                  <span className="refreshIcon refreshIconPopup refreshWaitingIcon RefreshDetailsGreenAlertShow"></span>
                                              )}
                                              {row.SelectType === 2.0 && (
                                                  <span className="refreshIcon refreshIconPopup refreshWaitingIcon RefreshDetailsRedAlertShow"></span>
                                              )}
                                              {row.SelectType === 3.0 && (
                                                  <span className="refreshIcon refreshIconPopup refreshWaitingIcon RefreshDetailsOrangeAlertShow"></span>
                                              )}
                                              <span>{row.SLA}</span>
                                          </div>
                                          <div className="PopupCell NotesnAlerts">
                                              <span title={row.NotesAndAlerts}>{row.NotesAndAlerts && formatAndTrimData(row.NotesAndAlerts, 117)}</span>
                                          </div>
                                      </div>
                                  ))}
                              </div>
                          </div>
    
                      </div>}
                      <div className="pull-left PaginationSection" id="PaginationSection">
                          <div className="pull-left PaginationRightSection">
                              <span
                                  className={`pull-left PaginationPagePrevious ${paginationConfig?.currentPage === 1 ? "PaginationDisabled" : ""
                                      }`}
                                  onClick={() => setPage(paginationConfig?.currentPage! - 1)}
                              >
                                  <span className="ImageSprite ImagePaginationPrevious"></span>
                              </span>
                              {Array.from({ length: paginationConfig?.totalPages! }, (_, index) => index + 1).map(
                                  (page) => (
                                      <span
                                          key={page}
                                          className={`pull-left PaginationPageBar ${paginationConfig?.currentPage === page ? "PaginationActivePage" : ""
                                              }`}
                                          onClick={() => setPage(page)}
                                      >
                                          <span>{page}</span>
                                      </span>
                                  )
                              )}
                              <span
                                  className={`pull-left PaginationPageNext ${paginationConfig?.currentPage === paginationConfig?.totalPages ? "PaginationDisabled" : ""
                                      }`}
                                  onClick={() => setPage(paginationConfig?.currentPage! + 1)}
                              >
                                  <span className="ImageSprite ImagePaginationNext"></span>
                              </span>
                              <div className="NavigateToPageTextBox">
                                  Jump to{" "}
                                  <input
                                      type="number"
                                      min={1}
                                      max={paginationConfig?.totalPages}
                                      value={jumpToPage}
                                      onChange={(e) => setJumpToPage(parseInt(e.target.value))}
                                      onKeyDown={(e) => {
                                          if (e.key === "Enter") {
                                              setPage(jumpToPage);
                                          }
                                      }}
                                  />{' '}
                                  <span>of {paginationConfig?.totalPages}</span>
                              </div>
                          </div>
                      </div>
    
                      <div className="popCloseButton pull-right">
                          <button type="button" className="pull-right" id="closeButton" onClick={() => setDataRefresh(!DataRefresh)}>Close</button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
  }
</div> 
}

export default DataRefresh