import React from 'react'
import '../Styles/dialogBox.css'
import LoadingGif from '../Images/Loading.gif'
type SaveAsReportInputBoxProps = {
    onSaveAs: (reportName: string) => Promise<void>,
    onCancel: () => void
}
function SaveAsReportInputBox({ onSaveAs, onCancel }: SaveAsReportInputBoxProps) {
    const [reportName, setReportName] = React.useState<string>('')
    const [showLoader, setShowLoader] = React.useState<boolean>(false)
    return (
        <div>
            <div className="unsaved-changes-dialog">
                <div className="unsaved-changes-dialog__content">
                    <div style={{ fontFamily: "'Segoe UI Semilight',wf_segoe-ui_semilight,helvetica,arial,sans-serif", fontSize: "24px" }}>
                        Save Report
                    </div>
                    <input
                        type="text"
                        placeholder="Enter report name"
                        value={reportName}
                        onChange={(e) => setReportName(e.target.value)}
                    />
                    <div className="unsaved-changes-dialog__buttons">
                        <button
                            className="save savec"
                            onClick={() => {
                                // Logic to save changes
                                console.log('Save changes')
                                setShowLoader(true)
                                onSaveAs(reportName)
                            }}
                        >
                            Save
                        </button>
                        <button
                            className='cancel save'
                            onClick={() => {
                                console.log('Close dialog')
                                onCancel()
                            }}
                        >Cancel</button>
                    </div>
                </div>
            </div>
            {/* Loading Spinner */}
        </div>
    )
}

export default SaveAsReportInputBox