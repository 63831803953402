import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import {
  autoUpdate,
  size,
  flip,
  useId,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  FloatingFocusManager,
  FloatingPortal,
} from "@floating-ui/react";
import { useNavigate } from "react-router-dom";


interface report {
  TileText: string;
  Description: string;
  ReportURL: string;
  IsPowerBI: string;
  ContactEmail: string;
  Persona: string;
}

interface props {
  reportCatalog: report[];
}

interface ItemProps {
  children: React.ReactNode;
  active: boolean;
}

const Item = forwardRef<
  HTMLDivElement,
  ItemProps & React.HTMLProps<HTMLDivElement>
>(({ children, active, ...rest }, ref) => {
  const id = useId();
  return (
    <div
      ref={ref}
      role="option"
      id={id}
      aria-selected={active}
      {...rest}
      style={{
        background: active ? "lightblue" : "none",
        padding: 4,
        cursor: "default",
        ...rest.style,
      }}
    >
      {children}
    </div>
  );
});

const SearchBar: React.FC<props> = ({ reportCatalog }) => {

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const listRef = useRef<Array<HTMLElement | null>>([]);
  const navigate=useNavigate();

  const { refs, floatingStyles, context } = useFloating<HTMLInputElement>({
    whileElementsMounted: autoUpdate,
    open,
    onOpenChange: setOpen,
    middleware: [
      flip({ padding: 10 }),
      size({
        apply({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${availableHeight < 200 ? availableHeight : 200}px`,
            height: "200px",
          });
        },
        padding: 10,
      }),
    ],
  });

  const role = useRole(context, { role: "listbox" });
  const dismiss = useDismiss(context);
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    virtual: true,
    loop: true,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [role, dismiss, listNav]
  );

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    setInputValue(value);

    if (value) {
      setOpen(true);
      setActiveIndex(0);
    } else {
      setOpen(false);
    }
  }
  const [items, setItems] = useState<report[]>([]);

  useEffect(() => {
    const debounce = setTimeout(() => {
      localSearch();
    }, 200);
    return () => clearTimeout(debounce);
  }, [inputValue]);

  function openReport(report: report) {
    if (report.ReportURL === "#") {
      return
    }
    
    if (report.IsPowerBI === "Excel Pivot") {
      window.location.href = window.location.origin + "/api/download/ReportLibraryFile?filename=" + report.ReportURL;
    }

    const queryParam = report.ReportURL.split("=")[1];
    // window.location.href = window.location.href + "?parent=" + queryParam;
    navigate(`/view?parent=${queryParam}`);
  }

  const localSearch = () => {
    if (inputValue.length > 0) {
      const searchResults: report[] = reportCatalog.filter(
        (report: {
          TileText: string;
          Persona: string;
          ContactEmail: string;
          ReportURL: string;
        }) => {
          const { TileText, Persona, ContactEmail, ReportURL } = report;
          const searchText = inputValue.toLowerCase();
          return (
            TileText.toLowerCase().includes(searchText) ||
            Persona.toLowerCase().includes(searchText) ||
            ContactEmail.toLowerCase().includes(searchText) ||
            ReportURL.toLowerCase().includes(searchText)
          );
        }
      );
      setItems(searchResults);
    }
  };

  return (
    <>
      <Form.Control style={ {border: "1px inset"}}
        {...getReferenceProps({
          ref: refs.setReference,
          onChange,
          value: inputValue,
          placeholder: "Search Reports by Name, Tags, Contacts, or URL",
          "aria-autocomplete": "list",
          onKeyDown(event) {
            if (
              event.key === "Enter" &&
              activeIndex != null &&
              items[activeIndex]
            ) {
              openReport(items[activeIndex]);
              setActiveIndex(null);
              setOpen(false);
            }
          },
        })}
      />
      <FloatingPortal>
        {open && (
          <FloatingFocusManager
            context={context}
            initialFocus={-1}
            visuallyHiddenDismiss
          >
            <div
              className="this"
              {...getFloatingProps({
                ref: refs.setFloating,
                style: {
                  ...floatingStyles,
                  background: "white",
                  color: "black",
                  overflowY: "auto",
                  border: "1px solid #ccc",
                  borderRadius: "5px"
                },
              })}
            >
              {items.map((item, index) => (
                <Item
                  {...getItemProps({
                    key: index,
                    ref(node) {
                      listRef.current[index] = node;
                    },
                    onClick() {
                      openReport(item);
                      setOpen(false);
                      refs.domReference.current?.focus();
                    },
                  })}
                  active={activeIndex === index}
                >
                  {item.TileText}
                </Item>
              ))}
              {items.length === 0 && (
                <div className="ErrorData">
                  <div>:(</div>
                  <div>No Data</div>
                </div>

              )}
            </div>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </>
  );
};

export default SearchBar;