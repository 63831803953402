import '../Styles/header.css'
import '../Styles/Hub.css'
import '../Styles/popup.css'
import RightSection from './RightSection'
import LeftSection from './LeftSection'
import { useEffect, useState } from 'react'


export default function Header() {
    const [style, setStyle] = useState({})

    const setBackgroundColour = () => {
        const url = window.location.host;
        if (url.includes("localhost")) {
            setStyle({ backgroundColor: "#3D6FB2" })
        }
        if (url.includes("dev")) {
            setStyle({ backgroundColor: "#3D6FB2" })
        }
        if (url.includes("uat")) {
            setStyle({ backgroundColor: "#355F62" })
        }
        if (url.includes("prod") || url.includes("ftbi.microsoft.com")) {
            setStyle({ backgroundColor: "#000" })
        }
    }

      useEffect(() => {
        setBackgroundColour()
      }, [])

    return (
        <>
            <div className="MasterPageSection">
                <div className="fxm">
                    <div style={style}  className="navbar uh-navbar-header navbar-fixed-top network-nav-universal max-width navbarbg" role="navigation">

                        <div className="container-rf" id='navbarContainer'>
                            <LeftSection/>
                            <div>
                            <RightSection/> 
                            </div>
                                                   
                        </div>
                    </div>
                </div>
                <div className="MasterPageSectionMobile">
                    <div id="toggle" className="mobile-collapse-icon" onClick={() => { /* Implement showHideMobileNavbar */ }}>
                        <img className="mobile-navbar-icon-col" src="~/Content/Images/collapse_mobile.svg" alt="collapse" />
                    </div>
                    <div className="mobile-filter-icon" onClick={() => { /* Implement toggleFilterPopUp */ }}>
                        <img className="mobile-navbar-icon-fil" src="~/Content/Images/filter_mobile.svg" alt="filter" /> Filters
                    </div>
                    <div className="mobile-refresh-icon">
                        <img className="mobile-navbar-icon-ref" src="~/Content/Images/refresh_mobile.svg" alt="refresh" /> Refresh
                    </div>
                </div>
            </div>
        </>
    );
}