import { useEffect, useState } from "react";
import axios from "axios";
import { ILatestNewLetterLink } from "./Interfaces/Interfaces";
import { Constants } from "./helpers/Constants";
import April2023 from "../Images/midasImages/April2023.png";
import April2024 from "../Images/midasImages/April2024.png";
import Feb2024 from "../Images/midasImages/Feb2024.png";
import July2023 from "../Images/midasImages/July2023.png";
import March2023 from "../Images/midasImages/March2023.png";
import March2024 from "../Images/midasImages/March2024.png";
import May2023 from "../Images/midasImages/May2023.png";
import November2023 from "../Images/midasImages/November2023.png";
import September2023 from "../Images/midasImages/Sept2023.png";
import MIDAs from "../Images/MIDAsImage.jpg"; 
import protectedAxiosInstance from "./helpers/api";






const imageMap: { [key: string]: string } = {
  "April-2023": April2023,
  "April-2024": April2024,
  "February-2024": Feb2024,
  "July-2023": July2023,
  "March-2023": March2023,
  "March-2024": March2024,
  "May-2023": May2023,
  "November-2023": November2023,
  "September-2023": September2023,
};

const LatestNewsletter = () => {
  const [newslettersLinks, setNewslettersLinks] = useState<ILatestNewLetterLink[]>([]);
  const [filteredNewsletters, setFilteredNewsletters] = useState<ILatestNewLetterLink[]>([]);
  const [filterByYear, setFilterByYear] = useState<string>("Filter by Year");
  const [filterByTitle, setFilterByTitle] = useState<string>("Filter by Title");
  const [searchKey, setSearchKey] = useState<string>("");

  const getNewsLetterData = async () => {
    try {
      const url = window.location.origin + "/api/Service/GetDataWithConfiguration";
      const response = await protectedAxiosInstance.post(
        url,
        {
          configID: Constants.LANDING_PAGE_CONFIG_ID,
        },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.Table4 && response.data.Table4.length > 0) {
        const lettersLinksData = response.data.Table4;
        const newslettersLinksArray = lettersLinksData.map((item: any) => ({
          Title: item.Title,
          Image: `${item.LongDate.split(' ')[0]}-${item.LongDate.split(' ')[2]}`, // Dynamically generate the key
          URL: item.URL,
          Date: item.NewsletterDate,
          Description: item.Description,
        }));
        setNewslettersLinks(newslettersLinksArray);
        setFilteredNewsletters(newslettersLinksArray);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getNewsLetterData();
  }, []);

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilterByYear(event.target.value);
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilterByTitle(event.target.value);
  };

  const localSearch = (searchKey: string) => {
    if (searchKey === "") {
      setFilteredNewsletters(newslettersLinks);
      return;
    }
    const filtered = newslettersLinks.filter(
      (item) =>
        item.Title.toLowerCase().includes(searchKey.toLowerCase()) ||
        item.Description.toLowerCase().includes(searchKey.toLowerCase())
    );
    setFilteredNewsletters(filtered);
  };

  const filteredNewslettersByFilters = filteredNewsletters.filter((item) => {
    const matchYear =
      filterByYear === "All" ||
      filterByYear === "Filter by Year" ||
      item.Date.includes(filterByYear);
    const matchTitle =
      filterByTitle === "All" ||
      filterByTitle === "Filter by Title" ||
      item.Title === filterByTitle;
    return matchYear && matchTitle;
  });

  return (
    <div className="NotificationSection2">
      <div className="tileHeaderExclusive">
        <h2 className="tileHeaderText ">Newsletters Archive</h2>
      </div>
      <div className="input-container d-flex align-items-center inCont">
        <input
          type="text"
          className="form-control search-input"
          placeholder="Search Newsletters by Title or Description"
          onChange={(e) => localSearch(e.target.value)}
        />
        <span
          className="ms-Icon ms-Icon--Search searchMagnifierIcon search-icon"
          title="Search Reports"
        ></span>
      </div>
      <div className="FilterContainer">
        <div className="byYear">
          <select
            className="yearSelect"
            value={filterByYear}
            onChange={handleYearChange}
			style={filterByYear === "Filter by Year" ? { color: "gray" }: { color: "black" }}
          >
            <option value="Filter by Year" disabled style={{color:"gray"}} >Filter by Year</option>
            <option value="All">All</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
          </select>
          <span></span>
        </div>
        <div className="byTitle">
          <select
            className="titleSelect"
            value={filterByTitle}
            onChange={handleTitleChange}
            style={filterByTitle === "Filter by Title" ? {color:"gray"} : {color:"black"}}
		    >
              <option value="Filter by Title" disabled>Filter by Title</option>
              <option value="All">All</option>
            <option value="Business Analytics Updates">
              Business Analytics Updates
            </option>
            <option value="MIDAS Updates">MIDAS Updates</option>
          </select>
        </div>
      </div>
      <div className="NewsAnnoumcementFullContainer">
        {filteredNewslettersByFilters.map(
          (item: ILatestNewLetterLink, index: number) => (
            <div className="NotificationGridItem" key={index}>
              <h6 className="NotificationGridDateItem">{item.Date}</h6>
              <div className="NotificationDiscriptionItem">
                <h6 className="NotificationGridTitle newsletterheading">
                  {item.Title}
                  <span
                    className="ms-Icon ms-Icon--NavigateExternalInline openLinkIcon" style={{padding:"7px",fontSize:"20px"}}
                    onClick={() => window.open(item.URL)}
                    title="Open Link"
                  ></span>
                </h6>{" "}
                <p className="NotifcationGridDiscription">{item.Description}</p>
              </div>
              <div className="NotificationGridImgItem">
                <img
                  className="NotificationImg"
                  src={imageMap[item.Image] || MIDAs} // Default to MIDAs if image not found
                  alt={item.Title}
                />
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default LatestNewsletter;