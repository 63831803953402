import { CopilotMessage as CopilotMessage, OutputCard, PromptStarter } from "@fluentui-copilot/react-copilot";
import { Avatar, Body1 } from "@fluentui/react-components";
import { ZeroPromptProps } from "./Common";
import { AppFolder16Regular, List20Regular as ListIcon, PersonQuestionMark20Regular as PeopeAskIcon, Lightbulb20Regular as LightbulbIcon } from "@fluentui/react-icons";

export const ZeroPrompt = (props: ZeroPromptProps) => {
  const { prompts, onPromptSelected } = props;
  const getIcon = (iconName: string) => {
    switch (iconName){
      case "ListIcon": {
        return <ListIcon color="dimgrey"/>;
      }
      case "PeopeAskIcon": {
        return <PeopeAskIcon color="dimgrey"/>;
      }
      case "LightbulbIcon": {
        return <LightbulbIcon color="dimgrey"/>;
      }
      default: {
        return <AppFolder16Regular color="dimgrey"/>;
      }
    }
  };
  
  return (
    <CopilotMessage
      // disclaimer=""
      // avatar={<Avatar
      //   size={24}
      //   image={{
      //     src: "https://res-2-sdf.cdn.office.net/files/fabric-cdn-prod_20240411.001/assets/brand-icons/product/svg/copilot_24x1.svg",
      //   }} />}
      // name="Copilot"
    >
      <Body1>Hi {JSON.parse(localStorage.getItem("user") ?? '{"givenName": "User"}').givenName},</Body1>
      <Body1 style={{ marginTop: "8px" }}>
        I can summarize insights and answer questions about the data shown in
        the report.
      </Body1>
      <Body1 style={{ marginTop: "8px" }}> Here are some suggestions: </Body1>
      {prompts.map((prompt, i) => (
        <PromptStarter style={{ marginTop: "8px" }}
          key={`prompt-${i}`}
          icon={getIcon(prompt.icon)}
          category={prompt.category}
          prompt={<Body1>{prompt.prompt}</Body1>}
          badge={prompt.badge}
          onClick={() => onPromptSelected?.(prompt)} />
      ))}
    </CopilotMessage>
  );
};
