import React, { useEffect, useState } from 'react';
import {
  DetailsList,
  IColumn,
  buildColumns,
  SelectionMode,
} from '@fluentui/react/lib/DetailsList';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import protectedAxiosInstance from './helpers/api';

// Define the types for the data model
interface RelationshipDataModel {
  fromTable: string;
  toTable: string;
  fromColumn: string;
  toColumn: string;
  relationshipCardinality: string;
}

// Props for the DataModel component
interface DataModelProps {
  reportId: string;
  workspaceId: string;
}

// Utility function for sorting
function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  const key = columnKey as keyof T;
  return items.slice().sort((a, b) => {
    if (a[key] < b[key]) return isSortedDescending ? 1 : -1;
    if (a[key] > b[key]) return isSortedDescending ? -1 : 1;
    return 0;
  });
}

// DataModel Component
const DataModel: React.FC<DataModelProps> = ({ reportId, workspaceId }) => {
  const [relationshipData, setRelationshipData] = useState<RelationshipDataModel[]>([]);
  const [sortedData, setSortedData] = useState<RelationshipDataModel[]>([]);
  const [columns, setColumns] = useState<IColumn[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Fetch relationship data
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await protectedAxiosInstance.get('/api/service/GetDatasetRelationship', {
          params: { reportId, workspaceId },
          withCredentials: true,
        });
        setRelationshipData(response.data.relationshipList);
        setSortedData(response.data.relationshipList);
      } catch (err) {
        setError('Data Model Not Available');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [reportId, workspaceId]);

  
  useEffect(() => {
    const formatHeaderName = (header: string): string => {
      return header
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .toUpperCase(); 
    };
  
    const columns: IColumn[] = buildColumns(relationshipData, false).map(column => ({
      ...column,
      name: formatHeaderName(column.fieldName || ''), 
      onColumnClick,
      showSortIconWhenUnsorted: true, 
    }));
  
    setColumns(columns);
  }, [relationshipData]);

  // Handle sorting on column click
  const onColumnClick = (event: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const isSortedDescending = !column.isSortedDescending;
    const sortedItems = _copyAndSort(relationshipData, column.fieldName!, isSortedDescending);

    setSortedData(sortedItems);
    setColumns(prevColumns =>
      prevColumns.map(col => ({
        ...col,
        isSorted: col.key === column.key,
        isSortedDescending: col.key === column.key ? isSortedDescending : false,
      })),
    );
  };

  
  const containerStyle = mergeStyles({
    padding: '20px',
  });

  const errorStyle = mergeStyles({
    color: 'black',
    fontWeight: 'bold',
  });


  const tableContainerStyle = mergeStyles({
    maxHeight: '700px', // allow y axis scroll
    overflowY: 'auto', 
   
  });

  return (
    <div className={containerStyle}>
      <h2 >Data Model Relationships</h2>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className={errorStyle}>{error}</p>
      ) : (
        <div className={tableContainerStyle}>
          <DetailsList
            items={sortedData}
            columns={columns}
            setKey="set"
            isHeaderVisible={true}
            selectionMode={SelectionMode.none} 
          />
        </div>
      )}
    </div>
  );
};

export default DataModel;
