import React, { useState } from 'react'
import Loading from '../Images/Loading.gif'
import '../Styles/dialogBox.css'



interface props {
    message: string;
    buttonText: string;
    saveChanges: () => Promise<void>;
    setShowDialogue: (showDialogue: boolean) => void;
    onCancel: () => void;
}
 
 
const AdminDialogueBox: React.FC<props> = ({ message,saveChanges, onCancel, setShowDialogue, buttonText}) => {
    const [loading, setLoading] = useState<boolean>(false)
 
    const handleSave = async () => {
        setLoading(true)
        console.log('Save changes')
        await saveChanges()
        reloadPage()
    }
    

    const handleClose = () => {
        console.log('Close dialog')
        onCancel()

        setShowDialogue(false)
        // reloadPage()
    }

    const reloadPage = () => {
        setTimeout(() => {
            // setLoading(false)
            window.location.reload()
        }, 2000);
    }

    return (
        <div className="unsaved-changes-dialog">
            {!loading && <div className="unsaved-changes-dialog__content" >
                <i className="ms-Icon ms-Icon--Cancel cancle" style={{cursor: "pointer"}} aria-hidden="true" onClick={() => setShowDialogue(false)}></i>
                <div className='Dialogue_text'
                >
                    <div style={{ fontFamily: "'Segoe UI Semilight',wf_segoe-ui_semilight,helvetica,arial,sans-serif", fontSize: "24px" }}>
                        {message}
                    </div>
                    {/* <div style={{fontFamily:"'Segoe UI Semilight',wf_segoe-ui_semilight,helvetica,arial,sans-serif",color:"#333333", fontSize:"14px", marginTop:"20px",marginBottom:"20px"}}>
                Would you like to save changes to this report?
                </div> */}
                </div>
                <div className="unsaved-changes-dialog__buttons">
                    <button className="save " style={{backgroundColor: `${buttonText === "Save" ? "#0078d4" : "#e11616"}`, color:"white"}} onClick={handleSave}>{buttonText}</button>
                    <button className="save" onClick={handleClose}>Discard</button>
                </div>

            </div>}
            {loading && /* {loading && <div className="unsaved-changes-dialog__content" >
            <img id="errorLoadingImg" src={Loading} />
                </div> */
                <img id="errorLoadingImg" src={Loading} />
            }
        </div>
    )
}

export default AdminDialogueBox