import React, { useEffect, useRef, useState } from 'react'
import '../Styles/Admin.css'
import AdminHorizontalTile from './AdminHorizontalTile'
import AdminNavList from './AdminNavList'
import AdminDialogueBox from './AdminDialogueBox'
import protectedAxiosInstance from './helpers/api'
import AdminReportCatalog from './AdminReportCatalog'

interface props {
    adminRef: React.RefObject<HTMLDivElement>;
}

const Admin:React.FC<props> = ({adminRef}) => {
    const [modifyHorizontalList, setModifyHorizontalList] = useState<boolean>(false)
    const [modifyNavList, setModifyNavList] = useState<boolean>(false)
    const [active, setActive] = useState<string>("")
    const [showDialogue, setShowDialogue] = useState<boolean>(false)
    const [editMode, setEditMode] = useState<string>("")
    const [navList, setNavList] = useState({})
    const [horizontalNavList, setHorizontalNavList] = useState({})
    const [modifyReportCatalog, setModifyReportCatalog] = useState<boolean>(false)

    const switchTabs = (tab: string) => {
        if(tab === "navList") {
            setActive("1");
            setModifyNavList(true);
            setModifyHorizontalList(false)
            setModifyReportCatalog(false)   
        } else if(tab === "horizontalList") {
            setActive("2");
            setModifyHorizontalList(true);
            setModifyNavList(false)
            setModifyReportCatalog(false)   
        }  else if(tab === "reportCatalog") {
            setActive("3");
            setModifyNavList(false);
            setModifyHorizontalList(false)
            setModifyReportCatalog(true)   
        }
    }


    const checkForUnsavedChanges = (tab: string) => {
        if (editMode!="" && editMode != tab) {
            setShowDialogue(true)
        } else {
            if(tab === "navList") {
                setActive("1");
                setModifyNavList(true);
                setModifyHorizontalList(false)
                setModifyReportCatalog(false)   
            } else if(tab === "horizontalList") {
                setActive("2");
                setModifyHorizontalList(true);
                setModifyNavList(false)
                setModifyReportCatalog(false)   
            }  else if(tab === "reportCatalog") {
                setActive("3");
                setModifyNavList(false);
                setModifyHorizontalList(false)
                setModifyReportCatalog(true)   
            }
        }
    }

    const onContinue = () => {
        const tab = editMode
        console.log("Tab", tab)
        switchTabs(tab)
        setEditMode("")
        setShowDialogue(false)
    }

    const onCancel = () => {
        setShowDialogue(false)
    }

    const fetchNavList = async () => {
        try {
            const response = await protectedAxiosInstance.get("/api/service/GetNavlist", {
                withCredentials: true,
            });
            setNavList(response.data.Table[0].ListData)
        } catch (error) {
            console.error("Error", error);
        }

        try{        
            const response = await protectedAxiosInstance.get("/api/service/GetHorizontalTileList", {
                withCredentials: true,
            });
            setHorizontalNavList(response.data.Table[0].ListData)
        } catch (error) {
            console.error("Error", error);
        }
    }
    
    useEffect(() => {
        fetchNavList();
      }, []);

    return (
        <div className="main_container">
            <h1>Admin Portal</h1>
            <div className="">
                <button style={{ color: active === "1" ? "white" : "black", backgroundColor: active === "1" ? "black" : "white" }} onClick={() => checkForUnsavedChanges("navList")}>Modify Nav Bar Items</button>
                <button style={{ color: active === "2" ? "white" : "black", backgroundColor: active === "2" ? "black" : "white" }} onClick={() => checkForUnsavedChanges("horizontalList")}>Modify Report Details</button>
                <button style={{ color: active === "3" ? "white" : "black", backgroundColor: active === "3" ? "black" : "white" }} onClick={() => checkForUnsavedChanges("reportCatalog")}>Modify Report Catalog</button>
            </div>
            {modifyHorizontalList && <AdminHorizontalTile editModeTab={editMode} setEditMode={(value: string) => setEditMode(value)} />}
            {modifyNavList && <AdminNavList editModeTab={editMode} setEditMode={(value: string) => setEditMode(value)} cachedNavList={navList} cachedHorizontalNavList={horizontalNavList} />}
            {modifyReportCatalog && <AdminReportCatalog editModeTab={editMode} setEditMode={(value: string) => setEditMode(value)} adminRef={adminRef}/>}
            {
                showDialogue && <div className="unsaved-changes-dialog">

                    <div className="unsaved-changes-dialog__content" >
                        <i className="ms-Icon ms-Icon--Cancel cancle" style={{ cursor: "pointer" }} aria-hidden="true" onClick={() => setShowDialogue(false)}></i>
                        <div className='Dialogue_text'
                        >
                            <div style={{ fontFamily: "'Segoe UI Semilight',wf_segoe-ui_semilight,helvetica,arial,sans-serif", fontSize: "24px" }}>
                                You have unsaved changes. Do you want to continue?
                            </div>
                        </div>
                        <div className="unsaved-changes-dialog__buttons">
                            <button className="save " style={{ backgroundColor: "#0078d4" , color: "white" }} onClick={onContinue}>Continue</button>
                            <button className="save" onClick={onCancel}>Cancel</button>
                        </div>

                    </div>

                </div>

            }

        </div>
    )
}

export default Admin