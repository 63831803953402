import React, { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import {
  TextField,
  PrimaryButton,
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
  IDetailsListStyles,
  IconButton,
  ConstrainMode,
} from "@fluentui/react";
import { Save16Regular, Dismiss16Regular, Dismiss24Regular } from "@fluentui/react-icons";
import { Button, Drawer, DrawerBody, DrawerHeader, DrawerHeaderTitle } from "@fluentui/react-components";
import protectedAxiosInstance from "./helpers/api";
import { Edit16Regular } from "@fluentui/react-icons";
import "../Styles/Admin.css";
import Loading from '../Images/Loading.gif'
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import VideoLinksDrawer from "./VideoLinksDrawer";
import { useNavigate } from "react-router-dom";
initializeIcons(); 

export interface ReportDetails {
  ID: number;
  TileText: string;
  Description: string;
  ReportURL: string;
  IsPowerBI: string;
  ContactEmail: string;
  Persona: string;
  Videos: Videos[];
}

export interface Videos {
  VideoID: number;
  VideoTitle: string; 
  VideoURL: string
}

export interface ReportDetailsMap {
  [key: string]: ReportDetails;
}

interface Props {
  editModeTab: string;
  setEditMode: (value: string) => void;
  adminRef: React.RefObject<HTMLDivElement>;
}

const AdminReportCatalog: React.FC<Props> = ({ editModeTab, setEditMode, adminRef }) => {
  
  const navigate = useNavigate();
  const [originalCatalog, setOriginalCatalog] = useState<ReportDetails[]>([]);        // orignal reference catalog, do not mutate
  const [displayedCatalog, setDisplayedCatalog] = useState<ReportDetails[]>([]);      // displayed catalog
  const [modifiedCatalogs, setModifiedCatalogs] = useState<ReportDetailsMap>({});     // json of all the modified reports with IDs as keys    
  const [searchTerm, setSearchTerm] = useState<string>("");                           
  const [isLoading, setIsLoading] = useState(true); // Loading state

  const loadData = async () => {
    try {
      const response: AxiosResponse = await protectedAxiosInstance.get(
        `${window.location.origin}/api/Service/GetReportLibraryConfiguration`,
        { withCredentials: true }
      );
      const responseData = response.data.Table as ReportDetails[];
      setOriginalCatalog(responseData);
      setDisplayedCatalog([...responseData]);
    } catch (error) {
      console.error("Error loading data", error);
    } finally {
      setIsLoading(false); // Set loading to false after data is fetched
    }
  };

  const handleEdit = (report: ReportDetails) => {
    setModifiedCatalogs((prevModifiedCatalogs: ReportDetailsMap) => ({
      ...prevModifiedCatalogs,
      [report.ID]: report,
    }));
  };

  const discardEdit = (id: number) => {
    const newModifiedCatalogs = { ...modifiedCatalogs };
    delete newModifiedCatalogs[id];
    setModifiedCatalogs(newModifiedCatalogs);
  }

  const handleSaveAllChanges = async () => {

    const changedData: ReportDetails[] = Object.values(modifiedCatalogs);

    try {
      const response = await protectedAxiosInstance.put(
        `${window.location.origin}/api/Service/PutReportLibraryConfiguration`,
        { ReportDetails: changedData },
        { withCredentials: true }
      );
      if (response.status === 200) {
        setModifiedCatalogs({}); // clear modified data
        setSearchTerm(""); // clear search term
        loadData(); // reload data
      }
    } catch (error) {
      setSearchTerm(""); // clear search term
      setModifiedCatalogs({}); // clear modified data
      console.error("Error saving data", error);
      console.log("Failed to save changes.");
    }
  };

  const handleCancelAllChanges = () => {
    setModifiedCatalogs({});
    setDisplayedCatalog(originalCatalog);
  }
  
  const searchCatalog = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    setDisplayedCatalog(
      originalCatalog.filter((report: ReportDetails) =>
        searchTerm.length === 0 ||
        report.TileText.toLowerCase().includes(searchTerm.toLowerCase()) ||
        report.Description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        report.Persona.toLowerCase().includes(searchTerm.toLowerCase()) ||
        report.ContactEmail.toLowerCase().includes(searchTerm.toLowerCase()) ||
        report.IsPowerBI.toLowerCase().includes(searchTerm.toLowerCase()) 
      )
    );
  }
  
  const getURL = (reportUrl: string): string => {
    let url = reportUrl;

    if (
      reportUrl.includes("ftbi.microsoft.com") ||
      reportUrl.includes("ftbiuat.microsoft.com")
    ) {
      let reportId = reportUrl.split("=")[1];
      let parent;
      if (reportId.includes("_")) {
        parent = reportId.split("_")[0];
        url = "/view?parent=" + parent + "&tileId=" + reportId;
      } else {
        url = "/view?parent=" + reportId;
      }
    }
    return url;
  };

  const columns: IColumn[] = [
    {
      key: "persona",
      name: "Persona",
      fieldName: "Persona",
      minWidth: 130,
      maxWidth: 190,
      isResizable: true,
      onRender: (item: ReportDetails) => (
        modifiedCatalogs.hasOwnProperty(item.ID) ? (
          <TextField
            className="text-cell-center"
            styles={{wrapper: {width: "100%"}}}
            value={modifiedCatalogs[item.ID].Persona}
            onChange={(e, newValue) => {
              setModifiedCatalogs((prevModifiedCatalogs: ReportDetailsMap) => ({
                ...prevModifiedCatalogs,
                [item.ID]: {
                  ...prevModifiedCatalogs[item.ID],
                  Persona: newValue || "",
                },
              }));
            }}
          />
        ) : (
          <div className="text-cell text-cell-center">{item.Persona}</div>
        )
      ),
    },
    {
      key: "title",
      name: "Title",
      fieldName: "TileText",
      minWidth: 150,
      maxWidth: 300,
      isResizable: true,
      onRender: (item: ReportDetails) => (
        <a onClick={()=>navigate(getURL(item.ReportURL))} target="_self" className="text-cell text-cell-center">
          {item.TileText}
        </a>
      ),
    },
    {
      key: "description",
      name: "Description",
      fieldName: "Description",
      minWidth: 200,
      maxWidth: 400,
      isResizable: true,
      onRender: (item: ReportDetails) => (
        modifiedCatalogs.hasOwnProperty(item.ID) ? (
          <TextField
          className="text-cell-center"
          styles={{wrapper: {width: "100%"}}}
          value={modifiedCatalogs[item.ID].Description}
          onChange={(e, newValue) => {
              setModifiedCatalogs((prevModifiedCatalogs: ReportDetailsMap) => ({
                ...prevModifiedCatalogs,
                [item.ID]: {
                  ...prevModifiedCatalogs[item.ID],
                  Description: newValue || "",
                },
              }));
            }}
          />
        ) : (
          <div className="text-cell text-cell-center">{item.Description}</div>
        )
      ),
    },
    {
      key: "typeOfReport",
      name: "Type of Report",
      fieldName: "IsPowerBI",
      minWidth: 70,
      maxWidth: 120,
      isResizable: true,
      onRender: (item: ReportDetails) => (
        <div className="text-cell text-cell-center">{item.IsPowerBI}</div>
      ),
    },
    {
      key: "contact",
      name: "Contact",
      fieldName: "ContactEmail",
      minWidth: 70,
      maxWidth: 120,
      isResizable: true,
      onRender: (item: ReportDetails) => (
        modifiedCatalogs.hasOwnProperty(item.ID) ? (
          <TextField
            className="text-cell-center"
            styles={{wrapper: {width: "100%"}}}
            value={modifiedCatalogs[item.ID].ContactEmail}
            onChange={(e, newValue) => {
              setModifiedCatalogs((prevModifiedCatalogs: ReportDetailsMap) => ({
                ...prevModifiedCatalogs,
                [item.ID]: {
                  ...prevModifiedCatalogs[item.ID],
                  ContactEmail: newValue || "",
                },
              }));
            }}
          />
        ) : (
          <div className="text-cell text-cell-center">{item.ContactEmail}</div>
        )
      ),
    },
    {
      key: "videos",
      name: "Videos",
      fieldName: "Videos",
      minWidth: 120,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: ReportDetails) => (
        <VideoLinksDrawer 
          isEditing={modifiedCatalogs.hasOwnProperty(item.ID)} 
          report={
            modifiedCatalogs.hasOwnProperty(item.ID)  ? 
            modifiedCatalogs[item.ID]                 : 
            item
          }
          setModifiedCatalogs={setModifiedCatalogs}
          adminRef={adminRef}
          orignalVideos={[...item.Videos]}
        />
      ),
    },
    {
      key: "actions",
      name: "Actions",
      fieldName: "Actions",
      minWidth: 80,
      maxWidth: 100,
      isResizable: true,
      onRender: (item: ReportDetails, index?: number) => (
        modifiedCatalogs.hasOwnProperty(item.ID) ? (
          <Button 
            onClick={()=>discardEdit(item.ID)}
            title="Discard changes"
            aria-label="Discard changes"
            icon={<Dismiss16Regular />} 
            appearance="secondary"
            style={{margin: "10px"}}

          />
        ) : (
          <Button 
            onClick={() => handleEdit(item)}
            title="Edit"
            aria-label="Edit"
            icon={<Edit16Regular />} 
            appearance="secondary"
            style={{margin: "10px"}}
          />
        )
      ),
    },
  ];

  const detailsListStyles: Partial<IDetailsListStyles> = {
    headerWrapper: {
      marginTop: "-12px",
      selectors: {
        ".ms-DetailsHeader": {
          backgroundColor: "#505050",
        },
        ".ms-DetailsHeader-cellName": {
          color: "white",
        },
        ".ms-DetailsHeader-cell:hover": {
          backgroundColor: "#505050",
        },
      },
    },
    
  };

  useEffect(() => {
    loadData();
  }, []);

  if (isLoading) {
    return (
      <div className="loader-container">
        <img src={Loading} alt="Loading..." className="loader" />
      </div>
    );
  }
  return (
    <div className="ReportContainer" style={{height: "65%"}}>
      <div className="inputContainer catalogContainer">
        <h5>Search Reports</h5>
        <input
          type="text"
          placeholder="Search Reports by Title, Description, or Contact"
          aria-label="Search Reports by Title, Description, or Contact"
          value={searchTerm}
          onChange={(e) => searchCatalog(e.target.value)}
          className="SearchBoxInput SearchBoxInputBox CatalogSearchbox"
          style={{ marginBottom: "12px" }}
        />
        <div className="searchMagnifierIcon" id="CatalogSearchIcon" title="Search Partners"></div>
      </div>
      <div className="ExternalReportContainer" style={{height: "100%"}}>
        <DetailsList
          items={displayedCatalog}
          columns={columns}
          setKey="set"
          layoutMode={DetailsListLayoutMode.justified}
          selectionMode={SelectionMode.none}
          styles={detailsListStyles}
          constrainMode={ConstrainMode.unconstrained}
        />

        {displayedCatalog.length === 0 && (
          <div className="ErrorData ErrorDataMessageDynamics">
            <center>
              <div className="SearchResultsEmoticon">No Data :(</div>
            </center>
          </div>
        )}
      </div>
      <div className="report-catalog-buttons" style={{display:"flex", justifyContent: "end", gap: "15px"}}>
        <Button 
          title="Save All Changes"
          aria-label="Save All Changes"
          appearance="primary"
          onClick={handleSaveAllChanges}
          disabledFocusable={Object.keys(modifiedCatalogs).length < 1}
          style={{marginTop: "20px"}} 
        >
          Save
        </Button>

        <Button 
          title="Cancel All Changes"
          aria-label="Cancel All Changes"
          appearance="secondary"
          onClick={handleCancelAllChanges}
          disabledFocusable={Object.keys(modifiedCatalogs).length < 1}
          style={{marginTop: "20px"}}         
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AdminReportCatalog;