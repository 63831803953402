import React from 'react'
import '../Styles/dialogBox.css'
// define the props for the component
type SaveAsDialogBoxProps = {
    message: string,
    onCancel: () => void,
    onConfirm: () => void,
    onDiscard: () => void
}
const SaveAsDialogBox = ({ message, onCancel, onConfirm, onDiscard }: SaveAsDialogBoxProps) => {

    const handleSave = () => {
        // Logic to save changes
        console.log('Save changes')
        onConfirm()
    }

    const handleDiscard = () => {
        onDiscard()
        console.log('Discard changes')
    }

    const handleClose = () => {
        console.log('Close dialog')
        onCancel()
    }

    return (
        <div className="unsaved-changes-dialog">
            <div className="unsaved-changes-dialog__content" >
                  <i className="ms-Icon ms-Icon--ChromeClose cancle" aria-hidden="true" onClick={handleClose}></i>
             <div className='Dialogue_text'
    >
                <div style={{color:"#333333",fontSize:"24px",fontWeight:"370",marginTop:"-6px"}}>
                Unsaved changes
                </div>
                <div style={{color:"#333333", fontSize:"14px", marginTop:"16px"}}>
                Would you like to save changes to this report?
                </div>
             </div>
            <div className="unsaved-changes-dialog__buttons">
                    <button  className="savec " onClick={handleSave}>Save As</button>
                    <button className="save" onClick={handleDiscard}>Discard</button>
                    <button  className="save" onClick={handleClose}>Cancel</button>
                </div>

            </div>   
              
            </div>
      
    
    )
}

export default SaveAsDialogBox
