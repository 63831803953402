import { CopilotProvider } from "@fluentui-copilot/react-copilot";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import axios from "axios";
import { useEffect, useState } from "react";
import protectedAxiosInstance from "./Components/helpers/api";
import LoadingImage from "./Images/Loading.gif";
import ProtectedApp from "./ProtectedApp";
import "./Styles/app.css";
import "./Styles/Hub.css";
import "./Styles/LandingPage.css";
import "./Styles/popup.css";
import "./Styles/report.css";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const fetchUserData = async () => {
    try {
      const response = await protectedAxiosInstance.get(
        `${window.location.origin}/api/Service/getGraphToken`,
        {
          withCredentials: true,
        }
      );
      const token = response.data;

      try {
        const graphData = await axios.get(
          "https://graph.microsoft.com/v1.0/me",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        localStorage.setItem("user", JSON.stringify(graphData.data));
      } catch (error) {
        console.error("Error fetching graph data:", error);
      }

      try {
        const userImage = await axios.get(
          "https://graph.microsoft.com/v1.0/me/photo/$value",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: "blob",
          }
        );
        const imgUrl = window.URL.createObjectURL(userImage.data);
        sessionStorage.setItem("UserImage", imgUrl);
      } catch (error) {
        // Do nothing
      }

      const pbiToken = await protectedAxiosInstance.get(
        `${window.location.origin}/api/Service/getPowerBIToken?regenerate=false`,
        {
          withCredentials: true,
        }
      );
      localStorage.setItem("powerbi_access_token", pbiToken.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get(
          `${window.location.origin}/api/AuthStatus/check`,
          {
            withCredentials: true,
          }
        );
        console.log("Auth check response:", response.data);
        const data = response.data;
        if (data.isLoggedIn === "false" || data.isLoggedIn === false) {
          window.location.href = `${
            window.location.origin
          }/api/service/login?uri_memory=${
            window.location.pathname + window.location.search
          }`;
        } else {
          await fetchUserData();
          setIsAuthenticated(true);
        }
      } catch (error) {
        console.error("Error checking login status:", error);
        setIsAuthenticated(false);
      }
    };

    checkAuth();
  }, []);

  return (
    <FluentProvider theme={webLightTheme}>
      <CopilotProvider
        mode="sidecar" //or 'canvas'
        // themeExtension={{
        //   colorBrandFlair1: "red", // replace with your brand colors
        //   colorBrandFlair2: "blue",
        //   colorBrandFlair3: "green",
        // }}
      >
        {isAuthenticated ? (
          <ProtectedApp />
        ) : (
          <div>
            <img
              src={LoadingImage}
              alt="Loading"
              style={{
                // center the image
                position: "absolute",
                top: "50%",
                left: "50%",
              }}
            />
          </div>
        )}
      </CopilotProvider>
    </FluentProvider>
  );
};

export default App;
