import React, { useState } from 'react'
import { Save16Regular, Dismiss16Regular, Dismiss24Regular, Delete16Regular as DeleteIcon } from "@fluentui/react-icons";
import { Button, Divider, Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerHeaderTitle, InlineDrawer, Link, OverlayDrawer } from "@fluentui/react-components";
import { ReportDetails, ReportDetailsMap, Videos } from './AdminReportCatalog';
import { ConstrainMode, DetailsList, DetailsListLayoutMode, IColumn, SelectionMode, TextField } from '@fluentui/react';

interface VideoLinksDrawerProps {
  isEditing: boolean;
  report: ReportDetails;
  setModifiedCatalogs: (value: React.SetStateAction<ReportDetailsMap>) => void;
  adminRef: React.RefObject<HTMLDivElement>;
  orignalVideos: Videos[];
}

const VideoLinksDrawer: React.FC<VideoLinksDrawerProps> = ({ report, isEditing, setModifiedCatalogs, adminRef, orignalVideos }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [invalidField, setInvalidField] = useState<Set<string>>(new Set()); // Validation is done by checking if the set is empty, if not, the save button is disabled. 
                                                                            // Set is added with the key of the field that is invalid and deleted when the field is valid.
  const columns: IColumn[] = [
    {
      key: "title",
      name: "Video Title",
      fieldName: "Video Title",
      minWidth: 130,
      maxWidth: 180,
      isResizable: true,
      onRender: (item: Videos) => (
        <TextField
          value={item.VideoTitle}
          disabled={!isEditing}
          onGetErrorMessage={(value) => {
            if (!value) {
              return "Title cannot be empty";
            }
          }}
          onChange={(e, newValue) => {
            setModifiedCatalogs((prevModifiedCatalogs: ReportDetailsMap) => {
              const updatedVideos = prevModifiedCatalogs[report.ID].Videos.map((video) =>
                video.VideoID === item.VideoID ? { ...video, VideoTitle: newValue || "" } : video
              );
            
              return {
                ...prevModifiedCatalogs,
                [report.ID]: {
                  ...prevModifiedCatalogs[report.ID],
                  Videos: updatedVideos,
                },
              };
            });
          }}
          onNotifyValidationResult={(errorMessage) => {
            invalidField
            if (errorMessage) {
              setInvalidField((prevInvalidField) => {
                return new Set(prevInvalidField.add(`${item.VideoID}-{title}`));
              });
            } else {
              setInvalidField((prevInvalidField) => {
                prevInvalidField.delete(`${item.VideoID}-{title}`);
                return new Set(prevInvalidField);
              });
            }
            invalidField
          }}
        />
      ),
    },
    {
      key: "url",
      name: "Video URL",
      fieldName: "videoURL",
      minWidth: 150,
      maxWidth: 300,
      isResizable: true,
      onRender: (item: Videos) => (
        <TextField
          value={item.VideoURL}
          disabled={!isEditing}
          onGetErrorMessage={(value) => {
            if (!value) {
              return "URL cannot be empty";
            }
            let url: URL;
            try {
              url = new URL(value);
            } catch (_) {
              return "Invalid URL";  
            }
          }}
          onChange={(e, newValue) => {
            setModifiedCatalogs((prevModifiedCatalogs: ReportDetailsMap) => {
              const updatedVideos = prevModifiedCatalogs[report.ID].Videos.map((video) =>
                video.VideoID === item.VideoID ? { ...video, VideoURL: newValue || "" } : video
              );
            
              return {
                ...prevModifiedCatalogs,
                [report.ID]: {
                  ...prevModifiedCatalogs[report.ID],
                  Videos: updatedVideos,
                },
              };
            });
          }}
          onNotifyValidationResult={(errorMessage) => {
            invalidField
            if (errorMessage) {
              setInvalidField((prevInvalidField) => {
                return new Set(prevInvalidField.add(`${item.VideoID}-{url}`));
              });
            } else {
              setInvalidField((prevInvalidField) => {
                prevInvalidField.delete(`${item.VideoID}-{url}`);
                return new Set(prevInvalidField);
              });
            }
            invalidField
          }}
        />
      ),
    },
    {
      key: "delete",
      name: "Delete",
      fieldName: "delete",
      minWidth: 50,
      maxWidth: 50,
      isResizable: false,
      onRender: (item: Videos) => (
        <Button
          icon={< DeleteIcon/>}
          onClick={() => {
            setModifiedCatalogs((prevModifiedCatalogs: ReportDetailsMap) => {
              const updatedVideos = prevModifiedCatalogs[report.ID].Videos.filter((video) => video.VideoID !== item.VideoID);
            
              return {
                ...prevModifiedCatalogs,
                [report.ID]: {
                  ...prevModifiedCatalogs[report.ID],
                  Videos: updatedVideos,
                },
              };
            });
            setInvalidField((prevInvalidField) => { 
              prevInvalidField.delete(`${item.VideoID}-{title}`); 
              prevInvalidField.delete(`${item.VideoID}-{url}`); 
              return new Set(prevInvalidField);
            });
          }}
          disabled={!isEditing}
        />
      ),
    },
  ];
  

  return (<div style={{display: "flex", alignItems: "center", height: "100%"}}>
    <Link
      // appearance="subtle"
      onClick={() => setIsOpen(!isOpen)}
      
    >
      {isEditing ? "Edit" : "View"} videos {'>'}
    </Link>
    <OverlayDrawer
      as="aside"
      mountNode={adminRef.current}
      open={isOpen}
      onOpenChange={(_, { open }) => setIsOpen(open)}
      size="medium"
      className="edit-link-drawer"
      position="end"
      style={{top: "56px"}}
      modalType="alert"
    >
      <DrawerHeader>
        <DrawerHeaderTitle>
          {isEditing ? "Edit" : "View"} Video Links
        </DrawerHeaderTitle>
      </DrawerHeader>

      <DrawerBody
        tabIndex={0}
        role="group"
        aria-label="Video list"
        style={{    height: "400px",
          minWidth: "320px",}}
      >
        <p><i>{report.TileText}</i></p>
        <DetailsList
          items={report.Videos}
          columns={columns}
          setKey="set"
          layoutMode={DetailsListLayoutMode.justified}
          selectionMode={SelectionMode.none}
          // styles={detailsListStyles}
          constrainMode={ConstrainMode.unconstrained}
        />
        {report.Videos.length === 0 && <Divider style={{width: "40%", justifySelf: "center"}} appearance="subtle">No Videos Added</Divider>}
        {isEditing && <Button
          appearance="secondary"
          style={{width: "100%", marginBottom: "1rem", marginTop: "1rem"}}
          onClick={() => {
            setModifiedCatalogs((prevModifiedCatalogs: ReportDetailsMap) => {
              const updatedVideos = [
                ...prevModifiedCatalogs[report.ID].Videos,
                {
                  // minus sign is used to differentiate between new videos and the ones that are already in the database
                  VideoID: -(prevModifiedCatalogs[report.ID].Videos.length + 1),
                  VideoTitle: "",
                  VideoURL: "",
                },
              ];
            
              return {
                ...prevModifiedCatalogs,
                [report.ID]: {
                  ...prevModifiedCatalogs[report.ID],
                  Videos: updatedVideos,
                },
              };
            });
          }}
          disabled={!isEditing}
        >
          + Add a video 
        </Button>}
      </DrawerBody>
      <DrawerFooter>
        <Button 
          appearance="primary" 
          disabled={!isEditing || invalidField.size !== 0}
          onClick={() => setIsOpen(!isOpen)}
        >
          Save as Draft
        </Button>
 
        <Button
          onClick={() => { 
            if (isEditing) {
              setModifiedCatalogs((prevModifiedCatalogs: ReportDetailsMap) => {
                return {
                  ...prevModifiedCatalogs,
                  [report.ID]: {
                    ...prevModifiedCatalogs[report.ID],
                    Videos: orignalVideos,
                  },
                };
              });
            }
            setIsOpen(false)
          }}
        >
          {isEditing ? "Discard" : "Close"}
        </Button>
      </DrawerFooter>
    </OverlayDrawer>
  </div>)
}

export default VideoLinksDrawer