import {
  AiGeneratedDisclaimer,
  ChatInput,
  ImperativeControlPlugin,
  ImperativeControlPluginRef,
  OutputCard,
} from "@fluentui-copilot/react-copilot";
import {
  CopilotChat,
  CopilotMessage as CopilotMessage,
} from "@fluentui-copilot/react-copilot-chat";
import { Avatar, Link, makeStyles, tokens } from "@fluentui/react-components";
import DOMPurify from "dompurify";
import * as powerbi from "powerbi-client";
import { useEffect, useRef, useState } from "react";
import { ReactTyped } from "react-typed";
import { formatCurrentTime } from "../helpers/Common";
import {
  INITIAL_PROMPTS,
  MessageData,
  PromptData,
  requestCopilot,
} from "./Common";
import { renderChatMessage } from "./renderChatMessage";
import { ZeroPrompt } from "./ZeroPrompt";

const useStyles = makeStyles({
  provider: {
    backgroundColor: tokens.colorNeutralBackground3,
    padding: `${tokens.spacingVerticalL} ${tokens.spacingHorizontalL}`,
    // borderRadius: tokens.borderRadiusXLarge,
    display: "flex",
    rowGap: "16px",
    flexDirection: "column",
    height: "87.6vh",
    maxWidth: "450px",
    borderLeft: `groove`,
  },
  chat: {
    height: "100%",
    padding: `${tokens.spacingVerticalL} ${tokens.spacingHorizontalL}`,
    overflowY: "auto",
  },
  disclaimer: {
    backgroundColor: "none",
  },
});

const ReportCopilot = ({ reportEmbed }: { reportEmbed: powerbi.Report }) => {
  const inputRef = useRef<ImperativeControlPluginRef>(null);
  const [messageHistory, setMessageHistory] = useState<MessageData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inputText, setInputText] = useState<string | undefined>("");
  const scrollDiv = useRef<HTMLDivElement>(null);
  const styles = useStyles();

  const handleSubmit = async (userMessage?: string) => {
    if (isLoading) return;
    userMessage = userMessage ?? inputText;
    if (userMessage === undefined || userMessage === "") {
      return;
    }
    setIsLoading(true);

    inputRef.current?.setInputText("");

    setMessageHistory((messageHistory) => [
      ...messageHistory,
      {
        type: "user",
        id: `user-${messageHistory.length}`,
        message: userMessage,
        timestamp: formatCurrentTime(),
      },
    ]);

    // Ping copilot
    const response = await requestCopilot(reportEmbed, userMessage);

    let responseMessage: string = (response as { summary: string }).summary;
    responseMessage = DOMPurify.sanitize(responseMessage); // Sanitize and modify the HTML string. Removes a tags and changes h3 tags to strong tags

    setMessageHistory((messages) => {
      return [
        ...messages,
        {
          type: "copilot",
          id: `copilot-${messageHistory.length}`,
          message: responseMessage,
          timestamp: formatCurrentTime(),
        },
      ];
    });

    setIsLoading(false);
  };

  const handlePromptSelected = (prompt: PromptData) => {
    handleSubmit(prompt.prompt);

    // if (prompt.category === "Ask") {
    //   handleSubmit(prompt.prompt);
    //   return;
    // }
    // const text = prompt.prompt.replace("...", " ");
    // inputRef.current?.setInputText(text);
  };

  useEffect(() => {
    scrollDiv.current?.scrollTo({ top: scrollDiv.current.scrollHeight });
  }, [messageHistory]);

  return (
    <div className={styles.provider}>
      <CopilotChat className={styles.chat} ref={scrollDiv}>
        <ZeroPrompt
          prompts={INITIAL_PROMPTS}
          onPromptSelected={handlePromptSelected}
        />
        
        {messageHistory.map(renderChatMessage)}

        {isLoading && (
          <CopilotMessage
            style={{ maxWidth: "none" }}
            // disclaimer=""
            // avatar={
            //   <Avatar
            //     size={24}
            //     image={{
            //       src: "https://res-2-sdf.cdn.office.net/files/fabric-cdn-prod_20240411.001/assets/brand-icons/product/svg/copilot_24x1.svg",
            //     }}
            //   />
            // }
            // name="Copilot"
            // loadingState="loading"
            progress={{ value: undefined }}
            isLoading={true}
          >
            <ReactTyped
              startWhenVisible
              strings={[
                "Searching for references...",
                "Thinking...",
                "Generating response...",
              ]}
              typeSpeed={30}
              loop
              backSpeed={10}
              backDelay={4000}
              cursorChar="&#8205" // code for blank character
              showCursor={true}
              />
          </CopilotMessage>
        )}
      </CopilotChat>

      <ChatInput
        aria-label="Copilot Chat"
        placeholderValue="Ask questions and work with this document"
        onSubmit={() => handleSubmit()}
        onChange={(e, d) => setInputText(d.value)}
        disableSend={isLoading}
        count={{ "data-testid": "count" } as any}
        maxLength={200}
        charactersRemainingMessage={(charactersRemaining) =>
          `${charactersRemaining} characters remaining`
        }
        characterLimitErrorMessage="You have reached the character limit."
      >
        <ImperativeControlPlugin ref={inputRef} />
      </ChatInput>

      <AiGeneratedDisclaimer className={styles.disclaimer}>
        Always review AI-generated content for mistakes.{" "}
        <Link inline href="https://go.microsoft.com/fwlink/?linkid=2275422">
          Learn more
        </Link>
      </AiGeneratedDisclaimer>
    </div>
  );
};

export default ReportCopilot;
