import axios from "axios";

const protectedAxiosInstance = axios.create();

protectedAxiosInstance.interceptors.request.use(
  async (config) => {
    const response = await axios.get(
      `${window.location.origin}/api/AuthStatus/check`,
      {
        withCredentials: true,
      }
    );
    if (response.data.isLoggedIn === "false" || response.data.isLoggedIn === false) {
      window.location.href = `${window.location.origin}/api/service/login?uri_memory=${window.location.pathname + window.location.search}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default protectedAxiosInstance