import { Element, HTMLReactParserOptions } from "html-react-parser";
import * as powerbi from "powerbi-client";

export const INITIAL_PROMPTS: PromptData[] = [
  {
    prompt: "Give me an executive summary",
    category: "Summarize",
    icon: "ListIcon",
  },
  {
    prompt: "Anticipate likely questions from leadership",
    category: "Question",
    icon: "PeopeAskIcon",
  },
  {
    prompt: "What insights should I look into?",
    category: "Ask",
    icon: "LightbulbIcon",
    // badge: "NEW",
  },
];

export type MessageData = {
  type: string;
  id: string;
  message: string;
  timestamp: string;
};

export type PromptData = {
  prompt: string;
  category: string;
  icon: string;
  badge?: string;
};

export type ZeroPromptProps = {
  prompts: any[];
  onPromptSelected: (prompt: PromptData) => void;
};

export async function requestCopilot(
  reportEmbed: powerbi.Report,
  summaryRequest: string
) {
  // Make a request to the server
  const page = await reportEmbed.getActivePage();
  let body = {
    prompt: summaryRequest,
    reportSelection: { 
      isEntireReportSelected: false,
      pages: {
          [page.name]: {
              isEntirePageSelected: true,
              // visuals: {
              //     [visuals[0].name]: true
              // }
          }
      }
  },
  };

  try {
    const contentWindow = reportEmbed.iframe?.contentWindow ?? undefined;
    const response = await reportEmbed.service.hpm.post(
      "/report/copilotSummary",
      body,
      { uid: reportEmbed.config.uniqueId },
      contentWindow
    );
    return response.body;
  } catch (error) {
    console.error(error, "Possible data set too large. Server error");
    return {
      summary: "Couldn't generate a response. Please try again later.",
    };
  }
}

export const transform: HTMLReactParserOptions["replace"] = (node) => {
  // Ensure the node is an Element
  if (node instanceof Element) {
    // Remove <p> elements that contain "References:" and everything inside them and also remove everything after that <p> element
    if (
      node.tagName === "p" &&
      node.children.some(
        (child) =>
          child.type === "text" &&
          (child.data.includes("References") ||
           child.data.includes("Reference"))
      )
    ) {
      return <></>; // Remove this <p> and everything inside it
    }
    
    // Change <h3> to <strong>
    if (node.tagName === "h3") {
      node.tagName = "strong";
      node.attribs = { ...node.attribs, style: "display: block;" };
    }

    if (node.tagName === "a") {
      return <></>;
    }
  }

  return node;
};

