import { IExternalReportData } from "../Interfaces/Interfaces";

export class Constants {
    static currview: string | null = null;
    static Pincurrview(view: string) {
        this.currview = view;
    }
    static ISV_GSIGSLinks: any = {};
    static PTD = {
        PTD_Report_URL: "http://aka.ms/ocpptd",
    };
    static PPED = {
        PPED_Report_URL: "http://aka.ms/ocppped",
    };
    static IOT = {
        IOT_Report_URL: "http://aka.ms/iotbusinessinsights",
    };
    static NOTIFICATION_EMBED_URL = "https://msit.powerbi.com/reportEmbed?reportId=7e3ff37a-c877-4792-a7eb-5508e9d4e68b&groupId=b3f8b31d-2b92-4ad0-b92f-3b1ed8411d8c&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9ERi1NU0lULVNDVVMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7InVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d"
    static NOTIFICATION_EMBED_URL_UAT = "https://msit.powerbi.com/reportEmbed?reportId=2baba4c2-9928-4088-a719-ee953ab9ef55&groupId=c7300506-4e4b-47d2-8d0f-bfdfd6897ec1&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9ERi1NU0lULVNDVVMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7InVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d"
    static NOTIFICATION_EMBED_URL_PROD = "https://msit.powerbi.com/reportEmbed?reportId=3a4a4434-e0f3-4193-bd48-9dcf98dde9c1&groupId=9a95ad97-5af4-4412-8c23-17d789e1170d&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9ERi1NU0lULVNDVVMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7InVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d"

    static MICROSOFT_LINK: string = "http://go.microsoft.com/fwlink/?LinkId=518021";
    static AZURE_SEARCH_URL_NON_PROD = "https://ftbi-dev-eastus2-aisearch01.search.windows.net/indexes/catalog-index/docs?api-version=2020-06-30-Preview&$top=10";
    static AZURE_SEARCH_URL = "https://ftbi-prod-eastus2-aisearch01.search.windows.net/indexes/catalog-index/docs?api-version=2020-06-30-Preview&$top=10";
    static FTBI_PROD_URL = 'https://ftbiprod.azurewebsites.net/';
    static FTBI_UAT_URL = 'https://ftbiuat.azurewebsites.net/';
    static MICROSOFT_SHAREPOINT_LINK = "https://microsoft.sharepoint-df.com/:w:/t/FastTrackSharedServices9/EfRTv0fXbNJEs19FMyuV6W8BqqvTpzYteXN6ujPWu7tjRg";
    static JOIN_SG_URL = "https://aka.ms/FTBIReportAccess";
    static WORKSPACE_URL = "https://aka.ms/FTCJoinPowerBI";
    static UPDATE_REPORTS_US = "https://o365fasttrack.visualstudio.com/FastTrack/_workitems/create/User%20Story?templateId=5a7dbe9f-8778-4116-8eae-d7b0883bcd3f&ownerId=affe1e8e-1b6c-45fe-a5f1-b38bc978fa87"
    static FUNNEL_SHAEPOINT_URL = "https://microsoft.sharepoint-df.com/:p:/t/FastTrackSharedServices9/EQr-sx9oOzZBvFbu2PkUEPgBv7fCUXu0r_tIQDnbFH6YlA?e=zAlqtV&xsdata=MDN8MDF8fDc4YTY1YzUwZWMwMTRlYjY4ZjllZDkwMjgxZGZhYTYyfDcyZjk4OGJmODZmMTQxYWY5MWFiMmQ3Y2QwMTFkYjQ3fDF8MHw2Mzc3NDQwMDk2NTE4NTE0MTF8R29vZHxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaUlpd2lRVTRpT2lJaUxDSlhWQ0k2TVRKOQ%3D%3D&sdata=RzcyY2tlWWZvTGc2Uy9RQVZXcUo4dExzYXpjQWVLdzZxckIrbFNVMTRObz0%3D&ovuser=72f988bf-86f1-41af-91ab-2d7cd011db47%2cv-lawrli%40microsoft.com&isSPOFile=1";
    static DATA_PLATFORM_URL = "https://microsoft-my.sharepoint.com/:p:/p/kishoredara/EUm4_HPrkbZNhrSS0R3O8eoByAm8yZXSd09xdmprM85cMQ?wdOrigin=TEAMS-WEB.p2p.bim&wdExp=TEAMS-CONTROL&wdhostclicktime=1668762433037&web=1";
    static DEMO_VIDEO_URL = "https://msit.microsoftstream.com/embed/video/09e90840-98dc-94f4-bfc5-f1ec5dc27ae4?autoplay=false&showinfo=true";
    static DEMO_VIDEO_NEW_URL = "https://msit.microsoftstream.com/video/09e90840-98dc-94f4-bfc5-f1ec5dc27ae4"
    static ROADMAP_URL = "https://microsoft.sharepoint-df.com/:w:/t/FastTrackSharedServices9/EfRTv0fXbNJEs19FMyuV6W8BqqvTpzYteXN6ujPWu7tjRg";

    static SIGNOUT_ENDPOINT: string = "service/SignOut";
    static isWWUser: boolean = false;
    static isWWUserURL: string = "api/service/GetIsWWUser";
    static AzureSearchKey: string = "";
    static GetAzureSearchKey: string = "api/service/GetAzureSearchKey";
    static isNPSUserURL: string = "api/service/GetIsNPSUser";
    static getNPSCounter: string = "api/service/GetNPSCounter";
    static IncrementNPSCounter: string = "api/service/NPSCounter";
    static Report: string = "Report";
    static Edit: string = "Edit";
    static ReadMe: string = "ReadMe";
    static PINcols: string[] = [
        "OCP CRM ID",
        "Subsidiary",
        "SubRegion",
        "PartnerOne Name - ID",
        "Sub-Segment",
        "Management Level",
    ];
    static AreaFilter: string[] = ["Area"];
    static RoleContact: string[] = ["Role", "Contact"];
    static TimePeriod: string[] = ["Time Period"];
    static Specialization: string[] = ["Segment"];
    static MetricFilter: string[] = ["Metric"];
    static PIN_EDIT_TAB_PBI: string = "ReportSectiond745047e87fbda94ebe8";
    static PINDetailsTabular: string = "PIN Details";
    static RoleContactTabular: string = "Role Contact";
    static TimePeriodTabular: string = "Time Period";
    static AreaTabular: string = "Area";
    static SpecializationTabular: string = "Segment";
    static MetricTabular: string = "Metric";
    static PlanningReportsLoaded: any = [];
    static SurfaceReportsLoaded: any = [];
    static PlanningCuratedTab: boolean = false;
    static PlanningReportTab: boolean = false;
    static PlanningEditTab: boolean = false;
    static SurfaceReportTab: boolean = false;
    static SurfaceEditTab: boolean = false;
    static SurfaceReadMETab: boolean = false;
    static IsLeftNavCollapse: boolean = false;
    static FiscalYear = "72";
    static FIRST_RUN = "1";
    static RESELLERSUMMARY = "ResellerSummary";
    static REFERRAL = "Referral";
    static BUSINESSPLAN = "BusinessPlan";
    static FIFTH_LEVEL_TAB_ID = "";
    static FIFTH_LEVEL_TAB_NAME = "";
    static SERVICE_CONTROLLER_URL: string =
        window.location.protocol + "//" + window.location.host + "/"; //Removed hardcoded service URL
    static SERVICE_URL: string = Constants.SERVICE_CONTROLLER_URL + "api/";
    static REQUEST_HANDLER: string = "service/RequestHandler";
    static ALERT_ACTIVE_REQUEST_HANDLER = "service/GetDataWithConfiguration"; //Fetch the active alert data message
    static MPL_NON_CACHE_ALERT_ACTIVE_REQUEST_HANDLER =
        "service/GetLatestDataWithConfiguration"; //Fetch the active alert data message
    static PIN_NON_CACHE_ALERT_ACTIVE_REQUEST_HANDLER =
        "service/GetLatestDataWithConfiguration"; //Fetch the active alert data message
    static TargetPlanning_NON_CACHE_ALERT_ACTIVE_REQUEST_HANDLER =
        "service/GetLatestDataWithConfiguration"; //Fetch the active alert data message
    static PBP_REQUEST_HANDLER = "service/PBP_GetDataWithConfiguration"; //Fetch PBP data
    static PBP_EXIST_HANDLER = "service/PBP_BlobExists"; //check Existance of blob
    static REPORT_LIBRARY_REQUEST_HANDLER =
        "service/GetReportLibraryConfiguration"; //Fetch the active alert data message
    static RELEVANCE_SEARCH_DATA_REQUEST_HANDLER =
        "service/GetRelevanceSearchResults";
    static SUMBIT_NPS_FEEDBACK = "filterPanel/SaveUserFeedback";
    static FILTER_PANEL_EMIT: string = "filterPanelClosed"; //Emit event reference to close the filter panel
    static CLAS_CURRENT_SECTION = "";
    static CLAS_CURRENT_REPORT = "";
    static ContainerIdstoQueryIdsMapping: any = {};
    static ContainerstoFilters: any = {};
    static PARTNERONEKEYRING_CONTROLLER: string = "partneronekeyring";
    static IS_ALERT_ACTIVE: boolean = false;
    static IS_ALERT_EXPANDED: boolean = false;
    static IS_LEFT_NAV_MOBILE_BUTTON_CLICKED: boolean;
    static ALERT_MESSAGES_REQUESTS = {
        INFORMATION_BAR_SAVE_ROUTE: "NewsAndAnnouncements/postAlert",
        INFORMATION_BAR_DELETE_ROUTE: "NewsAndAnnouncements/deleteAlert",
        INFORMATION_BAR_GET_ROUTE: "NewsAndAnnouncements/getAlertMessage",
    };
    static DISABLE_FILTER_LIST = [
        "OCPLanding",
        "ReportCatalog",
        "PartnerProgram",
        "OCPCRMBuildWith",
        "OCPCRMSellWith",
        "GTMAndPP",
        "OCPCRMBuildWith",
        "OCPCRMSellWith",
        "FinInsightsDataRefresh",
        "FinInsightsIntroduction",
        "PlanningTool",
        "PlanSummary",
        "OCPCRMSellWithIPCoSell_Teams",
        "OCPCRMSellWithIPCoSell_D365",
        "ManagedPartnersRC",
        "SPOIntroduction",
        "SPOProfile",
        "SPOPartnerIntroduction",
        "SPOPartnerProfile",
        "PCI",
        "LVStrategicPartnersDS",
        "ObjectiveCriteria_ReadMe",
        "Target_Worksheet",
        "Target_ReportPreview",
    ];
    static DELAY_FILTERS = [
        "EMPMDashboard_AllUpRevenue",
        "EMPMDashboard_PIRevenue",
        "EMPMDashboard_PIConsumption",
        "EMPMDashboard_Target",
        "EMPMDashboard_PartnerView",
        "EMPMDashboard_TrendView",
        "SAICSPAnalytics_SOB",
        "SAICSPAnalytics_IndirectProviders",
        "SAICSPAnalytics_LSPs",
        "SAICSPAnalytics_DirectMotion",
        "ObjectiveCriteria_SolutionAnalysis_Azure",
        "ObjectiveCriteria_SolutionAnalysis_BizApps",
        "ObjectiveCriteria_SolutionAnalysis_MWS",
        "ObjectiveCriteria_PartnerRecommendation_Azure",
        "ObjectiveCriteria_PartnerRecommendation_BizApps",
        "ObjectiveCriteria_PartnerRecommendation_BizAppsPowerApps",
        "ObjectiveCriteria_PartnerRecommendation_MWS",
    ];
    static ENABLE_FILTER_PANEL = [
        "GPSCRMSellWithCoSell_CoSell_Summary",
        "GPSCRMSellWithCoSell_CoSell_Opportunity",
        "GPSCRMSellWithCoSell_CoSell_SuccessEngagement",
        "GPSCRMSellWithCoSell_CoSell_InboundDeal",
        "GPSCRMSellWithCoSell_CoSell_Reference",
    ];
    static LIST_OF_TILE_ID_TO_DISABLE_REFRESH = [
        "PCI",
        "OCPLanding",
        "PlanningTool",
        "newsandAnnouncementFull",
        "BizApps_PCI",
        "SPOIntroduction",
        "Target_Worksheet",
    ];
    static LIST_OF_TILE_ID_TO_TO_BE_COLLAPSED = [
        "MUR",
        "PartnerSegments",
        "MotionsAndPrograms",
    ];
    static LIST_OF_SECTIONS_TO_BE_COLLAPSED = [
        "Business Unit",
        "ATC",
        "Role",
        "Competency",
    ];
    static LIST_OF_MULTISELECT_EXCEPTIONS = [
        "SPOCPPO365",
        "SPOCPPAzure",
        "GPSReferral",
    ];
    static UPLOAD_ALERT_MESSAGES_REQUESTS = {
        UPLOAD_SAVE_ROUTE: "UploadExcel/postAlert",
        UPLOAD_SAVE_ROUTE_SURFACE: "UploadExcel/postAlertSurface",
        UPLOAD_SAVE_ROUTE_PIN: "UploadExcel/PostAlertPINTool",
    };
    static NEWSANDANNOUNCEMENTS_REQUESTS = {
        NEWSANDANNOUNCEMENTS_BAR_SAVE_ROUTE:
            "NewsAndAnnouncements/postNewsAndAnnouncement",
        NEWSANDANNOUNCEMENTS_BAR_GET_ROUTE:
            "NewsAndAnnouncements/getNewsAndAnnouncements",
        NEWSANDANNOUNCEMENTS_BAR_DELETE_ROUTE:
            "NewsAndAnnouncements/deleteNewsAnnouncement",
    };
    static UPLOAD_ALERT_MESSAGES = {
        MPL_UPLOAD_SAVE_ROUTE: "uploadMPLFile/postAlert",
    };
    static ReportLevel = '';
    static TabLevel = '';
    static NavLevel = '';
    static DATAREFRESH_REQUEST = {
        DATAREFRESH_GET_ROUTE: "NewsAndAnnouncements/GetRefreshDetails",
        DATAREFRESH_SAVE_ROUTE: "NewsAndAnnouncements/UpdateRefreshDetails",
        DATAREFRESH_FUNCTIONALAREA_ROUTE: "NewsAndAnnouncements/GetFunctionalArea",
        DATAREFRESH_SUBFUNCTIONALAREA_ROUTE:
            "NewsAndAnnouncements/GetFunctionalAreaWithSubFunctionalArea",
    };
    static ALERT_MESSAGE_INFORMATION = {
        ALERT_OPTIONS: ["Critical Event", "Refresh Delayed", "Announcements"],
        ALERT_PLACEHOLDER: "Please choose Alert Type here",
    };
    static MPL_BULKUPLOAD_ERROR_MESSAGES = {
        NOFILESELECTED_ERROR_MESSAGE: "Select a file to upload",
    };
    static NEWSANDANNOUNCEMENTS_ERROR_MESSAGES = {
        TITLE_ERROR_MESSAGE: "Title field empty: Enter Title",
        DESCRIPTION_ERROR_MESSAGE: "Description field empty: Enter Description",
        URL_ERROR_MESSAGE: "URL field empty: Enter URL",
        ACTIVE_ERROR_MESSAGE: "Is Active field not selected: Select Is Active",
        ETA_ERROR_MESSAGE: "ETA to fix field not selected : Select Date",
        EXPIRE_ERROR_MESSAGE: "Expires By field not selected : Select Date",
        NOFILE_ERROR_MESSAGE: "File not uploaded: Kindly upload a file",
        NOFILESELECTED_ERROR_MESSAGE: "File not selected: Kindly select a file",
        FILE_UPLOAD_ERROR_MESSAGE:
            "Invalid file uploaded. Only doc files and email attachments are allowed.",
        EXCEL_FILE_UPLOAD_ERROR_MESSAGE:
            "Invalid file selected. Only Excel files are allowed.",
        FILE_SIZE_ERROR_MESSAGE: "File size greater than 5MB are not allowed",
        FILE_SIZE_ERROR_MESSAGE_MPL: "File size greater than 10MB are not allowed",
        MPLTOOl_EXCEL_FILE_UPLOAD_ERROR_MESSAGE:
            "Invalid file selected. Only .xlsm files are allowed. Please use the FY21 MPL template provided above.",
        MPLTOOl_FILE_SIZE_ERROR_MESSAGE:
            "File size greater than 30MB are not allowed",
        PINTOOl_EXCEL_FILE_UPLOAD_ERROR_MESSAGE:
            "Invalid file selected. Please use the template exported from the grid below.",
        PINTOOl_FILE_SIZE_ERROR_MESSAGE:
            "Files greater than 30 MB are not allowed. You can divide the content into multiple files and upload them separately.",
        PINTOOL_NOFILESELECTED_ERROR_MESSAGE:
            "File not selected. Please select a file.",
    };
    static TRAININGLIBRARY_REQUESTS = {
        TRAININGLIBRARY_GET_ROUTE: "Training/getTrainingLibrary",
        TRAININGDOCUMENTS_GET_ROUTE: "Training/getTrainingDocuments",
        TRAININGDOCUMENT_SAVE_ROUTE: "Training/postTrainingDocument",
        TRAININGDOCUMENT_DELETE_ROUTE: "Training/deleteTrainingDocument",
        TRAININGCATEGORIES_GET_ROUTE: "Training/getTrainingCategories",
        TRAININGCATEGORY_SAVE_ROUTE: "Training/postTrainingCategory",
        TRAININGCATEGORY_DELETE_ROUTE: "Training/deleteTrainingCategory",
    };
    static ReportCatalogIDMapping: any = {
        MostUsedReports: "Most Used Reports",
        CSSOperations: "CSS Operations",
        MWAOperations: "MWA Operations",
        SCIMHealth: "SCIM Health",
        FTCloudManagedEndpoints: "FT Cloud Managed Endpoints",
        TeamsPhoneHealth: "Teams Phone Health",
        CloudManagedEndpoints: "Cloud Managed Endpoints",
        FTReportingCatalog: "FT Reporting Catalog",
        FLWHealth: "FLW Health",
        Edge: "Edge",
        FRPOperations: "FRP Operations",
        SearchReport: "Search Report",
        Miscellaneous: "Miscellaneous",
    };
    static PARTNERPERFORMANCE_REQUEST = {
        PPR_GetFilterValues: "PartnerPerformance/GetFilterValues",
        PPR_GenerateReport: "PartnerPerformance/GenerateReport",
        PPR_ReportGenerationStatus: "PartnerPerformance/ReportGenerationStatus",
        PPR_SearchFilter: "PartnerPerformance/SearchFilter",
        PPR_DownloadFile: "api/download/DownloadFile?",
    };
    static OCPBIEXPORTTRACKER_REQUEST = {
        PPR_ExportReportGenerationStatus:
            "GPSBIExportTracker/ReportGenerationStatus",
    };
    static CLAS_PID_Export = "GPSBIExportTracker/CLASPIDExportHandler/";
    static PARTNERCOMPETENCYPERFORMANCE_REQUEST = {
        PCP_GetTileData: "PartnerCompetencyPerformance/GetTileData",
    };
    static PLANNINGTOOL_ACCESS_DETAILS = {
        PT_GetTableData: "PlanningTool/GetUserDetails",
        PT_StoreProjectionValues: "PlanningTool/SubmitAARData",
        PT_GetData: "PlanningTool/GetPTData",
        PT_GetPlanData: "PlanningTool/GetPlanData",
        PT_GetBaseData: "PlanningTool/GetBaseData",
        PT_GetMSSalesFlag: "PlanningTool/GetMSSalesFlag",
    };
    static HASWORLDWIDEACCESS: boolean = false;

    static SINGLEMPN_GRIDS = [];

    static GRIDSEARCH = {
        search: {
            SPOCPPSummary: "",
            SPOCPPO365: "",
            SPOCPPAzure: "",
            SPOCPPEnterprise: "",
            SPOCPPPowerBI: "",
            Dynamics365: "",
        },
    };
    static RFP_QUERY_MAPPING = [];
    static MPL_CONTROLLER = "mplplanning";
    static PINTOOL_CONTROLLER = "pinplanning";
    static MPL_CONTROLLER_SURFACE = "mplplanningsurface";
    static MPL_USER_HISTORY = "Partner Parenting User History Page";
    static SEARCH_HANDLER: string = "partnerdetails/searchResults";
    static SEARCH_RECENT_PARTERS: string =
        "partnerdetails/GetRecentPartnerSearches";
    static SEARCH_RECENT_PARTERS_UPDATE: string =
        "partnerdetails/UpdatePartnerSearches";
    static RECENT_REPORTS: string = "partnerdetails/GetRecentReports";
    static RECENT_REPORTS_UPDATE: string = "partnerdetails/UpdateRecentReports";
    static PARTNER_SEARCH_HANDLER: string = "partnerdetails/partnerSearchResults";
    static PARTNER_MIGRATION_HANDLER: string =
        "partnerdetails/partnerMigrationDetails";
    static PARTNER_DETAILS_GET_LIST: string = "partnerdetails/getList";
    static VORG_PARTNER_DETAILS_GET_LIST: string = "partnerdetails/getVOrgData";
    static PARTNER_DETAILS_GET_DETAILS: string = "partnerdetails/getData";
    static EXPORT_EXCEL_ROUTE: string = "ExportExcel/hub/RequestHandler/";
    static EXPORT_PPT_ROUTE: string = "ExportPPT/RequestPowerpoint/";
    static ANNOUNCEMENTS_VIEW_NAME: string = "AnnouncementsView";
    static DATA_REFRESH_VIEW_NAME: string = "DataRefreshView";
    static MIN_TILE_DISPLAY: number = 8;
    static RFP_FILTER_MAPPINGS: string = "1";
    static POWER_BI_CONFIG_ID: string = "2";
    static GSI_GS_POWER_BI_CONFIG_ID: string = "7";
    static LANDING_PAGE_CONFIG_ID: string = "5";
    static AZURE_CONSUMPTION_DATA_CONFIG_ID = "11";
    static AZURE_CONSUMPTION_COUNT_CONFIG_ID = "8";
    static TIME_PERIOD_SLICER_CONFIG_ID = "18";
    static PARTNER_ASSOCIATION_TYPE_SLICER_CONFIG_ID = "19";
    static PRODUCT_SLICER_CONFIG_ID = "21";
    static CUSTOMER_GEOGRAPHY_REFERRAL_CONFIG_ID = "900";
    static CUSTOMER_REGION_REFERRAL_CONFIG_ID = "902";
    static CUSTOMER_SUBREGION_REFERRAL_CONFIG_ID = "904";
    static CUSTOMER_SUBSIDIARY_REFERRAL_CONFIG_ID = "905";
    static PARTNER_DEAL_CREATED_FISCAL_YEAR = "906";
    static PARTNER_DEAL_CREATED_FISCAL_MONTH = "908";
    //static SALES_MODEL_SLICER_CONFIG_ID = "22";
    static SUBSCRIPTION_TYPE_SLICER_CONFIG_ID = "23";
    static SKU_TYPE_SLICER_CONFIG_ID = "24";
    static WORKLOAD_TYPE_SLICER_CONFIG_ID = "124";
    static CPP_PRODUCT_SLICER_CONFIG_ID = "25";
    static IS_AUTO_RENEW_SLICER_CONFIG_ID = "26";
    static SUBSCRIPTION_RENEW_SLICER_CONFIG_ID = "27";
    static SUBSCRIPTION_STATUS_SLICER_CONFIG_ID = "28";
    static OFFER_SLICER_CONFIG_ID = "29";
    static OFFER_TYPE_SLICER_CONFIG_ID = "30";
    static BILLING_SYSTEM_SLICER_CONFIG_ID = "31";
    static EXTERNAL_FILES_CONFIG_ID = "35";
    static IS_MCP_ID = "54";
    static IS_Associated_MPN = "55";
    static PARTNER_COUNT_ID = 8;
    static NAV_DEFAULT_VALUE_WITHOUT_PARTNER = "2";
    static NAV_DEFAULT_VALUE_WITH_PARTNER = "1";
    static CED_COUNTRY = "101";
    static MPL_VALIDATION_PROGRESS = "62";
    static MPL_BULK_UPLOAD_VALIDATION_PROGRESS = "72";
    static MPL_DATA_CHANGE_VERSION = "63";
    static MPL_DROWPDOWN = "64";
    static PIN_BULK_DROPDOWN_CASCADING = "300";
    static MPL_BULK_DROPDOWN = "74";
    static MPL_BULK_DROPDOWN_URL = "82";
    static MPL_GEOGRAPHYFILTER = "70";
    static MPL_REFRESHDATE = "61";
    static MPL_REFRESHDATE_SURFACE = "220";
    static MPL_VALIDATION_PROGRESS_SURFACE = "221";
    static MPL_DATA_CHANGE_VERSION_SURFACE = "222";
    static RELEVANCE_SEARCH = "40";
    static MPL_REPORTS = [
        "Curated",
        "AdhocReportGrid",
        "AdhocReport",
        "AdhocReportSurface",
        "AdhocReportGridSurface",
        "ManagedReportPBI",
        "UnManagedReportPBI",
        "ManagedReportPBISurface",
        "UnManagedReportPBISurface",
        "FullMetricReport",
    ];
    static PIN_REPORTS = [
        "PinReportTab",
        "PinEditTab",
        "PinReportGlobalPartnerTab",
        "PinReportPartnerTab",
    ];
    static Area = "60";
    static Region = "57";
    static SubRegion = "58";
    static Subsidiary = "59";
    static GeographyLevel = "69";
    static Geography = "71";
    static COArea = "98";
    static COSegment = "103";
    static COSubSegment = "102";
    static COCluster = "104";
    static COCloudAscentProductGroup = "99";
    static PIN_Subsidiary = "411"; // changed to PIN_Subsidiary
    static PINSubsidiary = "401";
    static Role = "402";
    static Contact = "403";
    static Metric = "404";
    //static Tier = "405";
    static OCPCRMID = "413";
    static PINSubRegion = "406";
    static PartnerOneNameID = "407";
    static Partnersegment = "408";
    static Partnersubsegment = "409";
    static PIN_SubRegion = "411";
    static PIN_REFRESHDATE = "415";
    static PIN_DROPDOWN = "416";
    static PIN_ROLE = "417";
    static AreaPIN = "418";
    static ManagementLevel = "419";
    static PIN_PartnerID = "421";
    static PIN_Contact = "422";
    static PIN_OCPCRMID = "414";
    static PIN_DATA_CHANGE_VERSION_SURFACE = "428";
    static PIN_USER = "429";
    static TPM_User = "19004";
    /*PBP*/
    static PBP_FETCH_REVENUE_START_FY18 = "1053";
    static PBP_FETCH_REVENUE_END_FY18 = "1067";
    static PBP_FETCH_SCORECARD_START_FY18 = "1068";
    static PBP_FETCH_SCORECARD_END_FY18 = "1071";
    static PBP_FETCH_BUILD_SELL_WITH_START_FY18 = "1075";
    static PBP_FETCH_BUILD_SELL_WITH_END_FY18 = "1084";
    static PBP_FETCH_DETAIL_START_FY18 = "1085";
    static PBP_FETCH_DETAIL_END_FY18 = "1096";
    static PBP_FETCH_REVENUE_START_FY19VTP = "1100";
    static PBP_FETCH_REVENUE_END_FY19VTP = "1124";
    static PBP_FETCH_BUILD_SELL_WITH_START_FY19VTP = "1075";
    static PBP_FETCH_BUILD_SELL_WITH_END_FY19VTP = "1084";
    static PBP_FETCH_DETAIL_START_FY19VTP = "1106";
    static PBP_FETCH_DETAIL_END_FY19VTP = "1119";
    static PBP_FETCH_PLAN_PPR = "1125";
    static PBP_FETCH_PLAN_SC = "1050";
    static PBP_FETCH_PLAN_SCA = "1051";
    static PBP_FETCH_PLAN_SCO = "1052";
    static PBP_FETCH_START_FY18 = "1053";
    static PBP_FETCH_START_CRM = "1075";
    static PBP_FETCH_SC = "1072";
    static PBP_FETCH_SCA = "1073";
    static PBP_FETCH_SCO = "1074";
    static PBP_FETCH_START_DET = "1125";
    static PBP_FETCH_END = "1125";
    static PBP_FETCH_FM = "1045";
    static PBP_GLOBAL_CONFIG_ID = "1044";
    static Training_Config_ID = "1152";
    static PBP_PERCENTAGEMETRICES = [
        "Billed Revenue YTD YoY%",
        "Growth Benchmark",
        "3rd Party Hosting / SPLA YoY%",
        "Cloud Growth YTD YoY%",
        "Azure Consumption YTD over YTD",
        "Azure Benchmark YTD YoY%",
        "Partner Influenced Azure Consumed Revenue YOY%",
        "CSP Total Revenue YTD YoY%",
    ];

    static PBP_COUNTMETRICES = [
        "Windows10MAD",
        "O365ProPlusMAD",
        "Partner Co-Sell",
        "Build-With Count of Solutions",
        "Build-With Co-Sell Ready Solutions",
        "Count of Opportunities",
        "Azure Net Customer Adds_YTD",
        "IPPartnerCo-Sell",
        "Wins",
        "M365PaidSubscribers",
        "AzureCustomerAdds",
        "BusinessAppsPremiumCustomers(H2 Only)",
        "D365CustomerAdds",
        "O365CoreWorkloadActiveUsage",
        "O365ModernCommsAppsActiveUsage",
        "CustomerandPartnerSatisfactionIndex(GPS)",
        "Partner Influenced O365 Commercial Paid Subscribers",
        "Partner Influenced Microsoft 365 Enterprise Commercial Subscribers",
        "Partner Influenced Windows 10 Enterprise Deployment",
        "Office 365 Customer Adds",
        "Office365CustomerAdds(SMC-C)",
        "Partner Influenced Azure Customer Adds",
        "M365E5Seats",
        "Partner Influenced Office 365 Active Usage",
        "Partner Influenced Office 365 Commercial Usage",
        "IP Partner Co-Sell",
        "PI Azure Customer Adds",
        "PI O365 Core Workload Active Usage",
        "PI O365 Core Workload Active Usage",
        "Office 365 Customer Adds (SMC-C)",
        "Microsoft 365 E5 Seats",
        "Microsoft 365 Paid Subscribers",
        "Windows 10 MAD",
        "Office 365 Proplus MAD",
        "Dynamics 365 Customer Adds",
    ];

    static PBP_REVENUEMETRICES = [
        "Billed Revenue",
        "3rd Party Hosting / SPLA",
        "Opportunity Revenue CD",
        "Estimated Monthly Revenue CD",
        "Approved Requested Amount",
        "Partner Influenced Surface Billed Revenue",
        "Azure Consumption $",
        "Billed Revenue YTD Growth",
        "SurfaceCommercialBilledRevenue",
        "AIConsumedRevenue",
        "AzureConsumedRevenue",
        "ADSConsumedRevenue",
        "EA",
        "Open",
        "TotalRevenue",
        "DPOR/PartnerInfluencedRevenue",
        "3rdPartyHosting/SPLA",
        "SurfaceCommercialBilledRevenue",
        "Direct",
        "Partner Influenced Dynamics 365 Revenue",
        "Partner Influenced Data Platform: SQL Server & Azure Data Services Revenue",
        "Partner Influenced Azure Consumed Revenue",
        "CSP Total Revenue",
        "Partner Influenced Surface Billed Revenue",
        "Other",
        "CSP",
        "Surface",
        "CSP Total Revenue YTD",
        "PI ADS Consumed Revenue",
        "PI Azure Consumed Revenue",
    ];

    // Key value Mapping
    static PBP_MAPPING = {
        "": "NA",
    };
    /*PBP END*/
    /*INV & GTM*/
    static INV_MS_SALES_ACCESS = "204";
    /*INV & GTM END*/
    static defaultsubfunctionalarea: "All";
    static SEARCH_TYPES = [
        { id: "PartnerOneID", name: "PartnerOne ID" },
        { id: "PartnerOneName", name: "PartnerOne Name" },
        { id: "PartnerOneSubID", name: "PartnerOne Sub ID" },
        { id: "PartnerID", name: "Partner ID" },
    ];

    static REFRESH_DETAILS_CONTROLLER_MAPPING: any = {
        actionpack: "Membership & Training",
        partnerbase: "Membership & Training",
        azureskillsinitiative: "Digital Reporting & Analytics",
        singlepartneroverview: "Partner Performance",
        home: "All",
        newsandannouncements: "Partner Performance",
        partnersearch: "Partner Performance",
        training: "Partner Performance",
        partnercompetencyperformance: "Partner Performance",
        OCPbiexporttracker: "Partner Performance",
        partnerperformance: "Partner Performance",
        distributor: "Partner Performance",
        lsp: "Partner Performance",
        var: "Partner Performance",
        pattool: "Partner Performance",
        error: "Partner Performance",
        ced: "Partner Performance",
        planningtool: "Partner Performance",
    };
    // Nav list used for DEV, UAT
    static NAV_TILE_LIST_NON_PROD: any = {
        "home": [
            {
                "tileId": "OCPLanding",
                "tileText": "Landing Page",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "MUR",
                "tileText": "Most Used Reports",
                "isDefault": false,
                "hasSubPages": true,
                "subPages": [
                    {
                        "tileId": "FTMetrics",
                        "tileText": "FastTrack Metrics",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "FTMWAP",
                        "tileText": "FTA Portfolio",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "FTRAP",
                        "tileText": "Regional Area Portfolio",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "PAP",
                        "tileText": "Prioritized Accounts Performance",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "FTCR",
                        "tileText": "Customer Review",
                        "isDefault": false,
                        "hasSubPages": true,
                        "subPages": [
                            {
                                "tileId": "CRPR",
                                "tileText": "Prioritized",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "CRBR",
                                "tileText": "By Request",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                        ]
                    },
                    {
                        "tileId": "FTFTPL",
                        "tileText": "P2000 & Opportunity",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    //{
                    //    "tileId": "FTList",
                    //    "tileText": "S2200 List",
                    //    "isDefault": false,
                    //    "hasSubPages": false,
                    //    "subPages": []
                    //},
                    {
                        "tileId": "FTFunnel",
                        "tileText": "Funnel",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "FTBIADO",
                        "tileText": "FTBA ADO",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },

                    /* {
                        "tileId": "FY22Insights",
                        "tileText": "FY22 Insights (Preview)",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                   {
                        "tileId": "TOC",
                        "tileText": "TOC",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    }, */
                ]
            },

            {
                "tileId": "MWAO",
                "tileText": "FTA Operations",
                "isDefault": false,
                "hasSubPages": true,
                "subPages": [
                    {
                        "tileId": "CustForecast",
                        "tileText": "Customer Forecast",
                        "isDefault": false,
                        "hasSubPages": true,
                        "subPages": [
                            {
                                "tileId": "PFTFS",
                                "tileText": "Forecast",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []

                            }
                        ]
                    },
                    {
                        "tileId": "FTDQSD",
                        "tileText": "Data Quality",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    //{
                    //    "tileId": "FSISurvey",
                    //    "tileText": "FSI Customer Summary",
                    //    "isDefault": false,
                    //    "hasSubPages": false,
                    //    "subPages": []
                    //},
                    {
                        "tileId": "Compete",
                        "tileText": "Compete dashboard",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    //{
                    //    "tileId": "SalesPlay",
                    //    "tileText": "Sales Play",
                    //    "isDefault": false,
                    //    "hasSubPages": false,
                    //    "subPages": []
                    //},
                    /*{
                        "tileId": "Revenue",
                        "tileText": "Revenue details",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },*/
                    {
                        "tileId": "FRPCustomer",
                        "tileText": "Partner Assigned Tenants",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    }
                ]
            },

            {
                "tileId": "SCIMH",
                "tileText": "OKRs",
                "isDefault": false,
                "hasSubPages": true,
                "subPages": [
                    {
                        "tileId": "OKRsIdentity",
                        "tileText": "Security",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "WorkloadFunnel",
                        "tileText": "Security Workload Funnels",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "OKRIdentity",
                        "tileText": "Identity",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "OKRPURVW",
                        "tileText": "Purview",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    }, {
                        "tileId": "OKRPURVWDPU",
                        "tileText": "Purview DPU",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "PWLF",
                        "tileText": "Purview Workload Funnels",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "FY24CoPilot",
                        "tileText": "M365 Copilot",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "OKRsTeamsPhone",
                        "tileText": "Teams Phone",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },

                    //{
                    //    "tileId": "OKRTEAMS",
                    //    "tileText": "Teams",
                    //    "isDefault": false,
                    //    "hasSubPages": true,
                    //    "subPages": [
                    //        //{
                    //        //    "tileId": "TeamsPhone",
                    //        //    "tileText": "Teams Phone",
                    //        //    "isDefault": false,
                    //        //    "hasSubPages": false,
                    //        //    "subPages": []
                    //        //},
                    //        //{
                    //        //    "tileId": "TeamsRoom",
                    //        //    "tileText": "Teams Room",
                    //        //    "isDefault": false,
                    //        //    "hasSubPages": false,
                    //        //    "subPages": []
                    //        //},
                    //    ]
                    //},
                    //{
                    //    "tileId": "Surface",
                    //    "tileText": "Surface",
                    //    "isDefault": false,
                    //    "hasSubPages": false,
                    //    "subPages": []
                    //},
                    //{
                    //    "tileId": "Windows365",
                    //    "tileText": "Windows 365",
                    //    "isDefault": false,
                    //    "hasSubPages": false,
                    //    "subPages": []
                    //},


                    {
                        "tileId": "OKRsIntune",
                        "tileText": "Intune",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "OKRsView",
                        "tileText": "Viva",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    //{
                    //    "tileId": "FTPilots",
                    //    "tileText": "FT Pilots",
                    //    "isDefault": false,
                    //    "hasSubPages": false,
                    //    "subPages": []
                    //},


                ]
            },

            //{
            //    "tileId": "TPH",
            //    "tileText": "Teams Phone",
            //    "isDefault": false,
            //    "hasSubPages": true,
            //    "subPages": [
            //        {
            //            "tileId": "Teams",
            //            "tileText": "Teams Phone",
            //            "isDefault": false,
            //            "hasSubPages": false,
            //            "subPages": []
            //        }
            //    ]
            //},



            {
                "tileId": "CME",
                "tileText": "By Request",
                "isDefault": false,
                "hasSubPages": true,
                "subPages": [

                    /* {
                         "tileId": "ESCIM",
                         "tileText": "E3 & SCIM",
                         "isDefault": false,
                         "hasSubPages": false,
                         "subPages": []
                     },*/
                    //{
                    //    "tileId": "Windows2",
                    //    "tileText": "Windows 11",
                    //    "isDefault": false,
                    //    "hasSubPages": false,
                    //    "subPages": []
                    //},
                    //{
                    //    "tileId": "OKAP",
                    //    "tileText": "Cloud Managed Endpoints",
                    //    "isDefault": false,
                    //    "hasSubPages": false,
                    //    "subPages": []
                    //},
                    //{
                    //    "tileId": "ReactiveFLW",
                    //    "tileText": "FLW",
                    //    "isDefault": false,
                    //    "hasSubPages": false,
                    //    "subPages": []
                    //},
                    //{
                    //    "tileId": "Edge",
                    //    "tileText": "Edge",
                    //    "isDefault": false,
                    //    "hasSubPages": true,
                    //    "subPages": [

                    //        //{
                    //        //    "tileId": "Adoption",
                    //        //    "tileText": "Adoption < 20%",
                    //        //    "isDefault": true,
                    //        //    "hasSubPages": false,
                    //        //    "subPages": []
                    //        //},

                    //        //{
                    //        //    "tileId": "PBMAU",
                    //        //    "tileText": "PBMAU",
                    //        //    "isDefault": false,
                    //        //    "hasSubPages": false,
                    //        //    "subPages": []
                    //        //}

                    //    ]
                    //},
                    //{
                    //    "tileId": "TP",
                    //    "tileText": "In Product Trials",
                    //    "isDefault": false,
                    //    "hasSubPages": false,
                    //    "subPages": []
                    //},
                    {

                        "tileId": "RFAS",
                        "tileText": "FY24 RFA Dashboard",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "RFAV",
                        "tileText": "FY24 FTC RFA Velocity",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "RFATS",
                        "tileText": "Targeting",
                        "isDefault": false,
                        "hasSubPages": true,
                        "subPages":
                            [
                                {
                                    "tileId": "RFATSTarg",
                                    "tileText": "People Targeting",
                                    "isDefault": true,
                                    "hasSubPages": false,
                                    "subPages": []
                                },
                                {
                                    "tileId": "RFATSTarg_Digital",
                                    "tileText": "Digital Targeting",
                                    "isDefault": true,
                                    "hasSubPages": false,
                                    "subPages": []
                                }
                                //{
                                //    "tileId": "RFATS400",
                                //    "tileText": "T400",
                                //    "isDefault": true,
                                //    "hasSubPages": false,
                                //    "subPages": []
                                //},
                            ]
                    },
                    {
                        "tileId": "RFAVelocity",
                        "tileText": "FRP RFA Velocity",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    }

                ]
            },

            {

                "tileId": "FRP",
                "tileText": "Delivery Modality",
                "isDefault": false,
                "hasSubPages": true,
                "subPages": [

                    {
                        "tileId": "FRPPP",
                        "tileText": "FRP",
                        "isDefault": false,
                        "hasSubPages": true,
                        "subPages": [

                            //{
                            //    "tileId": "ScorecardFM",
                            //    "tileText": "FRP Scorecard",
                            //    "isDefault": false,
                            //    "hasSubPages": false,
                            //    "subPages": []
                            //},
                            {
                                "tileId": "LVFTOP",
                                "tileText": "FRP Dashboard",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },

                            {
                                "tileId": "InternalCPOR",
                                "tileText": "FRP Internal-CPOR Claims & CPOR Usage",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            //{
                            //    "tileId": "CPORClaims",
                            //    "tileText": "FRP Internal-CPOR Claims",
                            //    "isDefault": false,
                            //    "hasSubPages": false,
                            //    "subPages": []
                            //},
                            //{
                            //    "tileId": "Workshops",
                            //    "tileText": "FRP Internal-Workshops",
                            //    "isDefault": false,
                            //    "hasSubPages": false,
                            //    "subPages": []
                            //},
                            {
                                "tileId": "PHE",
                                "tileText": "FRP Internal - Partner Requirement & Health",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "FRPWizard",
                                "tileText": "FRP Internal-ADG",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "FY24Capacity",
                                "tileText": "FPM Capacity",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "FPMRole",
                                "tileText": "FPM Role Dashboard",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "FRPScore",
                                "tileText": "FRP Scorecard",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            }
                            //{
                            //    "tileId": "FRPI",
                            //    "tileText": "FRP Internal-FRP Analysis",
                            //    "isDefault": false,
                            //    "hasSubPages": false,
                            //    "subPages": []
                            //},
                            /*  {
                                  "tileId": "FOA",
                                  "tileText": "FRP Internal-FRP OSU Automation",
                                  "isDefault": false,
                                  "hasSubPages": false,
                                  "subPages": []
                              }, */
                            //{
                            //    "tileId": "FGTT",
                            //    "tileText": "FRP Internal-FRP Gov. Tenants",
                            //    "isDefault": false,
                            //    "hasSubPages": false,
                            //    "subPages": []
                            //},
                            /*  ,
                              {
                                  "tileId": "FTUD",
                                  "tileText": "FT Usage Data",
                                  "isDefault": false,
                                  "hasSubPages": false,
                                  "subPages": []
                              }*/


                            //{
                            //    "tileId": "FRPCustomerSurveyy",
                            //    "tileText": "FRP Customer Survey",
                            //    "isDefault": false,
                            //    "hasSubPages": false,
                            //    "subPages": []
                            //},
                            //{
                            //    "tileId": "FRPSKU",
                            //    "tileText": "FRP SKU Insight",
                            //    "isDefault": false,
                            //    "hasSubPages": false,
                            //    "subPages": [
                            //    ]
                            //},
                            //{
                            //    "tileId": "FRPCapacity",
                            //    "tileText": "FRP Capacity",
                            //    "isDefault": false,
                            //    "hasSubPages": false,
                            //    "subPages": []
                            //},
                            ///*
                            //{
                            //    "tileId": "FRPEligibility",
                            //    "tileText": "FRP FY23 Eligibility",
                            //    "isDefault": false,
                            //    "hasSubPages": false,
                            //    "subPages": []
                            //},


                            //*/

                            //{
                            //    "tileId": "MRPP",
                            //    "tileText": "QTM-MRPP",
                            //    "isDefault": false,
                            //    "hasSubPages": false,
                            //    "subPages": []
                            //},
                            //{
                            //    "tileId": "WINDOWS11",
                            //    "tileText": "QTM-Windows 11",
                            //    "isDefault": false,
                            //    "hasSubPages": false,
                            //    "subPages": []
                            //},
                            //{
                            //    "tileId": "QTMFLW",
                            //    "tileText": "QTM-FLW",
                            //    "isDefault": false,
                            //    "hasSubPages": false,
                            //    "subPages": []
                            //},
                            //{
                            //    "tileId": "QTMEE",
                            //    "tileText": "QTM-Employee Experience (Viva)",
                            //    "isDefault": false,
                            //    "hasSubPages": false,
                            //    "subPages": []
                            //}


                            //{
                            //    "tileId": "CustomerSurvey",
                            //    "tileText": "FRP Customer Survey",
                            //    "isDefault": false,
                            //    "hasSubPages": false,
                            //    "subPages": []
                            //}
                        ]
                    },
                    {
                        "tileId": "CSS",
                        "tileText": "CSS",
                        "isDefault": false,
                        "hasSubPages": true,
                        "subPages": [
                            {
                                "tileId": "CSSCapacity",
                                "tileText": "Capacity",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "CSSTMDashboard",
                                "tileText": "TM Dashboard",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            //{
                            //    "tileId": "CSSFMD",
                            //    "tileText": "FM Dashboard",
                            //    "isDefault": false,
                            //    "hasSubPages": false,
                            //    "subPages": []
                            //},
                            {
                                "tileId": "CSSFED",
                                "tileText": "FE Dashboard",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },

                            /*{
                                "tileId": "AADP",
                                "tileText": "EMS-AADP",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "Intune",
                                "tileText": "EMS-Intune",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "SME",
                                "tileText": "EMS-SME",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "MDM",
                                "tileText": "EMS-MDM",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "TV",
                                "tileText": "EMS-Tasks View",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "CS",
                                "tileText": "EMS-Customer Summary",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "Tasksummary",
                                "tileText": "FTOP V2 Task summary",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "AR",
                                "tileText": "Audit Report",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "STT",
                                "tileText": "Service Task Time",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },*/
                            {
                                "tileId": "CSSSME",
                                "tileText": "SME",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "CSSDP",
                                "tileText": "Delivery Portfolio",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "FTCDashboard",
                                "tileText": "FTC Dashboard",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            }
                        ]
                    },
                    {
                        "tileId": "IPE",
                        "tileText": "IPE",
                        "isDefault": false,
                        "hasSubPages": true,
                        "subPages": [
                            {
                                "tileId": "IPEWizard",
                                "tileText": "ADG Impact",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "IPEProductOwnerView",
                                "tileText": "Product Owner View",
                                "isDefault": true,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "IPEFTOnDemand",
                                "tileText": "FT OnDemand",
                                "isDefault": true,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "IPESLTUsageData",
                                "tileText": "SLT Usage Data",
                                "isDefault": true,
                                "hasSubPages": false,
                                "subPages": []
                            }
                        ]
                    }
                ]
            },


            //{
            //    "tileId": "RFAT",
            //    "tileText": "RFA & Targeting",
            //    "isDefault": false,
            //    "hasSubPages": true,
            //    "subPages": [
            //        {
            //            "tileId": "RFAS",
            //            "tileText": "FY24 RFA Dashboard",
            //            "isDefault": false,
            //            "hasSubPages": false,
            //            "subPages": []
            //        },
            //        {
            //            "tileId": "RFAV",
            //            "tileText": "FY24 FTC RFA Velocity",
            //            "isDefault": false,
            //            "hasSubPages": false,
            //            "subPages": []
            //        },
            //        {
            //            "tileId": "RFATS",
            //            "tileText": "Targeting Summary",
            //            "isDefault": false,
            //            "hasSubPages": true,
            //            "subPages":
            //            [
            //                {
            //                "tileId": "RFATSTarg",
            //                "tileText": "Targeting",
            //                "isDefault": true,
            //                "hasSubPages": false,
            //                "subPages": []
            //                },
            //                {
            //                    "tileId": "RFATS400",
            //                    "tileText": "T400",
            //                    "isDefault": true,
            //                    "hasSubPages": false,
            //                    "subPages": []
            //                },
            //            ]
            //        },
            //        {
            //            "tileId": "RFAVelocity",
            //            "tileText": "FRP RFA Velocity",
            //            "isDefault": false,
            //            "hasSubPages": false,
            //            "subPages": []
            //        }
            //    ]
            //},


            //{
            //    "tileId": "SurfaceTab",
            //    "tileText": "Surface",
            //    "isDefault": false,
            //    "hasSubPages": true,
            //    "subPages": [
            //        {
            //            "tileId": "Surface",
            //            "tileText": "Surface",
            //            "isDefault": false,
            //            "hasSubPages": false,
            //            "subPages": []
            //        }
            //    ]
            //},
            //{
            //    "tileId": "Quality",
            //    "tileText": "Employee Experience",
            //    "isDefault": false,
            //    "hasSubPages": true,
            //    "subPages": [
            //        {
            //            "tileId": "EEFunnel",
            //            "tileText": "Funnel",
            //            "isDefault": false,
            //            "hasSubPages": false,
            //            "subPages": []
            //        }
            //    ]
            //},
            {
                "tileId": "FTCSU",
                "tileText": "FT Operations",
                "isDefault": false,
                "hasSubPages": true,
                "subPages": [
                    {
                        "tileId": "CES",
                        "tileText": "FastTrack VOC",
                        "isDefault": false,
                        "hasSubPages": true,
                        "subPages": [
                            {
                                "tileId": "FY25CustomerVoC",
                                "tileText": "FY25 End Customer VoC",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "CustomerES",
                                "tileText": "Customer Effort Score",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "EVC",
                                "tileText": "FY23 End Customer VoC",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            }
                        ]
                    },
                    {
                        "tileId": "EDMG",
                        "tileText": "EDM Governance",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },

                    // {
                    //     "tileId": "FTCSUU",
                    //     "tileText": "FT CSU Integration",
                    //     "isDefault": false,
                    //     "hasSubPages": true,
                    //     "subPages": [
                    //         {
                    //             "tileId": "FTCSUU_Funnel",
                    //             "tileText": "CSU Funnel",
                    //             "isDefault": false,
                    //             "hasSubPages": false,
                    //             "subPages": []
                    //         }
                    //         , {
                    //             "tileId": "FTCSUU_Definitions",
                    //             "tileText": "Definitions",
                    //             "isDefault": false,
                    //             "hasSubPages": false,
                    //             "subPages": []
                    //         },
                    //         {
                    //             "tileId": "FTCSUU_WorkloadMapping",
                    //             "tileText": "Workload Mapping",
                    //             "isDefault": false,
                    //             "hasSubPages": false,
                    //             "subPages": []
                    //         }
                    //     ]
                    // },
                    {
                        "tileId": "FTUSI",
                        "tileText": "Unified Support Insights",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "RECTDboard",
                        "tileText": "Reactive Dashboard",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    //{
                    //    "tileId": "FTAzure",
                    //    "tileText": "Azure Nominations",
                    //    "isDefault": false,
                    //    "hasSubPages": false,
                    //    "subPages": []
                    //},
                    {
                        "tileId": "SL4_Teams",
                        "tileText": "SL4",
                        "isDefault": false,
                        "hasSubPages": true,
                        "subPages": [
                            {
                                "tileId": "SL4",
                                "tileText": "Teams Premium",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "SL4Usage",
                                "tileText": "FT Teams Premium FY24",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "SL4FTC",
                                "tileText": "FT Copilot",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "FTPDS",
                                "tileText": "FT POCaaS for Data Security",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "ISI",
                                "tileText": "Intune Suite Incubation",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "IAMI",
                                "tileText": "Internet Access M365 Incubation",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "GPDSupport",
                                "tileText": "Glint and Pulse Deployment Support",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            }

                        ]
                    },

                    //{
                    //    "tileId": "PMCD",
                    //    "tileText": "Centralized PM",
                    //    "isDefault": false,
                    //    "hasSubPages": false,
                    //    "subPages": []
                    //},
                    //{
                    //    "tileId": "EndCust",
                    //    "tileText": "End Customer VoC",
                    //    "isDefault": false,
                    //    "hasSubPages": true,
                    //    "subPages": [
                    //        {
                    //            "tileId": "EVC",
                    //            "tileText": "FY23 End Customer VoC",
                    //            "isDefault": false,
                    //            "hasSubPages": false,
                    //            "subPages": []
                    //        }
                    //    ]
                    //},
                    //{
                    //    "tileId": "FTST",
                    //    "tileText": "FT Security Training",
                    //    "isDefault": false,
                    //    "hasSubPages": true,
                    //    "subPages": [
                    //        {
                    //            "tileId": "FTS",
                    //            "tileText": "FT Security Training",
                    //            "isDefault": false,
                    //            "hasSubPages": false,
                    //            "subPages": []
                    //        }
                    //    ]
                    //},

                    //{
                    //    "tileId": "FTH",
                    //    "tileText": "FT Help",
                    //    "isDefault": false,
                    //    "hasSubPages": true,
                    //    "subPages": [
                    //        {
                    //            "tileId": "FTHelp",
                    //            "tileText": "FT Help",
                    //            "isDefault": false,
                    //            "hasSubPages": false,
                    //            "subPages": []
                    //        }
                    //    ]
                    //},
                    {
                        "tileId": "FEED",
                        "tileText": "Feedback",
                        "isDefault": false,
                        "hasSubPages": true,
                        "subPages": [
                            {
                                "tileId": "CJIDashboard",
                                "tileText": "CJI Dashboard",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "FUNN",
                                "tileText": "Funnel",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            // {
                            //     "tileId": "SLA",
                            //     "tileText": "SLA",
                            //     "isDefault": false,
                            //     "hasSubPages": false,
                            //     "subPages": []
                            // },
                            // {
                            //     "tileId": "FBTracking",
                            //     "tileText": "Feedback Tracking",
                            //     "isDefault": false,
                            //     "hasSubPages": false,
                            //     "subPages": []
                            // },
                            // {
                            //     "tileId": "TriageM",
                            //     "tileText": "Triage Meetings (Summary)",
                            //     "isDefault": false,
                            //     "hasSubPages": false,
                            //     "subPages": []
                            // },
                            // {
                            //     "tileId": "GTAFO",
                            //     "tileText": "Global Top Active Feedback by OKR",
                            //     "isDefault": false,
                            //     "hasSubPages": false,
                            //     "subPages": []
                            // },
                            // {
                            //     "tileId": "FSummary",
                            //     "tileText": "Summary",
                            //     "isDefault": false,
                            //     "hasSubPages": false,
                            //     "subPages": []
                            // },
                            //{
                            //    "tileId": "VoteCamp",
                            //    "tileText": "+1 Vote Campaign",
                            //    "isDefault": false,
                            //    "hasSubPages": false,
                            //    "subPages": []
                            //},
                            //{
                            //    "tileId": "TAFItems",
                            //    "tileText": "Top Active Feedback Items",
                            //    "isDefault": false,
                            //    "hasSubPages": false,
                            //    "subPages": []
                            //},
                            //{
                            //    "tileId": "SPFee",
                            //    "tileText": "Strategic Product Feedback (Preview)",
                            //    "isDefault": false,
                            //    "hasSubPages": false,
                            //    "subPages": []
                            //},
                            // {
                            //     "tileId": "FReportD",
                            //     "tileText": "Report Definitions",
                            //     "isDefault": false,
                            //     "hasSubPages": false,
                            //     "subPages": []
                            // }
                        ]
                    },
                    /*{
                        "tileId": "REPDash",
                        "tileText": "REP Dashboard",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    }*/
                ]
            },





            {
                "tileId": "BIOps",
                "tileText": "BI Operations",
                "isDefault": false,
                "hasSubPages": true,
                "subPages": [
                    {
                        "tileId": "Usage",
                        "tileText": "Usage Metrics Report",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "WorkspaceAudit",
                        "tileText": "Workspace Audit",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },

                ]
            },
            {
                "tileId": "CPT",
                "tileText": "Fasttrack Shared Services - Release Team",
                "isDefault": false,
                "hasSubPages": true,
                "subPages": [
                    //{
                    //    "tileId": "SolutionArea",
                    //    "tileText": "Solution Area Dashboard",
                    //    "isDefault": false,
                    //    "hasSubPages": false,
                    //    "subPages": []
                    //},
                    {
                        "tileId": "WorkItemBreakdown",
                        "tileText": "Work Item Breakdown",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "MetricsReport",
                        "tileText": "Release Metrics",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    //{
                    //    "tileId": "PMPlaybook",
                    //    "tileText": "Playbook Article Life Cycle",
                    //    "isDefault": false,
                    //    "hasSubPages": false,
                    //    "subPages": []
                    //},
                    {
                        "tileId": "Release",
                        "tileText": "Release Dashboard",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    //{
                    //    "tileId": "Feature",
                    //    "tileText": "Release & Transformation",
                    //    "isDefault": false,
                    //    "hasSubPages": false,
                    //    "subPages": []
                    //},
                    //{
                    //    "tileId": "PlayMet",
                    //    "tileText": "Playbook Metrics",
                    //    "isDefault": false,
                    //    "hasSubPages": false,
                    //    "subPages": []
                    //},
                    {
                        "tileId": "FastTrack",
                        "tileText": "FastTrack Roadmap",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "CentralPMTeam",
                        "tileText": "Capacity Distribution",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    }
                ]
            },
            {
                "tileId": "CPTS",
                "tileText": "Playbook Reports",
                "isDefault": false,
                "hasSubPages": true,
                "subPages": [

                    {
                        "tileId": "PMPlaybook",
                        "tileText": "Playbook Article Life Cycle",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "PlayMet",
                        "tileText": "Playbook Metrics",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    }

                ]
            }
        ]
    }
    // Nav list used for Production
    static NAV_TILE_LIST: any = {
        "home": [
            {
                "tileId": "OCPLanding",
                "tileText": "Landing Page",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            //{
            //    "tileId": "RFATSTarg_EngN",
            //    "tileText": "Engagement Notes",
            //    "isDefault": true,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            {
                "tileId": "MUR",
                "tileText": "Most Used Reports",
                "isDefault": false,
                "hasSubPages": true,
                "subPages": [
                    {
                        "tileId": "FTMetrics",
                        "tileText": "FastTrack Metrics",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "FTMWAP",
                        "tileText": "FTA Portfolio",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "FTRAP",
                        "tileText": "Regional Area Portfolio",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "PAP",
                        "tileText": "Prioritized Accounts Performance",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "FTCR",
                        "tileText": "Customer Review",
                        "isDefault": false,
                        "hasSubPages": true,
                        "subPages": [
                            {

                                "tileId": "CRPR",
                                "tileText": "Prioritized",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {

                                "tileId": "CRBR",
                                "tileText": "By Request",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },

                        ]
                    },

                    {
                        "tileId": "FTFTPL",
                        "tileText": "P2000 & Opportunity",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    //{
                    //    "tileId": "FTList",
                    //    "tileText": "S2200 List",
                    //    "isDefault": false,
                    //    "hasSubPages": false,
                    //    "subPages": []
                    //},
                    {
                        "tileId": "FTFunnel",
                        "tileText": "Funnel",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "FTBIADO",
                        "tileText": "FTBA ADO",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                ]
            },

            {
                "tileId": "MWAO",
                "tileText": "FTA Operations",
                "isDefault": false,
                "hasSubPages": true,
                "subPages": [
                    {
                        "tileId": "CustForecast",
                        "tileText": "Customer Forecast",
                        "isDefault": false,
                        "hasSubPages": true,
                        "subPages": [
                            {
                                "tileId": "PFTFS",
                                "tileText": "Forecast",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            }
                        ]
                    },
                    {
                        "tileId": "FTDQSD",
                        "tileText": "Data Quality",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    //{
                    //    "tileId": "FSISurvey",
                    //    "tileText": "FSI Customer Summary",
                    //    "isDefault": false,
                    //    "hasSubPages": false,
                    //    "subPages": []
                    //},
                    {
                        "tileId": "Compete",
                        "tileText": "Compete dashboard",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    //{
                    //    "tileId": "SalesPlay",
                    //    "tileText": "Sales Play",
                    //    "isDefault": false,
                    //    "hasSubPages": false,
                    //    "subPages": []
                    //},
                    /* {
                         "tileId": "Revenue",
                         "tileText": "Revenue details",
                         "isDefault": false,
                         "hasSubPages": false,
                         "subPages": []
                     },*/
                ]
            },

            {
                "tileId": "SCIMH",
                "tileText": "OKRs",
                "isDefault": false,
                "hasSubPages": true,
                "subPages": [
                    {
                        "tileId": "Security",
                        "tileText": "Security",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "WorkloadFunnel",
                        "tileText": "Security Workload Funnels",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "Identity",
                        "tileText": "Identity",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "Purview",
                        "tileText": "Purview",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "PurviewDPU",
                        "tileText": "Purview DPU",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "PurviewWorkloadFunnel",
                        "tileText": "Purview Workload Funnels",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "FY24CoPilot",
                        "tileText": "M365 Copilot",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "TeamsPhone",
                        "tileText": "Teams Phone",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "OKR",
                        "tileText": "Intune",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "EEFunnel",
                        "tileText": "Viva",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    //{
                    //    "tileId": "FTPilots",
                    //    "tileText": "FT Pilots",
                    //    "isDefault": false,
                    //    "hasSubPages": false,
                    //    "subPages": []
                    //},
                    //{
                    //    "tileId": "OKRTEAMS",
                    //    "tileText": "Teams",
                    //    "isDefault": false,
                    //    "hasSubPages": true,
                    //    "subPages": [
                    //        {
                    //            "tileId": "TeamsPhone",
                    //            "tileText": "Teams Phone",
                    //            "isDefault": false,
                    //            "hasSubPages": false,
                    //            "subPages": []
                    //        },
                    //        {
                    //            "tileId": "TeamsRoom",
                    //            "tileText": "Teams Room",
                    //            "isDefault": false,
                    //            "hasSubPages": false,
                    //            "subPages": []
                    //        },
                    //    ]
                    //},
                    //{
                    //    "tileId": "Surface",
                    //    "tileText": "Surface",
                    //    "isDefault": false,
                    //    "hasSubPages": false,
                    //    "subPages": []
                    //},
                    //{
                    //    "tileId": "Windows365",
                    //    "tileText": "Windows 365",
                    //    "isDefault": false,
                    //    "hasSubPages": false,
                    //    "subPages": []
                    //},                      

                ]
            },

            //{
            //    "tileId": "TPH",
            //    "tileText": "Teams Phone",
            //    "isDefault": false,
            //    "hasSubPages": true,
            //    "subPages": [
            //        {
            //            "tileId": "Teams",
            //            "tileText": "Teams Phone",
            //            "isDefault": false,
            //            "hasSubPages": false,
            //            "subPages": []
            //        }
            //    ]
            //},



            {
                "tileId": "CME",
                "tileText": "By Request",
                "isDefault": false,
                "hasSubPages": true,
                "subPages": [

                    /* {
                         "tileId": "ESCIM",
                         "tileText": "E3 & SCIM",
                         "isDefault": false,
                         "hasSubPages": false,
                         "subPages": []
                     },*/
                    //{
                    //    "tileId": "Windows2",
                    //    "tileText": "Windows 11",
                    //    "isDefault": false,
                    //    "hasSubPages": false,
                    //    "subPages": []
                    //},
                    //{
                    //    "tileId": "OKAP",
                    //    "tileText": "Cloud Managed Endpoints",
                    //    "isDefault": false,
                    //    "hasSubPages": false,
                    //    "subPages": []
                    //},
                    //{
                    //    "tileId": "ReactiveFLW",
                    //    "tileText": "FLW",
                    //    "isDefault": false,
                    //    "hasSubPages": false,
                    //    "subPages": []
                    //},
                    //{
                    //    "tileId": "Edge",
                    //    "tileText": "Edge",
                    //    "isDefault": false,
                    //    "hasSubPages": true,
                    //    "subPages": [

                    //        //{
                    //        //    "tileId": "Adoption",
                    //        //    "tileText": "Adoption < 20%",
                    //        //    "isDefault": true,
                    //        //    "hasSubPages": false,
                    //        //    "subPages": []
                    //        //},

                    //        //{
                    //        //    "tileId": "PBMAU",
                    //        //    "tileText": "PBMAU",
                    //        //    "isDefault": false,
                    //        //    "hasSubPages": false,
                    //        //    "subPages": []
                    //        //}

                    //    ]
                    //},
                    //{
                    //    "tileId": "TP",
                    //    "tileText": "In Product Trials",
                    //    "isDefault": false,
                    //    "hasSubPages": false,
                    //    "subPages": []
                    //},
                    {

                        "tileId": "RFAS",
                        "tileText": "FY24 RFA Dashboard",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "RFAV",
                        "tileText": "FY24 FTC RFA Velocity",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "RFATS",
                        "tileText": "Targeting Summary",
                        "isDefault": false,
                        "hasSubPages": true,
                        "subPages":
                            [
                                {
                                    "tileId": "RFATSTarg",
                                    "tileText": "Targeting",
                                    "isDefault": true,
                                    "hasSubPages": false,
                                    "subPages": []
                                },
                                //{
                                //    "tileId": "RFATS400",
                                //    "tileText": "T400",
                                //    "isDefault": true,
                                //    "hasSubPages": false,
                                //    "subPages": []
                                //},
                            ]
                    },
                    {
                        "tileId": "RFAVelocity",
                        "tileText": "FRP RFA Velocity",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    }

                ]
            },

            //{
            //    "tileId": "SurfaceTab",
            //    "tileText": "Surface",
            //    "isDefault": false,
            //    "hasSubPages": true,
            //    "subPages": [
            //        {
            //            "tileId": "Surface",
            //            "tileText": "Surface",
            //            "isDefault": false,
            //            "hasSubPages": false,
            //            "subPages": []
            //        }
            //    ]
            //},
            /*  {
                  "tileId": "Quality",
                  "tileText": "Employee Experience",
                  "isDefault": false,
                  "hasSubPages": true,
                  "subPages": [
                      {
                          "tileId": "EEFunnel",
                          "tileText": "Funnel",
                          "isDefault": false,
                          "hasSubPages": false,
                          "subPages": []
                      }
                      //,{
                      //    "tileId": "EEUsage",
                      //    "tileText": "Usage",
                      //    "isDefault": false,
                      //    "hasSubPages": false,
                      //    "subPages": []
                      //}
                  ]
              }, */


            {
                "tileId": "FRP",
                "tileText": "Delivery Modality",
                "isDefault": false,
                "hasSubPages": true,
                "subPages": [
                    {
                        "tileId": "FRPP",
                        "tileText": "FRP",
                        "isDefault": false,
                        "hasSubPages": true,
                        "subPages": [
                            //{
                            //    "tileId": "ScorecardFM",
                            //    "tileText": "FRP Scorecard",
                            //    "isDefault": false,
                            //    "hasSubPages": false,
                            //    "subPages": [
                            //    ]
                            //},
                            {
                                "tileId": "LVFTOP",
                                "tileText": "FRP Dashboard",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "InternalCPORClaims",
                                "tileText": "FRP Internal-CPOR Claims & CPOR Usage",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            //{
                            //    "tileId": "CPORClaims",
                            //    "tileText": "FRP Internal-CPOR Claims",
                            //    "isDefault": false,
                            //    "hasSubPages": false,
                            //    "subPages": []
                            //},
                            //{
                            //    "tileId": "Workshops",
                            //    "tileText": "FRP Internal-Workshops",
                            //    "isDefault": false,
                            //    "hasSubPages": false,
                            //    "subPages": []
                            //},
                            {
                                "tileId": "PHE",
                                "tileText": "FRP Internal - Partner Requirement & Health",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "FRPWizard",
                                "tileText": "FRP Internal-ADG",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "FRPFPMCapacity",
                                "tileText": "FPM Capacity",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "FRPRDB",
                                "tileText": "FPM Role Dashboard",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            }
                        ]
                    },
                    {
                        "tileId": "CSS",
                        "tileText": "CSS",
                        "isDefault": false,
                        "hasSubPages": true,
                        "subPages": [
                            {
                                "tileId": "CSSCapacity",
                                "tileText": "Capacity",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "CSSTMDashboard",
                                "tileText": "TM Dashboard",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "CSSFED",
                                "tileText": "FE Dashboard",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },

                            /*{
                                "tileId": "AADP",
                                "tileText": "EMS-AADP",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "Intune",
                                "tileText": "EMS-Intune",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "SME",
                                "tileText": "EMS-SME",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "MDM",
                                "tileText": "EMS-MDM",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "TV",
                                "tileText": "EMS-Tasks View",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "CS",
                                "tileText": "EMS-Customer Summary",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "Tasksummary",
                                "tileText": "FTOP V2 Task summary",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "AR",
                                "tileText": "Audit Report",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "STT",
                                "tileText": "Service Task Time",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },*/
                            {
                                "tileId": "CSSSME",
                                "tileText": "SME",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "CSSDP",
                                "tileText": "Delivery Portfolio",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "FTCFTCDB",
                                "tileText": "FTC Dashboard",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            }
                        ]
                    },
                    {
                        "tileId": "IPE",
                        "tileText": "IPE",
                        "isDefault": false,
                        "hasSubPages": true,
                        "subPages": [
                            {
                                "tileId": "IPEWizard",
                                "tileText": "ADG Impact",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "IPEProductOwnerView",
                                "tileText": "Product Owner View",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "IPEFTOnDemand",
                                "tileText": "FT OnDemand",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "IPESLTUsageData",
                                "tileText": "SLT Usage Data",
                                "isDefault": true,
                                "hasSubPages": false,
                                "subPages": []
                            }

                        ]
                    },


                ]
            },

            //{
            //    "tileId": "RFAT",
            //    "tileText": "RFA & Targeting",
            //    "isDefault": false,
            //    "hasSubPages": true,
            //    "subPages": [
            //        {
            //            "tileId": "RFAS",
            //            "tileText": " FY24 RFA Dashboard",
            //            "isDefault": false,
            //            "hasSubPages": false,
            //            "subPages": []
            //        },
            //        {
            //            "tileId": "RFAV",
            //            "tileText": "FY24 FTC RFA Velocity",
            //            "isDefault": false,
            //            "hasSubPages": false,
            //            "subPages": []
            //        },
            //        {
            //            "tileId": "RFATS",
            //            "tileText": "Targeting Summary",
            //            "isDefault": false,
            //            "hasSubPages": true,
            //            "subPages": [
            //                {
            //                    "tileId": "RFATSTarg",
            //                    "tileText": "Targeting",
            //                    "isDefault": true,
            //                    "hasSubPages": false,
            //                    "subPages": []
            //                },
            //                {
            //                    "tileId": "RFATS400",
            //                    "tileText": "T400",
            //                    "isDefault": true,
            //                    "hasSubPages": false,
            //                    "subPages": []
            //                }
            //            ]
            //        },
            //        {
            //            "tileId": "RFAVelocity",
            //            "tileText": "FRP RFA Velocity",
            //            "isDefault": false,
            //            "hasSubPages": false,
            //            "subPages": []
            //        }
            //    ]
            //},
            {
                "tileId": "FTCSU",
                "tileText": "FT Operations",
                "isDefault": false,
                "hasSubPages": true,
                "subPages": [

                    {
                        "tileId": "CES",
                        "tileText": "FastTrack VOC",
                        "isDefault": false,
                        "hasSubPages": true,
                        "subPages": [
                            {
                                "tileId": "FY25CustomerVoC",
                                "tileText": " FY25 End Customer VoC",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "CustomerES",
                                "tileText": "Customer Effort Score",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "EVC",
                                "tileText": "FY23 End Customer VoC",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            }
                        ]
                    },
                    {
                        "tileId": "EDMG",
                        "tileText": "EDM Governance",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },

                    // {
                    //     "tileId": "FTCSUU",
                    //     "tileText": "FT CSU Integration",
                    //     "isDefault": false,
                    //     "hasSubPages": true,
                    //     "subPages": [
                    //         , {
                    //             "tileId": "FTCSUUFL",
                    //             "tileText": "CSU Funnel",
                    //             "isDefault": false,
                    //             "hasSubPages": false,
                    //             "subPages": []
                    //         }
                    //         , {
                    //             "tileId": "FTCSNOTIF",
                    //             "tileText": "Notifications",
                    //             "isDefault": false,
                    //             "hasSubPages": false,
                    //             "subPages": []
                    //         }
                    //         , {
                    //             "tileId": "FTCSUUDef",
                    //             "tileText": "Definitions",
                    //             "isDefault": false,
                    //             "hasSubPages": false,
                    //             "subPages": []
                    //         }
                    //         , {
                    //             "tileId": "FTCSUUWork",
                    //             "tileText": "Workload Mapping",
                    //             "isDefault": false,
                    //             "hasSubPages": false,
                    //             "subPages": []
                    //         }
                    //     ]
                    // },
                    {
                        "tileId": "FTUSI",
                        "tileText": "Unified Support Insights",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "FTORD",
                        "tileText": "Reactive Dashboard",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "SL4_Teams",
                        "tileText": "SL4",
                        "isDefault": false,
                        "hasSubPages": true,
                        "subPages": [
                            {
                                "tileId": "SL4",
                                "tileText": "Teams Premium",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "SL4Usage",
                                "tileText": "FT Teams Premium FY24",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "SL4FTC",
                                "tileText": "FT Copilot",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "FTPDS",
                                "tileText": "FT POCaaS for Data Security",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "ISI",
                                "tileText": "Intune Suite Incubation",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "IAMI",
                                "tileText": "Internet Access M365 Incubation",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "GlintPulseDeployment",
                                "tileText": "Glint and Pulse Deployment Support",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            }

                        ]
                    },
                    //{
                    //    "tileId": "PMCD",
                    //    "tileText": "Centralized PM",
                    //    "isDefault": false,
                    //    "hasSubPages": false,
                    //    "subPages": []
                    //},
                    //{
                    //    "tileId": "EndCust",
                    //    "tileText": "End Customer VoC",
                    //    "isDefault": false,
                    //    "hasSubPages": true,
                    //    "subPages": [
                    //        {
                    //            "tileId": "EVC",
                    //            "tileText": "End Customer VoC",
                    //            "isDefault": false,
                    //            "hasSubPages": false,
                    //            "subPages": []
                    //        }
                    //    ]
                    //},

                    {
                        "tileId": "FTST",
                        "tileText": "FT Security Training",
                        "isDefault": false,
                        "hasSubPages": true,
                        "subPages": [
                            {
                                "tileId": "FTS",
                                "tileText": "FT Security Training",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            }
                        ]
                    },

                    //{
                    //    "tileId": "FTH",
                    //    "tileText": "FT Help",
                    //    "isDefault": false,
                    //    "hasSubPages": true,
                    //    "subPages": [
                    //        {
                    //            "tileId": "FTHelp",
                    //            "tileText": "FT Help",
                    //            "isDefault": false,
                    //            "hasSubPages": false,
                    //            "subPages": []
                    //        }
                    //    ]
                    //},
                    {
                        "tileId": "FEED",
                        "tileText": "Feedback",
                        "isDefault": false,
                        "hasSubPages": true,
                        "subPages": [
                            {
                                "tileId": "CJIDashboard",
                                "tileText": "CJI Dashboard",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "FUNN",
                                "tileText": "Funnel",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            // {
                            //     "tileId": "SLA",
                            //     "tileText": "SLA",
                            //     "isDefault": false,
                            //     "hasSubPages": false,
                            //     "subPages": []
                            // },
                            /*{
                                "tileId": "FY23Insights",
                                "tileText": "FY23 Insights",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },
                            {
                                "tileId": "IMP",
                                "tileText": "Impact",
                                "isDefault": false,
                                "hasSubPages": false,
                                "subPages": []
                            },*/
                            // {
                            //     "tileId": "FBTracking",
                            //     "tileText": "Feedback Tracking",
                            //     "isDefault": false,
                            //     "hasSubPages": false,
                            //     "subPages": []
                            // },
                            // {
                            //     "tileId": "TriageM",
                            //     "tileText": "Triage Meetings (Summary)",
                            //     "isDefault": false,
                            //     "hasSubPages": false,
                            //     "subPages": []
                            // },
                            // {
                            //     "tileId": "GTAFO",
                            //     "tileText": "Global Top Active Feedback by OKR",
                            //     "isDefault": false,
                            //     "hasSubPages": false,
                            //     "subPages": []
                            // },
                            // {
                            //     "tileId": "FSummary",
                            //     "tileText": "Summary",
                            //     "isDefault": false,
                            //     "hasSubPages": false,
                            //     "subPages": []
                            // },

                            // {
                            //     "tileId": "FReportD",
                            //     "tileText": "Report Definitions",
                            //     "isDefault": false,
                            //     "hasSubPages": false,
                            //     "subPages": []
                            // }

                        ]
                    }
                    /*{
                        "tileId": "REPDash",
                        "tileText": "REP Dashboard",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    }*/
                ]
            },


            {
                "tileId": "BIOps",
                "tileText": "BI Operations",
                "isDefault": false,
                "hasSubPages": true,
                "subPages": [
                    {
                        "tileId": "Usage",
                        "tileText": "Usage Metrics Report",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "WorkspaceAudit",
                        "tileText": "Workspace Audit",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "DTLData",
                        "tileText": "DTL Data flow Details Report ",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "DTLUMR",
                        "tileText": "DTL Usage Metric Report",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "DTLPipe",
                        "tileText": "DTL Pipeline and Row Count Report",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    }

                ]
            },
            {
                "tileId": "CPT",
                "tileText": "Fasttrack Shared Services - Release Team",
                "isDefault": false,
                "hasSubPages": true,
                "subPages": [
                    //{
                    //    "tileId": "SolutionArea",
                    //    "tileText": "Solution Area Dashboard",
                    //    "isDefault": false,
                    //    "hasSubPages": false,
                    //    "subPages": []
                    //},
                    {
                        "tileId": "WorkItemBreakdown",
                        "tileText": "Work Item Breakdown",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "RelMetrics",
                        "tileText": "Release Metrics",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    //{
                    //    "tileId": "PMPlaybook",
                    //    "tileText": "Playbook Article Life Cycle",
                    //    "isDefault": false,
                    //    "hasSubPages": false,
                    //    "subPages": []
                    //},
                    {
                        "tileId": "Release",
                        "tileText": "Release Dashboard",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    //{
                    //    "tileId": "Feature",
                    //    "tileText": "Release & Transformation",
                    //    "isDefault": false,
                    //    "hasSubPages": false,
                    //    "subPages": []
                    //},
                    //{
                    //    "tileId": "PlayMet",
                    //    "tileText": "Playbook Metrics",
                    //    "isDefault": false,
                    //    "hasSubPages": false,
                    //    "subPages": []
                    //},
                    {
                        "tileId": "FastTrack",
                        "tileText": "FastTrack Roadmap",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "CentralPMTeam",
                        "tileText": "Capacity Distribution",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    }
                ]
            },

            {
                "tileId": "PALC",
                "tileText": "Playbook Reports",
                "isDefault": false,
                "hasSubPages": true,
                "subPages": [

                    {
                        "tileId": "PMPlaybook",
                        "tileText": "Playbook Article Life Cycle",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },
                    {
                        "tileId": "PlayMet",
                        "tileText": "Playbook Metrics",
                        "isDefault": false,
                        "hasSubPages": false,
                        "subPages": []
                    },

                ]
            }
        ]
    };

    static FIFTH_LEVEL_TILE_LIST: any = {};
    // Horizontal Tile List for DEV and UAT
    static HORIZONTAL_TILE_LIST_NON_PROD: any = {
        "FRPI": [
            {
                "tileId": "FRPI_Velocity",
                "tileText": "FRP CPOR - Velocity",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            /* {
                 "tileId": "FRPI_Tenant_SKU",
                 "tileText": "FRP Tenant SKU Insight",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             } */
        ],
        "FRPWizard": [
            {
                "tileId": "FRPWizard",
                "tileText": "FRP Internal-ADG",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }

        ],

        "FY24Capacity": [
            {
                "tileId": "FY24Capacity_FY24C",
                "tileText": "FPM Capacity",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FY24Capacity_PartnerSegmentation",
                "tileText": "Partner Segmentation",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "FPMRole": [
            {
                "tileId": "FPMRole_FPMRoleDb",
                "tileText": "Partner View",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "FRPScore": [
            {
                "tileId": "FRPScore_FRPScorecard",
                "tileText": "FRP Scorecard",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FRPScore_Definitions",
                "tileText": "Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            
            }
        ],


        "FTAzure": [
            {
                "tileId": "FTAzure_Summary",
                "tileText": "Summary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTAzure_Def",
                "tileText": "Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "EDMG": [
            {
                "tileId": "EDMG_Governance",
                "tileText": "EDM Governance",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "EDMG_EDMNoReasons",
                "tileText": "EDM No Reasons",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "RECTDboard": [
            {
                "tileId": "RECTDboard_Performance",
                "tileText": "Performance",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RECTDboard_AreaPerformance",
                "tileText": "Area Performance",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RECTDboard_Intent",
                "tileText": "Intent",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RECTDboard_TeamsPremium",
                "tileText": "Teams Premium Features",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RECTDboard_RelatedReports",
                "tileText": "Related Reports",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "FTUSI": [
            {
                "tileId": "FTUSI_SupportAO",
                "tileText": "Support Agreement Overview",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTUSI_Designated",
                "tileText": "Designated Engineering",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTUSI_EnhancedDE",
                "tileText": "Enhanced Designated Engineering",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTUSI_UnifiedSDD",
                "tileText": "Unified Support Delivery Details",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },

            {
                "tileId": "FTUSI_Definitions",
                "tileText": "Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTUSI_ByCustomer",
                "tileText": "By Customer",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "EVC": [
            {
                "tileId": "EVC_Overview",
                "tileText": "Overview",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "EVC_Themes",
                "tileText": "Themes",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            /*{
                "tileId": "EVC_H1SC",
                "tileText": "MS Direct H1 Scorecard",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "EVC_H2SC",
                "tileText": "MS Direct H2 Scorecard",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "EVC_FRPH2SC",
                "tileText": "FRP H2 Scorecard",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },*/
            {
                "tileId": "EVC_Global",
                "tileText": "Global VoC Submissions Scorecard",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "EVC_PLVoC",
                "tileText": "Partner Led VoC",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "EVC_Def",
                "tileText": "Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "MRPP": [
            {
                "tileId": "MRPP_OB",
                "tileText": "Onboarding",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "MRPP_Reg",
                "tileText": "Registration",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "MRPP_Sell",
                "tileText": "Sell Through",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "MRPP_Act",
                "tileText": "Activation",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "MRPP_Payment",
                "tileText": "Payment",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        //"WINDOWS11": [
        //    {
        //        "tileId": "WINDOWS11_POEDATA",
        //        "tileText": "Windows 11 POE Data",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "WINDOWS11_USAGEDATA",
        //        "tileText": "Windows 11 Usage Data",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }
        //],
        //"QTMFLW": [
        //    {
        //        "tileId": "QTMFLW_FSKU",
        //        "tileText": "F-SKU Usage Data",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }
        //],
        //"QTMEE": [
        //    {
        //        "tileId": "QTMEE_VIVAPOE",
        //        "tileText": "Viva POE",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "QTMEE_VIVAUSAGE",
        //        "tileText": "Viva Usage - Partner Claimed",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }
        //],
        "RFAVelocity": [
            {
                "tileId": "RFAVelocity_Assignment",
                "tileText": "RFAs by Assignment",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFAVelocity_Partner",
                "tileText": "RFAs by Partner",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            //{
            //    "tileId": "RFAVelocity_FCC",
            //    "tileText": "First Customer Contact",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            /* {
                 "tileId": "RFAVelocity_Registration",
                 "tileText": "Registrations (Claims)",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             }, */
            {
                "tileId": "RFAVelocity_Reassignments",
                "tileText": "Reassignments",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFAVelocity_ReportDef",
                "tileText": "Report Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFAVelocity_AssignmentReason",
                "tileText": "Assignment Reason Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
        ],
        "Subs": [
            {
                "tileId": "Subs_Top",
                "tileText": "TOP Submissions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Subs_Submissions",
                "tileText": "Submissions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Subs_Rank",
                "tileText": "Submitters Ranking",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Subs_Occurences",
                "tileText": "Occurrences(+ 1) Ranking",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "CJIDashboard": [

            //{
            //    "tileId": "CJIDashboard_Guidance",
            //    "tileText": "Guidance",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},

            {
                "tileId": "CJIDashboard_Tracking",
                "tileText": "Tracking",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CJIDashboard_Trending",
                "tileText": "Trending",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CJIDashboard_Submitter",
                "tileText": "Submitter",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }

        ],

        "FUNN": [
            {
                "tileId": "FUNN_FUN",
                "tileText": "Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "SLA": [
            {
                "tileId": "SLA_SLA",
                "tileText": "SLA",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
            //{
            //    "tileId": "SLA_Trend",
            //    "tileText": "SLA Trend (Preview)",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
        ],

        "FBTracking": [
            {
                "tileId": "FBTracking_Feedback",
                "tileText": "Feedback Tracking",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "TriageM": [
            {
                "tileId": "TriageM_Meetings",
                "tileText": "Triage  Meetings (Summary)",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "GTAFO": [
            {
                "tileId": "GTAFO_Global",
                "tileText": "Global Top Active Feedback by OKR",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "FSummary": [
            {
                "tileId": "FSummary_Summary",
                "tileText": "Summary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],


        "FReportD": [
            {
                "tileId": "FReportD_Definitions",
                "tileText": "Report Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "FY23Insights": [
            {
                "tileId": "FY23_Insights",
                "tileText": "FY23 Insights",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "SC": [
            {
                "tileId": "SC_Summary",
                "tileText": "Summary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "VoteCamp": [
            {
                "tileId": "ONE_VoteCamp",
                "tileText": "+1 Vote Campaign",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "TAFItems": [
            {
                "tileId": "GTAF_Items",
                "tileText": "Global Top Active Feedback Items",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "ATAF_Items",
                "tileText": "America's Top Active Feedback Items",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "ATAF_EOU_Items",
                "tileText": "America's Top Active Feedback Items (Includes EOU and +1 details)",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "AAF_Items",
                "tileText": "All Active Feedback Items",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "SPFee": [
            {
                "tileId": "SPFee_Preview",
                "tileText": "Strategic Product Feedback (Preview)",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "FeedReport": [
            {
                "tileId": "FeedReport_RPT",
                "tileText": "Report Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "SL4": [
            {
                "tileId": "SL4_TeamsPremimum",
                "tileText": "Teams Premium",
                "isDefault": false,
                "hasSubPages": true,
                "subPages": []
            },
        ],
        "GPDSupport": [
            {
                "tileId": "GPDSupport_GPDeploymentSupport",
                "tileText": "Glint and Pulse Deployment Support",
                "isDefault": false,
                "hasSubPages": true,
                "subPages": []
            }

        ],

        "SL4Usage": [
            {
                "tileId": "SL4Usage_Usage",
                "tileText": "Usage",
                "isDefault": false,
                "hasSubPages": true,
                "subPages": []
            },
        ],

        "SL4FTC": [
            {
                "tileId": "SL4FTC_FTCopilot",
                "tileText": "FT Copilot",
                "isDefault": false,
                "hasSubPages": true,
                "subPages": []
            },
        ],

        "FTPDS": [
            {
                "tileId": "FTPDS_FTPDataSecurity",
                "tileText": "FT POCaaS for Data Security",
                "isDefault": false,
                "hasSubPages": true,
                "subPages": []
            },
        ],

        "ISI": [
            {
                "tileId": "ISI_ISIncubation",
                "tileText": "Intune Suite Incubation",
                "isDefault": false,
                "hasSubPages": true,
                "subPages": []
            },
        ],

        "IAMI": [
            {
                "tileId": "IAMI_IAIncubation",
                "tileText": "Internet Access M365 Incubation",
                "isDefault": false,
                "hasSubPages": true,
                "subPages": []
            },
        ],

        /*"REPDash": [
            {
                "tileId": "REPDash_Role",
                "tileText": "Role Clarity ",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "REPDash_Capacity",
                "tileText": "Capacity",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "REPDash_Enablement",
                "tileText": "Enablement",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
    
    
        ],*/

        "Windows1": [
            {
                "tileId": "Windows1_TD",
                "tileText": "Task Details",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        //"Windows2": [
        //    {
        //        "tileId": "Windows2_CAF",
        //        "tileText": "Windows 11 Metrics Funnel",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    /* {
        //         "tileId": "Windows2_Actual",
        //         "tileText": "Actual V/ s Targets",
        //         "isDefault": false,
        //         "hasSubPages": false,
        //         "subPages": []
        //     },*/
        //    {
        //        "tileId": "Windows2_MoM",
        //        "tileText": "MoM Performance",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    /* {
        //         "tileId": "Windows2_TC",
        //         "tileText": "Targeting Customers",
        //         "isDefault": false,
        //         "hasSubPages": false,
        //         "subPages": []
        //     }, */
        //    {
        //        "tileId": "Windows2_RD",
        //        "tileText": "Report Definitions",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }
        //],
        "FRPEligibility": [
            {
                "tileId": "FRPEligibility_FY22",
                "tileText": "FRP FY23 Eligibility",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            /*{
                "tileId": "FRPEligibility_Insight",
                "tileText": "FRP FY23 Eligibility Insight",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            } */
        ],
        "CSSCapacity": [
            //{
            //    "tileId": "CSSCapacity_Intro",
            //    "tileText": "Intro",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            {
                "tileId": "CSSCapacity_CT",
                "tileText": "Customer Time",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
            /*,{
                "tileId": "CSSCapacity_A",
                "tileText": "Assignment",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSCapacity_CTPR",
                "tileText": "(MPR) - % Customer Time Per Region",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSCapacity_TT",
                "tileText": "(MPR) - Tracked Time",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSCapacity_PTT",
                "tileText": "(MPR) - % Tracked Time",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSCapacity_MCTT",
                "tileText": "(MPR) - % Customer Time Trend",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSCapacity_RCTT",
                "tileText": "Res.w / Customer Time Trend",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSCapacity_Headcount",
                "tileText": "SCIM Headcount Details",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSCapacity_IC",
                "tileText": "Active / Inactive Customers",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSCapacity_IT",
                "tileText": "Active / Inactive Tenants",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }*/
        ],
        /* "FOA": [
             {
                 "tileId": "FOA_CR",
                 "tileText": "Customer Registration",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             },
             {
                 "tileId": "FOA_CA",
                 "tileText": "CPOR Association",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             },
             {
                 "tileId": "FOA_VH",
                 "tileText": "Version History",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             }
         ], */

        "FGTT": [
            {
                "tileId": "FGT",
                "tileText": "FRP Internal-FRP Gov. Tenants",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
        ],
        "IMP": [
            {
                "tileId": "IMP_total",
                "tileText": "Total Impact",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "IMP_Detail",
                "tileText": "Total Impact Detail",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "IMP_BuildEPF",
                "tileText": "Build Evidence Product Feedback",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "IMP_BuildEOF",
                "tileText": "Build Evidence Operational Feedback",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "AISE": [
            {
                "tileId": "AISE_CSE",
                "tileText": "CME Scenarios Engine",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "AISE_RD",
                "tileText": "Report Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        //"FTS": [
        //    {
        //        "tileId": "FTS_LN",
        //        "tileText": "Legal Notice",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "FTS_CR",
        //        "tileText": "CXP/CSS Combined Report",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "FTS_CXP",
        //        "tileText": "CXP Only Report",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "FTS_CSS",
        //        "tileText": "CSS Only Report",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "FTS_EMS",
        //        "tileText": "EMS Only Report",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }
        //],
        "FTHelp": [
            {
                "tileId": "FTHelp_Das",
                "tileText": "Dashboard",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTHelp_C1B",
                "tileText": "Cat 1 Breakdown",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTHelp_C2B",
                "tileText": "Cat 2 Breakdown",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTHelp_C3B",
                "tileText": "Cat 3 Breakdown",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTHelp_US",
                "tileText": "Americas",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTHelp_EMEA",
                "tileText": "EMEA",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTHelp_ASIA",
                "tileText": "ASIA",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "CustomerES": [
            //{
            //    "tileId": "CustomerES_OverView",
            //    "tileText": "Overview",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "CustomerES_Themes",
            //    "tileText": "Themes",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "CustomerES_GVSS",
            //    "tileText": "Global VoC Submissions Scorecard",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            {
                "tileId": "CustomerES_CESSummary",
                "tileText": "CES Summary - Preview",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CustomerES_CustomerEffortAnalysis",
                "tileText": "Customer Effort Analysis - Preview",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CustomerES_Definition",
                "tileText": "Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "FYI": [
            {
                "tileId": "FYI_ID",
                "tileText": "FY 22 Initiatives Dashboard",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FYI_SR",
                "tileText": "Stack Ranking",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FYI_Def",
                "tileText": "Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        //"PMCD": [
        //    {
        //        "tileId": "PMCD_PMK",
        //        "tileText": "PM KPIs",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "PMCD_WIN",
        //        "tileText": "WinRate Detail",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "PMCD_SCIM",
        //        "tileText": "SCIM Detail",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "PMCD_TMPHD",
        //        "tileText": "Teams Phone Details",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "PMCD_CME",
        //        "tileText": "CME Detail",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "PMCD_FLW",
        //        "tileText": "FLW Detail",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "PMCD_EDGE",
        //        "tileText": "Edge Detail",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "PMCD_VIVA",
        //        "tileText": "Viva Detail",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "PMCD_Learn",
        //        "tileText": "Learn Detail",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "PMCD_Change",
        //        "tileText": "Change Detail",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "PMCD_SSA",
        //        "tileText": "SSA Detail",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "PMCD_Surface",
        //        "tileText": "Surface Detail",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    /* {
        //         "tileId": "PMCD_SDS",
        //         "tileText": "SDS Program",
        //         "isDefault": false,
        //         "hasSubPages": false,
        //         "subPages": []
        //     }, */
        //    {
        //        "tileId": "PMCD_Def",
        //        "tileText": "Definitions",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }
        //],
        "STT": [
            /*{
                "tileId": "STT_Intro",
                "tileText": "Intro",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "STT_STTA",
                "tileText": "Service Task Time All up",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "STT_HPTD",
                "tileText": "High Pri Tenant Detail",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "STT_STTD",
                "tileText": "Service Task Time Details",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "STT_STTI",
                "tileText": "Service Task Time Individuals",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "STT_TPWD",
                "tileText": "Tenant Priority weekly detail",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "STT_STT",
                "tileText": "Service Task Time (SME)",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "STT_SBS",
                "tileText": "ServiceTaskTime by Service",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "STT_MTT",
                "tileText": "MVM Task Time",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "STT_DD",
                "tileText": "Data Dictionary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "STT_TTFM",
                "tileText": "Task time by FM/ FE",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "STT_VH",
                "tileText": "Version History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }*/
        ],
        "AR": [
            /*{
                "tileId": "AR_INTRO",
                "tileText": "Intro",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "AR_FMV",
                "tileText": "FM View",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "AR_TBP",
                "tileText": "(RHD) Target Date/ In Progress",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "AR_TDB",
                "tileText": "(RHD) Target Date / Blocked",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "AR_SU",
                "tileText": "(RHD) Status Unknown - New / Exist",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "AR_PTRM",
                "tileText": "(RHD)Primary Tenant RM = Unknown",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "AR_FTCR",
                "tileText": "(RHD)FTC Region = Unknown",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "AR_Over",
                "tileText": "Overrides",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "AR_CLMS",
                "tileText": "Customer Led - MS - CSS",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "AR_ECC",
                "tileText": "Engagement Scenario Change - Audit",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "AR_FTCQ",
                "tileText": "FTCQueues",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "AR_VH",
                "tileText": "Version History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }*/
        ],
        "Tasksummary": [
            /*{
                "tileId": "Tasksummary_TSS",
                "tileText": "Task Status Summary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Tasksummary_WS",
                "tileText": "Workload Summary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Tasksummary_WAT",
                "tileText": "Workload Agnostic Tasks",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Tasksummary_FM",
                "tileText": "Task Status Summary - FM",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Tasksummary_FE",
                "tileText": "Task Status Summary - FE",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Tasksummary_SME",
                "tileText": "Task Status Summary - SME",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Tasksummary_TDD",
                "tileText": "Tenant Drill Down",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Tasksummary_TL",
                "tileText": "Task Lookup",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Tasksummary_VH",
                "tileText": "Version History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }*/
        ],
        "WorkspaceAudit": [
            {
                "tileId": "WorkspaceAudit_Dashboard",
                "tileText": "Dashboard",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkspaceAudit_RF",
                "tileText": "Refresh failures",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkspaceAudit_RLS",
                "tileText": "RLS not set",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkspaceAudit_RLSType",
                "tileText": "RLS type",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkspaceAudit_ST",
                "tileText": "Schedule Times",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkspaceAudit_Conn",
                "tileText": "Connections",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkspaceAudit_OLEDB",
                "tileText": "OleDb vs SSAS",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkspaceAudit_RD",
                "tileText": "Refresh duration",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkspaceAudit_FTC",
                "tileText": "FTC- AR - Dataflows usage Map",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkspaceAudit_FTCUAT",
                "tileText": "FTC - AR - Dataflows_UAT usage Map",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkspaceAudit_FTPBI",
                "tileText": "FTPBI Dataflow usage Map",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkspaceAudit_Dataset",
                "tileText": "Dataset UTC Check",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkspaceAudit_RH",
                "tileText": "Refresh History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkspaceAudit_NotRefreshing",
                "tileText": "Not Refreshing",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkspaceAudit_Cube",
                "tileText": "CubeMonitoringLog",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkspaceAudit_VH",
                "tileText": "Version History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "WorkItemBreakdown":[
            {
                "tileId": "WorkItemBreakdown_InitiativeWorkBreakdown",
                "tileText": "Initiative Work Breakdown",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkItemBreakdown_TeamWiseBreakdown",
                "tileText": "Team Wise Breakdown",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "MetricsReport": [

            {
                "tileId": "MetricsReport_Feature",
                "tileText": "Feature Detail",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "MetricsReport_Definition",
                "tileText": "Definition",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []


            }
        ],

        "SolutionArea": [

            {
                "tileId": "SolutionArea_Dashboard",
                "tileText": "Solution Area Dashboard",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "SolutionArea_Notes",
                "tileText": "Notes",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []


            }
        ],

        "PMPlaybook": [

            {
                "tileId": "PMPlaybook_Article",
                "tileText": "Playbook Article Life Cycle",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "Release": [
            {
                "tileId": "Release_Dashboard",
                "tileText": "Release Dashboard",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Release_Notes",
                "tileText": "Notes",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }

        ],
        "Feature": [
            {
                "tileId": "Feature_Detail",
                "tileText": "Feature Detail",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Feature_Definition",
                "tileText": "Definition",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }

        ],
        "PlayMet": [
            {
                "tileId": "PlayMet_Articles",
                "tileText": "Playbook Articles",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PlayMet_Projects",
                "tileText": "Playbook Projects",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "FastTrack": [
            {
                "tileId": "FastTrack_Roadmap",
                "tileText": "FastTrack Roadmap",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []

            }

        ],
        "CentralPMTeam": [
            {
                "tileId": "CentralPMTeam_Capacity",
                "tileText": "Capacity Details",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []

            },
            {
                "tileId": "CentralPMTeam_ReadMe",
                "tileText": "Read Me",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []

            }

        ],

        "Usage": [
            {
                "tileId": "Usage_RUM",
                "tileText": "Report(s) Usage Metrics",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Usage_ROR",
                "tileText": "Report(s) Open Requests",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Usage_RVM",
                "tileText": "Report(s) View Metrics",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Usage_RP",
                "tileText": "Report(s) Performance",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Usage_DA",
                "tileText": "Dataset aggregation",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Usage_RALT",
                "tileText": "Report(s) AVG Load Time",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Usage_RR",
                "tileText": "Report(s) Rank",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Usage_RMU",
                "tileText": "Report(s) Misc Usage",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Usage_FAQ",
                "tileText": "FAQ",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Usage_VH",
                "tileText": "Version History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "TP": [
            //{
            //    "tileId": "TP_Summary",
            //    "tileText": "Summary",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "TP_E5",
            //    "tileText": "E5 Trials To Paid ",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "TP_S2500",
            //    "tileText": "E3 Trials To Paid S2500",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "TP_MDO",
            //    "tileText": "MDO Trials",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //}
            //{
            //    "tileId": "TP_ALLTRIALS",
            //    "tileText": "Preview All Trials to Paid",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //}
        ],
        //"SalesPlay": [
        //    {
        //        "tileId": "SalesPlay_Mapping",
        //        "tileText": "FT Sales Play Mapping",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "SalesPlay_FAQ",
        //        "tileText": "FAQ'S",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "SalesPlay_Guide",
        //        "tileText": "Sales Play Mapping Guide",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }
        //],
        "FRPCustomer": [
            {
                "tileId": "FRPCustomer_Association",
                "tileText": "FRP Customer Association",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "Compete": [
            {
                "tileId": "Compete_WP",
                "tileText": "Welcome page",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Compete_RO",
                "tileText": "Regional Overview",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Compete_WOW",
                "tileText": "Week over Week Signals",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Compete_Compete",
                "tileText": "Compete Overview",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Compete_SO",
                "tileText": "Signal Overview",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
        ],
        //"FSISurvey": [
        //    {
        //        "tileId": "FSISurvey_Nav",
        //        "tileText": "Report Navigation",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "FSISurvey_CC",
        //        "tileText": "Customer Consumption",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "FSISurvey_CCPowerApp",
        //        "tileText": "Customer Consumption - With Power Apps",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "FSISurvey_Workload",
        //        "tileText": "Workload Usage Trends",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "FSISurvey_TwoCC",
        //        "tileText": "Two-Customer Comparison",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "FSISurvey_WUTC",
        //        "tileText": "Workload Usage Trends by Customers",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "FSISurvey_FSICust",
        //        "tileText": "FSI Customer Health Details",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "FSISurvey_VerHistory",
        //        "tileText": "Version History",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "FSISurvey_RD",
        //        "tileText": "Report Definitions",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "FSISurvey_MD",
        //        "tileText": "Metric Definitions",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }
        //],
        //"Surface": [
        //    {
        //        "tileId": "Surface_Activation",
        //        "tileText": "Surface Activation",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "Surface_DeviceActivation",
        //        "tileText": "Device Activation Targets Vs Actuals",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "Surface_KPI",
        //        "tileText": "Surface KPI",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    /*
        //    {
        //        "tileId": "Surface_Adds",
        //        "tileText": "Customer Adds",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },

        //    {
        //        "tileId": "Surface_AddsTargets",
        //        "tileText": "Customer Adds Targets vs Actuals",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }, */
        //    {
        //        "tileId": "Surface_Operations",
        //        "tileText": "Surface Projects Operations View",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "Surface_MAD",
        //        "tileText": "Surface MAD",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "Surface_SME",
        //        "tileText": "SME portfolio",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "Surface_Def",
        //        "tileText": "Report Definition",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "OKRSurface_Opportunity",
        //        "tileText": "Other Workloads | Surface Opportunity",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    /*
        //    {
        //        "tileId": "Surface_Action",
        //        "tileText": "Action Dashboard",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }, 
        //    {
        //        "tileId": "Surface_SOK",
        //        "tileText": "Sales Opportunity KPI",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }, */

        //],
        //"Windows365": [
        //    {
        //        "tileId": "Windows365_OKRF",
        //        "tileText": "Windows 365 OKR Funnel",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "Windows365_MOM",
        //        "tileText": "MoM  Performance",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "Windows365_TD",
        //        "tileText": "Task Details",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "TaskOutcomeForm",
        //        "tileText": "Task Outcome Form",
        //        "isDefault": true,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "Windows365_RD",
        //        "tileText": "Report Definition",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }
        //],
        /*"Intune": [
            {
                "tileId": "Intune_D",
                "tileText": "Intune Dashboard",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Intune_F",
                "tileText": "Intune Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "MDM": [
            {
                "tileId": "MDM_UC",
                "tileText": "Unique Count of MDM + Co-Mgmt",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "MDM_ID",
                "tileText": "Intune MDM Distribution",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
    
        "AADP": [
            {
                "tileId": "AADP_FU",
                "tileText": "AADP Feature Usage",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "AADP_SMEH",
                "tileText": "AADP SME History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "AADP_F",
                "tileText": "AADP Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "AADP_D",
                "tileText": "AADP Dashboard",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],*/
        //"OKAP": [
        //    {
        //        "tileId": "OKAP_CMEF",
        //        "tileText": "CME Metric Funnel",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    /* {
        //         "tileId": "OKAP_AVT",
        //         "tileText": "Actual V/s Targets",
        //         "isDefault": false,
        //         "hasSubPages": false,
        //         "subPages": []
        //     }, */
        //    {
        //        "tileId": "OKAP_MOMP",
        //        "tileText": "MoM Performance",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    /*  {
        //          "tileId": "OKAP_TargetingCustomers",
        //          "tileText": "Targeting Customers",
        //          "isDefault": false,
        //          "hasSubPages": false,
        //          "subPages": []
        //      }, 
        //      {
        //          "tileId": "OKAP_TaskDetails",
        //          "tileText": "Task Details",
        //          "isDefault": false,
        //          "hasSubPages": false,
        //          "subPages": []
        //      },*/
        //    {
        //        "tileId": "OKAP_RD",
        //        "tileText": "Report Definitions",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }
        //],

        //"ReactiveFLW": [
        //    {
        //        "tileId": "ReactiveFLW_FLW",
        //        "tileText": "FLW",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "ReactiveFLW_RFAD",
        //        "tileText": "FLW RFA Details",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },

        //],

        "MiscTP": [

            {
                "tileId": "MiscTP_S",
                "tileText": "Summary",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            //{
            //    "tileId": "RFATSTarg_EngN",
            //    "tileText": "Engagement Notes",
            //    "isDefault": true,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            {
                "tileId": "MiscTP_E5TP",
                "tileText": "E5 Trials To Paid",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "MiscTP_E3TP",
                "tileText": "E3 Trials To Paid S2500",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "MiscTP_MODT",
                "tileText": "MDO Trials",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "MiscSP": [
            {
                "tileId": "MiscSP_FTSPM",
                "tileText": "FT Sales Play Mapping",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "MiscSP_FAQS",
                "tileText": "FAQ'S",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "MiscSP_SPMG",
                "tileText": "Sales Play Mapping Guide",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        /*{
              "tileId": "RFATS_TCS",
              "tileText": "Targeting Campaign Summary",
              "isDefault": true,
              "hasSubPages": false,
              "subPages": []
          },
          {
              "tileId": "RFATS_TD",
              "tileText": "Tenant Details",
              "isDefault": true,
              "hasSubPages": false,
              "subPages": []
          },
          {
              "tileId": "RFATS_LR",
              "tileText": "L1 L2 L3 Reasons",
              "isDefault": true,
              "hasSubPages": false,
              "subPages": []
          },
          {
              "tileId": "RFATS_AC",
              "tileText": "Active Customers",
              "isDefault": true,
              "hasSubPages": false,
              "subPages": []
          },
          {
              "tileId": "RFATS_Q",
              "tileText": "Questionnaire",
              "isDefault": true,
              "hasSubPages": false,
              "subPages": []
          },
          {
              "tileId": "RFATS_DQ",
              "tileText": "Data Quality",
              "isDefault": true,
              "hasSubPages": false,
              "subPages": []
          },
          {
              "tileId": "RFATS_EN",
              "tileText": "Engagement Notes",
              "isDefault": true,
              "hasSubPages": false,
              "subPages": []
          },*/
        "RFATSTarg":
            [
                {
                    "tileId": "RFATSTarg_TDN",
                    "tileText": "Tenant Details",
                    "isDefault": true,
                    "hasSubPages": false,
                    "subPages": []
                },
                //{
                //    "tileId": "RFATSTarg_EngN",
                //    "tileText": "Engagement Notes",
                //    "isDefault": true,
                //    "hasSubPages": false,
                //    "subPages": []
                //},
                {
                    "tileId": "RFATSTarg_CampaignOverview",
                    "tileText": "Campaign Overview",
                    "isDefault": true,
                    "hasSubPages": false,
                    "subPages": []
                },
                {
                    "tileId": "RFATSTarg_SuccessMeasures",
                    "tileText": "Success Measures",
                    "isDefault": true,
                    "hasSubPages": false,
                    "subPages": []
                },
                {
                    "tileId": "RFATSTarg_OutcomeForms",
                    "tileText": "Outcomes Forms",
                    "isDefault": true,
                    "hasSubPages": false,
                    "subPages": []
                }

            ],

        //"RFATS400": [
        //    {
        //        "tileId": "RFATS400_CLP",
        //        "tileText": "T400 Customer Level Projects",
        //        "isDefault": true,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "RFATS400_Summary",
        //        "tileText": "T400 Tenant Summary",
        //        "isDefault": true,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "RFATS400_T400Notes",
        //        "tileText": "T400 Notes",
        //        "isDefault": true,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "RFATS400_T400DQ",
        //        "tileText": "T400 Data Quality",
        //        "isDefault": true,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }
        //],

        "RFAV": [
            {
                "tileId": "RFAV_DV",
                "tileText": "Delivery Volumes",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFAV_FCC",
                "tileText": "First Customer Contact",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFAV_Enablement",
                "tileText": "Enablement",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFAV_WORKLOADS",
                "tileText": "RFA Workloads",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFAV_DelPer",
                "tileText": "Delivery Performance",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFAV_Closeout",
                "tileText": "Closeout",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFAV_AO",
                "tileText": "Assignment Overview",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFAV_AD",
                "tileText": "Assignment Details",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFAV_RD",
                "tileText": "Report Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFAV_ARD",
                "tileText": "Assignment Reason Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFAV_RL",
                "tileText": "Report Logic",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
        ],
        "RFAS": [
            {
                "tileId": "RFAS_Inbound",
                "tileText": "RFA Inbound",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },

            {
                "tileId": "RFAS_D",
                "tileText": "RFA Details",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },

            /*{
                "tileId": "RFAS_RQ",
                "tileText": "FTCQ Reactive Queue",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },*/

            //{
            //    "tileId": "RFAS_IQ",
            //    "tileText": "FTCQ Inactive Queues",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},

            /*{
                "tileId": "RFAS_FQ",
                "tileText": "FTCQ Fraud Queue",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },*/

            {
                "tileId": "RFAS_MW",
                "tileText": "RFA Funnel - Modern Work",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFAS_SEC",
                "tileText": "RFA Funnel - Security",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFADetailsServices",
                "tileText": "RFA Details by Services",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "CSSSME": [
            {
                "tileId": "CSSSME_Overview",
                "tileText": "Overview",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            //{
            //    "tileId": "CSSSME_CME",
            //    "tileText": "CME",
            //    "isDefault": true,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "CSSSME_Windows11",
            //    "tileText": "Windows 11",
            //    "isDefault": true,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "CSSSME_Windows365",
            //    "tileText": "Windows 365",
            //    "isDefault": true,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "CSSSME_SDA",
            //    "tileText": "Surface Device Activations",
            //    "isDefault": true,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            /*
            {
                "tileId": "CSSSME_SCA",
                "tileText": "Surface Customer Adds",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            }, */
            {
                "tileId": "CSSSME_SME",
                "tileText": "Security SME (2200 & Non 2200)",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            /*{
                "tileId": "CSSSME_ES2500",
                "tileText": "Edge S2500",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSSME_EOD",
                "tileText": "Edge On-Demand",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSSME_EAU",
                "tileText": "Edge All Up",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },*/
            //{
            //    "tileId": "CSSSME_OKRFunnel",
            //    "tileText": "Viva",
            //    "isDefault": true,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            {
                "tileId": "CSSSME_TE",
                "tileText": "Time Entry",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSSME_TS",
                "tileText": "Task Status",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSSME_DQ",
                "tileText": "Data Quality",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
        ],
        "CSSDP": [
            {
                "tileId": "CSSDP_Intro",
                "tileText": "Intro",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSDP_TA",
                "tileText": "Tenant Assignments",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSDP_PMT",
                "tileText": "Playbook Mandatory Tasks",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSDP_TE",
                "tileText": "Time Entry",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSDP_DQ",
                "tileText": "FM Data Quality",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSDP_Portfolio",
                "tileText": "FE Portfolio",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },

            /*{
                "tileId": "CSSDP_VH",
                "tileText": "Version History",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            }*/

        ],
        "FTCDashboard": [
            {
                "tileId": "FTCDashboard_DB",
                "tileText": "FTC Dashboard",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            }

        ],

        "CSSFED": [

            {
                "tileId": "CSSFED_OSpace",
                "tileText": "Opportunity Space",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSFED_TenantAss",
                "tileText": "Tenant Assignments",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSFED_Playbook",
                "tileText": "Playbook Mandatory Tasks",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSFED_TimeEntry",
                "tileText": "Time Entry",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSFED_FEPrtflo",
                "tileText": " FE Portfolio",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSFED_DataQ",
                "tileText": "Data Quality",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            }

        ],
        "CSSTMDashboard": [
            {
                "tileId": "CSSTMDashboard_Intro",
                "tileText": "Intro",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSTMDashboard_PA",
                "tileText": "Portfolio Assignments",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSTMDashboard_PMT",
                "tileText": "Playbook Tasks",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSTMDashboard_ProjDel",
                "tileText": "Project Delivery",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            //{
            //    "tileId": "CSSTMDashboard_T400",
            //    "tileText": "T400",
            //    "isDefault": true,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            /*{
                "tileId": "CSSTMDashboard_RFAD",
                "tileText": "RFA Delivery",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },*/
            {
                "tileId": "CSSTMDashboard_CT",
                "tileText": "Customer time",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            /*{
                "tileId": "CSSTMDashboard_DQ",
                "tileText": "Data Quality",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            }*/
        ],
        "IPEWizard": [
            {
                "tileId": "IPEWizard_Impact",
                "tileText": "ADG Impact",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
        ],
        "IPEProductOwnerView": [
            {
                "tileId": "ProductOwnerView",
                "tileText": "Product Owner View",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "IPEFTOnDemand": [
            {
                "tileId": "IPEFTOnDemand_Overview",
                "tileText": "Overview",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            // {
            //     "tileId": "IPEFTOnDemand_SucMeasures",
            //     "tileText": "Success Measures",
            //     "isDefault": true,
            //     "hasSubPages": false,
            //     "subPages": []
            // },
            // {
            //     "tileId": "IPEFTOnDemand_ADG",
            //     "tileText": "ADG Interactions",
            //     "isDefault": true,
            //     "hasSubPages": false,
            //     "subPages": []
            // },
            // {
            //     "tileId": "IPEFTOnDemand_Wizard",
            //     "tileText": "Wizard Feedback",
            //     "isDefault": true,
            //     "hasSubPages": false,
            //     "subPages": []
            // }
        ],
        "IPESLTUsageData": [
            {
                "tileId": "IPESLTUsageData_Segment",
                "tileText": "Segment",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "IPESLTUsageData_WizardCustomers",
                "tileText": "ADG Customers",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            }
            ,
            {
                "tileId": "IPESLTUsageData_BOTCustomers",
                "tileText": "BOT Customers",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            //{
            //    "tileId": "IPESLTUsageData_WizardUsageSplit",
            //    "tileText": "Wizard Usage Split",
            //    "isDefault": true,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            {
                "tileId": "IPESLTUsageData_ExecSummaryInsights",
                "tileText": "Exec Summary Insights",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "IPESLTUsageData_Wizard_MoM_YTD_Trend",
                "tileText": "ADG MoM/YTD Trend",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "IPESLTUsageData_BOT_MoM_YTD_Trend",
                "tileText": "BOT MoM/YTD Trend",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "IPESLTUsageData_ADGYTDMAUTrend",
                "tileText": "ADG YTD MAU Trend",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "IPESLTUsageData_BOTYTDMAUTrend",
                "tileText": "BOT YTD MAU Trend",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "IPESLTUsageData_DataDictionary",
                "tileText": "Data Dictionary",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            //{
            //    "tileId": "IPESLTUsageData_Data_Dictionary",
            //    "tileText": "Data Dictionary",
            //    "isDefault": true,
            //    "hasSubPages": false,
            //    "subPages": []
            //}

        ],

        "OKRsTeamsPhone": [
            {
                "tileId": "OKRsTeamsPhone_Intent",
                "tileText": "Intent",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRsTeamsPhone_Deployment",
                "tileText": "Deployment",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRsTeamsPhone_KeyResult",
                "tileText": "Key Result",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRsTeamsPhone_AreaPerformance",
                "tileText": "Area Performance",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRsTeamsPhone_FunnelConversion",
                "tileText": "Funnel Conversion",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRsTeamsPhone_UsageTrends",
                "tileText": "Usage Trends",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRsTeamsPhone_MonthlyHeatMap",
                "tileText": "Monthly Heat Map",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRsTeamsPhone_TaskDetails",
                "tileText": "Task Details",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "FY24CoPilot": [
            {
                "tileId": "FY24CoPilot_RDS",
                "tileText": "Copilot Prerequisites",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            //{
            //    "tileId": "FY24CoPilot_EAP",
            //    "tileText": "EAP",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            {
                "tileId": "FY24CoPilot_MonthlyHM",
                "tileText": "Monthly Heat Map",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FY24CoPilot_Intent",
                "tileText": "Intent",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FY24CoPilot_Deployment",
                "tileText": "Deployment",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FY24CoPilot_KeyResults",
                "tileText": "Key Results",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FY24CoPilot_Usage",
                "tileText": "Usage Details",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FY24CoPilot_BoxMovements",
                "tileText": "LXP Motion-Non OKR",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FY24CoPilot_UsageHeatMap",
                "tileText": "Usage Heat Map",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FY24CoPilot_TargetingCustomers",
                "tileText": "Targeting Customers",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FY24CoPilot_Definitions",
                "tileText": "Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        //"TeamsPhone": [
        //    {
        //        "tileId": "TeamsPhone_DBD",
        //        "tileText": "Teams Phone Dashboard",
        //        "isDefault": true,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "TeamsPhone_Funnel",
        //        "tileText": "Teams Phone Funnel",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "TeamsPhone_OF",
        //        "tileText": "Operations Funnel",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "TeamsPhone_AP",
        //        "tileText": "Area Performance",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "TeamsPhone_PR",
        //        "tileText": "Progression/Regression",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "TeamsPhone_EW",
        //        "tileText": "Expansion Wins",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []

        //    },
        //    {
        //        "tileId": "TeamsPhone_MHP",
        //        "tileText": "Monthly Heat Map",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "TeamsPhone_FRCST",
        //        "tileText": "Forecast",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "TeamsPhone_CM",
        //        "tileText": "⁠Contribution Model",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "TeamsPhone_TOC",
        //        "tileText": "Table of Contents",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }
        //],

        //"TeamsRoom": [
        //    {
        //        "tileId": "TeamsRoom_DBD",
        //        "tileText": "Teams Rooms Dashboard",
        //        "isDefault": true,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "TeamsRoom_FUNNEL",
        //        "tileText": "Teams Rooms Funnel",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "TeamsRoom_OF",
        //        "tileText": "Operations Funnel",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "TeamsRoom_PR",
        //        "tileText": "Progression/Regression",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "TeamsRoom_AP",
        //        "tileText": "Area Performance",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "TeamsRoom_EXP",
        //        "tileText": "Expansion Wins",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },               
        //    {
        //        "tileId": "TeamsRoom_MHP",
        //        "tileText": "Monthly Heat Map",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "TeamsRoom_FRCST",
        //        "tileText": "Forecast",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "TeamsRoom_CM",
        //        "tileText": "Contribution Model",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "TeamsRoom_TOC",
        //        "tileText": "Table of Contents",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }
        //],


        "CRBR": [
            {
                "tileId": "CRBR_ByRequest",
                "tileText": "By Request",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],


        "CRPR": [
            {
                "tileId": "CRPR_Prioritized",
                "tileText": "Prioritized",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "FTFTPL": [
            {
                "tileId": "FTFTPL_FTPrioritizedlist",
                "tileText": "FastTrack Prioritized list",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },

            {
                "tileId": "FTFTPL_Opportunity",
                "tileText": "Opportunity View",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
        ],

        "LVFTOP": [
            {
                "tileId": "LVFTOP_LVReportFilters",
                "tileText": "Set Report Level Filters",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "LVFTOP_LVPerfOverview",
                "tileText": "Performance Overview",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "LVFTOP_LVPerfOverviewByUsage",
                "tileText": "Performance by Usage",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "LVFTOP_LVServicesUsage",
                "tileText": "Services Usage",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "LVFTOP_UsageTrends",
                "tileText": "Usage Trends",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "LVFTOP_PerformanceRequirement",
                "tileText": "FRP Performance Requirement",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "LVFTOP_ADG",
                "tileText": "ADG",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "LVFTOP_DataDictionary",
                "tileText": "Data Dictionary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }

        ],
        "FTCH": [
            {
                "tileId": "FTCH_HS",
                "tileText": "Health Summary",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTCH_PR",
                "tileText": "Progressions & Regressions",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTCH_SGHM",
                "tileText": "SCIM Groups & WL Health Movements",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTCH_Health",
                "tileText": "E3 Health",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTCH_Metrics",
                "tileText": "Metric Definitions",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "FLWH": [
            /* {
                 "tileId": "FLW_FLWSummary",
                 "tileText": "Summary",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             }, 
             {
                 "tileId": "FLW_FLWPR",
                 "tileText": "Progression / Regression",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             },
             {
                 "tileId": "FLW_FLWOKR",
                 "tileText": "OKR Funnel",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             },
             {
                 "tileId": "FLW_Deployment",
                 "tileText": "Deployment Projects",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             },
             {
                 "tileId": "FLW_OKRFC",
                 "tileText": "OKR Funnel Conversion",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             },
             {
                 "tileId": "FLW_Operations",
                 "tileText": "Operations Funnel",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             },
             {
                 "tileId": "FLW_CPM",
                 "tileText": "Conversion Probability Matrix",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             },
             {
                 "tileId": "FLW_RDHM",
                 "tileText": "RD Health Matrix",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             },
             {
                 "tileId": "FLW_HeatMap",
                 "tileText": "MoM Heat Map",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             },
             {
                 "tileId": "FLW_IndustryAP",
                 "tileText": "Industry / A14 Penetration",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             },
             {
                 "tileId": "FLW_CSU",
                 "tileText": "CSU MSX",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             },
             {
                 "tileId": "FLW_TimeToValue",
                 "tileText": "Time to Value",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             }, 
             {
                 "tileId": "FLW_Intent",
                 "tileText": "Intent Details",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             },
              {
                 "tileId": "FLW_Viva",
                 "tileText": "Viva Details",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             },
             {
                 "tileId": "FLW_COE",
                 "tileText": "COE",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             },
            {
                 "tileId": "FLW_DATAQUALITY",
                 "tileText": "Data Quality",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             }, 
             {
                 "tileId": "FLW_FSKU",
                 "tileText": "FRP-FLW",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             },
             {
                 "tileId": "FLW_TOC",
                 "tileText": "Table of Contents",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             } */
        ],
        "FTDQSD": [
            {
                "tileId": "FTDQSD_DQS",
                "tileText": "Data Quality Summary",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTDQSD_PV",
                "tileText": "Performance",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            //{
            //    "tileId": "FTDQSD_QDR",
            //    "tileText": "QDR",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            /*{
                "tileId": "FTDQSD_Forecast",
                "tileText": "Forecast",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTDQSD_Intent",
                "tileText": "Intent Summary View",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },*/
            {
                "tileId": "FTDQSD_VH",
                "tileText": "Version History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            //{
            //    "tileId": "FTDQSD_ReportDef",
            //    "tileText": "Definitions",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
        ],

        "FTMWAP": [
            {
                "tileId": "FTMWAP_WP",
                "tileText": "Welcome Page",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTMWAP_OS",
                "tileText": "Opportunity Space",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTMWAP_CPQDR",
                "tileText": "Compete, Projects",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTMWAP_DQ",
                "tileText": "Data Quality",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTMWAP_EAD",
                "tileText": "Entitlements & Assignments",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
            //{
            //    "tileId": "FTMWAP_PAT",
            //    "tileText": "Partner Assigned Tenants",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //}
            /*, {
                 "tileId": "FTMWAP_UNCL",
                 "tileText": "Unclaimed & Milestone Opportunity",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             }*/

        ],


        "PAP": [
            {
                "tileId": "PAP_Prioritized",
                "tileText": "Prioritized Accounts Performance",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
        ],

        "FTRAP": [
            {
                "tileId": "FTRAP_OKR",
                "tileText": "OKR Summary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTRAP_E5",
                "tileText": "Security",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTRAP_SI",
                "tileText": "Identity",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTRAP_PV",
                "tileText": "Purview",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },

            {
                "tileId": "FTRAP_MC",
                "tileText": "M365 Copilot",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTRAP_TP",
                "tileText": "Teams Phone",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTRAP_TR",
                "tileText": "Cloud Endpoints",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTRAP_VIVA",
                "tileText": "Viva",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            //{
            //    "tileId": "FTRAP_SRFC",
            //    "tileText": "Surface",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "FTRAP_W365",
            //    "tileText": "Windows 365",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            {
                "tileId": "FTRAP_SPLT",
                "tileText": "Spotlights",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTRAP_QLT",
                "tileText": "Customer Effort Score",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTRAP_INS",
                "tileText": "Insights",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTRAP_PROJECT",
                "tileText": "Projects",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTRAP_DEF1",
                "tileText": "Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],


        //"FTList": [
        //    {
        //        "tileId": "FTList_List",
        //        "tileText": "S2200 Customers",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "FTList_Custo",
        //        "tileText": "T400 Customers",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }
        //],

        "FTFunnel": [
            //{
            //    "tileId": "FTFunnel_SI",
            //    "tileText": "SI Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "FTFunnel_MDE",
            //    "tileText": "MDE Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "FTFunnel_MDO",
            //    "tileText": "MDO Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "FTFunnel_MDA",
            //    "tileText": "MDA Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "FTFunnel_MDI",
            //    "tileText": "MDI Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "FTFunnel_Purview",
            //    "tileText": "Purview Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},

            {
                "tileId": "FTFunnel_IdentityIntent",
                "tileText": "Identity Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTFunnel_PurviewIntent",
                "tileText": "Purview Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },

            {
                "tileId": "FTFunnel_FunnelPerformance",
                "tileText": "Intune Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            //{
            //    "tileId": "FTFunnel_MIP",
            //    "tileText": "MIP Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "FTFunnel_MIG",
            //    "tileText": "MIG Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "FTFunnel_IRM",
            //    "tileText": "IRM Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "FTFunnel_DR",
            //    "tileText": "D&R Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},

            {
                "tileId": "FTFunnel_TeamsPhoneFunnel",
                "tileText": "Teams Phone Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            //{
            //    "tileId": "FTFunnel_TPF",
            //    "tileText": "Teams Phone Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "FTFunnel_OF",
            //    "tileText": "Operations Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "FTFunnel_TRF",
            //    "tileText": "Teams Rooms Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            {
                "tileId": "FTFunnel_SecurityIntent",
                "tileText": "Security Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },

            {
                "tileId": "FTFunnel_VivaIntent",
                "tileText": "Viva",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            //{
            //    "tileId": "FTFunnel_VIVA",
            //    "tileText": "Viva",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "FTFunnel_SF",
            //    "tileText": "Surface Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
        ],

        "FTBIADO": [
            {
                "tileId": "FTBIADO_FTBI",
                "tileText": "FTBA ADO",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "PFTFS": [
            {
                "tileId": "PFTFS_RD",
                "tileText": "Report Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PFTFS_MWAF",
                "tileText": "FTA Forecast",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PFTFS_FTAAMP",
                "tileText": "FTA AM Portfolio",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PFTFS_OKRD",
                "tileText": "OKR Details",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            }

        ],
        "PWLF": [
            {
                "tileId": "PWLF_PurviewIPFunnel",
                "tileText": "Purview IP Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PWLF_PurviewIRMFunnel",
                "tileText": "Purview IRM Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PWLF_DLMFunnel",
                "tileText": "DLM Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PWLF_DNRFunnel",
                "tileText": "DNR Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PWLF_FTSP",
                "tileText": "FT SME Purview",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "WorkloadFunnel": [
            {
                "tileId": "WorkloadFunnel_MDE",
                "tileText": "MDE Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkloadFunnel_MDO",
                "tileText": "MDO Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkloadFunnel_MDI",
                "tileText": "MDI Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkloadFunnel_MDA",
                "tileText": "MDA Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkloadFunnel_",
                "tileText": "FT SME Security",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkloadFunnel_SCIMBestOpportunities",
                "tileText": " Security Best Opportunities",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
        ],
        "OKRsIdentity": [
            {
                "tileId": "OKRsIdentity_Intent",
                "tileText": "Intent",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRsIdentity_Deployment",
                "tileText": "Deployment",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRsIdentity_KeyResults",
                "tileText": "Key Results",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRsIdentity_AreaPerformance",
                "tileText": "Area Performance",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRsIdentity_SecurityTenantFeatureData",
                "tileText": "Features",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRsIdentity_FunnelConversion",
                "tileText": "Funnel Conversion",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRsIdentity_AllTasks",
                "tileText": "All Tasks",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRsIdentity_Definitions",
                "tileText": "Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            //{
            //    "tileId": "OKRsIdentity_OndemandFunnel",
            //    "tileText": "Ondemand Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},

            {
                "tileId": "OKRsIdentity_NewPocassReport",
                "tileText": "New Pocass Report",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRsIdentity_FY24KeyMetrics",
                "tileText": "FY24 Key Metrics",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            // {
            //     "tileId": "OKRsIdentity_PPMT",
            //     "tileText": "Preview-POC Management tool",
            //     "isDefault": false,
            //     "hasSubPages": false,
            //     "subPages": []
            // },
            //{
            //   "tileId": "OKRsIdentity_TOF",
            //    "tileText": "Task Outcome Form",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //}
            {
                "tileId": "OKRsIdentity_SecManComTelemetry",
                "tileText": "Security Managed Commercial Telemetry",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRsIdentity_EDMTF",
                "tileText": "EDM Outcome Form",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }

        ],

        "OKRIdentity": [
            {
                "tileId": "OKRIdentity_Intent",
                "tileText": "Intent",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRIdentity_Deployment",
                "tileText": "Deployment",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRIdentity_KeyResults",
                "tileText": "Key Results",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRIdentity_AreaPerformance",
                "tileText": "Area Performance",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRIdentity_IdentityTenantFeatureData",
                "tileText": "Features",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRIdentity_FunnelConversion",
                "tileText": "Funnel Conversion",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRIdentity_AllTasks",
                "tileText": "All Tasks",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRIdentity_FY24KeyMetrics",
                "tileText": "FY24 Key Metrics",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRIdentity_Definitions",
                "tileText": "Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRIdentity_OndemandFunnel",
                "tileText": "Ondemand Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRIdentity_FTIdentity",
                "tileText": "FT SME Identity",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRIdentity_SCIMBestOpportunities",
                "tileText": "Security Best Opportunities",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRIdentity_EntraCAFunnel",
                "tileText": "Entra CA Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "OKRsIntune": [

            {
                "tileId": "OKRsIntune_FunnelPerformance",
                "tileText": "Funnel Performance",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRsIntune_AreaPerformance",
                "tileText": "Area Performance",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRsIntune_FunnelConversion",
                "tileText": "Funnel Conversion",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },

            {
                "tileId": "OKRsIntune_CEReport",
                "tileText": "CE Report",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRsIntune_Windows365",
                "tileText": "Windows 365",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRsIntune_ReportDefinitions",
                "tileText": "Report Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }

        ],

        "OKRPURVW": [

            {
                "tileId": "OKRPURVW_Intent",
                "tileText": "Intent",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRPURVW_Deployment",
                "tileText": "Deployment",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRPURVW_KeyResults",
                "tileText": "Key Results",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRPURVW_AreaPerformance",
                "tileText": "Area Performance",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRPURVW_PurviewTenantFeatureData",
                "tileText": "Features",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRPURVW_FunnelConversion",
                "tileText": "Funnel Conversion",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRPURVW_AllTasks",
                "tileText": "All Tasks",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRPURVW_FY24KeyMetrics",
                "tileText": "FY24 Key Metrics",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRPURVW_Definitions",
                "tileText": "Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRPURVW_OndemandFunnel",
                "tileText": "Ondemand Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRPURVW_TaskOutcomeForm",
                "tileText": "EDM Outcome Form",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }

            //{
            //    "tileId": "PURVW_LXP",
            //    "tileText": "LXP Usage Score",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "PURVW_FTCF",
            //    "tileText": "Purview Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "PURVW_SIPCS",
            //    "tileText": "SI & Purview Customer Summary",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "PURVW_SIPTS",
            //    "tileText": "SI & Purview Tenant Summary",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "PURVW_LXPFD",
            //    "tileText": "LXP & Funnel Definition",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{

            //    "tileId": "PURVW_MIP",
            //    "tileText": "PurviewIP Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "PURVW_MIG",
            //    "tileText": "PurviewDLM Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "PURVW_IRM",
            //    "tileText": "PurviewIRM Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []

            //},
            //{
            //    "tileId": "PURVW_S2500",
            //    "tileText": "SME Dashboard (2200 and Non 2200)",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "PURVW_DR",
            //    "tileText": "D&R Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "PURVW_AT",
            //    "tileText": "All Tasks",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "PURVW_SCF",
            //    "tileText": "Security Purview Features",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "PURVW_RD",
            //    "tileText": "Report Definitions",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //}



        ],

        "OKRPURVWDPU": [

            {
                "tileId": "OKRPURVWDPU_Intent",
                "tileText": "DPU Intent",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRPURVWDPU_Deployment",
                "tileText": "DPU Deployment",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRPURVWDPU_KeyResults",
                "tileText": "DPU Key Results",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRPURVWDPU_AreaPerformance",
                "tileText": "DPU Area Performance",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRPURVWDPU_FunnelConversion",
                "tileText": "DPU Funnel Conversion",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRPURVWDPU_FY24KeyMetrics",
                "tileText": "FY24 Key Metrics",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRPURVWDPU_EDMOutcomeForm",
                "tileText": "EDM Outcome Form",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRPURVWDPU_Definitions",
                "tileText": "Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRPURVWDPU_SCIMBestOpportunities",
                "tileText": "Security Best Opportunities",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "OKRsView": [

            {
                "tileId": "OKRsView_Intent",
                "tileText": "Intent",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRsView_Deployment",
                "tileText": "Deployment",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRsView_KeyResults",
                "tileText": "Key Results",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },

            {
                "tileId": "OKRsView_AreaPerformance",
                "tileText": "Area Performance",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRsView_FunnelConversion",
                "tileText": "Funnel Conversion",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRsView_UsageTrends",
                "tileText": "Usage Trends",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRsView_FTA",
                "tileText": "FTA",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRsView_Definitions",
                "tileText": "Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        //"EEFunnel": [
        //    {
        //        "tileId": "EEFunnel_OKRFunnel",
        //        "tileText": "OKR Funnel",
        //        "isDefault": true,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    /*{
        //        "tileId": "EEFunnel_OKR",
        //        "tileText": "OKR",
        //        "isDefault": true,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },*/
        //    {
        //        "tileId": "EEFunnel_PB",
        //        "tileText": "Playbook Execution",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "EEFunnel_MAU",
        //        "tileText": "MAU",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    /*{
        //        "tileId": "EEFunnel_VG",
        //        "tileText": "Viva Goals",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },*/
        //    {
        //        "tileId": "EEFunnel_PBY",
        //        "tileText": "Playbook Execution (Yammer Only)",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "EEFunnel_MAUYE",
        //        "tileText": "MAU (Yammer/Engage)",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "EEFunnel_GetGr",
        //        "tileText": "Get to Green - FT Direct",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "GettoGreenIPE",
        //        "tileText": "Get to Green - IPE",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "EEFunnel_VivaMBR",
        //        "tileText": "Viva MBR",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    /*{
        //        "tileId": "EEFunnel_DQ",
        //        "tileText": "Data Quality",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },*/
        //    {
        //        "tileId": "EEFunnel_FY",
        //        "tileText": "Definitions",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }
        //],
        //"FTPilots": [
        //    {
        //        "tileId": "FTPilots_Pilots",
        //        "tileText": "FT Pilots",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }
        //    //{
        //    //    "tileId": "FTPilots_Usage",
        //    //    "tileText": "Usage",
        //    //    "isDefault": false,
        //    //    "hasSubPages": false,
        //    //    "subPages": []
        //    //}
        //            ],

        "EEUsage": [
            {
                "tileId": "EEUsage_FUD",
                "tileText": "FY21 Usage Details",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "FTCD": [
            {
                "tileId": "FTCD_SF",
                "tileText": "SCIM Funnel",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTCD_CS",
                "tileText": "Compliance Summary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTCD_TOC",
                "tileText": "Preview All Tasks",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        /*  "ESCIM": [
              {
                  "tileId": "ESCIM_Summary",
                  "tileText": "E3 Summary",
                  "isDefault": true,
                  "hasSubPages": false,
                  "subPages": []
              },
              {
                  "tileId": "ESCIM_SCIM",
                  "tileText": "SCIM Summary",
                  "isDefault": false,
                  "hasSubPages": false,
                  "subPages": []
              }
          ],*/

        "FTFLWD": [
            {
                "tileId": "FTFLWD_FLWD",
                "tileText": "FLW Dashboard",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTFLWD_FLWOKRF",
                "tileText": "FLW OKR Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTFLWD_CPM",
                "tileText": "Conversion Probability Matrix",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTFLWD_RDHM",
                "tileText": "Regional Director Health Matrix",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTFLWD_FLWDMD",
                "tileText": "FLW Dashboard Monthly Distribution",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTFLWD_MWAD",
                "tileText": "MWA Deployment",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTFLWD_TOC",
                "tileText": "Table of Contents",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "FTCME": [
            {
                "tileId": "FTCME_RN",
                "tileText": "Report Navigation",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTCME_MFS",
                "tileText": "CME Metric Funnel Summary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTCME_AVT",
                "tileText": "CME Actuals Vs Targets",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTCME_SCENE",
                "tileText": "CME Scenarios",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTCME_RD",
                "tileText": "Report Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTCME_VH",
                "tileText": "Version History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "PFTC": [
            {
                "tileId": "PFTC_ID",
                "tileText": "Intune Dashboard",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PFTC_SMEP",
                "tileText": "SME Portfolio",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PFTC_TV",
                "tileText": "Tasks View",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PFTC_AFU",
                "tileText": "AADP Feature Usage",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PFTC_ASMEH",
                "tileText": "AADP SME History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PFTC_ISMEH",
                "tileText": "Intune SME History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PFTC_CS",
                "tileText": "Customer Summary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PFTC_UCM",
                "tileText": "Unique Count of MDM + Co-Mgmt",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PFTC_IMDMD",
                "tileText": "Intune MDM Distribution",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PFTC_IF",
                "tileText": "Intune Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PFTC_AADPF",
                "tileText": "AADP Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PFTC_TMP",
                "tileText": "TM Portfolio",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PFTC_AADPD",
                "tileText": "AADP Dashboard",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PFTC_TOC",
                "tileText": "Table of Contents",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],


        //"PBMAU": [
        //    {
        //        "tileId": "PBMAU_SPBMAU",
        //        "tileText": "S2500",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "PBMAU_OnDemand",
        //        "tileText": "On Demand",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "PBMAU_AUP",
        //        "tileText": "All Up",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }
        //],

        "Intent": [
            {
                "tileId": "Intent_ID",
                "tileText": "Distribution",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Intent_WIM",
                "tileText": "Week Over Week",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Intent_MMOBC",
                "tileText": "Blocked Customers",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "Wizard": [
            {
                "tileId": "Wizard",
                "tileText": "Wizard",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "Adoption": [
            {
                "tileId": "Adoption",
                "tileText": "Adoption",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        /*"FTCSUU_Summary": [
            {
                "tileId": "FTCSU_Summary",
                "tileText": "Summary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],*/
        // "FTCSUU_Funnel": [
        //     {
        //         "tileId": "FTCSU_Funnel",
        //         "tileText": "CSU Funnel",
        //         "isDefault": false,
        //         "hasSubPages": false,
        //         "subPages": []
        //     }
        // ],
        /* "FTCSUU_PlaybookView": [
             {
                 "tileId": "FTCSU_PlaybookView",
                 "tileText": "Playbook View",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             }
         ],
    
         "FTCSUU_TaskView": [
             {
                 "tileId": "FTCSU_TaskView",
                 "tileText": "Task View",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             }
         ],
    
         "FTCSUU_SummaryDetails": [
             {
                 "tileId": "FTCSU_SummaryDetails",
                 "tileText": "Summary Details",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             }
         ],*/
        // "FTCSUU_Definitions": [
        //     {
        //         "tileId": "FTCSU_Definitions",
        //         "tileText": "Definitions",
        //         "isDefault": false,
        //         "hasSubPages": false,
        //         "subPages": []
        //     }
        // ],
        // "FTCSUU_WorkloadMapping": [
        //     {
        //         "tileId": "FTCSU_WorkloadMapping",
        //         "tileText": "Workload Mapping",
        //         "isDefault": false,
        //         "hasSubPages": false,
        //         "subPages": []
        //     }
        // ],

        "ScorecardFM": [
            //{
            //    "tileId": "ScorecardFM_FY23OKRsTenant",
            //    "tileText": "FY23 OKRs - Tenant",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "ScorecardFM_MGRYTD",
            //    "tileText": "MGR YTD",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},

            {
                "tileId": "ScorecardFT_MGR",
                "tileText": "FT MGR",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },

            {
                "tileId": "ScorecardA15_Volume",
                "tileText": "A15 Volume",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "ScorecardFM_Definition",
                "tileText": "Report Definition",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }

        ],

        "FRPCustomerSurveyy": [
            {
                "tileId": "FRPCustomerSurvey",
                "tileText": "FRP Customer Survey",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "FRPSKU": [
            {
                "tileId": "FRPSKU_E3nE5",
                "tileText": "E3 & E5 SKU",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "FRPCapacity": [

            {
                "tileId": "FRPCapacity_FPM",
                "tileText": "FPM Capacity Reporting",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FRPCapacity_TvP",
                "tileText": " Time vs Partner Alignment",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FRPCapacity_WT",
                "tileText": "Weekly Time",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FRPCapacity_VH",
                "tileText": "Version History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "InternalCPOR": [
            {
                "tileId": "InternalCPOR_Welcome",
                "tileText": "Welcome Page",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "InternalCPOR_ClaimsDetails",
                "tileText": "CPOR Claims Details",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "InternalCPOR_CPORSummary",
                "tileText": "CPOR Summary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "InternalCPOR_Partner",
                "tileText": "Partner & Usage Threshold",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "InternalCPOR_CDSS",
                "tileText": "CPOR Details - Segments and Size",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        //"CPORClaims": [
        //    {
        //        "tileId": "CPORClaims_Summary",
        //        "tileText": "Claim summary",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "CPORClaims_Details",
        //        "tileText": "Claim details",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }
        //],

        "Workshops": [
            {
                "tileId": "Workshops_Status",
                "tileText": "Workshop status",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Workshops_CPOR",
                "tileText": "Workshop Impact - CPOR Claims",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Workshops_Summary",
                "tileText": "Workshop Partner Summary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Workshops_Eligibility",
                "tileText": "Workshop Eligibility",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Workshops_FRPSummary",
                "tileText": "Workshop Summary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "PHE": [
            {
                "tileId": "PHE_Perf",
                "tileText": "FPC Performance Requirement",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PHE_DataDictionary",
                "tileText": "Data Dictionary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
            //{
            //    "tileId": "PHE_FRPE",
            //    "tileText": "FRP Eligibility",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
        ],

        "FTUD": [
            {
                "tileId": "FTUD_Portfolio",
                "tileText": "Partner Portfolio detail",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTUD_AU",
                "tileText": "Active Usage per Partner",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTUD_WU",
                "tileText": "Partner Workload Usage Milestones",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTUD_FU",
                "tileText": "AADP Feature Usage",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTUD_PU",
                "tileText": "Partner Usage",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "OPSFTF": [
            {
                "tileId": "OPSFTF_Funnel",
                "tileText": "Funnel",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSFTF_SLA",
                "tileText": "SLAs",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSFTF_TI",
                "tileText": "Total Impact",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSFTF_TID",
                "tileText": "Total Impact Detail",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSFTF_SC",
                "tileText": "Summary - Charts",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSFTF_TOPS",
                "tileText": "TOP Submissions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSFTF_Sub",
                "tileText": "Submissions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSFTF_SR",
                "tileText": "Submitters Ranking",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSFTF_OR",
                "tileText": "Occurrences (+1) Ranking",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSFTF_RD",
                "tileText": "Report Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "TMD": [
            {
                "tileId": "TMD_I",
                "tileText": "Intro",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "TMD_PA",
                "tileText": "1.Portfolio Assignments",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "TMD_PMT",
                "tileText": "2.Playbook Mandatory Tasks",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "TMD_RFAD",
                "tileText": "3.RFA Delivery",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "TMD_CT",
                "tileText": "4.Customer time",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "TMD_DQ",
                "tileText": "5.Data Quality",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "TMD_MT",
                "tileText": "Mandatory Tasks - Completed by",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "PCR": [
            {
                "tileId": "PCR_I",
                "tileText": "Intro",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PCR_CT",
                "tileText": "Customer time",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PCR_ASSIGN",
                "tileText": "Assignment",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PCR_CTTPR",
                "tileText": "(MPR) - % Customer time trend per region",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PCR_TT",
                "tileText": "(MPR) - Tracked time",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PCR_PTT",
                "tileText": "(MPR) - % Tracked time",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PCR_PCTT",
                "tileText": "(MPR) - % Customer time trend",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PCR_CTT",
                "tileText": "Res.w/ Customer Time trend",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],


        "OPSBD": [
            {
                "tileId": "OPSBD_PD",
                "tileText": "Portfolio Dashboard",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_VTU",
                "tileText": "Volume to 40% Usage",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_NT",
                "tileText": "New Tenants",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_NEE",
                "tileText": "New Eligible Entitlements",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_CTL1",
                "tileText": "Consumption Taxonomy - L1",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_CTL2",
                "tileText": "Consumption Taxonomy - L2",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_IPL2",
                "tileText": "In-Progress - L2",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_L3F",
                "tileText": "L3 Focus",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_HIS",
                "tileText": "L1, L2, L3 History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_FTC",
                "tileText": "FT: O365 Core (EXO+SPO)",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_FTMA",
                "tileText": "FT: M365 Apps",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_FTEMSI",
                "tileText": "FT: EMS - Intune",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_FTEMSA",
                "tileText": "FT: EMS - AADP",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_FTS",
                "tileText": "FT: E5 Security",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_FTW",
                "tileText": "FT: Workload AU",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_DT",
                "tileText": "FT: O365 - Delivery Team",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_IDT",
                "tileText": "FT: EMS Intune by Delivery Team",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_ADT",
                "tileText": "FT: EMS AADP by Delivery Team",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_SDT",
                "tileText": "FT: E5 Security by Delivery Team",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_DD",
                "tileText": "Data Dictionary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_VH",
                "tileText": "Version History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }

        ],

        "OPSFD": [
            {
                "tileId": "OPSFD_INTRO",
                "tileText": "Intro",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSFD_SRF",
                "tileText": "Start Here - Set Report Filters",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSFD_DQS",
                "tileText": "DQ Summary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSFD_WD",
                "tileText": "Workload Details",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSFD_TE",
                "tileText": "Time Entry",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSFD_VH",
                "tileText": "Version History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSFD_AUR",
                "tileText": "AU Results",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "PDPS": [
            {
                "tileId": "PDPS_INTRO",
                "tileText": "Intro",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PDPS_TA",
                "tileText": "Tenant Assignments",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PDPS_PMT",
                "tileText": "Playbook Mandatory Tasks",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PDPS_CT",
                "tileText": "Customer time",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PDPS_VH",
                "tileText": "Version History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "FTFRPM": [
            {
                "tileId": "FTFRPM_FRP2500",
                "tileText": "FRP S2500 | FY22 Scorecard",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTFRPM_FRP2501",
                "tileText": "FRP S2501+ | FY22 Scorecard",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTFRPM_FRPS",
                "tileText": "FRP Sustain | FY22 Scorecard",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTFRPM_VH",
                "tileText": "Version History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTFRPM_TOC",
                "tileText": "Table of Contents",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "FTSM": [
            {
                "tileId": "FTSM_FTSM",
                "tileText": "FT Scenario Mapping",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTSM_FAQ",
                "tileText": "FAQ's",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTSM_SMG",
                "tileText": "Scenario Mapping Guide",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTSM_TOC",
                "tileText": "Table Of Contents",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "ASDR": [
            {
                "tileId": "ASDR_Help",
                "tileText": "Help",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "ASDR_Info",
                "tileText": "Info",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "ASDR_Measure",
                "tileText": "Measure",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "ASDR_Columns",
                "tileText": "Columns",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "ASDR_Relationships",
                "tileText": "Relationships",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "ASDR_Perspective",
                "tileText": "Perspectives",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "ASDR_Tables",
                "tileText": "Tables",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "ASDR_Changelog",
                "tileText": "Change Log",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "ASDR_Unused",
                "tileText": "Unused Field Log",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "ASDR_Lastprocessed",
                "tileText": "Last Processed",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "OPS": [
            {
                "tileId": "OPS_Index",
                "tileText": "Report Index",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_Introduction",
                "tileText": "Introduction",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_Summary",
                "tileText": "Summary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_Bucket",
                "tileText": "Partner and Usage Bucket",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_Segment",
                "tileText": "Segment and Size",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_Portfolio",
                "tileText": "Portfolio",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_Active",
                "tileText": "Active Usage",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_Workload",
                "tileText": "Partner Workload",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_Feature",
                "tileText": "Feature Usage",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_Fifteen",
                "tileText": "15% Forecast vs Actual",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_Fourty",
                "tileText": "40% Forecast vs Actual",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_Partner",
                "tileText": "Partner Usage",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_OLM",
                "tileText": "OLM MAM",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_AADP",
                "tileText": "AADP 3rd Party",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_Health",
                "tileText": "Data Health",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_HealthByRegion",
                "tileText": "Data Health By Region",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_Performance",
                "tileText": "Performance Requirement",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_ClaimSummary",
                "tileText": "Claim Summary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
            //,{
            //    "tileId": "OPS_ClaimDetail",
            //    "tileText": "Claim Details",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "OPS_Eligibility",
            //    "tileText": "Workshop Eligibility",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "OPS_Edge",
            //    "tileText": "Edge",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "OPS_Status",
            //    "tileText": "Workshop Status",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "OPS_Impact",
            //    "tileText": "Workshop Impact",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "OPS_Partner",
            //    "tileText": "Workshop Partner",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "OPS_History",
            //    "tileText": "History",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //}
        ],
        "FRPComp": [
            {
                "tileId": "FRPComp_Summary",
                "tileText": "Details",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FRPComp_Details",
                "tileText": "Summary Compliance",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FRPComp_Export",
                "tileText": "PRM Export",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "SP": [
            {
                "tileId": "SP_Strategic",
                "tileText": "Strategic Partners",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "SP_S2500",
                "tileText": "S2500 vs S2500+",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ]
    }
    // Horizontal Tile List for Production
    static HORIZONTAL_TILE_LIST: any = {
        "FRPI": [
            {
                "tileId": "FRPI_Velocity",
                "tileText": "FRP CPOR - Velocity",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },

        ],


        "CustomerES": [
            //{
            //    "tileId": "CustomerES_OverView",
            //    "tileText": "Overview",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "CustomerES_Themes",
            //    "tileText": "Themes",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "CustomerES_GVSS",
            //    "tileText": "Global VoC Submissions Scorecard",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            {
                "tileId": "CustomerES_CESSummary",
                "tileText": "CES Summary - Preview",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CustomerES_CustomerEffortAnalysis",
                "tileText": "Customer Effort Analysis - Preview",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CustomerES_Definition",
                "tileText": "Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "FY24CoPilot": [
            {
                "tileId": "FY24CoPilot_RDS",
                "tileText": "Copilot Prerequisites",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },

            //{
            //    "tileId": "FY24CoPilot_EAP",
            //    "tileText": "EAP",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            {
                "tileId": "FY24CoPilot_MonthlyHM",
                "tileText": "Monthly Heat Map",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },

            {
                "tileId": "FY24CoPilot_Intent",
                "tileText": "Intent",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },

            {
                "tileId": "FY24CoPilot_Deployment",
                "tileText": "Deployment",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FY24CoPilot_KeyResults",
                "tileText": "Key Results",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FY24CoPilot_Usage",
                "tileText": "Usage Details",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FY24CoPilot_BoxMovements",
                "tileText": "LXP Motion-Non OKR",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FY24CoPilot_UsageHeatMap",
                "tileText": "Usage Heat Map",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FY24CoPilot_TargetingCustomers",
                "tileText": "Targeting Customers",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FY24CoPilot_Definitions",
                "tileText": "Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "FTAzure": [
            {
                "tileId": "FTAzure_Summary",
                "tileText": "Summary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTAzure_Def",
                "tileText": "Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "EDMG": [
            {
                "tileId": "EDMG_Governance",
                "tileText": "EDM Governance",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "EDMG_EDMNoReasons",
                "tileText": "EDM No Reasons",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "EVC": [
            {
                "tileId": "EVC_Overview",
                "tileText": "Overview",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "EVC_Themes",
                "tileText": "Themes",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            /*{
                "tileId": "EVC_H1SC",
                "tileText": "MS Direct H1 Scorecard",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "EVC_H2SC",
                "tileText": "MS Direct H2 Scorecard",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "EVC_FRPH2SC",
                "tileText": "FRP H2 Scorecard",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },*/
            {
                "tileId": "EVC_Global",
                "tileText": "Global VoC Submissions Scorecard",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "EVC_PLVoC",
                "tileText": "Partner Led VoC",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "EVC_Def",
                "tileText": "Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "RFAVelocity": [
            {
                "tileId": "RFAVelocity_Assignment",
                "tileText": "RFAs by Assignment",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFAVelocity_Partner",
                "tileText": "RFAs by Partner",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            //{
            //    "tileId": "RFAVelocity_FCC",
            //    "tileText": "First Customer Contact",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            {
                "tileId": "RFAVelocity_Reassignments",
                "tileText": "Reassignments",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFAVelocity_ReportDef",
                "tileText": "Report Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFAVelocity_AssignmentReason",
                "tileText": "Assignment Reason Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "FRPCapacity": [
            {
                "tileId": "FRPCapacity_FPM",
                "tileText": "FPM Capacity Reporting",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FRPCapacity_TvP",
                "tileText": "Time vs Partner Alignment",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FRPCapacity_WT",
                "tileText": "Weekly Time",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FRPCapacity_VH",
                "tileText": "Version History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "MRPP": [
            {
                "tileId": "MRPP_OB",
                "tileText": "Onboarding",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "MRPP_Reg",
                "tileText": "Registration",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "MRPP_Sell",
                "tileText": "Sell Through",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "MRPP_Act",
                "tileText": "Activation",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "MRPP_Payment",
                "tileText": "Payment",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        //"WINDOWS11": [
        //    {
        //        "tileId": "WINDOWS11_POEDATA",
        //        "tileText": "Windows 11 POE Data",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "WINDOWS11_USAGEDATA",
        //        "tileText": "Windows 11 Usage Data",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }
        //],
        //"QTMFLW": [
        //    //{
        //    //    "tileId": "QTMFLW_FSKU",
        //    //    "tileText": "F-SKU Usage Data",
        //    //    "isDefault": false,
        //    //    "hasSubPages": false,
        //    //    "subPages": []
        //    //}
        //],
        "QTMEE": [
            //{
            //    "tileId": "QTMEE_VIVAPOE",
            //    "tileText": "Viva POE",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "QTMEE_VIVAUSAGE",
            //    "tileText": "Viva Usage - Partner Claimed",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //}
        ],
        "Subs": [
            {
                "tileId": "Subs_Top",
                "tileText": "TOP Submissions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Subs_Submissions",
                "tileText": "Submissions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Subs_Rank",
                "tileText": "Submitters Ranking",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Subs_Occurences",
                "tileText": "Occurrences(+ 1) Ranking",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "SLA": [
            {
                "tileId": "SLA_SLA",
                "tileText": "SLA",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
            //{
            //    "tileId": "SLA_Trend",
            //    "tileText": "SLA Trend (Preview)",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
        ],
        "FBTracking": [
            {
                "tileId": "FBTracking_Feedback",
                "tileText": "Feedback Tracking",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "TriageM": [
            {
                "tileId": "TriageM_Meetings",
                "tileText": "Triage  Meetings (Summary)",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "GTAFO": [
            {
                "tileId": "GTAFO_Global",
                "tileText": "Global Top Active Feedback by OKR",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "FSummary": [
            {
                "tileId": "FSummary_Summary",
                "tileText": "Summary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],


        "FReportD": [
            {
                "tileId": "FReportD_Definitions",
                "tileText": "Report Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "FUNN": [
            {
                "tileId": "FUNN_FUN",
                "tileText": "Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "CJIDashboard": [

            //{
            //    "tileId": "CJIDashboard_Guidance",
            //    "tileText": "Guidance",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},

            {
                "tileId": "CJIDashboard_Tracking",
                "tileText": "Tracking",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CJIDashboard_Trending",
                "tileText": "Trending",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CJIDashboard_Submitter",
                "tileText": "Submitter",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },

        ],
        /* "FY23Insights": [
             {
                 "tileId": "FY23_Insights",
                 "tileText": "FY23 Insights",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             }
         ],*/
        "SC": [
            {
                "tileId": "SC_Summary",
                "tileText": "Summary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "VoteCamp": [
            {
                "tileId": "ONE_VoteCamp",
                "tileText": "+1 Vote Campaign",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "TAFItems": [
            {
                "tileId": "TAFItems_GTAFI",
                "tileText": "Global Top Active Feedback Items",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "TAFItems_ATAFI",
                "tileText": "America's Top Active Feedback Items",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "TAFItems_EOUI",
                "tileText": "America's Top Active Feedback Items (Includes EOU and +1 details)",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "TAFItems_AAFI",
                "tileText": "All Active Feedback Items",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "SPFee": [
            {
                "tileId": "SPFee_Preview",
                "tileText": "Strategic Product Feedback (Preview)",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "FeedReport": [
            {
                "tileId": "FeedReport_RPT",
                "tileText": "Report Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "SL4": [
            {
                "tileId": "SL4_TeamsPremimum",
                "tileText": "Teams Premium",
                "isDefault": false,
                "hasSubPages": true,
                "subPages": []
            },
        ],

        "SL4Usage": [
            {
                "tileId": "SL4Usage_Usage",
                "tileText": "Usage",
                "isDefault": false,
                "hasSubPages": true,
                "subPages": []
            },
        ],

        "SL4FTC": [
            {
                "tileId": "SL4FTC_FTCopilot",
                "tileText": "FT Copilot",
                "isDefault": false,
                "hasSubPages": true,
                "subPages": []
            },
        ],

        "FTPDS": [
            {
                "tileId": "FTPDS_FTPDataSecurity",
                "tileText": "FT POCaaS for Data Security",
                "isDefault": false,
                "hasSubPages": true,
                "subPages": []
            },
        ],

        "ISI": [
            {
                "tileId": "ISI_ISIncubation",
                "tileText": "Intune Suite Incubation",
                "isDefault": false,
                "hasSubPages": true,
                "subPages": []
            },
        ],

        "IAMI": [
            {
                "tileId": "IAMI_IAIncubation",
                "tileText": "Internet Access M365 Incubation",
                "isDefault": false,
                "hasSubPages": true,
                "subPages": []
            },
        ],

        "GlintPulseDeployment": [
            {
                "tileId": "GlintPulseDeployment_GPDS",
                "tileText": "Glint and Pulse Deployment Support",
                "isDefault": false,
                "hasSubPages": true,
                "subPages": []
            },
        ],


        /* "REPDash": [
             {
                 "tileId": "REPDash_Role",
                 "tileText": "Role Clarity ",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             },
             {
                 "tileId": "REPDash_Capacity",
                 "tileText": "Capacity",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             },
             {
                 "tileId": "REPDash_Enablement",
                 "tileText": "Enablement",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             }
 
 
         ],*/

        "Windows1": [
            {
                "tileId": "Windows1_TD",
                "tileText": "Task Details",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        //"Windows2": [
        //    {
        //        "tileId": "Windows2_CAF",
        //        "tileText": "Windows 11 Metrics Funnel",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "Windows2_MoM",
        //        "tileText": "MoM Performance",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "Windows2_TD",
        //        "tileText": "Task Details",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "Windows2_RD",
        //        "tileText": "Report Definitions",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }
        //],

        "CSSCapacity": [
            //{
            //    "tileId": "CSSCapacity_Intro",
            //    "tileText": "Intro",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            {
                "tileId": "CSSCapacity_CT",
                "tileText": "Customer Time",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
            /* {
                 "tileId": "CSSCapacity_A",
                 "tileText": "Assignment",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             },
             {
                 "tileId": "CSSCapacity_CTPR",
                 "tileText": "(MPR) - % Customer Time Per Region",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             },
             {
                 "tileId": "CSSCapacity_TT",
                 "tileText": "(MPR) - Tracked Time",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             },
             {
                 "tileId": "CSSCapacity_PTT",
                 "tileText": "(MPR) - % Tracked Time",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             },
             {
                 "tileId": "CSSCapacity_MCTT",
                 "tileText": "(MPR) - % Customer Time Trend",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             },
             {
                 "tileId": "CSSCapacity_RCTT",
                 "tileText": "Res.w / Customer Time Trend",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             },
             {
                 "tileId": "CSSCapacity_Headcount",
                 "tileText": "SCIM Headcount Details",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             },
             {
                 "tileId": "CSSCapacity_IC",
                 "tileText": "Active / Inactive Customers",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             },
             {
                 "tileId": "CSSCapacity_IT",
                 "tileText": "Active / Inactive Tenants",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             }*/
        ],

        "FGT": [
            {
                "tileId": "FGT_FRPINT",
                "tileText": "FRP Gov Tenants",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }],
        "FRPCustomerSurvey": [
            {
                "tileId": "FRPCustomerSurvey_FRPINT",
                "tileText": "Customer Survey",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }],
        /*"IMP": [
            {
                "tileId": "IMP_total",
                "tileText": "Total Impact",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "IMP_Detail",
                "tileText": "Total Impact Detail",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "IMP_BuildEPF",
                "tileText": "Build Evidence Product Feedback",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "IMP_BuildEOF",
                "tileText": "Build Evidence Operational Feedback",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],*/
        "AISE": [
            {
                "tileId": "AISE_CSE",
                "tileText": "CME Scenarios Engine",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "AISE_RD",
                "tileText": "Report Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "FTS": [
            {
                "tileId": "FTS_LN",
                "tileText": "Legal Notice",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTS_CR",
                "tileText": "CXP/CSS Combined Report",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTS_CXP",
                "tileText": "CXP Only Report",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTS_CSS",
                "tileText": "CSS Only Report",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTS_EMS",
                "tileText": "EMS Only Report",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "FTHelp": [
            {
                "tileId": "FTHelp_Das",
                "tileText": "Dashboard",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTHelp_C1B",
                "tileText": "Cat 1 Breakdown",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTHelp_C2B",
                "tileText": "Cat 2 Breakdown",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTHelp_C3B",
                "tileText": "Cat 3 Breakdown",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTHelp_US",
                "tileText": "Americas",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTHelp_EMEA",
                "tileText": "EMEA",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTHelp_ASIA",
                "tileText": "ASIA",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "FYI": [
            {
                "tileId": "FYI_ID",
                "tileText": "FY 22 Initiatives Dashboard",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FYI_SR",
                "tileText": "Stack Ranking",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FYI_Def",
                "tileText": "Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        //"PMCD": [
        //    {
        //        "tileId": "PMCD_PMK",
        //        "tileText": "PM KPIs",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "PMCD_WRD",
        //        "tileText": "WinRate Detail",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "PMCD_SCIM",
        //        "tileText": "SCIM Detail",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "PMCD_TPD",
        //        "tileText": "Teams Phone Details",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "PMCD_CMED",
        //        "tileText": "CME Detail",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "PMCD_FLWD",
        //        "tileText": "FLW Detail",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "PMCD_EGD",
        //        "tileText": "Edge Detail",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "PMCD_VIVAD",
        //        "tileText": "VIVA Detail",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "PMCD_Learn",
        //        "tileText": "Learn Detail",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "PMCD_Change",
        //        "tileText": "Change Detail",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "PMCD_SSAD",
        //        "tileText": "SSA Detail",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "PMCD_SFD",
        //        "tileText": "Surface Detail",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "PMCD_Def",
        //        "tileText": "Definitions",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }
        //],
        "STT": [
            /*{
                "tileId": "STT_Intro",
                "tileText": "Intro",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "STT_STTA",
                "tileText": "Service Task Time All up",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "STT_HPTD",
                "tileText": "High Pri Tenant Detail",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "STT_STTD",
                "tileText": "Service Task Time Details",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "STT_STTI",
                "tileText": "Service Task Time Individuals",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "STT_TPWD",
                "tileText": "Tenant Priority weekly detail",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "STT_STT",
                "tileText": "Service Task Time (SME)",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "STT_SBS",
                "tileText": "ServiceTaskTime by Service",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "STT_MTT",
                "tileText": "MVM Task Time",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "STT_DD",
                "tileText": "Data Dictionary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "STT_TTFM",
                "tileText": "Task time by FM/ FE",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "STT_VH",
                "tileText": "Version History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }*/
        ],
        "AR": [
            /*{
                "tileId": "AR_INTRO",
                "tileText": "Intro",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "AR_FMV",
                "tileText": "FM View",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "AR_TBP",
                "tileText": "(RHD) Target Date/ In Progress",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "AR_TDB",
                "tileText": "(RHD) Target Date / Blocked",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "AR_SU",
                "tileText": "(RHD) Status Unknown - New / Exist",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "AR_PTRM",
                "tileText": "(RHD)Primary Tenant RM = Unknown",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "AR_FTCR",
                "tileText": "(RHD)FTC Region = Unknown",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "AR_Over",
                "tileText": "Overrides",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "AR_CLMS",
                "tileText": "Customer Led - MS - CSS",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "AR_ECC",
                "tileText": "Engagement Scenario Change - Audit",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "AR_FTCQ",
                "tileText": "FTCQueues",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "AR_VH",
                "tileText": "Version History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }*/
        ],
        "Tasksummary": [
            /*{
                "tileId": "Tasksummary_TSS",
                "tileText": "Task Status Summary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Tasksummary_WS",
                "tileText": "Workload Summary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Tasksummary_WAT",
                "tileText": "Workload Agnostic Tasks",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Tasksummary_FM",
                "tileText": "Task Status Summary - FM",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Tasksummary_FE",
                "tileText": "Task Status Summary - FE",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Tasksummary_SME",
                "tileText": "Task Status Summary - SME",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Tasksummary_TDD",
                "tileText": "Tenant Drill Down",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Tasksummary_TL",
                "tileText": "Task Lookup",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Tasksummary_VH",
                "tileText": "Version History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }*/
        ],
        "WorkspaceAudit": [
            {
                "tileId": "WorkspaceAudit_Dashboard",
                "tileText": "Dashboard",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkspaceAudit_RF",
                "tileText": "Refresh failures",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkspaceAudit_RLS",
                "tileText": "RLS not set",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkspaceAudit_RLSType",
                "tileText": "RLS type",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkspaceAudit_ST",
                "tileText": "Schedule Times",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkspaceAudit_Conn",
                "tileText": "Connections",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkspaceAudit_OLEDB",
                "tileText": "OleDb vs SSAS",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkspaceAudit_RD",
                "tileText": "Refresh duration",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkspaceAudit_FTC",
                "tileText": "FTC- AR - Dataflows usage Map",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkspaceAudit_FTCUAT",
                "tileText": "FTC - AR - Dataflows_UAT usage Map",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkspaceAudit_FTPBI",
                "tileText": "FTPBI Dataflow usage Map",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkspaceAudit_Dataset",
                "tileText": "Dataset UTC Check",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkspaceAudit_RH",
                "tileText": "Refresh History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkspaceAudit_NotRefreshing",
                "tileText": "Not Refreshing",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkspaceAudit_Cube",
                "tileText": "CubeMonitoringLog",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkspaceAudit_VH",
                "tileText": "Version History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "DTLData": [

            {
                "tileId": "DTLData_DATAFLOW",
                "tileText": "Dataflow Details",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "DTLData_CES",
                "tileText": "Customer Entitlement Status",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "DTLData_ES",
                "tileText": "Entitlement Status",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "DTLData_EDGE",
                "tileText": "Edge",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "DTLData_FLW",
                "tileText": "FLW",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "DTLData_FTCM",
                "tileText": "FTC Metrics",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "DTLData_FTOPV3",
                "tileText": "FTOP V3",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "DTLData_LXP",
                "tileText": "LXP",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "DTLData_MAU",
                "tileText": "MAU",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "DTLData_SURF",
                "tileText": "Surface",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }

        ],

        "DTLUMR": [

            {
                "tileId": "DTLUMR_ProdVM",
                "tileText": "PROD VM Monitoring Metrics",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "DTLUMR_UATVM",
                "tileText": "UAT VM Monitoring Metrics ",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "DTLUMR_DevVM",
                "tileText": "DEV VM Monitoring Metrics ",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "DTLUMR_ProdSQL",
                "tileText": "PROD SQL Server Monitoring Metrics ",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "DTLUMR_UATSQL",
                "tileText": "UAT SQL Server Monitoring Metrics",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "DTLUMR_DevSQL",
                "tileText": "DEV SQL Server Monitoring Metrics ",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
        ],
        "DTLPipe": [

            {
                "tileId": "DTLPipe_ProdADF",
                "tileText": "Prod ADF Pipeline & Activity Run",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "DTLPipe_UATADF",
                "tileText": "UAT ADF Pipeline & Activity Run",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "DTLPipe_DevADF",
                "tileText": "Dev ADF Pipeline & Activity Run",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "DTLPipe_RUN",
                "tileText": "Pipeline Run: Row Count and Variance",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "DTLPipe_DDTL",
                "tileText": "DTL Data Extraction and Error Log",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "DTLPipe_PWU",
                "tileText": "Prod Workload Usage",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
        ],
        "WorkItemBreakdown":[
            {
                "tileId": "WorkItemBreakdown_InitiativeWorkBreakdown",
                "tileText": "Initiative Work Breakdown",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkItemBreakdown_TeamWiseBreakdown",
                "tileText": "Team Wise Breakdown",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "RelMetrics": [
            {
                "tileId": "RelMetrics_FeatureDetail",
                "tileText": "Feature Detail",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RelMetrics_Definition",
                "tileText": "Definition",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "SolutionArea": [

            {
                "tileId": "SolutionArea_Dashboard",
                "tileText": "Solution Area Dashboard",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "SolutionArea_Notes",
                "tileText": "Notes",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []


            }
        ],
        "PMPlaybook": [
            {
                "tileId": "PMPlaybook_Article",
                "tileText": "Playbook Article Life Cycle",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "Release": [
            {
                "tileId": "Release_Dashboard",
                "tileText": "Release Dashboard",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Release_Notes",
                "tileText": "Release Notes",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "Feature": [
            {
                "tileId": "Feature_Detail",
                "tileText": "Feature Detail",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Feature_Definition",
                "tileText": "Definition",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "PlayMet": [
            {
                "tileId": "PlayMet_Articles",
                "tileText": "Playbook Articles",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PlayMet_Projects",
                "tileText": "Playbook Projects",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "CentralPMTeam": [
            {
                "tileId": "CentralPMTeam_Capacity",
                "tileText": "Capacity Details",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []

            },
            {
                "tileId": "CentralPMTeam_ReadMe",
                "tileText": "Read Me",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []

            }

        ],

        "FastTrack": [
            {
                "tileId": "FastTrack_Roadmap",
                "tileText": "FastTrack Roadmap",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []

            }

        ],


        "Usage": [
            {
                "tileId": "Usage_RUM",
                "tileText": "Report(s) Usage Metrics",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Usage_ROR",
                "tileText": "Report(s) Open Requests",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Usage_RVM",
                "tileText": "Report(s) View Metrics",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Usage_RP",
                "tileText": "Report(s) Performance",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Usage_DA",
                "tileText": "Dataset aggregation",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Usage_RALT",
                "tileText": "Report(s) AVG Load Time",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Usage_RR",
                "tileText": "Report(s) Rank",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Usage_RMU",
                "tileText": "Report(s) Misc Usage",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Usage_FAQ",
                "tileText": "FAQ",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Usage_VH",
                "tileText": "Version History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "TP": [
            //{
            //    "tileId": "TP_Summary",
            //    "tileText": "Summary",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "TP_E5",
            //    "tileText": "E5 Trials To Paid ",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "TP_S2500",
            //    "tileText": "E3 Trials To Paid S2500",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "TP_MDO",
            //    "tileText": "MDO Trials",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //}
            //{
            //    "tileId": "TP_ALLTRIALS",
            //    "tileText": "Preview All Trials to Paid",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //}
        ],
        //"SalesPlay": [
        //    {
        //        "tileId": "SalesPlay_Mapping",
        //        "tileText": "FT Sales Play Mapping",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "SalesPlay_FAQ",
        //        "tileText": "FAQ'S",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "SalesPlay_Guide",
        //        "tileText": "Sales Play Mapping Guide",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }
        //],
        "Compete": [
            {
                "tileId": "Compete_WP",
                "tileText": "Welcome page",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Compete_RO",
                "tileText": "Regional Overview",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Compete_WOW",
                "tileText": "Week over Week Signals",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Compete_Compete",
                "tileText": "Compete Overview",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Compete_SO",
                "tileText": "Signal Overview",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
        ],
        //"FSISurvey": [
        //    {
        //        "tileId": "FSISurvey_Nav",
        //        "tileText": "Report Navigation",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "FSISurvey_CC",
        //        "tileText": "Customer Consumption",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "FSISurvey_CCPowerApp",
        //        "tileText": "Customer Consumption - With Power Apps",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "FSISurvey_Workload",
        //        "tileText": "Workload Usage Trends",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "FSISurvey_TwoCC",
        //        "tileText": "Two-Customer Comparison",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "FSISurvey_WUTC",
        //        "tileText": "Workload Usage Trends by Customers",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "FSISurvey_FSICust",
        //        "tileText": "FSI Customer Health Details",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "FSISurvey_VerHistory",
        //        "tileText": "Version History",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "FSISurvey_RD",
        //        "tileText": "Report Definitions",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "FSISurvey_MD",
        //        "tileText": "Metric Definitions",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }
        //],
        //"Surface": [
        //    {
        //        "tileId": "Surface_Activation",
        //        "tileText": "Surface Activation",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "Surface_DeviceActivation",
        //        "tileText": "Device Activation Targets Vs Actuals",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "Surface_KPI",
        //        "tileText": "Surface KPI",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "Surface_Operations",
        //        "tileText": "Surface Projects Operations View",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "Surface_MAD",
        //        "tileText": "Surface MAD",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "Surface_SME",
        //        "tileText": "SME Portfolio",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "Surface_Def",
        //        "tileText": "Report Definition",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "Surface_Other",
        //        "tileText": "Other Workloads | Surface Opportunity",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }
        //],
        "Intune": [
            {
                "tileId": "Intune_D",
                "tileText": "Intune Dashboard",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Intune_F",
                "tileText": "Intune Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        /*"MDM": [
            {
                "tileId": "MDM_UC",
                "tileText": "Unique Count of MDM + Co-Mgmt",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "MDM_ID",
                "tileText": "Intune MDM Distribution",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],*/

        /*"AADP": [
            {
                "tileId": "AADP_FU",
                "tileText": "AADP Feature Usage",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "AADP_SMEH",
                "tileText": "AADP SME History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "AADP_F",
                "tileText": "AADP Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "AADP_D",
                "tileText": "AADP Dashboard",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],*/

        //"ReactiveFLW": [
        //     {
        //        "tileId": "ReactiveFLW_FLW",
        //         "tileText": "FLW",
        //         "isDefault": false,
        //         "hasSubPages": false,
        //         "subPages": []
        //    },
        //    {
        //        "tileId": "ReactiveFLW_RFAD",
        //        "tileText": "FLW RFA Details",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }
        //    ],

        //"OKAP": [
        //    {
        //        "tileId": "OKAP_CMEF",
        //        "tileText": "CME Metric Funnel",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "OKAP_MOMP",
        //        "tileText": "MoM Performance",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "OKAP_RD",
        //        "tileText": "Report Definitions",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }
        //],

        "MiscTP": [

            {
                "tileId": "MiscTP_S",
                "tileText": "Summary",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "MiscTP_E5TP",
                "tileText": "E5 Trials To Paid",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "MiscTP_E3TP",
                "tileText": "E3 Trials To Paid S2500",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "MiscTP_MODT",
                "tileText": "MDO Trials",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "MiscSP": [
            {
                "tileId": "MiscSP_FTSPM",
                "tileText": "FT Sales Play Mapping",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "MiscSP_FAQS",
                "tileText": "FAQ'S",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "MiscSP_SPMG",
                "tileText": "Sales Play Mapping Guide",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "RFATSTarg": [
            {
                "tileId": "RFATSTarg_TDN",
                "tileText": "Tenant Details",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            //{
            //    "tileId": "RFATSTarg_EngN",
            //    "tileText": "Engagement Notes",
            //    "isDefault": true,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            {
                "tileId": "RFATSTarg_CampaOverview",
                "tileText": "Campaign Overview",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFATSTarg_SuccessMeasures",
                "tileText": "Success Measures",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFATSTarg_OutcomeForms",
                "tileText": "Outcomes Forms",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        // "RFATS400":[
        /*{
                "tileId": "RFATS_TCS",
                "tileText": "Targeting Campaign Summary",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFATS_TD",
                "tileText": "Tenant Details",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFATS_LR",
                "tileText": "L1 L2 L3 Reasons",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFATS_AC",
                "tileText": "Active Customers",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFATS_Q",
                "tileText": "Questionnaire",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFATS_DQ",
                "tileText": "Data Quality",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFATS_EN",
                "tileText": "Engagement Notes",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },*/
        //{
        //    "tileId": "RFATS400_CLP",
        //    "tileText": "T400 Customer Level Projects",
        //    "isDefault": true,
        //    "hasSubPages": false,
        //    "subPages": []
        //},
        //{
        //    "tileId": "RFATS400_TNS",
        //    "tileText": "T400 Tenant Summary",
        //    "isDefault": true,
        //    "hasSubPages": false,
        //    "subPages": []
        //},
        //{
        //    "tileId": "RFATS400_TFN",
        //    "tileText": "T400 Notes",
        //    "isDefault": true,
        //    "hasSubPages": false,
        //    "subPages": []
        //},
        //{
        //    "tileId": "RFATS400_TDQ",
        //    "tileText": "T400 Data Quality",
        //    "isDefault": true,
        //    "hasSubPages": false,
        //    "subPages": []
        //}   
        // ],
        "RFAV": [
            {
                "tileId": "RFAV_DV",
                "tileText": "Delivery Volumes",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFAV_FCC",
                "tileText": "First Customer Contact",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFAV_Enablement",
                "tileText": "Enablement",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFAV_Closeout",
                "tileText": "Closeout",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFAV_AO",
                "tileText": "Assignment Overview",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFAV_AD",
                "tileText": "Assignment Details",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFAV_WORKLOADS",
                "tileText": "RFA Workloads",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFAV_DelPer",
                "tileText": "Delivery Performance",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFAV_RD",
                "tileText": "Report Definitions",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFAV_ARD",
                "tileText": "Assignment Reason Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFAV_RL",
                "tileText": "Report Logic",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
        ],
        "RFAS": [
            {
                "tileId": "RFAS_Inbound",
                "tileText": "RFA Inbound",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },

            {
                "tileId": "RFAS_D",
                "tileText": "RFA Details",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },

            /*{
                "tileId": "RFAS_RQ",
                "tileText": "FTCQ Reactive Queue",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },*/

            //{
            //    "tileId": "RFAS_IQ",
            //    "tileText": "FTCQ Inactive Queues",
            //    "isDefault": true,
            //    "hasSubPages": false,
            //    "subPages": []
            //},

            /*{
                "tileId": "RFAS_FQ",
                "tileText": "FTCQ Fraud Queue",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },*/
            {
                "tileId": "RFAS_MW",
                "tileText": "RFA Funnel - Modern Work",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFAS_SEC",
                "tileText": "RFA Funnel - Security",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "RFADetailsServices",
                "tileText": "RFA Details by Services",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "CSSSME": [
            {
                "tileId": "CSSSME_Overview",
                "tileText": "Overview",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            //{
            //    "tileId": "CSSSME_CME",
            //    "tileText": "CME",
            //    "isDefault": true,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "CSSSME_Windows11",
            //    "tileText": "Windows 11",
            //    "isDefault": true,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "CSSSME_Windows365",
            //    "tileText": "Windows 365",
            //    "isDefault": true,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "CSSSME_SDA",
            //    "tileText": "Surface Device Activations",
            //    "isDefault": true,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            /*{
                "tileId": "CSSSME_SCA",
                "tileText": "Surface Customer Adds",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },*/
            {
                "tileId": "CSSSME_SME",
                "tileText": "Security SME (2200 & Non 2200)",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            /*{
                "tileId": "CSSSME_ES2500",
                "tileText": "Edge S2500",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSSME_EOD",
                "tileText": "Edge On-Demand",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSSME_EAU",
                "tileText": "Edge All Up",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },*/
            //{
            //    "tileId": "CSSSME_OKRFunnel",
            //    "tileText": "Viva",
            //    "isDefault": true,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            {
                "tileId": "CSSSME_TE",
                "tileText": "Time Entry",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSSME_TS",
                "tileText": "Task Status",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSSME_DQ",
                "tileText": "Data Quality",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
        ],

        "FTCFTCDB": [
            {
                "tileId": "FTCFTCDB_Dashboard",
                "tileText": "FTC Dashboard",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
        ],

        "CSSDP": [
            {
                "tileId": "CSSDP_Intro",
                "tileText": "Intro",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSDP_TA",
                "tileText": "Tenant Assignments",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSDP_PMT",
                "tileText": "Playbook Mandatory Tasks",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSDP_TE",
                "tileText": "Time Entry",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSDP_DQ",
                "tileText": "FM Data Quality",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSDP_Portfolio",
                "tileText": "FE Portfolio",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            /*{
                "tileId": "CSSDP_VH",
                "tileText": "Version History",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            }*/

        ],
        //"CSSFED": [
        //    {
        //        "tileId": "CSSFED_FEAssignmentStatus",
        //        "tileText": "FE Assignment Status",
        //        "isDefault": true,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "CSSFED_FEP",
        //        "tileText": "FE Portfolio",
        //        "isDefault": true,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "CSSFED_HATTimeTracked",
        //        "tileText": "HAT Time Tracked",
        //        "isDefault": true,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "CSSFED_HATTD",
        //        "tileText": "HAT 4163 Task Details",
        //        "isDefault": true,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }
        //],.
        "IPEWizard": [
            {
                "tileId": "IPEWizard_Impact",
                "tileText": "ADG Impact",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
        ],
        "IPEProductOwnerView": [
            {
                "tileId": "ProductOwnerView",
                "tileText": "Product Owner View",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "IPEFTOnDemand": [
            {
                "tileId": "IPEFTOnDemand_Overview",
                "tileText": "Overview",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            // {
            //     "tileId": "IPEFTOnDemand_SucMeasures",
            //     "tileText": "Success Measures",
            //     "isDefault": false,
            //     "hasSubPages": false,
            //     "subPages": []
            // },
            // {
            //     "tileId": "IPEFTOnDemand_ADG",
            //     "tileText": "ADG Interactions",
            //     "isDefault": false,
            //     "hasSubPages": false,
            //     "subPages": []
            // },
            // {
            //     "tileId": "IPEFTOnDemand_Wizard",
            //     "tileText": "Wizard Feedback",
            //     "isDefault": true,
            //     "hasSubPages": false,
            //     "subPages": []
            // }
        ],
        "IPESLTUsageData": [
            {
                "tileId": "IPESLTUsageData_Segment",
                "tileText": "Segment",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "IPESLTUsageData_WizardCustomers",
                "tileText": "ADG Customers",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "IPESLTUsageData_BOTCustomers",
                "tileText": "BOT Customers",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            //{
            //    "tileId": "IPESLTUsageData_WizardUsageSplit",
            //    "tileText": "Wizard Usage Split",
            //    "isDefault": true,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            {
                "tileId": "IPESLTUsageData_ExecSummaryInsights",
                "tileText": "Exec Summary Insights",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "IPESLTUsageData_Wizard_MoM_YTD_Trend",
                "tileText": "ADG MoM/YTD Trend",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "IPESLTUsageData_BOT_MoM_YTD_Trend",
                "tileText": "BOT MoM/YTD Trend",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "IPESLTUsageData_ADGYTDMAUTrend",
                "tileText": "ADG YTD MAU Trend",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "IPESLTUsageData_YTDMAUTrend",
                "tileText": "BOT YTD MAU Trend",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "IPESLTUsageData_Data_Dictionary",
                "tileText": "Data Dictionary",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            }

        ],
        "CSSFED": [
            {
                "tileId": "CSSFED_OSpace",
                "tileText": "Opportunity Space",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSFED_TenantAss",
                "tileText": "Tenant Assignments",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSFED_Playbook",
                "tileText": "Playbook Mandatory Tasks",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSFED_TE",
                "tileText": "Time Entry",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSFED_FEPrtflo",
                "tileText": "FE Portfolio",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSFED_DataQ",
                "tileText": "Data Quality",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            }],

        "CSSTMDashboard": [
            {
                "tileId": "CSSTMDashboard_Intro",
                "tileText": "Intro",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSTMDashboard_PA",
                "tileText": "Portfolio Assignments",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSTMDashboard_PMT",
                "tileText": "Playbook Tasks ",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CSSTMDashboard_ProjDel",
                "tileText": "Project Delivery",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            //{
            //    "tileId": "CSSTMDashboard_T400",
            //    "tileText": "T400",
            //    "isDefault": true,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            /*{
                "tileId": "CSSTMDashboard_RFAD",
                "tileText": "RFA Delivery",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },*/
            {
                "tileId": "CSSTMDashboard_CT",
                "tileText": "Customer time",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            /*{
                "tileId": "CSSTMDashboard_DQ",
                "tileText": "Data Quality",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            }*/
        ],
        "TeamsPhone": [

            {
                "tileId": "TeamsPhone_Intent",
                "tileText": "Intent",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "TeamsPhone_Deployment",
                "tileText": "Deployment",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "TeamsPhone_KeyResult",
                "tileText": "Key Results",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "TeamsPhone_AreaPerformance",
                "tileText": "Area Performance",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "TeamsPhone_FunnelConversion",
                "tileText": "Funnel Conversion",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "TeamsPhone_UsageTrends",
                "tileText": "Usage Trends",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "TeamsPhone_MonthlyHeatMap",
                "tileText": "Monthly Heat Map",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKRsTeamsPhone_TaskDetails",
                "tileText": "Task Details",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
            //{
            //    "tileId": "TeamsPhone_DBD",
            //    "tileText": "Teams Phone Dashboard",
            //    "isDefault": true,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "TeamsPhone_TPF",
            //    "tileText": "Teams Phone Funnel",
            //    "isDefault": true,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "TeamsPhone_OF",
            //    "tileText": "Operations Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "TeamsPhone_AP",
            //    "tileText": "Area Performance",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "TeamsPhone_PR",
            //    "tileText": "Progression/Regression",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "TeamsPhone_EW",
            //    "tileText": "Expansion Wins",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},                
            //{
            //    "tileId": "TeamsPhone_MHM",
            //    "tileText": "Monthly Heat Map",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "TeamsPhone_FRCST",
            //    "tileText": "Forecast",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "TeamsPhone_CM",
            //    "tileText": "Contribution Model",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "TeamsPhone_TOC",
            //    "tileText": "Table of Contents",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //}
        ],

        //"TeamsRoom": [
        //    {
        //        "tileId": "TeamsRoom_DBD",
        //        "tileText": "Teams Rooms Dashboard",
        //        "isDefault": true,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "TeamsRoom_FUNNEL",
        //        "tileText": "Teams Rooms Funnel",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "TeamsRoom_OF",
        //        "tileText": "Operations Funnel",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "TeamsRoom_PR",
        //        "tileText": "Progression/Regression",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "TeamsRoom_AP",
        //        "tileText": "Area Performance",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "TeamsRoom_EXP",
        //        "tileText": "Expansion Wins",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },               
        //    {
        //        "tileId": "TeamsRoom_MHM",
        //        "tileText": "Monthly Heat Map",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "TeamsRoom_FRCST",
        //        "tileText": "Forecast",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "TeamsRoom_CM",
        //        "tileText": "Contribution Model",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "TeamsRoom_TOC",
        //        "tileText": "Table of Contents",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }
        //],

        //"CSSOPS": [
        //    {
        //        "tileId": "CSSOPS_AU",
        //        "tileText": "Active Usage",
        //        "isDefault": true,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "CSSOPS_ES",
        //        "tileText": "Entitlement Status",
        //        "isDefault": true,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "CSSOPS_FTOPProjects",
        //        "tileText": "FTOP Projects",
        //        "isDefault": true,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }

        //], 
        "LVFTOP": [
            {
                "tileId": "LVFTOP_LVReportFilters",
                "tileText": "Set Report Level Filters",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "LVFTOP_LVPerfOverview",
                "tileText": "Performance Overview",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "LVFTOP_LVPerfOverviewByUsage",
                "tileText": "Performance by Usage",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "LVFTOP_LVServicesUsage",
                "tileText": "Services Usage",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "LVFTOP_UsageTrends",
                "tileText": "Usage Trends",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "LVFTOP_PerfRequirement",
                "tileText": "FRP Performance Requirement",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "LVFTOP_ADG",
                "tileText": "ADG",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "LVFTOP_DataDictionary",
                "tileText": "Data Dictionary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "FTCH": [
            {
                "tileId": "FTCH_HS",
                "tileText": "Health Summary",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTCH_PR",
                "tileText": "Progressions & Regressions",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTCH_SGHM",
                "tileText": "SCIM Groups & WL Health Movements",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTCH_Health",
                "tileText": "E3 Health",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTCH_Metrics",
                "tileText": "Metric Definitions",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "FTDQSD": [
            {
                "tileId": "FTDQSD_DQS",
                "tileText": "Data Quality Summary",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTDQSD_SV",
                "tileText": "Performance",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            //{
            //    "tileId": "FTDQSD_QDR",
            //    "tileText": "QDR",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            {
                "tileId": "FTDQSD_ReportDef",
                "tileText": "Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTDQSD_VH",
                "tileText": "Version History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
        ],

        "FTMWAP": [
            {
                "tileId": "FTMWAP_WP",
                "tileText": "Welcome Page",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTMWAP_OS",
                "tileText": "Opportunity Space",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTMWAP_CPQ",
                "tileText": "Compete, Projects",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTMWAP_DQ",
                "tileText": "Data Quality",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTMWAP_EAD",
                "tileText": "Entitlements & Assignments",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
            //{
            //    "tileId": "FTMWAP_PAT",
            //    "tileText": "Partner Assigned Tenants",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},           
        ],

        "PAP": [
            {
                "tileId": "PAP_Prioritized",
                "tileText": "Prioritized Accounts Performance",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
        ],

        "FTRAP": [
            {
                "tileId": "FTRAP_OKR",
                "tileText": "OKR Summary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTRAP_E5",
                "tileText": "Security",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTRAP_SI",
                "tileText": "Identity",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTRAP_PV",
                "tileText": "Purview",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTRAP_M365C",
                "tileText": "M365 Copilot",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTRAP_TP",
                "tileText": "Teams Phone",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTRAP_TR",
                "tileText": "Cloud Endpoints",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTRAP_VIVA",
                "tileText": "Viva",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            //{
            //    "tileId": "FTRAP_SRFC",
            //    "tileText": "Surface",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "FTRAP_W365",
            //    "tileText": "Windows 365",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            {
                "tileId": "FTRAP_QLT",
                "tileText": "Customer Effort Score",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTRAP_Insights",
                "tileText": "Insights",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTRAP_PROJECT",
                "tileText": "Projects",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTRAP_SPLT",
                "tileText": "Spotlights",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTRAP_DEF1",
                "tileText": "Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        //"FTList": [
        //    {
        //        "tileId": "FTList_List",
        //        "tileText": "S2200 Customers",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "FTList_T400",
        //        "tileText": "T400 Customers",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }
        //],

        "CRBR": [
            {
                "tileId": "CRBR_ByRequest",
                "tileText": "By Request",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }

        ],
        "CRPR": [
            {
                "tileId": "CRPR_Prioritized",
                "tileText": "Prioritized",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],


        "FTFTPL": [
            {
                "tileId": "FTFTPL_FTPrioritizedlist",
                "tileText": "FastTrack Prioritized list",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },

            {
                "tileId": "FTFTPL_Opportunity",
                "tileText": "Opportunity View",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
        ],

        "FTFunnel": [
            //{
            //    "tileId": "FTFunnel_SI",
            //    "tileText": "SI Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            {
                "tileId": "FTFunnel_IdentityFunnel",
                "tileText": "Identity Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTFunnel_Purview",
                "tileText": "Purview Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTFunnel_FunnelPerformance",
                "tileText": "Intune Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            //{
            //    "tileId": "FTFunnel_TPF",
            //    "tileText": "Teams Phone Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            {
                "tileId": "FTFunnel_TRF",
                "tileText": "Teams Phone Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []

            },
            {
                "tileId": "FTFunnel_SecurityFunnel",
                "tileText": "Security Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTFunnel_VIVA",
                "tileText": "Viva",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            //{
            //    "tileId": "FTFunnel_SF",
            //    "tileText": "Surface Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
        ],
        "FTBIADO": [
            {
                "tileId": "FTBIADO_FTBI",
                "tileText": "FTBA ADO",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "FTORD": [
            {
                "tileId": "FTORD_Performance",
                "tileText": "Performance",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTORD_AreaPerformance",
                "tileText": "Area Performance",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTORD_Intent",
                "tileText": "Intent",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTORD_TeamsPremium",
                "tileText": "Teams Premium Features",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTORD_RelatedReports",
                "tileText": "Related Reports",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "FTUSI": [
            {
                "tileId": "FTUSI_SupportAO",
                "tileText": "Support Agreement Overview",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTUSI_Designated",
                "tileText": "Designated Engineering",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTUSI_EnhancedDE",
                "tileText": "Enhanced Designated Engineering",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTUSI_UnifiedSDD",
                "tileText": "Unified Support Delivery Details",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },

            {
                "tileId": "FTUSI_Definitions",
                "tileText": "Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTUSI_ByCustomer",
                "tileText": "By Customer",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "PFTFS": [
            {
                "tileId": "PFTFS_RD",
                "tileText": "Report Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PFTFS_MWAF",
                "tileText": "FTA Forecast",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PFTFS_FTAAMP",
                "tileText": "FTA AM Portfolio",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PFTFS_OKRD",
                "tileText": "OKR Details",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            }
            //{
            //    "tileId": "PFTFS_AM",
            //    "tileText": "AM Forecast Portfolio",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "OKRForecast_Portfolio",
            //    "tileText": "S2200 OKR Forecast Portfolio",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "SecurityandIdentity",
            //    "tileText": "Security & Identity",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "OKRForecastPurview",
            //    "tileText": "Purview",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "TeamsPhone_TeamsRooms",
            //    "tileText": "Teams Phone & Teams Rooms",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "TeamsPhoneTeamsRoomsUsage",
            //    "tileText": "Teams Phone & Teams Rooms Usage",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "OKRForecast_Viva",
            //    "tileText": "Viva",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "OKRForecast_Surface",
            //    "tileText": "Surface",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
        ],
        "PurviewWorkloadFunnel": [
            {
                "tileId": "PurviewWorkloadFunnel_PIF",
                "tileText": "Purview IP Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PurviewWorkloadFunnel_PIRMF",
                "tileText": "Purview IRM Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PurviewWorkloadFunnel_DLMF",
                "tileText": "DLM Funnel ",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PurviewWorkloadFunnel_DNRF",
                "tileText": "DNR Funnel ",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PurviewWorkloadFunnel_FTSP",
                "tileText": "FT SME Purview",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }

        ],
        "WorkloadFunnel": [
            {
                "tileId": "WorkloadFunnel_MDEFunnel",
                "tileText": "MDE Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkloadFunnel_MDOFunnel",
                "tileText": "MDO Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkloadFunnel_MDIFunnel",
                "tileText": "MDI Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkloadFunnel_MDAFunnel",
                "tileText": "MDA Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkloadFunnel_FTSecurity",
                "tileText": "FT SME Security",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "WorkloadFunnel_SCIMBestOpportunities",
                "tileText": "Security Best Opportunities",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "Security": [

            {
                "tileId": "Security_Intent",
                "tileText": "Intent",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Security_Deployment",
                "tileText": "Deployment",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Security_KeyResult",
                "tileText": "Key Results",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Security_AreaPerformance",
                "tileText": "Area Performance",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Security_TenantFeatureData",
                "tileText": "Features",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            //{
            //    "tileId": "Security_FunnelConversion",
            //    "tileText": "Funnel Conversion",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "PFTSID_LXP",
            //    "tileText": "LXP Usage Score",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "PFTSID_SCIMF",
            //    "tileText": "SI Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "PFTSID_SIPCS",
            //    "tileText": "SI & Purview Customer Summary",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "PFTSID_SIPTS",
            //    "tileText": "SI & Purview Tenant Summary",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "PFTSID_TOC",
            //    "tileText": "All Tasks",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "PFTSID_S2500",
            //    "tileText": "SME Dashboard (2200 and Non 2200)",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "PFTSID_ID",
            //    "tileText": "Identity(AADP1&P2)",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},                
            //{
            //    "tileId": "PFTSID_FC",
            //    "tileText": "SLG Federal Customers",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "PFTSID_SCFD",
            //    "tileText": "Security Purview Features",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "PFTSID_MDE",
            //    "tileText": "MDE Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "PFTSID_MDO",
            //    "tileText": "MDO Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "PFTSID_MDA",
            //    "tileText": "MDA Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "PFTSID_MDI",
            //    "tileText": "MDI Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "PFTSID_OUF",
            //    "tileText": "Outcome Form",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},

            {
                "tileId": "Security_FunnelConversion",
                "tileText": "Funnel Conversion",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Security_AllTasks",
                "tileText": "All Tasks",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Security_Definitions",
                "tileText": "Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },

            {
                "tileId": "Security_OndemandFunnel",
                "tileText": "POCaas Report",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Security_FY24KeyMetrics",
                "tileText": "FY24 Key Metrics",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            // {
            //     "tileId": "Security_PPMT",
            //     "tileText": "Preview-POC Management tool",
            //     "isDefault": false,
            //     "hasSubPages": false,
            //     "subPages": []
            // },
            {
                "tileId": "Security_ManComTelemetry",
                "tileText": "Security Managed Commercial Telemetry",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Security_TOF",
                "tileText": "EDM Outcome Form",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
            //{
            //    "tileId": "PFTSID_RD",
            //    "tileText": "Report Definition",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //}
            /*,{
                "tileId": "PFTSID_LXPDEF",
                "tileText": "LXP & Funnel Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }*/
        ],


        "Purview": [
            {
                "tileId": "Purview_Intent",
                "tileText": "Intent",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Purview_Deployment",
                "tileText": "Deployment",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Purview_KeyResult",
                "tileText": "Key Results",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Purview_AreaPerformance",
                "tileText": "Area Performance",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Purview_TenantFeatureData",
                "tileText": "Features",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Purview_FunnelConversion",
                "tileText": "Funnel Conversion",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Purview_AllTasks",
                "tileText": "All Tasks",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Purview_FY24KeyMetrics",
                "tileText": "FY24 Key Metrics",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Purview_Definitions",
                "tileText": "Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Purview_Ondemand",
                "tileText": "Ondemand Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Purview_TaskOutcomeForm",
                "tileText": "EDM Outcome Form",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }



            //{
            //    "tileId": "PURVW_LXP",
            //    "tileText": "LXP Usage Score",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "PURVW_FTCFFFF",
            //    "tileText": "Purview Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "PURVW_SIPCS",
            //    "tileText": "SI & Purview Customer Summary",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "PURVW_SIPTS",
            //    "tileText": "SI & Purview Tenant Summary",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "PURVW_LXPFD",
            //    "tileText": "LXP & Funnel Definition",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{

            //    "tileId": "PURVW_MIP",
            //    "tileText": "PurviewIP Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "PURVW_MIG",
            //    "tileText": "PurviewDLM Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "PURVW_IRM",
            //    "tileText": "PurviewIRM Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []

            //},
            //{
            //    "tileId": "PURVW_S2500",
            //    "tileText": "SME Dashboard (2200 and Non 2200)",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "PURVW_DR",
            //    "tileText": "D&R Funnel",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "PURVW_AT",
            //    "tileText": "All Tasks",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "PURVW_SCF",
            //    "tileText": "Security Purview Features",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "PURVW_RD",
            //    "tileText": "Report Definitions",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //}
        ],
        "PurviewDPU": [
            {
                "tileId": "PurviewDPU_DPUIntent",
                "tileText": "DPU Intent",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PurviewDPU_Deployment",
                "tileText": "DPU Deployment",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PurviewDPU_KeyResults",
                "tileText": "DPU Key Results",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PurviewDPU_AreaPerformance",
                "tileText": "DPU Area Performance",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PurviewDPU_Features",
                "tileText": "Features",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PurviewDPU_FunnelConversion",
                "tileText": "DPU Funnel Conversion",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PurviewDPU_FY24KeyMetrics",
                "tileText": "FY24 Key Metrics",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PurviewDPU_EDMOutcomeForm",
                "tileText": "EDM Outcome Form",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PurviewDPU_Definitions",
                "tileText": "Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PurviewDPU_SCIMBestOpportunities",
                "tileText": "Security Best Opportunities",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        //"Windows365": [
        //    {
        //        "tileId": "W365_OKRFunnel",
        //        "tileText": "Windows 365 OKR Funnel",
        //        "isDefault": true,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "W365_MoMPerformance",
        //        "tileText": "MoM Performance",
        //        "isDefault": true,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "W365_TaskDetails",
        //        "tileText": "Task Details",
        //        "isDefault": true,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "TaskOutcomeForm",
        //        "tileText": "Task Outcome Form",
        //        "isDefault": true,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "W365_ReportDefinition",
        //        "tileText": "Report Definition",
        //        "isDefault": true,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }
        //],
        "EEFunnel": [

            {
                "tileId": "EEFunnel_Intent",
                "tileText": "Intent",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "EEFunnel_Deployment",
                "tileText": "Deployment",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "EEFunnel_KeyResult",
                "tileText": "Key Results",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "EEFunnel_AreaPerformance",
                "tileText": "Area Performance",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "EEFunnel_FunnelConversion",
                "tileText": "Funnel Conversion",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },

            {
                "tileId": "EEFunnel_UsageTrends",
                "tileText": "Usage Trends",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "EEFunnel_FTA",
                "tileText": "FTA",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },

            {
                "tileId": "EEFunnel_Definitions",
                "tileText": "Definitions",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            }
            //    {
            //        "tileId": "EEFunnel_OKR",
            //        "tileText": "OKR Funnel",
            //        "isDefault": true,
            //        "hasSubPages": false,
            //        "subPages": []
            //    },
            //{
            //    "tileId": "EEFunnel_PB",
            //    "tileText": "Playbook Execution",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "EEFunnel_MAU",
            //    "tileText": "MAU",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            /*{
                "tileId": "EEFunnel_VG",
                "tileText": "Viva Goals",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },*/
            //{
            //    "tileId": "EEFunnel_PBY",
            //    "tileText": "Playbook Execution (Yammer only)",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "EEFunnel_MAUYE",
            //    "tileText": "MAU (Yammer/Engage)",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "EEFunnel_GetGr",
            //    "tileText": "Get to Green - FT Direct",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "GettoGreenIPE",
            //    "tileText": "Get to Green - IPE",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "EEFunnel_VivaMBR",
            //    "tileText": "Viva MBR",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "EEFunnel_FY",
            //    "tileText": "Definitions",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //}
        ],

        "OKR": [

            {
                "tileId": "OKR_Funnel",
                "tileText": "Funnel Performance",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKR_AreaPerformance",
                "tileText": "Area Performance",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKR_FunnelConversion",
                "tileText": "Funnel Conversion",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKR_CEReport",
                "tileText": "CE Report",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OKR_ReportDefinitions",
                "tileText": "Report Definitions",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        //"FTPilots": [
        //    {
        //        "tileId": "FTPilots_Pilots",
        //        "tileText": "FT Pilots",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }
        //    //{
        //    //    "tileId": "FTPilots_Usage",
        //    //    "tileText": "Usage",
        //    //    "isDefault": false,
        //    //    "hasSubPages": false,
        //    //    "subPages": []
        //    //}
        //],


        "Identity": [
            {
                "tileId": "Identity_Intent",
                "tileText": "Intent",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Identity_Deployment",
                "tileText": "Deployment",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Identity_KeyResult",
                "tileText": "Key Results",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Identity_AreaPerformance",
                "tileText": "Area Performance",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Identity_TenantFeatureData",
                "tileText": "Features",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Identity_FunnelConversion",
                "tileText": "Funnel Conversion",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Identity_AllTasks",
                "tileText": "All Tasks",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Identity_FY24KeyMetrics",
                "tileText": "FY24 Key Metrics",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Identity_Definitions",
                "tileText": "Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Identity_Ondemand",
                "tileText": "Ondemand Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Identity_FTSMEIdentity",
                "tileText": "FT SME Identity",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Identity_SCIMBestOpportunities",
                "tileText": "Security Best Opportunities",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Identity_EntraCAFunnel",
                "tileText": "Entra CA Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "EEUsage": [
            {
                "tileId": "EEUsage_FUD",
                "tileText": "FY21 Usage Details",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "FTCD": [
            {
                "tileId": "FTCD_SF",
                "tileText": "SCIM Funnel",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTCD_CS",
                "tileText": "Compliance Summary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTCD_TOC",
                "tileText": "Preview All Tasks",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        /* "ESCIM": [
             {
                 "tileId": "ESCIM_Summary",
                 "tileText": "E3 Summary",
                 "isDefault": true,
                 "hasSubPages": false,
                 "subPages": []
             },
             {
                 "tileId": "ESCIM_SCIM",
                 "tileText": "SCIM Summary",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             }
         ],*/

        "FTFLWD": [
            {
                "tileId": "FTFLWD_FLWD",
                "tileText": "FLW Dashboard",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTFLWD_FLWOKRF",
                "tileText": "FLW OKR Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTFLWD_CPM",
                "tileText": "Conversion Probability Matrix",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTFLWD_RDHM",
                "tileText": "Regional Director Health Matrix",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTFLWD_FLWDMD",
                "tileText": "FLW Dashboard Monthly Distribution",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTFLWD_MWAD",
                "tileText": "MWA Deployment",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTFLWD_TOC",
                "tileText": "Table of Contents",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "FTCME": [
            {
                "tileId": "FTCME_RN",
                "tileText": "Report Navigation",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTCME_MFS",
                "tileText": "CME Metric Funnel Summary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTCME_AVT",
                "tileText": "CME Actuals Vs Targets",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTCME_SCENE",
                "tileText": "CME Scenarios",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTCME_RD",
                "tileText": "Report Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTCME_VH",
                "tileText": "Version History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "PFTC": [
            {
                "tileId": "PFTC_ID",
                "tileText": "Intune Dashboard",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PFTC_SMEP",
                "tileText": "SME Portfolio",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PFTC_TV",
                "tileText": "Tasks View",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PFTC_AFU",
                "tileText": "AADP Feature Usage",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PFTC_ASMEH",
                "tileText": "AADP SME History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PFTC_ISMEH",
                "tileText": "Intune SME History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PFTC_CS",
                "tileText": "Customer Summary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PFTC_UCM",
                "tileText": "Unique Count of MDM + Co-Mgmt",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PFTC_IMDMD",
                "tileText": "Intune MDM Distribution",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PFTC_IF",
                "tileText": "Intune Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PFTC_AADPF",
                "tileText": "AADP Funnel",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PFTC_TMP",
                "tileText": "TM Portfolio",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PFTC_AADPD",
                "tileText": "AADP Dashboard",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PFTC_TOC",
                "tileText": "Table of Contents",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        //"Adoption": [
        //    {
        //    "tileId": "Adoption",
        //    "tileText": "Adoption < 20%",
        //    "isDefault": false,
        //    "hasSubPages": false,
        //    "subPages": []
        //    },
        //],

        "Wizard": [
            {
                "tileId": "Wizard",
                "tileText": "Wizard",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        //"PBMAU":[
        //    {
        //        "tileId": "PBMAU_SPBMAU",
        //        "tileText": "S2500",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "PBMAU_OnDemand",
        //        "tileText": "On Demand",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "PBMAU_AUP",
        //        "tileText": "All Up",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }
        //],

        /*"FTCSUUSY": [
            {
                "tileId": "FTCSUUSY_FTCSUSummary",
                "tileText": "Summary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],*/

        // "FTCSUUFL": [
        //     {
        //         "tileId": "FTCSUUFL_FTCSUFunnel",
        //         "tileText": "CSU Funnel",
        //         "isDefault": false,
        //         "hasSubPages": false,
        //         "subPages": []
        //     }
        // ],

        // "FTCSNOTIF": [
        //     {
        //         "tileId": "FTCSNOTIF_Notifications",
        //         "tileText": "Notifications",
        //         "isDefault": false,
        //         "hasSubPages": false,
        //         "subPages": []
        //     }
        // ],

        /* "FTCSUUPV": [
             {
                 "tileId": "FTCSUUPV_FTCSUPlaybook",
                 "tileText": "Playbook View",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             }
         ],
 
         "FTCSUUTV": [
             {
                 "tileId": "FTCSUUTV_FTCSUTaskView",
                 "tileText": "Task View",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             }
         ],
 
         "FTCSUUSD": [
             {
                 "tileId": "FTCSUUSD_FTCSUSummaryDetails",
                 "tileText": "Summary Details",
                 "isDefault": false,
                 "hasSubPages": false,
                 "subPages": []
             }
         ],*/

        // "FTCSUUDef": [
        //     {
        //         "tileId": "FTCSUUDef_FTCSUDefinitions",
        //         "tileText": "Definitions",
        //         "isDefault": false,
        //         "hasSubPages": false,
        //         "subPages": []
        //     }
        // ],
        // "FTCSUUWork": [
        //     {
        //         "tileId": "FTCSUUWork_FTCSUWorkloadMapping",
        //         "tileText": "Workload Mapping",
        //         "isDefault": false,
        //         "hasSubPages": false,
        //         "subPages": []
        //     }
        // ],

        //"ScorecardFM": [
        //    //{
        //    //    "tileId": "ScorecardFM_FY23OKRsTenant",
        //    //    "tileText": "FY23 OKRs - Tenant",
        //    //    "isDefault": false,
        //    //    "hasSubPages": false,
        //    //    "subPages": []
        //    //},
        //    //{
        //    //    "tileId": "ScorecardFM_MGRYTD",
        //    //    "tileText": "MGR YTD",
        //    //    "isDefault": false,
        //    //    "hasSubPages": false,
        //    //    "subPages": []
        //    //},
        //    {
        //        "tileId": "ScorecardFT_MGR",
        //        "tileText": "FT MGR",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "ScorecardA15_Volume",
        //        "tileText": "A15 Volume",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    },
        //    {
        //        "tileId": "ScorecardFM_Definition",
        //        "tileText": "Report Definition",
        //        "isDefault": false,
        //        "hasSubPages": false,
        //        "subPages": []
        //    }
        //],



        "InternalCPORClaims": [
            {
                "tileId": "InternalCPORClaims_Welcome",
                "tileText": "Welcome Page",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "InternalCPORClaims_ClaimsDetails",
                "tileText": "CPOR Claims Details",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },

            {
                "tileId": "InternalCPORClaims_CPORSummary",
                "tileText": "CPOR Summary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },

            {
                "tileId": "InternalCPORClaims_UsageThreshold",
                "tileText": "Partner & Usage Threshold",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "InternalCPORClaims_DetailsSegment",
                "tileText": "CPOR Details - Segment and Size",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }

        ],

        "CPORClaims": [
            {
                "tileId": "CPORClaims_Summary",
                "tileText": "Claim summary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "CPORClaims_Details",
                "tileText": "Claim details",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "Workshops": [
            {
                "tileId": "Workshops_Status",
                "tileText": "Workshop status",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Workshops_CPOR",
                "tileText": "Workshop Impact - CPOR Claims",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Workshops_Summary",
                "tileText": "Workshop Partner Summary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "Workshops_Eligibility",
                "tileText": "Workshop Eligibility",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            /*
            {
                "tileId": "Workshops_FRPSummary",
                "tileText": "Workshop Summary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            } 
            */
        ],

        "PHE": [
            {
                "tileId": "PHE_Perf",
                "tileText": "FRP Performance Requirement",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            //{
            //    "tileId": "PHE_FRPE",
            //    "tileText": "FRP Eligibilty",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            {
                "tileId": "PHE_DataDictionary",
                "tileText": "Data Dictionary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "FRPWizard": [
            {
                "tileId": "FRPWizard_Wizard",
                "tileText": "FRP Internal-ADG",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
        ],


        "FRPFPMCapacity": [
            {
                "tileId": "FRPFPMCapacity_FPMCap",
                "tileText": "FPM Capacity",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FRPFPMCapacity_PartnerSegmentation",
                "tileText": "Partner Segmentation",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
        ],

        "FRPRDB": [
            {
                "tileId": "FRPRDB_Roledashboard",
                "tileText": "Partner View",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
        ],

        "FTUD": [
            {
                "tileId": "FTUD_Portfolio",
                "tileText": "Partner Portfolio detail",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTUD_AU",
                "tileText": "Active Usage per Partner",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTUD_WU",
                "tileText": "Partner Workload Usage Milestones",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTUD_FU",
                "tileText": "AADP Feature Usage",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTUD_PU",
                "tileText": "Partner Usage",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "OPSFTF": [
            {
                "tileId": "OPSFTF_Funnel",
                "tileText": "Funnel",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSFTF_SLA",
                "tileText": "SLAs",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSFTF_TI",
                "tileText": "Total Impact",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSFTF_TID",
                "tileText": "Total Impact Detail",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSFTF_SC",
                "tileText": "Summary - Charts",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSFTF_TOPS",
                "tileText": "TOP Submissions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSFTF_Sub",
                "tileText": "Submissions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSFTF_SR",
                "tileText": "Submitters Ranking",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSFTF_OR",
                "tileText": "Occurrences (+1) Ranking",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSFTF_RD",
                "tileText": "Report Definitions",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "TMD": [
            {
                "tileId": "TMD_I",
                "tileText": "Intro",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "TMD_PA",
                "tileText": "1.Portfolio Assignments",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "TMD_PMT",
                "tileText": "2.Playbook Mandatory Tasks",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "TMD_RFAD",
                "tileText": "3.RFA Delivery",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "TMD_CT",
                "tileText": "4.Customer time",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "TMD_DQ",
                "tileText": "5.Data Quality",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "TMD_MT",
                "tileText": "Mandatory Tasks - Completed by",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "PCR": [
            {
                "tileId": "PCR_I",
                "tileText": "Intro",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PCR_CT",
                "tileText": "Customer time",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PCR_ASSIGN",
                "tileText": "Assignment",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PCR_CTTPR",
                "tileText": "(MPR) - % Customer time trend per region",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PCR_TT",
                "tileText": "(MPR) - Tracked time",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PCR_PTT",
                "tileText": "(MPR) - % Tracked time",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PCR_PCTT",
                "tileText": "(MPR) - % Customer time trend",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PCR_CTT",
                "tileText": "Res.w/ Customer Time trend",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],


        "OPSBD": [
            {
                "tileId": "OPSBD_PD",
                "tileText": "Portfolio Dashboard",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_VTU",
                "tileText": "Volume to 40% Usage",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_NT",
                "tileText": "New Tenants",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_NEE",
                "tileText": "New Eligible Entitlements",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_CTL1",
                "tileText": "Consumption Taxonomy - L1",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_CTL2",
                "tileText": "Consumption Taxonomy - L2",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_IPL2",
                "tileText": "In-Progress - L2",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_L3F",
                "tileText": "L3 Focus",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_HIS",
                "tileText": "L1, L2, L3 History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_FTC",
                "tileText": "FT: O365 Core (EXO+SPO)",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_FTMA",
                "tileText": "FT: M365 Apps",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_FTEMSI",
                "tileText": "FT: EMS - Intune",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_FTEMSA",
                "tileText": "FT: EMS - AADP",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_FTS",
                "tileText": "FT: E5 Security",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_FTW",
                "tileText": "FT: Workload AU",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_DT",
                "tileText": "FT: O365 - Delivery Team",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_IDT",
                "tileText": "FT: EMS Intune by Delivery Team",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_ADT",
                "tileText": "FT: EMS AADP by Delivery Team",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_SDT",
                "tileText": "FT: E5 Security by Delivery Team",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_DD",
                "tileText": "Data Dictionary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSBD_VH",
                "tileText": "Version History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }

        ],

        "OPSFD": [
            {
                "tileId": "OPSFD_INTRO",
                "tileText": "Intro",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSFD_SRF",
                "tileText": "Start Here - Set Report Filters",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSFD_DQS",
                "tileText": "DQ Summary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSFD_WD",
                "tileText": "Workload Details",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSFD_TE",
                "tileText": "Time Entry",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSFD_VH",
                "tileText": "Version History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPSFD_AUR",
                "tileText": "AU Results",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "PDPS": [
            {
                "tileId": "PDPS_INTRO",
                "tileText": "Intro",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PDPS_TA",
                "tileText": "Tenant Assignments",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PDPS_PMT",
                "tileText": "Playbook Mandatory Tasks",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PDPS_CT",
                "tileText": "Customer time",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "PDPS_VH",
                "tileText": "Version History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "FTFRPM": [
            {
                "tileId": "FTFRPM_FRP2500",
                "tileText": "FRP S2500 | FY22 Scorecard",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTFRPM_FRP2501",
                "tileText": "FRP S2501+ | FY22 Scorecard",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTFRPM_FRPS",
                "tileText": "FRP Sustain | FY22 Scorecard",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTFRPM_VH",
                "tileText": "Version History",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTFRPM_TOC",
                "tileText": "Table of Contents",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],

        "FTSM": [
            {
                "tileId": "FTSM_FTSM",
                "tileText": "FT Scenario Mapping",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTSM_FAQ",
                "tileText": "FAQ's",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTSM_SMG",
                "tileText": "Scenario Mapping Guide",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FTSM_TOC",
                "tileText": "Table Of Contents",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "ASDR": [
            {
                "tileId": "ASDR_Help",
                "tileText": "Help",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "ASDR_Info",
                "tileText": "Info",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "ASDR_Measure",
                "tileText": "Measure",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "ASDR_Columns",
                "tileText": "Columns",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "ASDR_Relationships",
                "tileText": "Relationships",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "ASDR_Perspective",
                "tileText": "Perspectives",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "ASDR_Tables",
                "tileText": "Tables",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "ASDR_Changelog",
                "tileText": "Change Log",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "ASDR_Unused",
                "tileText": "Unused Field Log",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "ASDR_Lastprocessed",
                "tileText": "Last Processed",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "OPS": [
            {
                "tileId": "OPS_Index",
                "tileText": "Report Index",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_Introduction",
                "tileText": "Introduction",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_Summary",
                "tileText": "Summary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_Bucket",
                "tileText": "Partner and Usage Bucket",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_Segment",
                "tileText": "Segment and Size",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_Portfolio",
                "tileText": "Portfolio",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_Active",
                "tileText": "Active Usage",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_Workload",
                "tileText": "Partner Workload",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_Feature",
                "tileText": "Feature Usage",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_Fifteen",
                "tileText": "15% Forecast vs Actual",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_Fourty",
                "tileText": "40% Forecast vs Actual",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_Partner",
                "tileText": "Partner Usage",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_OLM",
                "tileText": "OLM MAM",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_AADP",
                "tileText": "AADP 3rd Party",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_Health",
                "tileText": "Data Health",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_HealthByRegion",
                "tileText": "Data Health By Region",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_Performance",
                "tileText": "Performance Requirement",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "OPS_ClaimSummary",
                "tileText": "Claim Summary",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
            //,{
            //    "tileId": "OPS_ClaimDetail",
            //    "tileText": "Claim Details",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "OPS_Eligibility",
            //    "tileText": "Workshop Eligibility",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "OPS_Edge",
            //    "tileText": "Edge",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "OPS_Status",
            //    "tileText": "Workshop Status",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "OPS_Impact",
            //    "tileText": "Workshop Impact",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "OPS_Partner",
            //    "tileText": "Workshop Partner",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //},
            //{
            //    "tileId": "OPS_History",
            //    "tileText": "History",
            //    "isDefault": false,
            //    "hasSubPages": false,
            //    "subPages": []
            //}
        ],
        "FRPComp": [
            {
                "tileId": "FRPComp_Summary",
                "tileText": "Details",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FRPComp_Details",
                "tileText": "Summary Compliance",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "FRPComp_Export",
                "tileText": "PRM Export",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ],
        "SP": [
            {
                "tileId": "SP_Strategic",
                "tileText": "Strategic Partners",
                "isDefault": true,
                "hasSubPages": false,
                "subPages": []
            },
            {
                "tileId": "SP_S2500",
                "tileText": "S2500 vs S2500+",
                "isDefault": false,
                "hasSubPages": false,
                "subPages": []
            }
        ]
    }
    //Add ConatinerID for which you want to switch reports between LFT and Live reports
    static SWITCH_REPORT = [
        "LVExecutionDashboard",
        "PDMInsightsPBP_EM_PV",
        "LVPerformancePlans",
    ];
    //For above mentioned reports add the filter group name and publishtag for filters
    static SWITCH_REPORT_FILTER: any = {
        LVPerformancePlans: [
            {
                Metric: [
                    {
                        PublishTag: "Metric",
                        IsValueFilterReq: true,
                        value: [
                            "Azure Consumed Revenue",
                            "Data & AI Consumed Revenue",
                            "Dynamics 365 Billed Revenue (excluding recurring)",
                            "E5 Billed Revenue",
                        ],
                    },
                ],
            },
        ],
        LVExecutionDashboard: [
            {
                "Partner Attributes": [
                    {
                        PublishTag: "ManagementType",
                        IsValueFilterReq: false,
                    },
                    {
                        PublishTag: "StrategicPartner",
                        IsValueFilterReq: false,
                    },
                    {
                        PublishTag: "ContributionType",
                        IsValueFilterReq: false,
                    },
                ],
            },
        ],
        PDMInsightsPBP_EM_PV: [
            {
                Time: [
                    {
                        PublishTag: "FMClose",
                        IsValueFilterReq: true,
                        value: ["Yes"],
                    },
                    {
                        PublishTag: "FiscalYear",
                        IsValueFilterReq: true,
                        value: ["FY20"],
                    },
                ],
            },
            {
                "Field Role": [
                    {
                        PublishTag: "M2Manager",
                        IsValueFilterReq: false,
                    },
                    {
                        PublishTag: "M1Manager",
                        IsValueFilterReq: false,
                    },
                    {
                        PublishTag: "Role",
                        IsValueFilterReq: true,
                        value: ["PDM"],
                    },
                    {
                        PublishTag: "Contact",
                        IsValueFilterReq: false,
                    },
                    {
                        PublishTag: "IsPrimary",
                        IsValueFilterReq: true,
                        value: ["Yes"],
                    },
                ],
            },
            {
                "Partner Attributes": [
                    {
                        PublishTag: "PartnerOneNameID",
                        IsValueFilterReq: false,
                    },
                    {
                        PublishTag: "PartnerOneSubID",
                        IsValueFilterReq: false,
                    },
                    {
                        PublishTag: "ManagementLevel",
                        IsValueFilterReq: false,
                    },
                    {
                        PublishTag: "Specialization",
                        IsValueFilterReq: false,
                    },
                    {
                        PublishTag: "SubSpecialization",
                        IsValueFilterReq: false,
                    },
                ],
            },
            {
                "Customer Attributes": [
                    {
                        PublishTag: "CustomerArea",
                        IsValueFilterReq: false,
                    },
                    {
                        PublishTag: "CustomerSubsidiary",
                        IsValueFilterReq: false,
                    },
                    {
                        PublishTag: "CustomerSegment",
                        IsValueFilterReq: false,
                    },
                    {
                        PublishTag: "CustomerSubSegment",
                        IsValueFilterReq: false,
                    },
                ],
            },
        ],
    };
    //SBLTL = Switch between LFT to Live
    static SBLTL_EMIT_ON_LIVE = "LoadLiveReport";
    static SBLTL_EMIT_ON_LFT = "LoadLFTReport";
    static SBLTL_EMIT_ON_WWUSER = "LoadWWUserReport";
    static LIST_NO_FILTERS = [
        "OCPLanding",
        "LVExecutionDashboard",
        "LVExecutionMotionGeoBenchmark",
        "LVExecutionMotionDrivers",
        "LVPerformancePlans",
        "LVPerformanceSummary",
        "LVOFinancials",
        "LVVSU",
    ];
    static MPL_TEMPLATE_DOWNLOAD_URL =
        Constants.SERVICE_CONTROLLER_URL + "api/download/mpldownload?";
    static MPL_BULK_UPLOAD_TEMPLATE_DOWNLOAD_URL =
        Constants.SERVICE_CONTROLLER_URL + "api/download/mplbulkuploaddownload?";
    static MPLtileGridConfig: any = {
        Report: {
            Geography: {
                selectedKey: "Worldwide",
            },
            GeographyLevel: {
                selectedKey: "Worldwide",
            },
        },
        Edit: {
            Area: {
                selectedKey: "ALL",
            },
            Region: {
                selectedKey: "ALL",
            },
            SubRegion: {
                selectedKey: "ALL",
            },
            Subsidiary: {
                selectedKey: "ALL",
            },
        },
    };
    static PINtileGridConfig = {
        Subsidiary: {
            selectedKey: "ALL",
        },
        Area: {
            selectedKey: "ALL",
        },
        Role: {
            selectedKey: "ALL",
        },
        Contact: {
            selectedKey: "ALL",
        },
        OCPCRMID: {
            selectedKey: "ALL",
        },
        MetricName: {
            selectedKey: "ALL",
        },
        SubRegion: {
            selectedKey: "ALL",
        },
        PartnerOneNameID: {
            selectedKey: "ALL",
        },
        PartnerSegment: {
            selectedKey: "ALL",
        },
        PartnerSubSegment: {
            selectedKey: "ALL",
        },
        ManagementLevel: {
            selectedKey: "ALL",
        },
        View: {
            selectedKey: "Metric View",
        },
        TimePeriod: {
            selectedKey: "YTD",
        },
    };
    static MAPPING_POWER_BI_NAMES: any = {
        Subsidiary: "Subsidiary",
        Area: "Area",
        Role: "Role",
        Contact: "Contact",
        "OCP CRM ID": "OCPCRMID",
        Segment: "PartnerSegment",
        "Sub-Segment": "PartnerSubSegment",
        "PartnerOne Name - ID": "PartnerOneNameID",
        Metric: "MetricName",
        SubRegion: "SubRegion",
        "Management Level": "ManagementLevel",
        //"Tier": "Tier",
        "Time Period": "TimePeriod",
    };
    static REPORT_TYPE = "report";
    static REPLACE_REPORTID = "[[reportId]]";
    static REPLACE_GROUPID = "[[groupId]]";
    static POWERBI_EMBED_URL: string =
        "https://msit.powerbi.com/reportEmbed?reportId=" +
        Constants.REPLACE_REPORTID;
    static REPORT_FIRST_PAGE: string = "ReportSection";
    static REPORT_SELECTED_SECTION: string;
    static ERROR_MESSAGE: string = "Something went wrong";
    static BAD_REQUEST_MESSAGE: string = "Something went wrong";
    static SERVER_ERROR_MESSAGE: string = "Something went wrong";
    static NO_DATA_MESSGAE: string = "No data available";
    static INSUFFICIENT_PARAMETERS_MESSAGE: string =
        "Sufficient parameters were not provided";
    static HTTP_STATUS = {
        OK: 200,
        NOTFOUND: 404,
        BADREQUEST: 400,
        SERVERERROR: 500,
        NOCONTENT: 204,
        FORBIDDEN: 403,
    };
    static FILE_NOT_UPDATED = "fileNotUpdated";
    static STRING_EMPTY: string = "";
    static EXPORT_EMPTY_PARAM: string = "$EMPTY$";
    static ADJUST_HEIGHT_TRIGGER: string = "adjustHeightTrigger";
    static NOT_APPLICABLE: string = "NA";
    static COMING_SOON: string = "Coming soon";
    static HYPHEN: string = "-";
    static TRAININGLIBRARY_SUCCESS_MESSAGES = {
        ADD_DOCUMENT: "New training document has been added.",
        ADD_DOCUMENT_LONG_UPLOAD:
            "Processing request.  Due to the size of your file, it may take some time before your training document is ready for viewing.",
        ADD_CATEGORY: "New training category has been added.",
        EDIT_DOCUMENT: "Training document data has been saved.",
        EDIT_DOCUMENT_LONG_UPLOAD:
            "Processing request.  Due to the size of your file, it may take some time before your training document changes take effect.",
        EDIT_CATEGORY: "Training category has been saved.",
        DELETE_DOCUMENT: "Training document has been deleted.",
        DELETE_CATEGORY: "Training category has been deleted.",
    };
    static TRAININGLIBRARY_ERROR_MESSAGES = {
        FORM_SAVE_FAILED:
            "An error has occurred while saving your changes.  Please try again later, or contact PI support.",
        CATEGORY_ERROR_MESSAGE:
            "Category was not selected.  Please select a category and/or subcategory.",
        SUBCATEGORY_ERROR_MESSAGE:
            'Subcategory was not selected.  Please select a subcategory other than "(none)".',
        FILE_MISSING_ERROR_MESSAGE: "No file was uploaded.  Please upload a file.",
        FILE_TOO_LARGE_ERROR_MESSAGE:
            "The file you are attempting to upload is too large.",
        TITLE_ERROR_MESSAGE: "Title cannot be blank.  Please enter a title.",
        DESCRIPTION_ERROR_MESSAGE:
            "Description cannot be blank.  Please enter a description.",
        TRAININGROLES_ERROR_MESSAGE:
            "Roles cannot be blank.  Please include roles to which this training applies.",
        TRAININGLEVEL_ERROR_MESSAGE: "Training level must be a 3 digit number.",
        TRAININGLENGTH_ERROR_MESSAGE:
            "Training length cannot be blank.  Please describe the volume or length of the training document.",
        FILETYPE_ERROR_MESSAGE:
            "Document type cannot be blank.  Please enter the document type.",
        CATEGORY_NAME_EMPTY_ERROR_MESSAGE:
            "Category name cannot be blank.  Please enter a name.",
        CATEGORY_NAME_ALREADY_EXISTS_ERROR_MESSAGE:
            "Another primary category already has this name.  Please choose a different name",
        SUBCATEGORY_NAME_ALREADY_EXISTS_ERROR_MESSAGE:
            "Another subcategory already has this name.  Please choose a different name",
        CATEGORY_HIERARCHY_ERROR_MESSAGE:
            "Category cannot be a subcategory of another subcategory.",
    };
    static TRAININGLIBRARY_CONFIRM_MESSAGES = {
        DELETE_DOCUMENT_MESSAGE: "Are you sure you want to delete this document?",
        DELETE_CATEGORY_MESSAGE:
            "Are you sure you want to delete this category?  All subcategories will be deleted, and all training documents will be orphaned under Library Overview.",
    };
    static NEWS_ANNOUNCEMENTS_DOWNLOAD_URL =
        Constants.SERVICE_CONTROLLER_URL + "api/download/newsannouncementfile?";
    static TRAINING_LIBRARY_DOWNLOAD_URL =
        Constants.SERVICE_CONTROLLER_URL + "api/download/trainingfile?";
    static GRAPH_API_USER =
        "https://graph.microsoft.com/v1.0/users/[#USERNAME#]/";
    static GRAPH_API_USER_IMAGE =
        "https://graph.microsoft.com/v1.0/users/[#USERNAME#]/photo/$value";
    static GRAPH_API_CURRENT_USER_IMAGE =
        "https://graph.microsoft.com/v1.0/me/photo/$value";
    static NO_IMAGE_URL = "../Images/UserImage.png";
    static NO_PROFILE_PIC_URL = "../Images/UserImage.png";
    static DOWNARROW_IMAGE_URL = "../Images/DownArrow.png";
    static SIDEARROW_IMAGE_URL = "../Images/sidearrow.svg";
    static UPARROW_IMAGE_URL = "../Images/UpArrow.png";
    static GRAPH_API_USER_CONFIG = {
        headers: { Authorization: "Bearer [##GraphAuth##]" },
    };
    static GRAPH_API_USER_IMAGE_CONFIG = {
        headers: {
            Authorization: "Bearer [##GraphAuth##]",
        },
        responseType: "arraybuffer",
    };
    static AZURE_SEARCH_RESULT =
        "indexes/catalog-index/docs/suggest?api-version=2020-06-30-Preview&search=";
    static AZURE_SEARCH_RESULT_B =
        "&suggesterName=sug-acro&$select=ID,ReportsTitle,ReportURL,Type&$top=10";
    static AZURE_SEARCH_RESULT_C =
        "indexes/catalog-index/docs?api-version=2020-06-30-Preview&$top=10&search=";
    static SINGLETON_POPUP_VISIBLE_EVENT = "popupUpdate"; // use this variable in global context for any flyout/dropdown/popup
    static ISATTAINEDINPARTNERCENTER_SET_EVENT = "isattainedinpartnercenter"; //Emit the event on IsAttainedInPartnerCenter values set
    static RECENTPARTNERSEARCH_UPDATE_EVENT = "RecentPartnerSearchUpdated"; //Emit the event on RecentPartnerSearchUpdated values set
    static RECENTREPORTS_UPDATE_EVENT = "RecentReportsUpdated"; //Emit the event on RecentReportsUpdated values set
    static FILTERS_POPUP_VISIBLE_EVENT = "showFilters"; //Emit the event opening any FilterPanel
    static FETCH_FILTER_CONFIG_EVENT = "fetchFilterConfig"; //Emit the event opening any FilterPanel
    static PERPECTIVETYPE_SELECTED = "PartnerSearch"; //Emit the event opening any FilterPanel

    static FILTER_PANEL: any = {
        FILTER_PANEL_REQUEST_HANDLER: "filterPanel/getConfiguration", //Handler to fetch the filter data
        FILTER_PANEL_REQUEST_HANDLER_REPORT: "filterPanel/getReportConfiguration", //Handler to fetch the filter data
        FILTER_PANEL_SINGLE_REQUEST_HANDLER: "filterPanel/getFilterData", //Handler to fetch the filter data
        FILTER_PANEL_POST_VIEW_HANDLER: "filterPanel/postView", //Handler to post the view information
        FILTER_PANEL_GET_VIEW_HANDLER: "filterPanel/getView", //Handler to get the view data
        FILTER_PANEL_EDIT_VIEW_HANDLER: "filterPanel/editView", //Handler to edit the view for the user
        FILTER_PANEL_DELETE_VIEW_HANDLER: "filterPanel/deleteView", //Handler to delete the view for the user
        FILTER_PANEL_NAME_VIEW_NULL: "View name cannot be empty", //Empty view name error message
        FILTER_PANEL_NAME_VIEW_UNIQUE: "Please enter a unique view name", //Duplicate view name error message
        FILTER_PANEL_EMIT: "filterPanelClosed", //Emit event reference to close the filter panel
        FILTER_APPLIED_CALL: "filterAppliedEmit", //Emit event reference on applying he filters in the filter panel
        BASIC_FILTER_URL: "http://powerbi.com/product/schema#basic", //Apply basic filter to PBI report
        ADVANCE_FILTER_URL: "http://powerbi.com/product/schema#advanced", //Apply advanced filter to PBI report
        FILTER_NAME_REPLACE: {
            table: {},
            column: {},
        },
        FILTER_PANEL_USER_VIEWS: "UserViewsLoaded", //Emit event reference on successfully fetching User Views
        PARTNER_ONE_COUNT_HANDLER: "partnerdetails/getCount",
        PARTNER_ONE_COUNT_HANDLER_FOR_APD_PBR: "partnerservice/RequestHandler",
        MSIT_STATUS: "filterPanel/getMSITstatus",
    };
    static PARAMETRISED_PARTNER_COUNT: any = ["partnerbase", "actionpack"];
    static HIDE_TRAINING_ICON: any = ["training"];
    static TOKEN_REFRESH_HANDLER = "token/getTokens"; //Handler to refresh the tokens near expiry
    static PAGINATION_SIZE_DEFAULT = 5;
    static SILENT_TOKEN_REFRESH_INTERVAL = 30; //Time (in minutes) after which tocken should expire silently
    static FILE_UPLOAD_MAXIMUM_SIZE_IN_BYTES = 104857600; //100 MB
    static FILE_UPLOAD_WARNING_SIZE_IN_BYTES = 5242880; //5 MB

    static ALERT_TIMEOUT = 5000; //5 seconds

    static REGEX_WHITESPACE: RegExp = /^\s*$/;
    static REGEX_TRAINING_LEVEL: RegExp = /^\d{3}$/;
    static EXTERNAL_REPORT_TILES: Array<IExternalReportData> = [
        {
            tileText: "Partner Performance Dashboard",
            url: "https://aka.ms/ppreporting",
            isClickable: true,
            isVisible: true,
            group: "",
            subGroup: "",
        },
    ];
    static HEADER_DROPDOWN_ID = "ExpandCollapsePopup";
    static USER_DETAILS_POPUP_ID = "UserDetailsPopup";
    static REFRESH_DETAILS_POPUP_ID = "RefreshDetailsPopup";
    static HUB_REFRESH_DETAILS_POPUP_ID = "HubRefreshDetailsPopup";
    static SUB_FUNCTIONAL_AREA_POPUP = "SubFunctionaAreaPopup";
    static PATTOOL_REGION_POPUP_ID = "PATToolRegionPopup";
    static PATTOOL_COUNTRY_POPUP_ID = "PATToolCountryPopup";
    static PBI_FILTER_GET_ROUTE = "ReportFilter/";
    static PBI_FILTER_DATA_GET_ROUTE = "ReportFilter/listdetails/";
    static HFP_FILTER_COUNT_GET_ROUTE = "ReportFilter/filtercountdetails/";
    static SEPARATOR: string = "$|$";
    static PARTNER_PARAMETER_FILTER_MAPPING = {
        PartnerID: {
            Table: "Partner",
            Column: "Partner ID",
        },
        PartnerOneID: {
            Table: "PartnerOne",
            Column: "PartnerOne ID",
        },
        PartnerOneSubID: {
            Table: "PartnerOne Sub",
            Column: "PartnerOne Sub ID",
        },
    };

    static HTML_GRID_TO_FILTER_PANEL_MAPPING = [
        {
            QueryID: 1570,
            Parameter: "ReportingArea",
        },
        {
            QueryID: 1571,
            Parameter: "ReportingRegion",
        },
        {
            QueryID: 1572,
            Parameter: "ReportingSubRegion",
        },
        {
            QueryID: 1573,
            Parameter: "ReportingSubsidiary",
        },
        {
            QueryID: 1574,
            Parameter: "IsFMClose",
        },
    ];
    static PARTNERONE_FILTER_CONTAINER_INT = [
        "SPOSWOpportunity",
        "SPOSWSuccessEngagement",
        "SPOSWPartnerDeals",
        "SPOCPPSummary",
        "SPOCPPO365",
        "SPOCPPD365Performance",
        "SPOCPPAzure",
        "SPOCPPEnterprise",
        "SPOCPPPowerBI",
        "SPOBilledRevenue",
        "SPOInvestment",
        "SPOIncentive",
        "SPOClaims",
        "PBP",
        "TVTab",
    ];
    static PARTNER_FILTER_PARAMETER_INT = [];
    static PARTNER_FILTER_CONTAINER_INT = [
        "SPOCPPSummary",
        "SPOCPPO365",
        "SPOCPPD365Performance",
        "SPOCPPAzure",
        "SPOCPPEnterprise",
        "SPOBilledRevenue",
        "SPOCPPPowerBI",
        "GPSReferral",
        "SPOCOSummary",
        "SPOPartnerMembership",
        "SPOPartnerProfile",
        "SPOPartnerPCProfile",
        "SPOResellerSummary",
        "SPOPartnerTraining",
        "SPOPartnerTrainingadmin",
        "PBP",
        "TVTab",
    ];
    static HEADER_CONTROLLER_MAPPING: any = {
        home: {
            Header: "Partner Insights",
            Breadcrumb: "",
            BreadcrumbLink: "",
            docTitle: "FastTrack BI",
            hasBreadcrumb: false,
            showOnBoardingPopUp: true,
        },
        singlepartneroverview: {
            Header: "Single Partner Overview",
            Breadcrumb: "Partner Insights",
            BreadcrumbLink: "/",
            docTitle: "Single Partner Overview",
            hasBreadcrumb: true,
            showOnBoardingPopUp: true,
        },
        partnerperformance: {
            Header: "Partner Performance Tool",
            Breadcrumb: "Partner Insights",
            BreadcrumbLink: "/",
            docTitle: "Partner Performance Tool",
            hasBreadcrumb: true,
            showOnBoardingPopUp: false,
        },
        gpsbiexporttracker: {
            Header: "GPS Partner Insights Export Tracker",
            Breadcrumb: "Partner Insights",
            BreadcrumbLink: "/",
            docTitle: "GPS Partner Insights Export Tracker",
            hasBreadcrumb: true,
            showOnBoardingPopUp: false,
        },
        partnersearch: {
            Header: "Partner Search",
            Breadcrumb: "Partner Insights",
            BreadcrumbLink: "/",
            docTitle: "Partner Search",
            hasBreadcrumb: true,
            showOnBoardingPopUp: false,
        },
        whatsnew: {
            Header: "What's New",
            Breadcrumb: "Partner Insights",
            BreadcrumbLink: "/",
            docTitle: "What's New",
            hasBreadcrumb: true,
            showOnBoardingPopUp: false,
        },
        notifications: {
            Header: "Notifications",
            Breadcrumb: "Partner Insights",
            BreadcrumbLink: "/",
            docTitle: "Notifications",
            hasBreadcrumb: true,
            showOnBoardingPopUp: false,
        },
        partnerbase: {
            Header: "Partner Base Report",
            Breadcrumb: "Partner Insights",
            BreadcrumbLink: "/",
            docTitle: "Partner Base Report",
            hasBreadcrumb: true,
            showOnBoardingPopUp: false,
        },
        powerbidetails: {
            Header: "Detail Report",
            Breadcrumb: "Partner Insights",
            BreadcrumbLink: "/",
            docTitle: "Detail Report",
            hasBreadcrumb: true,
            showOnBoardingPopUp: false,
        },
        azureskillsinitiative: {
            Header: "Azure Skills Initiative",
            Breadcrumb: "Partner Insights",
            BreadcrumbLink: "/",
            docTitle: "Azure Skills Initiative",
            hasBreadcrumb: true,
            showOnBoardingPopUp: false,
        },
        newsandannouncements: {
            Header: "News and Announcements",
            Breadcrumb: "Partner Insights",
            BreadcrumbLink: "/",
            docTitle: "FastTrack BI",
            hasBreadcrumb: true,
            showOnBoardingPopUp: false,
        },
        errorerror404: {
            Header: "Error",
            Breadcrumb: "Partner Insights",
            BreadcrumbLink: "/",
            docTitle: "FastTrack BI",
            hasBreadcrumb: true,
            showOnBoardingPopUp: false,
        },
        actionpack: {
            Header: "Action Pack Dashboard",
            Breadcrumb: "Partner Insights",
            BreadcrumbLink: "/",
            docTitle: "Action Pack Dashboard",
            hasBreadcrumb: true,
            showOnBoardingPopUp: false,
        },
        training: {
            Header: "Training Library",
            Breadcrumb: "Partner Insights",
            BreadcrumbLink: "/",
            docTitle: "FastTrack BI",
            hasBreadcrumb: true,
            showOnBoardingPopUp: false,
        },

        partnercompetencyperformance: {
            Header: "Partner Competency Performance",
            Breadcrumb: "Partner Insights",
            BreadcrumbLink: "/",
            docTitle: "Partner Competency Performance",
            hasBreadcrumb: true,
            showOnBoardingPopUp: false,
        },
        pattool: {
            Header: "PAT tool summary",
            Breadcrumb: "Partner Insights",
            BreadcrumbLink: "/",
            docTitle: "PAT Tool",
            hasBreadcrumb: true,
            showOnBoardingPopUp: false,
        },
        distributor: {
            Header: "Partner Account Targeting tool for Distributor​s",
            Breadcrumb: "Partner Insights",
            BreadcrumbLink: "/",
            docTitle: "PAT Tool",
            hasBreadcrumb: true,
            showOnBoardingPopUp: false,
        },
        lsp: {
            Header: "Partner Account Targeting tool for LSPs",
            Breadcrumb: "Partner Insights",
            BreadcrumbLink: "/",
            docTitle: "PAT Tool",
            hasBreadcrumb: true,
            showOnBoardingPopUp: false,
        },
        planningtool: {
            Header: "Scorecard Performance Management Planning Tool",
            Breadcrumb: "Partner Insights",
            BreadcrumbLink: "/",
            docTitle: "FY19 Capacity Planning",
            hasBreadcrumb: true,

            showOnBoardingPopUp: false,
        },
        var: {
            Header: "Partner Account Targeting tool​ for Top VARs",
            Breadcrumb: "Partner Insights",
            BreadcrumbLink: "/",
            docTitle: "PAT Tool",
            hasBreadcrumb: true,
            showOnBoardingPopUp: false,
        },
        partneronekeyring: {
            Header: "PartnerOne KeyRing",
            Breadcrumb: "Partner Insights",
            BreadcrumbLink: "/?tab=8_1",
            docTitle: "PartnerOne KeyRing",
            hasBreadcrumb: true,
            showOnBoardingPopUp: false,
        },
    };
    static RESTRICTED_PARTNER_ORG_TYPE = "1"; //Compare text string to check if a PartnerID is Distributor
    static RESTRICTED_Virtual_ORG_TYPE = "2"; //Compare text string to check if a VOrgID is Distributor
    static GLOBAL_PARTNER_TYPE = "1";
    static VORG_HIERARCHY_TITLE = "Virtual Organization"; //compare text string to check if a PartnerID is for VORG
    static LOCATION_HIERARCHY_TITLE = "Location"; //compare text string to check if a PartnerID is for VORG
    static HEAD_QUARTER_HIERARCHY_TITLE = "Head Quarters"; //compare text string to check if a PartnerID is for VORG
    //Effective parameter mapping in case of VORG based on the container for PowerBI embedded reports
    static EFFECTIVE_PARAM_LIST = {
        VORGPARTNER: "VOrgPartnerID",
        LOCATION: "PartnerID",
    };

    static AUTO_JOIN_LINK = "";
    static O365_GROUPID = "";
    static GRAPH_API_CHECKGROUPMEMBERSHIP =
        "https://graph.microsoft.com/v1.0/me/checkMemberGroups/";

    static EFFECTIVE_PARAM_CONFIG: any = {
        VOrgPartnerID: {
            SPOCPPSummary: {
                Table: "UnifiedPartner",
                Column: "VOrg Partner ID",
            },
            SPOCPPO365: {
                Table: "UnifiedPartner",
                Column: "VOrg Partner ID",
            },
            SPOCPPAzure: {
                Table: "UnifiedPartner",
                Column: "VOrg Partner ID",
            },
            SPOCPPD365Performance: {
                Table: "UnifiedPartner",
                Column: "VOrg Partner ID",
            },
            SPOCPPEnterprise: {
                Table: "UnifiedPartner",
                Column: "VOrg Partner ID",
            },
            SPOCPPPowerBI: {
                Table: "UnifiedPartner",
                Column: "VOrg Partner ID",
            },
            SPOPartnerTraining: {
                Table: "Partner",
                Column: "VOrg Partner ID",
            },
            SPOPartnerTrainingadmin: {
                Table: "Partner",
                Column: "VOrg Partner ID",
            },
            SPOPartnerMembership: {
                Table: "Partner",
                Column: "VOrg Partner ID",
            },
            SPOPartnerProfile: {
                Table: "Partner",
                Column: "VOrg Partner ID",
            },
        },
        PartnerID: {
            SPOPartnerTraining: {
                Table: "Partner",
                Column: "Partner ID",
            },
            SPOPartnerTrainingadmin: {
                Table: "Partner",
                Column: "Partner ID",
            },
        },
    };
    static SINGLE_PARTNER_DROPDOWN_SLICER_CONFIG: any = {
        MPL: {
            Report: {
                Geography: "WorldWide",
                GeographyLevel: "WorldWide",
            },
            Edit: {
                Area: "ALL",
                Region: "ALL",
                SubRegion: "ALL",
                Subsidiary: "ALL",
            },
        },
        PIN: {
            Subsidiary: "ALL",
            Area: "ALL",
            Role: "ALL",
            Contact: "ALL",
            OCPCRMID: "ALL",
            MetricName: "ALL",
            SubRegion: "ALL",
            PartnerOneNameID: "ALL",
            PartnerSegment: "ALL",
            PartnerSubSegment: "ALL",
            ManagementLevel: "ALL",
            TimePeriod: "YTD",
        },
        CPP: {
            Summary: {
                TimePeriod: "TTM",
                SubscriptionStatus: "ALL",
                SubscriptionRenewal: "ALL",
                IsAutoRenew: "ALL",
                SummaryPartnerAttachType: "ALL",
                CPPProduct: "ALL",
                StatusCategory: "Active",
            },
            O365: {
                TimePeriod: "TTM",
                O365PartnerAttachType: "ALL",
                SKU: "ALL",
                Workload: "EXO",
            },
            Azure: {
                TimePeriod: "TTM",
                Offer: "ALL",
                OfferType: "ALL",
                BilledSystem: "ALL",
                AzurePartnerAttachType: "ALL",
            },
            EnterpriseMobility: {
                TimePeriod: "TTM",
                PartnerAttachType: "ALL",
            },
            Dynamics365: {
                TimePeriod: "TTM",
                PartnerAttachType: "ALL",
            },
            PowerBI: {
                TimePeriod: "TTM",
                PartnerAttachType: "ALL",
            },
        },
        IUR: {
            IUR: {
                IURVsCommercial: "ALL",
                Product: "ALL",
                SubscriptionType: "ALL",
            },
        },
        ProfileMembershipTraining: {
            Training: {
                IsMCP: "ALL",
                IsAssociatedMPN: "ALL",
            },
            Learning: {
                Roles: "ALL",
                Products: "ALL",
                Levels: "ALL",
            },
        },
        Referral: {
            Referral: {
                ReferralCustomerGeography: "ALL",
                ReferralCustomerRegion: "ALL",
                ReferralCustomerSubRegion: "ALL",
                ReferralCustomerSubsidiary: "ALL",
                PartnerDealCreatedFiscalYear: "ALL",
                PartnerDealCreatedFiscalMonth: "ALL",
            },
        },
        CustomerOpportunities: {
            CustomerOpportunities: {
                COCloudAscentProductGroup: "ALL",
                COArea: "ALL",
                COSegment: "ALL",
                COSubSegment: "ALL",
                COCluster: "ALL",
                COMPNVOrgID: "",
                MPNVOrgID: "",
                MPNID: "",
            },
        },
        PT: {
            ACR: {
                CreditedArea: "ALL",
                CreditedSubregion: "ALL",
                CreditedSubsidiary: "ALL",
                PartnerGeography: "ALL",
                PartnerSubsidiary: "ALL",
                IsFY19Managed: "ALL",
                PartnerSpecialization: "ALL",
                PartnerSubSpecialization: "ALL",
                ManagementLevel: "ALL",
                IsManaged: "ALL",
            },
            ACR2: {
                CreditedArea: "ALL",
                CreditedSubregion: "ALL",
                CreditedSubsidiary: "ALL",
                PartnerGeography2: "ALL",
                Segment: "ALL",
                Industry: "ALL",
                Vertical: "ALL",
            },
        },
    };
    static SINGLE_PARTNER_POWERBI_PAGE_FILTERS: any = {
        Report: {
            Geography: {
                Table: "",
                Column: "",
            },
            GeographyLevel: {
                Table: "",
                Column: "",
            },
        },
        Edit: {
            Area: {
                Table: "",
                Column: "",
            },
            Region: {
                Table: "",
                Column: "",
            },
            SubRegion: {
                Table: "",
                Column: "",
            },
            Subsidiary: {
                Table: "",
                Column: "",
            },
        },
        Summary: {
            TimePeriod: {
                Table: "Time Period",
                Column: "Time Period",
            },
            SummaryPartnerAttachType: {
                Table: "Partner Attach Type",
                Column: "Partner Attach Type",
            },
            SubscriptionStatus: {
                Table: "Subscription Status",
                Column: "Status Name",
            },
            StatusCategory: {
                Table: "Subscription Status",
                Column: "Status Category",
            },
            IsAutoRenew: {
                Table: "Subscription Type",
                Column: "Is Auto Renew",
            },
            SubscriptionRenewal: {
                Table: "Subscription Type",
                Column: "Due In",
            },
            CPPProduct: {
                Table: "Product",
                Column: "Reporting Product Name",
            },
        },
        O365: {
            TimePeriod: {
                Table: "Time Period",
                Column: "Time Period",
            },
            O365PartnerAttachType: {
                Table: "Partner Attach Type",
                Column: "Partner Attach Type",
            },
            SKU: {
                Table: "SKU Type",
                Column: "SKUType",
            },
            Workload: {
                Table: "O365 Workload",
                Column: "Workload",
            },
        },
        Azure: {
            TimePeriod: {
                Table: "Time Period",
                Column: "Time Period",
            },
            AzurePartnerAttachType: {
                Table: "Partner Attach Type",
                Column: "Partner Attach Type",
            },
            Offer: {
                Table: "Azure Offer",
                Column: "Offer",
            },
            OfferType: {
                Table: "Azure Offer",
                Column: "Offer Type",
            },
            BilledSystem: {
                Table: "Billing System",
                Column: "Billing System",
            },
        },
        EnterpriseMobility: {
            TimePeriod: {
                Table: "Time Period",
                Column: "Time Period",
            },
            PartnerAttachType: {
                Table: "Partner Attach Type",
                Column: "Partner Attach Type",
            },
        },
        Dynamics365: {
            TimePeriod: {
                Table: "Time Period",
                Column: "Time Period",
            },
            PartnerAttachType: {
                Table: "Partner Attach Type",
                Column: "Partner Attach Type",
            },
        },
        PowerBI: {
            TimePeriod: {
                Table: "Time Period",
                Column: "Time Period",
            },
            PartnerAttachType: {
                Table: "Partner Attach Type",
                Column: "Partner Attach Type",
            },
        },
        Learning: {
            Roles: {
                Table: "MS Learn Role",
                Column: "Role Name",
            },
            Products: {
                Table: "MS Learn Product",
                Column: "Sub Product",
            },
            Levels: {
                Table: "MS Learn Level",
                Column: "Level Name",
            },
        },
        IUR: {
            IURVsCommercial: {
                Table: "IUR vs Commercial",
                Column: "IUR vs Commercial",
            },
        },
        Referral: {
            ReferralCustomerGeography: {
                Table: "Customer Geography",
                Column: "Area",
            },
            ReferralCustomerRegion: {
                Table: "Customer Geography",
                Column: "Region",
            },
            ReferralCustomerSubRegion: {
                Table: "Customer Geography",
                Column: "Sub Region",
            },
            ReferralCustomerSubsidiary: {
                Table: "Customer Geography",
                Column: "Subsidiary",
            },
            PartnerDealCreatedFiscalYear: {
                Table: "Partner Deal",
                Column: "Partner Deal Created Fiscal Year",
            },
            PartnerDealCreatedFiscalMonth: {
                Table: "Partner Deal",
                Column: "Partner Deal Created Fiscal Month",
            },
        },
        CustomerOpportunities: {
            COCloudAscentProductGroup: {
                Table: "Customer Opportunities Product Hierarchy",
                Column: "Product Group",
            },
            COArea: {
                Table: "Customer",
                Column: "Customer Area",
            },
            COSegment: {
                Table: "Customer",
                Column: "Segment Name (TPID)",
            },
            COSubSegment: {
                Table: "Customer",
                Column: "Sub Segment Name (TPID)",
            },
            COCluster: {
                Table: "Cluster",
                Column: "Cluster",
            },
            MPNVOrgID: {
                Table: "Customer Opportunities",
                Column: "MPNVOrgID",
            },
            COMPNVOrgID: {
                Table: "Expirations",
                Column: "MPNVOrgID",
            },
            MPNID: {
                Table: "License Version",
                Column: "MPNVOrgID",
            },
        },
    };
    static PARTNER_PARAMETER_PLACEHOLDER_MAPPING: any = {
        PartnerOneID: "PartnerOneKey",
        PartnerID: "PartnerKey",
        PartnerOneSubID: "PartnerOneSubKey",
        VOrgPartnerID: "PartnerVORG",
    };
    static SINLGE_PARTNER_HTML_SLICER_DATA_CONFIG: any = {
        PartnerDealCreatedFiscalMonth: {
            id: [],
            name: [],
        },
        PartnerDealCreatedFiscalYear: {
            id: [],
            name: [],
        },
        ReferralCustomerGeography: {
            id: [],
            name: [],
        },
        ReferralCustomerRegion: {
            id: [],
            name: [],
        },
        ReferralCustomerSubRegion: {
            id: [],
            name: [],
        },
        ReferralCustomerSubsidiary: {
            id: [],
            name: [],
        },
        TimePeriod: {
            id: [1, 2, 3],
            name: ["YTD", "QTD", "TTM"], //Hardcoded for default load (Bug 105638 fix)
        },
        PartnerAttachType: {
            id: [
                "Partner of Record",
                "Transacting Partner of Record",
                "Delegated Admin Privilege",
                "FastTrack",
            ],
            name: [
                "Partner of Record",
                "Transacting Partner of Record",
                "Delegated Admin Privilege",
                "FastTrack",
            ],
        },
        EMSPartnerAttachType: {
            id: [
                "Partner of Record",
                "Transacting Partner of Record",
                "Delegated Admin Privilege",
                "CPOR - FRP",
                "CPOR - Competency",
            ],
            name: [
                "Partner of Record",
                "Transacting Partner of Record",
                "Delegated Admin Privilege",
                "CPOR - FRP",
                "CPOR - Competency",
            ],
        },
        D365PartnerAttachType: {
            id: [
                "Partner of Record",
                "Transacting Partner of Record",
                "Delegated Admin Privilege",
                "FastTrack",
                "CPOR",
            ],
            name: [
                "Partner of Record",
                "Transacting Partner of Record",
                "Delegated Admin Privilege",
                "FastTrack",
                "CPOR",
            ],
        },
        SummaryPartnerAttachType: {
            id: [
                "Partner of Record",
                "CSP Tier1",
                "CSP Tier2",
                "TPOR-SOA",
                "TPOR-DIR",
                "TPOR-IND",
                "Partner Reported ACR",
                "Delegated Admin Privilege",
                "FastTrack",
                "Manual Attribution China",
                "Partner As End Customer",
                "Partner Admin Link",
                "Marketplace",
                "Customer Usage Attribution",
                "Deal Registration",
                "Dual Credit",
            ],
            name: [
                "Partner of Record",
                "CSP Tier1",
                "CSP Tier2",
                "TPOR-SOA",
                "TPOR-DIR",
                "TPOR-IND",
                "Partner Reported ACR",
                "Delegated Admin Privilege",
                "FastTrack",
                "Manual Attribution China",
                "Partner As End Customer",
                "Partner Admin Link",
                "Marketplace",
                "Customer Usage Attribution",
                "Deal Registration",
                "Dual Credit",
            ],
        },
        AzurePartnerAttachType: {
            id: [
                "Partner of Record",
                "CSP Tier1",
                "CSP Tier2",
                "TPOR-SOA",
                "TPOR-DIR",
                "TPOR-IND",
                "Partner Reported ACR",
                "Manual Attribution China",
                "Partner As End Customer",
                "Partner Admin Link",
                "Marketplace",
                "Customer Usage Attribution",
                "Deal Registration",
                "Dual Credit",
            ],
            name: [
                "Partner of Record",
                "CSP Tier1",
                "CSP Tier2",
                "TPOR-SOA",
                "TPOR-DIR",
                "TPOR-IND",
                "Partner Reported ACR",
                "Manual Attribution China",
                "Partner As End Customer",
                "Partner Admin Link",
                "Marketplace",
                "Customer Usage Attribution",
                "Deal Registration",
                "Dual Credit",
            ],
        },
        O365PartnerAttachType: {
            id: [],
            name: [],
        },
        IURVsCommercial: {
            name: ["IUR", "Commercial"],
            id: [1, 2], //Hardcoded values for IUR report. These values will not be fetched from cube
        },
        Product: {
            id: [],
            name: [],
        },
        SubscriptionType: {
            id: [],
            name: [],
        },
        SKU: {
            id: [],
            name: [],
        },
        Workload: {
            id: [],
            name: [],
        },
        CPPProduct: {
            id: [],
            name: [],
        },
        IsAutoRenew: {
            id: [],
            name: [],
        },
        SubscriptionRenewal: {
            name: [],
            id: [],
        },
        SubscriptionStatus: {
            id: [],
            name: [],
        },
        StatusCategory: {
            id: [1, 2],
            name: ["Active", "Inactive"],
        },
        Offer: {
            id: [],
            name: [],
        },
        OfferType: {
            id: [],
            name: [],
        },
        BilledSystem: {
            id: [],
            name: [],
        },
        IsMCP: {
            id: [],
            name: [],
        },
        IsAssociatedMPN: {
            id: [],
            name: [],
        },
        Roles: {
            id: [],
            name: [],
        },
        Products: {
            id: [],
            name: [],
        },
        Levels: {
            id: [],
            name: [],
        },
        Area: {
            id: [],
            name: [],
        },
        Region: {
            id: [],
            name: [],
        },
        SubRegion: {
            id: [],
            name: [],
        },
        Subsidiary: {
            id: [],
            name: [],
        },
        GeographyLevel: {
            id: [],
            name: [],
        },
        Geography: {
            id: [],
            name: [],
        },
        SolutionArea: {
            id: [],
            name: [],
        },
        CreditedArea: {
            id: [],
            name: [],
        },
        CreditedSubregion: {
            name: [],
            id: [],
        },
        CreditedSubsidiary: {
            id: [],
            name: [],
        },
        PartnerGeography: {
            id: [],
            name: [],
        },
        PartnerSubsidiary: {
            id: [],
            name: [],
        },
        IsFY19Managed: {
            id: [],
            name: [],
        },
        IsManaged: {
            id: [],
            name: [],
        },
        ManagementLevel: {
            id: [],
            name: [],
        },
        PartnerSpecialization: {
            id: [],
            name: [],
        },
        PartnerSubSpecialization: {
            id: [],
            name: [],
        },
        PartnerGeography2: {
            id: [],
            name: [],
        },
        Segment: {
            id: [],
            name: [],
        },
        Industry: {
            id: [],
            name: [],
        },
        Vertical: {
            id: [],
            name: [],
        },
        COSegment: {
            id: [],
            name: [],
        },
        COCluster: {
            id: [],
            name: [],
        },
        COSubSegment: {
            id: [],
            name: [],
        },
        COArea: {
            id: [],
            name: [],
        },
        COCloudAscentProductGroup: {
            id: [],
            name: [],
        },
        FiscalYear: {
            id: [1, 2, 3],
            name: ["YTD", "Current Quarter", "FY19"],
        },
        PBPFiscalYear: {
            id: [1, 3],
            name: ["YTD", "FY19"],
        },
    };
    static SINLGE_PARTNER_HTML_SLICER_DATA_CONFIG_PIN: any = {
        Subsidiary: {
            id: [],
            name: [],
        },
        Area: {
            id: [],
            name: [],
        },
        Role: {
            id: [],
            name: [],
        },
        Contact: {
            id: [],
            name: [],
        },
        OCPCRMID: {
            id: [],
            name: [],
        },
        MetricName: {
            id: [],
            name: [],
        },
        SubRegion: {
            id: [],
            name: [],
        },
        PartnerOneNameID: {
            id: [],
            name: [],
        },
        PartnerSegment: {
            id: [],
            name: [],
        },
        PartnerSubSegment: {
            id: [],
            name: [],
        },
        ManagementLevel: {
            id: [],
            name: [],
        },
        View: {
            id: [1, 2, 3],
            name: ["Metric View", "Partner View", "Global Partner View"],
        },
        TimePeriod: {
            id: [1, 2],
            name: ["YTD", "MTD"],
        },
    };
    static SINLGE_PARTNER_HTML_SLICER_CONFIG_MAPPING: any = {
        900: "ReferralCustomerGeography",
        902: "ReferralCustomerRegion",
        904: "ReferralCustomerSubRegion",
        905: "ReferralCustomerSubsidiary",
        906: "PartnerDealCreatedFiscalYear",
        908: "PartnerDealCreatedFiscalMonth",
        18: "TimePeriod",
        19: "O365PartnerAttachType",
        21: "Product",
        23: "SubscriptionType",
        24: "SKU",
        124: "Workload",
        25: "CPPProduct",
        26: "IsAutoRenew",
        27: "SubscriptionRenewal",
        28: "SubscriptionStatus",
        29: "Offer",
        30: "OfferType",
        31: "BilledSystem",
        54: "IsMCP",
        55: "IsAssociatedMPN",
        57: "Region",
        58: "SubRegion",
        59: "Subsidiary",
        60: "Area",
        69: "GeographyLevel",
        71: "Geography",
        72: "FiscalYear",
        98: "COArea",
        103: "COSegment",
        102: "COSubSegment",
        104: "COCluster",
        99: "COCloudAscentProductGroup",
    };
    static SINLGE_PARTNER_HTML_SLICER_CONFIG_MAPPING_PIN: any = {
        401: "Subsidiary",
        402: "Role",
        403: "Contact",
        413: "OCPCRMID",
        404: "MetricName",
        406: "SubRegion",
        407: "PartnerOneNameID",
        408: "PartnerSegment",
        409: "PartnerSubSegment",
        418: "Area",
        419: "ManagementLevel",
    };
    static D365_ACCESS_CHECK = false;
    // Constants for Planning Tool page
    static PLANNING_TOOL_SOLUTION_AREA_CONFIG_ID = "515";
    static PLANNING_TOOL_TILE_DATA = "501";
    static PLANNING_TOOL_BASE_DATA = "521";
    static PLANNING_TOOL_AREA_CONFIG_ID = "505";
    static PLANNING_TOOL_SUB_REGION_CONFIG_ID = "506";
    static PLANNING_TOOL_SUBSIDIARY_CONFIG_ID = "507";
    static PLANNING_TOOL_PARTNER_GEO_CONFIG_ID = "508";
    static PLANNING_TOOL_PARTNER_SUBSIDIARY_CONFIG_ID = "518";
    static PLANNING_TOOL_FY19MANAGED_CONFIG_ID = "502";
    static PLANNING_TOOL_ISMANAGED_CONFIG_ID = "520";
    static PLANNING_TOOL_MANAGED_LEVEL_CONFIG_ID = "503";
    static PLANNING_TOOL_PARTNER_SEGMENT_CONFIG_ID = "509";
    static PLANNING_TOOL_PARTNER_SUBSEGMENT_CONFIG_ID = "510";
    static PLANNING_TOOL_CUSTOMER_GEO_CONFIG_ID = "511";
    static PLANNING_TOOL_SEGMENT_CONFIG_ID = "512";
    static PLANNING_TOOL_INDUSTRY_CONFIG_ID = "504";
    static PLANNING_TOOL_VERTICAL_CONFIG_ID = "514";
    static PLANNING_TOOL_FILTERS_MAPPING = {
        515: "SolutionArea",
        505: "CreditedArea",
        506: "CreditedSubregion",
        507: "CreditedSubsidiary",
        508: "PartnerGeography",
        518: "PartnerSubsidiary",
        502: "IsFY19Managed",
        520: "IsManaged",
        503: "ManagementLevel",
        509: "PartnerSpecialization",
        510: "PartnerSubSpecialization",
        511: "PartnerGeography2",
        512: "Segment",
        504: "Industry",
        514: "Vertical",
    };
    static PLANNING_TOOL_PAGE_MAPPING = {
        "Teams Active Usage": 66,
        "M365 E5 Billed Revenue": 62,
        "Azure Partner Certified Individuals": 61,
        ACR: 95,
        "Data & AI Consumed Revenue": 100,
        "D365 Billed Revenue": 90,
        "IP Co-Sell for Azure": 139,
        "IP Co-Sell for D365": 140,
        "IP Co-Sell for Teams": 141,
        "Biz Apps Certified Individuals": 67,
    };
    static PLANNING_TOOL_DEFAULT_YIELD = {
        "Teams Active Usage": 1000,
        "M365 E5 Billed Revenue": 100000,
        "Azure Partner Certified Individuals": 0,
        ACR: 100000,
        "Data & AI Consumed Revenue": 50000,
        "D365 Billed Revenue": 300000,
        "IP Co-Sell for Azure": 0,
        "IP Co-Sell for Teams": 0,
        "IP Co-Sell for D365": 0,
        "Biz Apps Certified Individuals": 0,
    };
    static PLANNING_TOOL_DATA_MAPPING = {
        MetricKey: "Metric Key Tool",
        SelectedArea: "Credited Area Tool",
        SelectedSubRegion: "Credited SubRegion Tool",
        Target: "Target",
        MinAccountability: "Minimum Accountability",
        ServicesGuidance: "Services Guidance",
        ISVGuidance: "ISV Guidance",
        Baseline: "PY Baseline",
        ServicesContribution: "PY Services Contribution",
        ServicesContribution_CoSellPrio: "PY Services Contribution CoSell",
        ServicesContribution_Rest: "PY Services Contribution Rest",
        ServicesPartnerCount_CoSellPrio: "PY Services Partner Count Cosell",
        ServicesPartnerCount_Rest: "PY Services Partner Count Rest",
        ServicesYield_CoSellPrio: "PY Services Yield Cosell",
        ServicesYield_Rest: "PY Services Yield Rest",
        ISVContribution: "PY ISV Contribution",
        ISVContribution_CoSellPrio: "PY ISV Contribution CoSell",
        ISVContribution_Rest: "PY ISV Contribution Rest",
        ISVPartnerCount_CoSellPrio: "PY ISV Partner Count Cosell",
        ISVPartnerCount_Rest: "PY ISV Partner Count Rest",
        ISVYield_CoSellPrio: "PY ISV Yield Cosell",
        ISVYield_Rest: "PY ISV Yield Rest",
        UnmanagedContribution: "PY Unmanaged Contribution",
        UnmanagedContribution_CoSellPrio: "PY Unmanaged Contribution CoSell",
        UnmanagedContribution_Rest: "PY Unmanaged Contribution Rest",
        UnmanagedPartnerCount_CoSellPrio: "PY Unmanaged Partner Count Cosell",
        UnmanagedPartnerCount_Rest: "PY Unmanaged Partner Count Rest",
        UnmanagedYield_CoSellPrio: "PY Unmanaged Yield Cosell",
        UnmanagedYield_Rest: "PY Unmanaged Yield Rest",
        MPAActual: "MPA Actual",
        MPAServices: "MPA Services Contribution",
        MPAISV: "MPA ISV Contribution",
        MPAManaged: "MPA Managed Contribution",
        MPAUnmanaged: "MPA Unmanaged Contribution",
        PartnerDedupRatioServices_CoSellPrio:
            "Area Services Cosell Partner Dedup Ratio",
        PartnerDedupRatioServices_Rest: "Area Services Rest Partner Dedup Ratio",
        PartnerDedupRatioISV_CoSellPrio: "Area ISV Cosell Partner Dedup Ratio",
        PartnerDedupRatioISV_Rest: "Area ISV Rest Partner Dedup Ratio",
        Area_ServicesContribution_CoSellPrio: "Area Services Cosell Contribution",
        Area_ServicesContribution_Rest: "Area Services Rest Contribution",
        Area_ISVContribution_CoSellPrio: "Area ISV Cosell Contribution",
        Area_ISVContribution_Rest: "Area ISV Rest Contribution",
        Area_UnmanagedContribution: "Area Unmanaged Contribution",
        Area_TotalContribution: "Area Total Contribution",
        Area_ServicesPartnerCount_CoSellPrio:
            "Area Services Dedup Cosell Partner Count",
        Area_ServicesPartnerCount_Rest: "Area Services Dedup Rest Partner Count",
        Area_ISVPartnerCount_CoSellPrio: "Area ISV Dedup Cosell Partner Count",
        Area_ISVPartnerCount_Rest: "Area ISV Dedup Rest Partner Count",
        HasFullAccess: "UserAccess",
        Metric: "[Metric].[Metric ID].",
        Area: "[Credited Geography].[Credited Area ID].",
        SubRegion: "[Credited Geography].[Credited SubRegion].",
        Subsidiary: "[Credited Geography].[Credited Subsidiary].",
        PartnerArea: "[PartnerOne Sub].[Partner Area].",
        PartnerSpecialization: "[PartnerOne Sub].[Partner Specialization].",
        Industry: "[Customer].[Industry].",
        Tabular_SelectedArea: "[Measures].[Credited Area Tool]",
        Tabular_SelectedSubRegion: "[Measures].[Credited SubRegion Tool]",
        Tabular_MetricKey: "[Measures].[Metric Key Tool]",
        Tabular_ISVPartnerCount_CoSellPrio:
            "[Measures].[PY ISV Cosell Partner Count]",
        Tabular_ISVYield_CoSellPrio: "[Measures].[PY ISV Cosell Yield]",
        Tabular_ISVPartnerCount_Rest: "[Measures].[PY ISV Rest Partner Count]",
        Tabular_ISVYield_Rest: "[Measures].[PY ISV Rest Yield]",
        Tabular_ServicesPartnerCount_CoSellPrio:
            "[Measures].[PY Services Cosell Partner Count]",
        Tabular_ServicesYield_CoSellPrio: "[Measures].[PY Services Cosell Yield]",
        Tabular_ServicesPartnerCount_Rest:
            "[Measures].[PY Services Rest Partner Count]",
        Tabular_ServicesYield_Rest: "[Measures].[PY Services Rest Yield]",
        Tabular_UnmanagedPartnerCount_CoSellPrio:
            "[Measures].[PY Unmanaged Cosell Partner Count]",
        Tabular_UnmanagedYield_CoSellPrio: "[Measures].[PY Unmanaged Cosell Yield]",
        Tabular_UnmanagedPartnerCount_Rest:
            "[Measures].[PY Unmanaged Rest Partner Count]",
        Tabular_UnmanagedYield_Rest: "[Measures].[PY Unmanaged Rest Yield]",
    };
    static PLANNING_TOOL_GRIDS = [
        "PlanningToolPartnerPivotACR",
        "PlanningToolPartnerPivotD365BR",
        "PlanningToolPartnerPivotAzureSkills",
        "PlanningToolPartnerPivotDAICRCR",
        "PlanningToolPartnerPivotTeamsAU",
        "PlanningToolPartnerPivotM365BR",
        "PlanningToolPartnerPivotIPCosellAzure",
        "PlanningToolPartnerPivotBizAppsCP",
    ];
    static PLANNING_TOOL_GRIDS_DB = [
        "PlanningToolPartnerPivotACRDB",
        "PlanningToolPartnerPivotD365BRDB",
        "PlanningToolPartnerPivotAzureSkillsDB",
        "PlanningToolPartnerPivotDAICRCRDB",
        "PlanningToolPartnerPivotTeamsAUDB",
        "PlanningToolPartnerPivotM365BRDB",
        "PlanningToolPartnerPivotIPCosellAzureDB",
        "PlanningToolPartnerPivotBizAppsCPDB",
    ];

    static PLANNING_TOOL_MULTISUBAREAS = [
        "APAC",
        "Central and Eastern Europe",
        "Greater China",
        "Latam",
        "MEA",
        "Western Europe",
    ];
    static PLANNING_TOOL_GUIDANCE_NA = [
        "United Arab Emirates",
        "Middle East Cluster",
    ];

    static MPL_PLANNING_UNMANAGED_HTML_GRID = "AllUnmanagedRecords&Grid";
    static SINGLE_PARTNER_HTML_GRIDS_CONFIG: any = {
        "LeaderViewDataRefreshReport&Grid": {
            gridid: "LeaderViewDataRefreshReport&Grid",
            type: "single",
            parameters: [
                { Key: "SortBy", Value: [[""]] },
                { Key: "SortOrder", Value: [[""]] },
            ],
            page: "",
            exportParameters: [],
        },
        AdvanceSearchGrid: {
            gridid: "AdvanceSearchGrid",
            type: "single",
            parameters: [
                { Key: "searchQuery", Value: [[""]] },
                { Key: "id", Value: [[""]] },
                { Key: "bu", Value: [[""]] },
                { Key: "PartnerID", Value: [[""]] },
                { Key: "atc", Value: [[""]] },
                { Key: "role", Value: [[""]] },
                { Key: "competency", Value: [[""]] },
                { Key: "Start", Value: [["0"]] },
                { Key: "callCounter", Value: [[]] },
            ],
            page: "",
            exportParameters: [],
        },
        "AllUnmanagedRecords&Grid": {
            gridid: "AllUnmanagedRecords&Grid",
            type: "single",
            parameters: [
                { Key: "Area", Value: [[""]] },
                { Key: "Region", Value: [[""]] },
                { Key: "SubRegion", Value: [[""]] },
                { Key: "Subsidiary", Value: [[""]] },
                { Key: "Start", Value: [["0"]] },
            ],
            page: "Edit",
            exportParameters: [{}],
        },
        //CLAS
        "SPOCOSummaryExpirations&Grid": {
            gridid: "SPOCOSummaryExpirations&Grid",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "SortBy", Value: [["[Expiring Revenue]"]] },
                { Key: "SortOrder", Value: [["DESC"]] },
                { Key: "ExpirationVORG", Value: [[""]] },
                { Key: "OptyVORG", Value: [[""]] },
                { Key: "Area", Value: [["ALL"]] },
                { Key: "ProductGroup", Value: [["ALL"]] },
                { Key: "Segment", Value: [["ALL"]] },
                { Key: "SubSegment", Value: [["ALL"]] },
                { Key: "Cluster", Value: [["ALL"]] },
            ],
            page: "CustomerOpportunities",
            sortable: true,
            exportParameters: [
                {
                    Placeholder: "ExpirationVORG",
                    Key: "MPNVOrgID",
                    CubeMeasure: "[Expirations].[MPNVOrgID]",
                },
                {
                    Placeholder: "OptyVORG",
                    Key: "MPNVOrgID",
                    CubeMeasure: "[Customer Opportunities].[MPNVOrgID]",
                },
                {
                    Placeholder: "Area",
                    Key: "Customer Area",
                    CubeMeasure: "[Customer].[Customer Area]",
                },
                {
                    Placeholder: "ProductGroup",
                    Key: "ProductGroup",
                    CubeMeasure:
                        "[Customer Opportunities Product Hierarchy].[ProductGroup]",
                },
                {
                    Placeholder: "Segment",
                    Key: "Segment Name (TPID)",
                    CubeMeasure: "[Customer].[Segment Name (TPID)]",
                },
                {
                    Placeholder: "SubSegment",
                    Key: "Sub Segment Name (TPID)",
                    CubeMeasure: "[Customer].[Sub Segment Name (TPID)]",
                },
                {
                    Placeholder: "Cluster",
                    Key: "Cluster",
                    CubeMeasure: "[Cluster].[Cluster]",
                },
            ],
        },
        "SPOCOSummaryAzure&Grid": {
            gridid: "SPOCOSummaryAzure&Grid",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "SortBy", Value: [["[Customer ID]"]] },
                { Key: "SortOrder", Value: [["ASC"]] },
                { Key: "OptyVORG", Value: [[""]] },
                { Key: "Area", Value: [["ALL"]] },
                { Key: "Segment", Value: [["ALL"]] },
                { Key: "SubSegment", Value: [["ALL"]] },
                { Key: "Cluster", Value: [["ALL"]] },
            ],
            page: "CustomerOpportunities",
            sortable: true,
            exportParameters: [
                {
                    Placeholder: "OptyVORG",
                    Key: "MPNVOrgID",
                    CubeMeasure: "[Customer Opportunities].[MPNVOrgID]",
                },
                {
                    Placeholder: "Area",
                    Key: "Customer Area",
                    CubeMeasure: "[Customer].[Customer Area]",
                },
                {
                    Placeholder: "Segment",
                    Key: "Segment Name (TPID)",
                    CubeMeasure: "[Customer].[Segment Name (TPID)]",
                },
                {
                    Placeholder: "SubSegment",
                    Key: "Sub Segment Name (TPID)",
                    CubeMeasure: "[Customer].[Sub Segment Name (TPID)]",
                },
                {
                    Placeholder: "Cluster",
                    Key: "Cluster",
                    CubeMeasure: "[Cluster].[Cluster]",
                },
            ],
        },

        "SPOCOSummaryD365&Grid": {
            gridid: "SPOCOSummaryD365&Grid",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "SortBy", Value: [["[Customer ID]"]] },
                { Key: "SortOrder", Value: [["ASC"]] },
                { Key: "OptyVORG", Value: [[""]] },
                { Key: "Area", Value: [["ALL"]] },
                { Key: "Segment", Value: [["ALL"]] },
                { Key: "SubSegment", Value: [["ALL"]] },
                { Key: "Cluster", Value: [["ALL"]] },
            ],
            page: "CustomerOpportunities",
            sortable: true,
            exportParameters: [
                {
                    Placeholder: "OptyVORG",
                    Key: "MPNVOrgID",
                    CubeMeasure: "[Customer Opportunities].[MPNVOrgID]",
                },
                {
                    Placeholder: "Area",
                    Key: "Customer Area",
                    CubeMeasure: "[Customer].[Customer Area]",
                },
                {
                    Placeholder: "Segment",
                    Key: "Segment Name (TPID)",
                    CubeMeasure: "[Customer].[Segment Name (TPID)]",
                },
                {
                    Placeholder: "SubSegment",
                    Key: "Sub Segment Name (TPID)",
                    CubeMeasure: "[Customer].[Sub Segment Name (TPID)]",
                },
                {
                    Placeholder: "Cluster",
                    Key: "Cluster",
                    CubeMeasure: "[Cluster].[Cluster]",
                },
            ],
        },
        "SPOCOSummaryEMS&Grid": {
            gridid: "SPOCOSummaryEMS&Grid",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "SortBy", Value: [["[Customer ID]"]] },
                { Key: "SortOrder", Value: [["ASC"]] },
                { Key: "OptyVORG", Value: [[""]] },
                { Key: "Area", Value: [["ALL"]] },
                { Key: "Segment", Value: [["ALL"]] },
                { Key: "SubSegment", Value: [["ALL"]] },
                { Key: "Cluster", Value: [["ALL"]] },
            ],
            page: "CustomerOpportunities",
            sortable: true,
            exportParameters: [
                {
                    Placeholder: "OptyVORG",
                    Key: "MPNVOrgID",
                    CubeMeasure: "[Customer Opportunities].[MPNVOrgID]",
                },
                {
                    Placeholder: "Area",
                    Key: "Customer Area",
                    CubeMeasure: "[Customer].[Customer Area]",
                },
                {
                    Placeholder: "Segment",
                    Key: "Segment Name (TPID)",
                    CubeMeasure: "[Customer].[Segment Name (TPID)]",
                },
                {
                    Placeholder: "SubSegment",
                    Key: "Sub Segment Name (TPID)",
                    CubeMeasure: "[Customer].[Sub Segment Name (TPID)]",
                },
                {
                    Placeholder: "Cluster",
                    Key: "Cluster",
                    CubeMeasure: "[Cluster].[Cluster]",
                },
            ],
        },
        "SPOCOSummaryM365&Grid": {
            gridid: "SPOCOSummaryM365&Grid",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "SortBy", Value: [["[Customer ID]"]] },
                { Key: "SortOrder", Value: [["ASC"]] },
                { Key: "OptyVORG", Value: [[""]] },
                { Key: "Area", Value: [["ALL"]] },
                { Key: "Segment", Value: [["ALL"]] },
                { Key: "SubSegment", Value: [["ALL"]] },
                { Key: "Cluster", Value: [["ALL"]] },
            ],
            page: "CustomerOpportunities",
            sortable: true,
            exportParameters: [
                {
                    Placeholder: "OptyVORG",
                    Key: "MPNVOrgID",
                    CubeMeasure: "[Customer Opportunities].[MPNVOrgID]",
                },
                {
                    Placeholder: "Area",
                    Key: "Customer Area",
                    CubeMeasure: "[Customer].[Customer Area]",
                },
                {
                    Placeholder: "Segment",
                    Key: "Segment Name (TPID)",
                    CubeMeasure: "[Customer].[Segment Name (TPID)]",
                },
                {
                    Placeholder: "SubSegment",
                    Key: "Sub Segment Name (TPID)",
                    CubeMeasure: "[Customer].[Sub Segment Name (TPID)]",
                },
                {
                    Placeholder: "Cluster",
                    Key: "Cluster",
                    CubeMeasure: "[Cluster].[Cluster]",
                },
            ],
        },
        "SPOCOSummaryO365&Grid": {
            gridid: "SPOCOSummaryO365&Grid",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "SortBy", Value: [["[Customer ID]"]] },
                { Key: "SortOrder", Value: [["ASC"]] },
                { Key: "OptyVORG", Value: [[""]] },
                { Key: "Area", Value: [["ALL"]] },
                { Key: "Segment", Value: [["ALL"]] },
                { Key: "SubSegment", Value: [["ALL"]] },
                { Key: "Cluster", Value: [["ALL"]] },
            ],
            page: "CustomerOpportunities",
            sortable: true,
            exportParameters: [
                {
                    Placeholder: "OptyVORG",
                    Key: "MPNVOrgID",
                    CubeMeasure: "[Customer Opportunities].[MPNVOrgID]",
                },
                {
                    Placeholder: "Area",
                    Key: "Customer Area",
                    CubeMeasure: "[Customer].[Customer Area]",
                },
                {
                    Placeholder: "Segment",
                    Key: "Segment Name (TPID)",
                    CubeMeasure: "[Customer].[Segment Name (TPID)]",
                },
                {
                    Placeholder: "SubSegment",
                    Key: "Sub Segment Name (TPID)",
                    CubeMeasure: "[Customer].[Sub Segment Name (TPID)]",
                },
                {
                    Placeholder: "Cluster",
                    Key: "Cluster",
                    CubeMeasure: "[Cluster].[Cluster]",
                },
            ],
        },
        "SPOCOSummarySQL&Grid": {
            gridid: "SPOCOSummarySQL&Grid",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "SortBy", Value: [["[Customer ID]"]] },
                { Key: "SortOrder", Value: [["ASC"]] },
                { Key: "OptyVORG", Value: [[""]] },
                { Key: "Area", Value: [["ALL"]] },
                { Key: "Segment", Value: [["ALL"]] },
                { Key: "SubSegment", Value: [["ALL"]] },
                { Key: "Cluster", Value: [["ALL"]] },
            ],
            page: "CustomerOpportunities",
            sortable: true,
            exportParameters: [
                {
                    Placeholder: "OptyVORG",
                    Key: "MPNVOrgID",
                    CubeMeasure: "[Customer Opportunities].[MPNVOrgID]",
                },
                {
                    Placeholder: "Area",
                    Key: "Customer Area",
                    CubeMeasure: "[Customer].[Customer Area]",
                },
                {
                    Placeholder: "Segment",
                    Key: "Segment Name (TPID)",
                    CubeMeasure: "[Customer].[Segment Name (TPID)]",
                },
                {
                    Placeholder: "SubSegment",
                    Key: "Sub Segment Name (TPID)",
                    CubeMeasure: "[Customer].[Sub Segment Name (TPID)]",
                },
                {
                    Placeholder: "Cluster",
                    Key: "Cluster",
                    CubeMeasure: " [Cluster].[Cluster]",
                },
            ],
        },

        //CPP Enterprise Mobility HTML Grids
        SPOCPPEnterpriseSubscription: {
            gridid: "SPOCPPEnterpriseSubscription",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "SearchString", Value: [[""]] },
                { Key: "SortBy", Value: [["[Active Usage]"]] },
                { Key: "SortType", Value: [["DESC"]] },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]], updateExportParameter: true },
                { Key: "PartnerAttachType", Value: [["ALL"]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
            ],
            page: "EnterpriseMobility",
            sortable: true,
            exportParameters: [
                {
                    Placeholder: "PartnerVORG",
                    Key: "VOrg Partner ID",
                    CubeMeasure: "[UnifiedPartner].[VOrg Partner ID]",
                },
                {
                    Placeholder: "PartnerOneKey",
                    Key: "PartnerOne ID",
                    CubeMeasure: "[Reporting PartnerOne].[PartnerOne ID]",
                },
                {
                    Placeholder: "TimePeriod",
                    Key: "Time Period",
                    CubeMeasure: "[Time Period].[Time Period]",
                },
                {
                    Placeholder: "PartnerAttachType",
                    Key: "Partner Attach Type",
                    CubeMeasure: "[Partner Attach Type].[Partner Attach Type]",
                },
                {
                    Placeholder: "PartnerKey",
                    Key: "Partner ID",
                    CubeMeasure: "[UnifiedPartner].[Partner ID]",
                },
            ],
        },
        SPOCPPEnterpriseTenant: {
            gridid: "SPOCPPEnterpriseTenant",
            type: "multi",
            subgrid: ["SPOCPPEnterpriseTenantSub"],
            parameters: [
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "SearchString", Value: [[""]] },
                { Key: "SortBy", Value: [["[Active Seats]"]] },
                { Key: "SortType", Value: [["DESC"]] },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]], updateExportParameter: true },
                { Key: "PartnerAttachType", Value: [["ALL"]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
            ],
            page: "EnterpriseMobility",
            sortable: true,
            exportParameters: [
                {
                    Placeholder: "PartnerVORG",
                    Key: "VOrg Partner ID",
                    CubeMeasure: "[UnifiedPartner].[VOrg Partner ID]",
                },
                {
                    Placeholder: "PartnerOneKey",
                    Key: "PartnerOne ID",
                    CubeMeasure: "[Reporting PartnerOne].[PartnerOne ID]",
                },
                {
                    Placeholder: "TimePeriod",
                    Key: "Time Period",
                    CubeMeasure: "[Time Period].[Time Period]",
                },
                {
                    Placeholder: "PartnerAttachType",
                    Key: "Partner Attach Type",
                    CubeMeasure: "[Partner Attach Type].[Partner Attach Type]",
                },
                {
                    Placeholder: "PartnerKey",
                    Key: "Partner ID",
                    CubeMeasure: "[UnifiedPartner].[Partner ID]",
                },
            ],
        },
        SPOCPPEnterpriseTPID: {
            gridid: "SPOCPPEnterpriseTPID",
            type: "multi",
            subgrid: ["SPOCPPEnterpriseTPIDSub"],
            parameters: [
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "SearchString", Value: [[""]] },
                { Key: "SortBy", Value: [["[Active Seats]"]] },
                { Key: "SortType", Value: [["DESC"]] },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]], updateExportParameter: true },
                { Key: "PartnerAttachType", Value: [["ALL"]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
            ],
            page: "EnterpriseMobility",
            sortable: true,
            exportParameters: [
                {
                    Placeholder: "PartnerVORG",
                    Key: "VOrg Partner ID",
                    CubeMeasure: "[UnifiedPartner].[VOrg Partner ID]",
                },
                {
                    Placeholder: "PartnerOneKey",
                    Key: "PartnerOne ID",
                    CubeMeasure: "[Reporting PartnerOne].[PartnerOne ID]",
                },
                {
                    Placeholder: "TimePeriod",
                    Key: "Time Period",
                    CubeMeasure: "[Time Period].[Time Period]",
                },
                {
                    Placeholder: "PartnerAttachType",
                    Key: "Partner Attach Type",
                    CubeMeasure: "[Partner Attach Type].[Partner Attach Type]",
                },
                {
                    Placeholder: "PartnerKey",
                    Key: "Partner ID",
                    CubeMeasure: "[UnifiedPartner].[Partner ID]",
                },
            ],
        },
        SPOCPPEnterpriseTenantSub: {
            gridid: "SPOCPPEnterpriseTenantSub",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]] },
                { Key: "PartnerAttachType", Value: [["ALL"]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "TenantKey", Value: [["ALL"]] },
            ],
            filter: "TenantKey",
            page: "EnterpriseMobility",
        },
        SPOCPPEnterpriseTPIDSub: {
            gridid: "SPOCPPEnterpriseTPIDSub",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "PartnerAttachType", Value: [["ALL"]] },
                { Key: "CustomerKey", Value: [["ALL"]] },
            ],
            filter: "CustomerID$#$PartnerAttachType",
            page: "EnterpriseMobility",
        },
        //CPP Dynamics 365 HTML Grids
        SPOCPPDynamics365Subscription: {
            gridid: "SPOCPPDynamics365Subscription",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "SearchString", Value: [[""]] },
                { Key: "SortBy", Value: [["[Active Seats]"]] },
                { Key: "SortType", Value: [["DESC"]] },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]], updateExportParameter: true },
                { Key: "PartnerAttachType", Value: [["ALL"]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
            ],
            page: "Dynamics365",
            sortable: true,
            exportParameters: [
                {
                    Placeholder: "PartnerVORG",
                    Key: "VOrg Partner ID",
                    CubeMeasure: "[UnifiedPartner].[VOrg Partner ID]",
                },
                {
                    Placeholder: "PartnerOneKey",
                    Key: "PartnerOne ID",
                    CubeMeasure: "[Reporting PartnerOne].[PartnerOne ID]",
                },
                {
                    Placeholder: "TimePeriod",
                    Key: "Time Period",
                    CubeMeasure: "[Time Period].[Time Period]",
                },
                {
                    Placeholder: "PartnerAttachType",
                    Key: "Partner Attach Type",
                    CubeMeasure: "[Partner Attach Type].[Partner Attach Type]",
                },
                {
                    Placeholder: "PartnerKey",
                    Key: "Partner ID",
                    CubeMeasure: "[UnifiedPartner].[Partner ID]",
                },
            ],
        },
        SPOCPPDynamics365Tenant: {
            gridid: "SPOCPPDynamics365Tenant",
            type: "multi",
            subgrid: ["SPOCPPDynamics365TenantSub"],
            parameters: [
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "SearchString", Value: [[""]] },
                { Key: "SortBy", Value: [["[Active Seats]"]] },
                { Key: "SortType", Value: [["DESC"]] },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]], updateExportParameter: true },
                { Key: "PartnerAttachType", Value: [["ALL"]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
            ],
            page: "Dynamics365",
            sortable: true,
            exportParameters: [
                {
                    Placeholder: "PartnerVORG",
                    Key: "VOrg Partner ID",
                    CubeMeasure: "[UnifiedPartner].[VOrg Partner ID]",
                },
                {
                    Placeholder: "PartnerOneKey",
                    Key: "PartnerOne ID",
                    CubeMeasure: "[Reporting PartnerOne].[PartnerOne ID]",
                },
                {
                    Placeholder: "TimePeriod",
                    Key: "Time Period",
                    CubeMeasure: "[Time Period].[Time Period]",
                },
                {
                    Placeholder: "PartnerAttachType",
                    Key: "Partner Attach Type",
                    CubeMeasure: "[Partner Attach Type].[Partner Attach Type]",
                },
                {
                    Placeholder: "PartnerKey",
                    Key: "Partner ID",
                    CubeMeasure: "[UnifiedPartner].[Partner ID]",
                },
            ],
        },
        SPOCPPDynamics365TPID: {
            gridid: "SPOCPPDynamics365TPID",
            type: "multi",
            subgrid: ["SPOCPPDynamics365TPIDSub"],
            parameters: [
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "SearchString", Value: [[""]] },
                { Key: "SortBy", Value: [["[Active Seats]"]] },
                { Key: "SortType", Value: [["DESC"]] },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]], updateExportParameter: true },
                { Key: "PartnerAttachType", Value: [["ALL"]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
            ],
            page: "Dynamics365",
            sortable: true,
            exportParameters: [
                {
                    Placeholder: "PartnerVORG",
                    Key: "VOrg Partner ID",
                    CubeMeasure: "[UnifiedPartner].[VOrg Partner ID]",
                },
                {
                    Placeholder: "PartnerOneKey",
                    Key: "PartnerOne ID",
                    CubeMeasure: "[Reporting PartnerOne].[PartnerOne ID]",
                },
                {
                    Placeholder: "TimePeriod",
                    Key: "Time Period",
                    CubeMeasure: "[Time Period].[Time Period]",
                },
                {
                    Placeholder: "PartnerAttachType",
                    Key: "Partner Attach Type",
                    CubeMeasure: "[Partner Attach Type].[Partner Attach Type]",
                },
                {
                    Placeholder: "PartnerKey",
                    Key: "Partner ID",
                    CubeMeasure: "[UnifiedPartner].[Partner ID]",
                },
            ],
        },
        SPOCPPDynamics365TenantSub: {
            gridid: "SPOCPPDynamics365TenantSub",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]] },
                { Key: "PartnerAttachType", Value: [["ALL"]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "TenantKey", Value: [["ALL"]] },
            ],
            filter: "TenantKey",
            page: "Dynamics365",
        },
        SPOCPPDynamics365TPIDSub: {
            gridid: "SPOCPPDynamics365TPIDSub",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]] },
                { Key: "PartnerOneSubKey", Value: [["ALL"]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "PartnerAttachType", Value: [["ALL"]] },
                { Key: "CustomerKey", Value: [["ALL"]] },
            ],
            filter: "CustomerID$#$PartnerAttachType",
            page: "Dynamics365",
        },
        //CPP PowerBI HTML Grids
        SPOCPPPowerBISubscription: {
            gridid: "SPOCPPPowerBISubscription",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "SearchString", Value: [[""]] },
                { Key: "SortBy", Value: [["[Active Usage]"]] },
                { Key: "SortType", Value: [["DESC"]] },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]], updateExportParameter: true },
                { Key: "PartnerAttachType", Value: [["ALL"]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
            ],
            page: "PowerBI",
            sortable: true,
            exportParameters: [
                {
                    Placeholder: "PartnerVORG",
                    Key: "VOrg Partner ID",
                    CubeMeasure: "[UnifiedPartner].[VOrg Partner ID]",
                },
                {
                    Placeholder: "PartnerOneKey",
                    Key: "PartnerOne ID",
                    CubeMeasure: "[Reporting PartnerOne].[PartnerOne ID]",
                },
                {
                    Placeholder: "TimePeriod",
                    Key: "Time Period",
                    CubeMeasure: "[Time Period].[Time Period]",
                },
                {
                    Placeholder: "PartnerAttachType",
                    Key: "Partner Attach Type",
                    CubeMeasure: "[Partner Attach Type].[Partner Attach Type]",
                },
                {
                    Placeholder: "PartnerKey",
                    Key: "Partner ID",
                    CubeMeasure: "[UnifiedPartner].[Partner ID]",
                },
            ],
        },
        SPOCPPPowerBITenant: {
            gridid: "SPOCPPPowerBITenant",
            type: "multi",
            subgrid: ["SPOCPPPowerBITenantSub"],
            parameters: [
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "SearchString", Value: [[""]] },
                { Key: "SortBy", Value: [["[Active Usage]"]] },
                { Key: "SortType", Value: [["DESC"]] },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]], updateExportParameter: true },
                { Key: "PartnerAttachType", Value: [["ALL"]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
            ],
            page: "PowerBI",
            sortable: true,
            exportParameters: [
                {
                    Placeholder: "PartnerVORG",
                    Key: "VOrg Partner ID",
                    CubeMeasure: "[UnifiedPartner].[VOrg Partner ID]",
                },
                {
                    Placeholder: "PartnerOneKey",
                    Key: "PartnerOne ID",
                    CubeMeasure: "[Reporting PartnerOne].[PartnerOne ID]",
                },
                {
                    Placeholder: "TimePeriod",
                    Key: "Time Period",
                    CubeMeasure: "[Time Period].[Time Period]",
                },
                {
                    Placeholder: "PartnerAttachType",
                    Key: "Partner Attach Type",
                    CubeMeasure: "[Partner Attach Type].[Partner Attach Type]",
                },
                {
                    Placeholder: "PartnerKey",
                    Key: "Partner ID",
                    CubeMeasure: "[UnifiedPartner].[Partner ID]",
                },
            ],
        },
        SPOCPPPowerBITPID: {
            gridid: "SPOCPPPowerBITPID",
            type: "multi",
            subgrid: ["SPOCPPPowerBITPIDSub"],
            parameters: [
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "SearchString", Value: [[""]] },
                { Key: "SortBy", Value: [["[Active Usage]"]] },
                { Key: "SortType", Value: [["DESC"]] },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]], updateExportParameter: true },
                { Key: "PartnerAttachType", Value: [["ALL"]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
            ],
            page: "PowerBI",
            sortable: true,
            exportParameters: [
                {
                    Placeholder: "PartnerVORG",
                    Key: "VOrg Partner ID",
                    CubeMeasure: "[UnifiedPartner].[VOrg Partner ID]",
                },
                {
                    Placeholder: "PartnerOneKey",
                    Key: "PartnerOne ID",
                    CubeMeasure: "[Reporting PartnerOne].[PartnerOne ID]",
                },
                {
                    Placeholder: "TimePeriod",
                    Key: "Time Period",
                    CubeMeasure: "[Time Period].[Time Period]",
                },
                {
                    Placeholder: "PartnerAttachType",
                    Key: "Partner Attach Type",
                    CubeMeasure: "[Partner Attach Type].[Partner Attach Type]",
                },
                {
                    Placeholder: "PartnerKey",
                    Key: "Partner ID",
                    CubeMeasure: "[UnifiedPartner].[Partner ID]",
                },
            ],
        },
        SPOCPPPowerBITenantSub: {
            gridid: "SPOCPPPowerBITenantSub",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]] },
                { Key: "PartnerAttachType", Value: [["ALL"]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "TenantKey", Value: [["ALL"]] },
            ],
            filter: "TenantKey",
            page: "PowerBI",
        },
        SPOCPPPowerBITPIDSub: {
            gridid: "SPOCPPPowerBITPIDSub",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "PartnerAttachType", Value: [["ALL"]] },
                { Key: "CustomerKey", Value: [["ALL"]] },
            ],
            filter: "CustomerID$#$PartnerAttachType",
            page: "PowerBI",
        },
        //Partner Search HTML Grids
        PartnerOneIDSearch: {
            gridid: "PartnerOneIDSearch",
            type: "multi",
            subgrid: ["PartnerOneSubSearch"],
            parameters: [
                { Key: "pSortBy", Value: [[null]] },
                { Key: "pSortOrder", Value: [["ASC"]] },
                { Key: "pCompetency", Value: [[null]] },
                { Key: "pCompetencyLevel", Value: [[null]] },
                { Key: "pProgramTier", Value: [[null]] },
                { Key: "pCompetencyStatusDetail", Value: [[null]] },
                { Key: "pPartnerCenterMigrationDate", Value: [[null]] },
                { Key: "pCompetencyStatus", Value: [[null]] },
                { Key: "pSubProgramTier", Value: [[null]] },
                { Key: "pProgramStatus", Value: [[null]] },
                { Key: "pProgramEndDate", Value: [[null]] },
                { Key: "pPriorityCompetency", Value: [[null]] },
                { Key: "pResourceCenter", Value: [[null]] },
                { Key: "pQuickSearchPartnerName", Value: [[null]] },
                { Key: "pWhereClausePI", Value: [[null]] },
                { Key: "pIsQuickSearchPartnerName", Value: [[null]] },
                { Key: "pReportID", Value: [["02"]] },
                { Key: "pDrillDown", Value: [["0"]] },
                { Key: "pWhereClause", Value: [[null]] },
            ],
            page: "PartnerSearch",
            section: "Partnerone",
            sortable: true,
            exportParameters: [],
            exportSource: "Database",
        },
        PartnerOneSubSearch: {
            gridid: "PartnerOneSubSearch",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "pSortBy", Value: [[null]] },
                { Key: "pSortOrder", Value: [["ASC"]] },
                { Key: "pCompetency", Value: [[null]] },
                { Key: "pCompetencyLevel", Value: [[null]] },
                { Key: "pProgramTier", Value: [[null]] },
                { Key: "pCompetencyStatusDetail", Value: [[null]] },
                { Key: "pPartnerCenterMigrationDate", Value: [[null]] },
                { Key: "pCompetencyStatus", Value: [[null]] },
                { Key: "pSubProgramTier", Value: [[null]] },
                { Key: "pProgramStatus", Value: [[null]] },
                { Key: "pProgramEndDate", Value: [[null]] },
                { Key: "pPriorityCompetency", Value: [[null]] },
                { Key: "pResourceCenter", Value: [[null]] },
                { Key: "pQuickSearchPartnerName", Value: [[null]] },
                { Key: "pWhereClausePI", Value: [[null]] },
                { Key: "pIsQuickSearchPartnerName", Value: [[null]] },
                { Key: "pReportID", Value: [["02"]] },
                { Key: "pDrillDown", Value: [["1"]] },
                { Key: "pWhereClause", Value: [[null]] },
            ],
            page: "PartnerSearch",
            section: "Partnerone",
            sortable: true,
        },
        PartnerIDSearch: {
            gridid: "PartnerIDSearch",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "pSortBy", Value: [["[Org Type]"]] },
                { Key: "pSortOrder", Value: [["ASC"]] },
                { Key: "pCompetency", Value: [[null]] },
                { Key: "pCompetencyLevel", Value: [[null]] },
                { Key: "pProgramTier", Value: [[null]] },
                { Key: "pCompetencyStatusDetail", Value: [[null]] },
                { Key: "pPartnerCenterMigrationDate", Value: [[null]] },
                { Key: "pCompetencyStatus", Value: [[null]] },
                { Key: "pSubProgramTier", Value: [[null]] },
                { Key: "pProgramStatus", Value: [[null]] },
                { Key: "pProgramEndDate", Value: [[null]] },
                { Key: "pPriorityCompetency", Value: [[null]] },
                { Key: "pResourceCenter", Value: [[null]] },
                { Key: "pQuickSearchPartnerName", Value: [[null]] },
                { Key: "pWhereClausePI", Value: [[null]] },
                { Key: "pIsQuickSearchPartnerName", Value: [[null]] },
                { Key: "pReportID", Value: [["01"]] },
                { Key: "pDrillDown", Value: [["0"]] },
                { Key: "pWhereClause", Value: [[null]] },
            ],
            page: "PartnerSearch",
            section: "MPN",
            sortable: true,
            exportParameters: [],
            exportSource: "Database",
        },
        PartnerIDEPLPopup: {
            gridid: "PartnerIDEPLPopup",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "pSortBy", Value: [["[Partner ID]"]] },
                { Key: "pSortOrder", Value: [["ASC"]] },
                { Key: "pCompetency", Value: [[null]] },
                { Key: "pCompetencyLevel", Value: [[null]] },
                { Key: "pProgramTier", Value: [[null]] },
                { Key: "pCompetencyStatusDetail", Value: [[null]] },
                { Key: "pPartnerCenterMigrationDate", Value: [[null]] },
                { Key: "pCompetencyStatus", Value: [[null]] },
                { Key: "pSubProgramTier", Value: [[null]] },
                { Key: "pProgramStatus", Value: [[null]] },
                { Key: "pProgramEndDate", Value: [[null]] },
                { Key: "pPriorityCompetency", Value: [[null]] },
                { Key: "pResourceCenter", Value: [[null]] },
                { Key: "pQuickSearchPartnerName", Value: [[null]] },
                { Key: "pWhereClausePI", Value: [[null]] },
                { Key: "pIsQuickSearchPartnerName", Value: [[null]] },
                { Key: "pReportID", Value: [["01"]] },
                { Key: "pDrillDown", Value: [["1"]] },
                { Key: "pWhereClause", Value: [[null]] },
            ],
            page: "PartnerSearch",
            section: "MPN",
            sortable: true,
        },
        PartnerOneIDFilterCount: {
            gridid: "PartnerOneIDFilterCount",
            parameters: [
                { Key: "pSortBy", Value: [[null]] },
                { Key: "pSortOrder", Value: [["ASC"]] },
                { Key: "pCompetency", Value: [[null]] },
                { Key: "pCompetencyLevel", Value: [[null]] },
                { Key: "pProgramTier", Value: [[null]] },
                { Key: "pCompetencyStatusDetail", Value: [[null]] },
                { Key: "pPartnerCenterMigrationDate", Value: [[null]] },
                { Key: "pCompetencyStatus", Value: [[null]] },
                { Key: "pSubProgramTier", Value: [[null]] },
                { Key: "pProgramStatus", Value: [[null]] },
                { Key: "pProgramEndDate", Value: [[null]] },
                { Key: "pPriorityCompetency", Value: [[null]] },
                { Key: "pResourceCenter", Value: [[null]] },
                { Key: "pQuickSearchPartnerName", Value: [[null]] },
                { Key: "pWhereClausePI", Value: [[null]] },
                { Key: "pIsQuickSearchPartnerName", Value: [[null]] },
                { Key: "pReportID", Value: [["02"]] },
                { Key: "pDrillDown", Value: [["0"]] },
                { Key: "pWhereClause", Value: [[null]] },
            ],
        },
        PartnerIDFilterCount: {
            gridid: "PartnerIDFilterCount",
            parameters: [
                { Key: "pSortBy", Value: [["[Partner ID]"]] },
                { Key: "pSortOrder", Value: [["ASC"]] },
                { Key: "pCompetency", Value: [[null]] },
                { Key: "pCompetencyLevel", Value: [[null]] },
                { Key: "pProgramTier", Value: [[null]] },
                { Key: "pCompetencyStatusDetail", Value: [[null]] },
                { Key: "pPartnerCenterMigrationDate", Value: [[null]] },
                { Key: "pCompetencyStatus", Value: [[null]] },
                { Key: "pSubProgramTier", Value: [[null]] },
                { Key: "pProgramStatus", Value: [[null]] },
                { Key: "pProgramEndDate", Value: [[null]] },
                { Key: "pPriorityCompetency", Value: [[null]] },
                { Key: "pResourceCenter", Value: [[null]] },
                { Key: "pQuickSearchPartnerName", Value: [[null]] },
                { Key: "pWhereClausePI", Value: [[null]] },
                { Key: "pIsQuickSearchPartnerName", Value: [[null]] },
                { Key: "pReportID", Value: [["01"]] },
                { Key: "pDrillDown", Value: [["0"]] },
                { Key: "pWhereClause", Value: [[null]] },
            ],
        },
        //IUR O365 HTML Grids
        SPOIURO365: {
            gridid: "SPOIURO365",
            type: "multi",
            subgrid: ["SPOIURO365Workload", "SPOIURO365SKU"],
            parameters: [
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "IURVsCommercial", Value: [["ALL"]] },
            ],
            page: "IUR",
            subGridHeader: {
                SPOIURO365Workload: "Workload",
                SPOIURO365SKU: "SKU",
            },
            section: "Office O365",
            exportParameters: [
                {
                    Placeholder: "PartnerKey",
                    Key: "MPN Partner ID",
                    CubeMeasure: "[Partner Details].[MPN Partner ID]",
                },
                {
                    Placeholder: "IURVsCommercial",
                    Key: "IUR vs Commercial",
                    CubeMeasure: "[IUR vs Commercial].[IUR vs Commercial]",
                },
            ],
        },
        SPOIURO365Workload: {
            gridid: "SPOIURO365Workload",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "IURVsCommercial", Value: [["ALL"]] },
                { Key: "TenantID", Value: [["ALL"]] },
            ],
            filter: "[Tenant].[Tenant ID].[Tenant ID].[MEMBER_CAPTION]",
            page: "IUR",
            section: "Office O365",
        },
        SPOIURO365SKU: {
            gridid: "SPOIURO365SKU",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "IURVsCommercial", Value: [["ALL"]] },
                { Key: "TenantID", Value: [["ALL"]] },
            ],
            filter: "[Tenant].[Tenant ID].[Tenant ID].[MEMBER_CAPTION]",
            page: "IUR",
            section: "Office O365",
        },
        //IUR Enterprise Mobility HTML Grids
        SPOIUREMSSubscription: {
            gridid: "SPOIUREMSSubscription",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "IURVsCommercial", Value: [["ALL"]] },
            ],
            page: "IUR",
            section: "Enterprise Mobility",
            exportParameters: [
                {
                    Placeholder: "PartnerKey",
                    Key: "MPN Partner ID",
                    CubeMeasure: "[Partner Details].[MPN Partner ID]",
                },
                {
                    Placeholder: "IURVsCommercial",
                    Key: "IUR vs Commercial",
                    CubeMeasure: "[IUR vs Commercial].[IUR vs Commercial]",
                },
            ],
        },
        SPOIUREMSPartnerTenant: {
            gridid: "SPOIUREMSPartnerTenant",
            type: "multi",
            subgrid: ["SPOIUREMSPartnerTenantSub"],
            parameters: [
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "IURVsCommercial", Value: [["ALL"]] },
            ],
            page: "IUR",
            section: "Enterprise Mobility",
            exportParameters: [
                {
                    Placeholder: "PartnerKey",
                    Key: "MPN Partner ID",
                    CubeMeasure: "[Partner Details].[MPN Partner ID]",
                },
                {
                    Placeholder: "IURVsCommercial",
                    Key: "IUR vs Commercial",
                    CubeMeasure: "[IUR vs Commercial].[IUR vs Commercial]",
                },
            ],
        },
        SPOIUREMSPartnerTenantSub: {
            gridid: "SPOIUREMSPartnerTenantSub",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "IURVsCommercial", Value: [["ALL"]] },
                { Key: "TenantID", Value: [["ALL"]] },
            ],
            filter: "[Tenant].[Tenant ID].[Tenant ID].[MEMBER_CAPTION]",
            page: "IUR",
            section: "Enterprise Mobility",
        },
        //IUR Power BI HTML Grids
        SPOIURPowerBISubscription: {
            gridid: "SPOIURPowerBISubscription",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "IURVsCommercial", Value: [["ALL"]] },
            ],
            page: "IUR",
            section: "Power BI",
            exportParameters: [
                {
                    Placeholder: "PartnerKey",
                    Key: "MPN Partner ID",
                    CubeMeasure: "[Partner Details].[MPN Partner ID]",
                },
                {
                    Placeholder: "IURVsCommercial",
                    Key: "IUR vs Commercial",
                    CubeMeasure: "[IUR vs Commercial].[IUR vs Commercial]",
                },
            ],
        },
        //IUR Dynamics 365 HTML Grids
        SPOIURDynamics365Subscription: {
            gridid: "SPOIURDynamics365Subscription",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "IURVsCommercial", Value: [["ALL"]] },
            ],
            page: "IUR",
            section: "CRM Online",
            exportParameters: [
                {
                    Placeholder: "PartnerKey",
                    Key: "MPN Partner ID",
                    CubeMeasure: "[Partner Details].[MPN Partner ID]",
                },
                {
                    Placeholder: "IURVsCommercial",
                    Key: "IUR vs Commercial",
                    CubeMeasure: "[IUR vs Commercial].[IUR vs Commercial]",
                },
            ],
        },
        SPOIURDynamics365PartnerTenant: {
            gridid: "SPOIURDynamics365PartnerTenant",
            type: "multi",
            subgrid: ["SPOIURDynamics365PartnerTenantSub"],
            parameters: [
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "IURVsCommercial", Value: [["ALL"]] },
            ],
            page: "IUR",
            section: "CRM Online",
            exportParameters: [
                {
                    Placeholder: "PartnerKey",
                    Key: "MPN Partner ID",
                    CubeMeasure: "[Partner Details].[MPN Partner ID]",
                },
                {
                    Placeholder: "IURVsCommercial",
                    Key: "IUR vs Commercial",
                    CubeMeasure: "[IUR vs Commercial].[IUR vs Commercial]",
                },
            ],
        },
        "SPOReferral&Grid": {
            gridid: "SPOReferral&Grid",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "Start", Value: [["0"]] },
                { Key: "MaxRows", Value: [["7"]] },
                { Key: "PartnerID", Value: [[1121211]] },
                { Key: "SortOrder", Value: [["ASC"]] },
                { Key: "SortBy", Value: [[null]] },
            ],
            page: "Referral",
            section: "",
        },
        SPOIURDynamics365PartnerTenantSub: {
            gridid: "SPOIURDynamics365PartnerTenantSub",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "IURVsCommercial", Value: [["ALL"]] },
                { Key: "TenantID", Value: [["ALL"]] },
            ],
            filter: "[Tenant].[Tenant ID].[Tenant ID].[MEMBER_CAPTION]",
            page: "IUR",
            section: "CRM Online",
        },
        //IUR Seats Except Azure HTML Grids
        SPOIURSeats: {
            gridid: "SPOIURSeats",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "IURVsCommercial", Value: [["ALL"]] },
                { Key: "Product", Value: [["ALL"]] },
            ],
            page: "IUR",
            section: "Seats",
            exportParameters: [
                {
                    Placeholder: "PartnerKey",
                    Key: "MPN Partner ID",
                    CubeMeasure: "[Partner Details].[MPN Partner ID]",
                },
                {
                    Placeholder: "IURVsCommercial",
                    Key: "IUR vs Commercial",
                    CubeMeasure: "[IUR vs Commercial].[IUR vs Commercial]",
                },
                {
                    Placeholder: "Product",
                    Key: "Reporting Product",
                    CubeMeasure: "[Product].[Reporting Product]",
                },
            ],
        },
        //IUR Seats Azure HTML Grids
        SPOIURSeatsAzure: {
            gridid: "SPOIURSeatsAzure",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "IURVsCommercial", Value: [["ALL"]] },
                { Key: "SubscriptionType", Value: [["ALL"]] },
            ],
            page: "IUR",
            section: "Azure",
            exportParameters: [
                {
                    Placeholder: "PartnerKey",
                    Key: "MPN Partner ID",
                    CubeMeasure: "[Partner Details].[MPN Partner ID]",
                },
                {
                    Placeholder: "IURVsCommercial",
                    Key: "IUR vs Commercial",
                    CubeMeasure: "[IUR vs Commercial].[IUR vs Commercial]",
                },
                {
                    Placeholder: "SubscriptionType",
                    Key: "Subscription Type",
                    CubeMeasure: "[Subscription Type].[Subscription Type]",
                },
            ],
        },
        //OCP Priorities Grid for PBP
        OCPPrioritiesPBP: {
            gridid: "OCPPrioritiesPBP",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "PBPPartnerOneKey", Value: [["ALL"]] },
                { Key: "PBPPartnerOneSubKey", Value: [["ALL"]] },
                { Key: "startsc", Value: [["ALL"]] },
                { Key: "endsc", Value: [["ALL"]] },
                { Key: "fyear", Value: [["FY19"]] },
            ],
            page: "PBP",
            section: "OCPPriorities",
            exportParameters: [
                {
                    Placeholder: "PBPPartnerOneKey",
                    Key: "PartnerOne ID",
                    CubeMeasure: "[UnifiedPartnerOne].[PartnerOne ID]",
                },
                {
                    Placeholder: "PBPPartnerOneSubKey",
                    Key: "PartnerOne Sub ID",
                    CubeMeasure: "[UnifiedPartnerOneSub].[PartnerOne Sub ID]",
                },
                {
                    Placeholder: "startsc",
                    Key: "startsc",
                    CubeMeasure: "[Time].[Fiscal Month]",
                },
                {
                    Placeholder: "endsc",
                    Key: "endsc",
                    CubeMeasure: "[Time].[Fiscal Month]",
                },
                {
                    Placeholder: "fyear",
                    Key: "fyear",
                    CubeMeasure: "[Time].[Fiscal Year]",
                },
            ],
        },

        //CPP O365 HTML Grids
        SPOCPPO365CustomerTPID: {
            gridid: "SPOCPPO365CustomerTPID",
            type: "multi",
            subgrid: ["SPOCPPO365CustomerTPIDWorkload"],
            parameters: [
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "SearchString", Value: [[""]] },
                { Key: "SortBy", Value: [["[Active Entitlements]"]] },
                { Key: "SortType", Value: [["DESC"]] },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]] },
                { Key: "O365PartnerAttachType", Value: [["ALL"]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "Workload", Value: [["ALL"]] },
            ],
            page: "O365",
            subGridHeader: {
                SPOCPPO365CustomerTPIDWorkload: "Workload",
            },
            sortable: true,
            exportParameters: [
                {
                    Placeholder: "PartnerVORG",
                    Key: "VOrg Partner ID",
                    CubeMeasure: "[UnifiedPartner].[VOrg Partner ID]",
                },
                {
                    Placeholder: "PartnerOneKey",
                    Key: "PartnerOne ID",
                    CubeMeasure: "[Reporting PartnerOne].[PartnerOne ID]",
                },
                {
                    Placeholder: "TimePeriod",
                    Key: "Time Period",
                    CubeMeasure: "[Time Period].[Time Period]",
                },
                {
                    Placeholder: "PartnerAttachType",
                    Key: "Partner Attach Type",
                    CubeMeasure: "[Partner Attach Type].[Partner Attach Type]",
                },
                {
                    Placeholder: "PartnerKey",
                    Key: "Partner ID",
                    CubeMeasure: "[UnifiedPartner].[Partner ID]",
                },
            ],
        },
        SPOCPPO365CustomerTPIDWorkload: {
            gridid: "SPOCPPO365CustomerTPIDWorkload",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]] },
                { Key: "O365PartnerAttachType", Value: [["ALL"]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "Workload", Value: [["ALL"]] },
                { Key: "CustomerID", Value: [["ALL"]] },
            ],
            filter: "CustomerID",
            page: "O365",
        },
        SPOCPPO365CustomerTenant: {
            gridid: "SPOCPPO365CustomerTenant",
            type: "multi",
            subgrid: ["SPOCPPO365CustomerTenantWorkload"],
            parameters: [
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "SearchString", Value: [[""]] },
                { Key: "SortBy", Value: [["[Active Entitlements]"]] },
                { Key: "SortType", Value: [["DESC"]] },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]] },
                { Key: "O365PartnerAttachType", Value: [["ALL"]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "Workload", Value: [["ALL"]] },
            ],
            page: "O365",
            subGridHeader: {
                SPOCPPO365CustomerTenantWorkload: "Workload",
            },
            sortable: true,
            exportParameters: [
                {
                    Placeholder: "PartnerVORG",
                    Key: "VOrg Partner ID",
                    CubeMeasure: "[UnifiedPartner].[VOrg Partner ID]",
                },
                {
                    Placeholder: "PartnerOneKey",
                    Key: "PartnerOne ID",
                    CubeMeasure: "[Reporting PartnerOne].[PartnerOne ID]",
                },
                {
                    Placeholder: "TimePeriod",
                    Key: "Time Period",
                    CubeMeasure: "[Time Period].[Time Period]",
                },
                {
                    Placeholder: "PartnerAttachType",
                    Key: "Partner Attach Type",
                    CubeMeasure: "[Partner Attach Type].[Partner Attach Type]",
                },
                {
                    Placeholder: "PartnerKey",
                    Key: "Partner ID",
                    CubeMeasure: "[UnifiedPartner].[Partner ID]",
                },
            ],
        },
        SPOCPPO365CustomerTenantWorkload: {
            gridid: "SPOCPPO365CustomerTenantWorkload",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]] },
                { Key: "O365PartnerAttachType", Value: [["ALL"]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "Workload", Value: [["ALL"]] },
                { Key: "TenantID", Value: [["ALL"]] },
            ],
            filter: "TenantID",
            page: "O365",
        },
        //CPP Azure HTML Grids

        "SPOCPPAzureNotYetBilledSubscription&Data": {
            gridid: "SPOCPPAzureNotYetBilledSubscription&Data",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "PartnerOneKey", Value: [[""]] },
                { Key: "PartnerOneSubKey", Value: [[""]] },
                { Key: "PartnerVORG", Value: [[""]] },
                { Key: "PartnerKey", Value: [[""]] },
                { Key: "MaxRows", Value: [["15"]] },
                { Key: "Start", Value: [["0"]] },
                { Key: "SortBy", Value: [["[Subscription GUID]"]] },
                { Key: "sortColumn", Value: [["ASC"]] },
                { Key: "Pagination", Value: [["0"]] },
            ],
            page: "Azure",
            filter: [],
            exportParameters: [
                {
                    Placeholder: "PartnerVORG",
                    Key: "VOrg Partner ID",
                    CubeMeasure: "[Partner].[VOrg Partner ID]",
                },
                {
                    Placeholder: "PartnerOneKey",
                    Key: "PartnerOne ID",
                    CubeMeasure: "[PartnerOne].[PartnerOne ID]",
                },
                {
                    Placeholder: "TimePeriod",
                    Key: "Time Period",
                    CubeMeasure: "[Time Period].[Time Period]",
                },
                {
                    Placeholder: "PartnerAssociationType",
                    Key: "Partner Association Type",
                    CubeMeasure: "[Partner Association Type].[Partner Association Type]",
                },
                {
                    Placeholder: "PartnerOneSubKey",
                    Key: "PartnerOne Sub ID",
                    CubeMeasure: "[PartnerOne Sub].[PartnerOne Sub ID]",
                },
                {
                    Placeholder: "PartnerKey",
                    Key: "Partner ID",
                    CubeMeasure: "[Partner].[Partner ID]",
                },
                {
                    Placeholder: "Offer",
                    Key: "Offer",
                    CubeMeasure: "[Azure Offer].[Offer]",
                },
                {
                    Placeholder: "OfferType",
                    Key: "Offer Type",
                    CubeMeasure: "[Azure Offer].[Offer Type]",
                },
                {
                    Placeholder: "BilledSystem",
                    Key: "Billing System",
                    CubeMeasure: "[Billing System].[Billing System]",
                },
            ],
        },
        SPOCPPAzureSubscription: {
            gridid: "SPOCPPAzureSubscription",
            type: "multi",
            subgrid: [
                "SPOCPPAzureSubscriptionServiceLevel",
                "SPOCPPAzureSubscriptionPartnerLevel",
                "SPOCPPAzureSubscriptionPricingLevel",
                "SPOCPPAzureSubscriptionOfferName",
                "SPOCPPAzureSubscriptionCustomerIDlevel",
                "SPOCPPAzureSubscriptionEnrollmentLevel",
            ],
            parameters: [
                { Key: "IsFMClose", Value: [["Yes"]], IsFilterPanelParam: "True" },
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "SearchString", Value: [[""]] },
                { Key: "SortBy", Value: [["[Azure Consumption]"]] },
                { Key: "SortType", Value: [["DESC"]] },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]], updateExportParameter: true },
                { Key: "PartnerAttachType", Value: [["ALL"]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "Offer", Value: [["ALL"]] },
                { Key: "OfferType", Value: [["ALL"]] },
                { Key: "BilledSystem", Value: [["ALL"]] },
            ],
            page: "Azure",
            sortable: true,
            subGridHeader: {
                SPOCPPAzureSubscriptionServiceLevel: "Service Level",
                SPOCPPAzureSubscriptionPartnerLevel: "Partner",
                SPOCPPAzureSubscriptionPricingLevel: "Pricing Level",
                SPOCPPAzureSubscriptionOfferName: "Offer Name",
                SPOCPPAzureSubscriptionCustomerIDlevel: "Customer",
                SPOCPPAzureSubscriptionEnrollmentLevel: "Enrollment",
            },
            exportParameters: [
                {
                    Placeholder: "PartnerVORG",
                    Key: "VOrg Partner ID",
                    CubeMeasure: "[UnifiedPartner].[VOrg Partner ID]",
                },
                {
                    Placeholder: "PartnerOneKey",
                    Key: "PartnerOne ID",
                    CubeMeasure: "[Reporting PartnerOne].[PartnerOne ID]",
                },
                {
                    Placeholder: "TimePeriod",
                    Key: "Time Period",
                    CubeMeasure: "[Time Period].[Time Period]",
                },
                {
                    Placeholder: "PartnerAttachType",
                    Key: "Partner Attach Type",
                    CubeMeasure: "[Partner Attach Type].[Partner Attach Type]",
                },
                {
                    Placeholder: "PartnerKey",
                    Key: "Partner ID",
                    CubeMeasure: "[UnifiedPartner].[Partner ID]",
                },
                {
                    Placeholder: "Offer",
                    Key: "Offer",
                    CubeMeasure: "[Azure Offer].[Offer]",
                },
                {
                    Placeholder: "OfferType",
                    Key: "Offer Type",
                    CubeMeasure: "[Azure Offer].[Offer Type]",
                },
                {
                    Placeholder: "BilledSystem",
                    Key: "Billing System",
                    CubeMeasure: "[Billing System].[Billing System]",
                },
            ],
        },
        SPOCPPAzureSubscriptionServiceLevel: {
            gridid: "SPOCPPAzureSubscriptionServiceLevel",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "IsFMClose", Value: [["Yes"]], IsFilterPanelParam: "True" },
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]] },
                { Key: "PartnerAttachType", Value: [["ALL"]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "Offer", Value: [["ALL"]] },
                { Key: "OfferType", Value: [["ALL"]] },
                { Key: "BilledSystem", Value: [["ALL"]] },
                { Key: "SubscriptionGUID", Value: [["ALL"]] },
            ],
            filter: "SubscriptionGUID",
            page: "Azure",
        },
        SPOCPPAzureSubscriptionPartnerLevel: {
            gridid: "SPOCPPAzureSubscriptionPartnerLevel",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "IsFMClose", Value: [["Yes"]], IsFilterPanelParam: "True" },
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]] },
                { Key: "PartnerAttachType", Value: [["ALL"]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "Offer", Value: [["ALL"]] },
                { Key: "OfferType", Value: [["ALL"]] },
                { Key: "BilledSystem", Value: [["ALL"]] },
                { Key: "SubscriptionGUID", Value: [["ALL"]] },
            ],
            filter: "SubscriptionGUID",
            page: "Azure",
        },
        SPOCPPAzureSubscriptionPricingLevel: {
            gridid: "SPOCPPAzureSubscriptionPricingLevel",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "IsFMClose", Value: [["Yes"]], IsFilterPanelParam: "True" },
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]] },
                { Key: "PartnerAttachType", Value: [["ALL"]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "Offer", Value: [["ALL"]] },
                { Key: "OfferType", Value: [["ALL"]] },
                { Key: "BilledSystem", Value: [["ALL"]] },
                { Key: "SubscriptionGUID", Value: [["ALL"]] },
            ],
            filter: "SubscriptionGUID",
            page: "Azure",
        },
        SPOCPPAzureSubscriptionOfferName: {
            gridid: "SPOCPPAzureSubscriptionOfferName",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "IsFMClose", Value: [["Yes"]], IsFilterPanelParam: "True" },
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]] },
                { Key: "PartnerAttachType", Value: [["ALL"]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "Offer", Value: [["ALL"]] },
                { Key: "OfferType", Value: [["ALL"]] },
                { Key: "BilledSystem", Value: [["ALL"]] },
                { Key: "SubscriptionGUID", Value: [["ALL"]] },
            ],
            filter: "SubscriptionGUID",
            page: "Azure",
        },
        SPOCPPAzureSubscriptionCustomerIDlevel: {
            gridid: "SPOCPPAzureSubscriptionCustomerIDlevel",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "IsFMClose", Value: [["Yes"]], IsFilterPanelParam: "True" },
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]] },
                { Key: "PartnerAttachType", Value: [["ALL"]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "Offer", Value: [["ALL"]] },
                { Key: "OfferType", Value: [["ALL"]] },
                { Key: "BilledSystem", Value: [["ALL"]] },
                { Key: "SubscriptionGUID", Value: [["ALL"]] },
            ],
            filter: "SubscriptionGUID",
            page: "Azure",
        },
        SPOCPPAzureSubscriptionEnrollmentLevel: {
            gridid: "SPOCPPAzureSubscriptionEnrollmentLevel",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "IsFMClose", Value: [["Yes"]], IsFilterPanelParam: "True" },
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "SortBy", Value: [["[Azure Consumption]"]] },
                { Key: "SortOrder", Value: [["DESC"]] },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]] },
                { Key: "PartnerAttachType", Value: [["ALL"]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "Offer", Value: [["ALL"]] },
                { Key: "OfferType", Value: [["ALL"]] },
                { Key: "BilledSystem", Value: [["ALL"]] },
                { Key: "SubscriptionGUID", Value: [["ALL"]] },
            ],
            filter: "SubscriptionGUID",
            page: "Azure",
        },
        SPOCPPAzureTPID: {
            gridid: "SPOCPPAzureTPID",
            type: "multi",
            subgrid: ["SPOCPPAzureTPIDSubscription"],
            parameters: [
                { Key: "IsFMClose", Value: [["Yes"]], IsFilterPanelParam: "True" },
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "SearchString", Value: [[""]] },
                { Key: "SortBy", Value: [["[Azure Consumption]"]] },
                { Key: "SortType", Value: [["DESC"]] },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]], updateExportParameter: true },
                { Key: "PartnerAttachType", Value: [["ALL"]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "Offer", Value: [["ALL"]] },
                { Key: "OfferType", Value: [["ALL"]] },
                { Key: "BilledSystem", Value: [["ALL"]] },
            ],
            page: "Azure",
            sortable: true,
            exportParameters: [
                {
                    Placeholder: "PartnerVORG",
                    Key: "VOrg Partner ID",
                    CubeMeasure: "[UnifiedPartner].[VOrg Partner ID]",
                },
                {
                    Placeholder: "PartnerOneKey",
                    Key: "PartnerOne ID",
                    CubeMeasure: "[Reporting PartnerOne].[PartnerOne ID]",
                },
                {
                    Placeholder: "TimePeriod",
                    Key: "Time Period",
                    CubeMeasure: "[Time Period].[Time Period]",
                },
                {
                    Placeholder: "PartnerAttachType",
                    Key: "Partner Attach Type",
                    CubeMeasure: "[Partner Attach Type].[Partner Attach Type]",
                },
                {
                    Placeholder: "PartnerKey",
                    Key: "Partner ID",
                    CubeMeasure: "[UnifiedPartner].[Partner ID]",
                },
                {
                    Placeholder: "Offer",
                    Key: "Offer",
                    CubeMeasure: "[Azure Offer].[Offer]",
                },
                {
                    Placeholder: "OfferType",
                    Key: "Offer Type",
                    CubeMeasure: "[Azure Offer].[Offer Type]",
                },
                {
                    Placeholder: "BilledSystem",
                    Key: "Billing System",
                    CubeMeasure: "[Billing System].[Billing System]",
                },
            ],
        },
        SPOCPPAzureTPIDSubscription: {
            gridid: "SPOCPPAzureTPIDSubscription",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "IsFMClose", Value: [["Yes"]], IsFilterPanelParam: "True" },
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "Offer", Value: [["ALL"]] },
                { Key: "OfferType", Value: [["ALL"]] },
                { Key: "BilledSystem", Value: [["ALL"]] },
                { Key: "PartnerAttachType", Value: [["ALL"]] },
                { Key: "CustomerID", Value: [["ALL"]] },
            ],
            filter: "CustomerID$#$PartnerAttachType",
            page: "Azure",
        },
        //CPP Summary Non Azure view grids
        SPOCPPSummaryNonAzureSubscription: {
            gridid: "SPOCPPSummaryNonAzureSubscription",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "IsFMClose", Value: [["Yes"]], IsFilterPanelParam: "True" },
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "SearchString", Value: [[""]] },
                { Key: "SortBy", Value: [["[Assigned Seats]"]] },
                { Key: "SortType", Value: [["DESC"]] },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]], updateExportParameter: true },
                { Key: "PartnerAttachType", Value: [["ALL"]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "SubscriptionStatus", Value: [["ALL"]] },
                { Key: "SubscriptionRenewal", Value: [["ALL"]] },
                { Key: "IsAutoRenew", Value: [["ALL"]] },
                { Key: "CPPProduct", Value: [["ALL"]] },
                { Key: "StatusCategory", Value: [[1]] },
            ],
            page: "Summary",
            section: "CPPNonAzure",
            sortable: true,
            exportParameters: [
                {
                    Placeholder: "PartnerVORG",
                    Key: "VOrg Partner ID",
                    CubeMeasure: "[UnifiedPartner].[VOrg Partner ID]",
                },
                {
                    Placeholder: "PartnerOneKey",
                    Key: "PartnerOne ID",
                    CubeMeasure: "[Reporting PartnerOne].[PartnerOne ID]",
                },
                {
                    Placeholder: "TimePeriod",
                    Key: "Time Period",
                    CubeMeasure: "[Time Period].[Time Period]",
                },
                {
                    Placeholder: "PartnerAttachType",
                    Key: "Partner Attach Type",
                    CubeMeasure: "[Partner Attach Type].[Partner Attach Type]",
                },
                {
                    Placeholder: "PartnerKey",
                    Key: "Partner ID",
                    CubeMeasure: "[UnifiedPartner].[Partner ID]",
                },
                {
                    Placeholder: "SubscriptionStatus",
                    Key: "Status Name",
                    CubeMeasure: "[Subscription Status].[Status Name]",
                },
                {
                    Placeholder: "SubscriptionRenewal",
                    Key: "Due In",
                    CubeMeasure: "[Subscription Type].[Due In]",
                },
                {
                    Placeholder: "IsAutoRenew",
                    Key: "Is Auto Renew",
                    CubeMeasure: "[Subscription Type].[Is Auto Renew]",
                },
                {
                    Placeholder: "CPPProduct",
                    Key: "Reporting Product Name",
                    CubeMeasure: "[Product].[Product]",
                },
                {
                    Placeholder: "StatusCategory",
                    Key: "Status Category",
                    CubeMeasure: "[Subscription Status].[Status Category]",
                },
            ],
        },
        SPOCPPSummaryNonAzureCustomerTenant: {
            gridid: "SPOCPPSummaryNonAzureCustomerTenant",
            type: "multi",
            subgrid: ["SPOCPPSummaryNonAzureCustomerTenantSub"],
            parameters: [
                { Key: "IsFMClose", Value: [["Yes"]], IsFilterPanelParam: "True" },
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "SearchString", Value: [[""]] },
                { Key: "SortBy", Value: [["[Assigned Seats]"]] },
                { Key: "SortType", Value: [["DESC"]] },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]], updateExportParameter: true },
                { Key: "PartnerAttachType", Value: [["ALL"]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "SubscriptionStatus", Value: [["ALL"]] },
                { Key: "SubscriptionRenewal", Value: [["ALL"]] },
                { Key: "CPPProduct", Value: [["ALL"]] },
                { Key: "IsAutoRenew", Value: [["ALL"]] },
                { Key: "StatusCategory", Value: [[1]] },
            ],
            page: "Summary",
            section: "CPPNonAzure",
            sortable: true,
            exportParameters: [
                {
                    Placeholder: "PartnerVORG",
                    Key: "VOrg Partner ID",
                    CubeMeasure: "[UnifiedPartner].[VOrg Partner ID]",
                },
                {
                    Placeholder: "PartnerOneKey",
                    Key: "PartnerOne ID",
                    CubeMeasure: "[Reporting PartnerOne].[PartnerOne ID]",
                },
                {
                    Placeholder: "TimePeriod",
                    Key: "Time Period",
                    CubeMeasure: "[Time Period].[Time Period]",
                },
                {
                    Placeholder: "PartnerAttachType",
                    Key: "Partner Attach Type",
                    CubeMeasure: "[Partner Attach Type].[Partner Attach Type]",
                },
                {
                    Placeholder: "PartnerKey",
                    Key: "Partner ID",
                    CubeMeasure: "[UnifiedPartner].[Partner ID]",
                },
                {
                    Placeholder: "SubscriptionStatus",
                    Key: "Status Name",
                    CubeMeasure: "[Subscription Status].[Status Name]",
                },
                {
                    Placeholder: "SubscriptionRenewal",
                    Key: "Due In",
                    CubeMeasure: "[Subscription Type].[Due In]",
                },
                {
                    Placeholder: "IsAutoRenew",
                    Key: "Is Auto Renew",
                    CubeMeasure: "[Subscription Type].[Is Auto Renew]",
                },
                {
                    Placeholder: "CPPProduct",
                    Key: "Reporting Product Name",
                    CubeMeasure: "[Product].[Product]",
                },
                {
                    Placeholder: "StatusCategory",
                    Key: "Status Category",
                    CubeMeasure: "[Subscription Status].[Status Category]",
                },
            ],
        },
        SPOCPPSummaryNonAzureCustomerTenantSub: {
            gridid: "SPOCPPSummaryNonAzureCustomerTenantSub",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "IsFMClose", Value: [["Yes"]], IsFilterPanelParam: "True" },
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]] },
                { Key: "PartnerAttachType", Value: [["ALL"]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "SubscriptionStatus", Value: [["ALL"]] },
                { Key: "SubscriptionRenewal", Value: [["ALL"]] },
                { Key: "CPPProduct", Value: [["ALL"]] },
                { Key: "IsAutoRenew", Value: [["ALL"]] },
                { Key: "StatusCategory", Value: [[1]] },
                { Key: "TenantKey", Value: [["ALL"]] },
            ],
            filter: "TenantKey",
            page: "Summary",
            section: "CPPNonAzure",
        },
        SPOCPPSummaryNonAzureCustomerTPID: {
            gridid: "SPOCPPSummaryNonAzureCustomerTPID",
            type: "multi",
            subgrid: ["SPOCPPSummaryNonAzureCustomerTPIDSub"],
            parameters: [
                { Key: "IsFMClose", Value: [["Yes"]], IsFilterPanelParam: "True" },
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "SearchString", Value: [[""]] },
                { Key: "SortBy", Value: [["[Assigned Seats]"]] },
                { Key: "SortOrder", Value: [["DESC"]] },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]], updateExportParameter: true },
                { Key: "PartnerAttachType", Value: [["ALL"]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "SubscriptionStatus", Value: [["ALL"]] },
                { Key: "SubscriptionRenewal", Value: [["ALL"]] },
                { Key: "CPPProduct", Value: [["ALL"]] },
                { Key: "IsAutoRenew", Value: [["ALL"]] },
                { Key: "StatusCategory", Value: [[1]] },
            ],
            page: "Summary",
            section: "CPPNonAzure",
            sortable: true,
            exportParameters: [
                {
                    Placeholder: "PartnerVORG",
                    Key: "VOrg Partner ID",
                    CubeMeasure: "[UnifiedPartner].[VOrg Partner ID]",
                },
                {
                    Placeholder: "PartnerOneKey",
                    Key: "PartnerOne ID",
                    CubeMeasure: "[Reporting PartnerOne].[PartnerOne ID]",
                },
                {
                    Placeholder: "TimePeriod",
                    Key: "Time Period",
                    CubeMeasure: "[Time Period].[Time Period]",
                },
                {
                    Placeholder: "PartnerAttachType",
                    Key: "Partner Attach Type",
                    CubeMeasure: "[Partner Attach Type].[Partner Attach Type]",
                },
                {
                    Placeholder: "PartnerKey",
                    Key: "Partner ID",
                    CubeMeasure: "[UnifiedPartner].[Partner ID]",
                },
                {
                    Placeholder: "SubscriptionStatus",
                    Key: "Status Name",
                    CubeMeasure: "[Subscription Status].[Status Name]",
                },
                {
                    Placeholder: "SubscriptionRenewal",
                    Key: "Due In",
                    CubeMeasure: "[Subscription Type].[Due In]",
                },
                {
                    Placeholder: "IsAutoRenew",
                    Key: "Is Auto Renew",
                    CubeMeasure: "[Subscription Type].[Is Auto Renew]",
                },
                {
                    Placeholder: "CPPProduct",
                    Key: "Reporting Product Name",
                    CubeMeasure: "[Product].[Product]",
                },
                {
                    Placeholder: "StatusCategory",
                    Key: "Status Category",
                    CubeMeasure: "[Subscription Status].[Status Category]",
                },
            ],
        },
        SPOCPPSummaryNonAzureCustomerTPIDSub: {
            gridid: "SPOCPPSummaryNonAzureCustomerTPIDSub",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "IsFMClose", Value: [["Yes"]], IsFilterPanelParam: "True" },
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]] },
                { Key: "PartnerAttachType", Value: [["ALL"]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "SubscriptionStatus", Value: [["ALL"]] },
                { Key: "SubscriptionRenewal", Value: [["ALL"]] },
                { Key: "CPPProduct", Value: [["ALL"]] },
                { Key: "IsAutoRenew", Value: [["ALL"]] },
                { Key: "StatusCategory", Value: [[1]] },
                { Key: "CustomerKey", Value: [["ALL"]] },
            ],
            filter: "CustomerKey",
            page: "Summary",
            section: "CPPNonAzure",
        },
        SPOCPPSummaryAzureSubscription: {
            gridid: "SPOCPPSummaryAzureSubscription",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "IsFMClose", Value: [["Yes"]], IsFilterPanelParam: "True" },
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "SearchString", Value: [[""]] },
                { Key: "SortBy", Value: [["[Azure Consumption]"]] },
                { Key: "SortType", Value: [["DESC"]] },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]], updateExportParameter: true },
                { Key: "PartnerAttachType", Value: [["ALL"]] },
                {
                    Key: "PartnerOneSubKey",
                    Value: [["ALL"]],
                    updateExportParameter: true,
                },
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "SubscriptionStatus", Value: [["ALL"]] },
                { Key: "SubscriptionRenewal", Value: [["ALL"]] },
                { Key: "IsAutoRenew", Value: [["ALL"]] },
                { Key: "CPPProduct", Value: [["ALL"]] },
                { Key: "StatusCategory", Value: [[1]] },
            ],
            page: "Summary",
            section: "CPPAzure",
            sortable: true,
            exportParameters: [
                {
                    Placeholder: "PartnerVORG",
                    Key: "VOrg Partner ID",
                    CubeMeasure: "[UnifiedPartner].[VOrg Partner ID]",
                },
                {
                    Placeholder: "PartnerOneKey",
                    Key: "PartnerOne ID",
                    CubeMeasure: "[Reporting PartnerOne].[PartnerOne ID]",
                },
                {
                    Placeholder: "TimePeriod",
                    Key: "Time Period",
                    CubeMeasure: "[Time Period].[Time Period]",
                },
                {
                    Placeholder: "PartnerAttachType",
                    Key: "Partner Attach Type",
                    CubeMeasure: "[Partner Attach Type].[Partner Attach Type]",
                },
                {
                    Placeholder: "PartnerKey",
                    Key: "Partner ID",
                    CubeMeasure: "[UnifiedPartner].[Partner ID]",
                },
                {
                    Placeholder: "SubscriptionStatus",
                    Key: "Status Name",
                    CubeMeasure: "[Subscription Status].[Status Name]",
                },
                {
                    Placeholder: "SubscriptionRenewal",
                    Key: "Due In",
                    CubeMeasure: "[Subscription Type].[Due In]",
                },
                {
                    Placeholder: "IsAutoRenew",
                    Key: "Is Auto Renew",
                    CubeMeasure: "[Subscription Type].[Is Auto Renew]",
                },
                {
                    Placeholder: "CPPProduct",
                    Key: "Reporting Product Name",
                    CubeMeasure: "[Product].[Product]",
                },
                {
                    Placeholder: "StatusCategory",
                    Key: "Status Category",
                    CubeMeasure: "[Subscription Status].[Status Category]",
                },
            ],
        },
        SPOCPPSummaryAzureCustomerTPID: {
            gridid: "SPOCPPSummaryAzureCustomerTPID",
            type: "multi",
            subgrid: ["SPOCPPSummaryAzureCustomerTPIDSub"],
            parameters: [
                { Key: "IsFMClose", Value: [["Yes"]], IsFilterPanelParam: "True" },
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "SearchString", Value: [[""]] },
                { Key: "SortBy", Value: [["[Azure Consumption]"]] },
                { Key: "SortType", Value: [["DESC"]] },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]], updateExportParameter: true },
                { Key: "PartnerAttachType", Value: [["ALL"]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "SubscriptionStatus", Value: [["ALL"]] },
                { Key: "SubscriptionRenewal", Value: [["ALL"]] },
                { Key: "IsAutoRenew", Value: [["ALL"]] },
                { Key: "CPPProduct", Value: [["ALL"]] },
                { Key: "StatusCategory", Value: [["ALL"]] },
            ],
            page: "Summary",
            section: "CPPAzure",
            sortable: true,
            exportParameters: [
                {
                    Placeholder: "PartnerVORG",
                    Key: "VOrg Partner ID",
                    CubeMeasure: "[UnifiedPartner].[VOrg Partner ID]",
                },
                {
                    Placeholder: "PartnerOneKey",
                    Key: "PartnerOne ID",
                    CubeMeasure: "[Reporting PartnerOne].[PartnerOne ID]",
                },
                {
                    Placeholder: "TimePeriod",
                    Key: "Time Period",
                    CubeMeasure: "[Time Period].[Time Period]",
                },
                {
                    Placeholder: "PartnerAttachType",
                    Key: "Partner Attach Type",
                    CubeMeasure: "[Partner Attach Type].[Partner Attach Type]",
                },
                {
                    Placeholder: "PartnerKey",
                    Key: "Partner ID",
                    CubeMeasure: "[UnifiedPartner].[Partner ID]",
                },
                {
                    Placeholder: "SubscriptionStatus",
                    Key: "Status Name",
                    CubeMeasure: "[Subscription Status].[Status Name]",
                },
                {
                    Placeholder: "SubscriptionRenewal",
                    Key: "Due In",
                    CubeMeasure: "[Subscription Type].[Due In]",
                },
                {
                    Placeholder: "IsAutoRenew",
                    Key: "Is Auto Renew",
                    CubeMeasure: "[Subscription Type].[Is Auto Renew]",
                },
                {
                    Placeholder: "CPPProduct",
                    Key: "Reporting Product Name",
                    CubeMeasure: "[Product].[Product]",
                },
                {
                    Placeholder: "StatusCategory",
                    Key: "Status Category",
                    CubeMeasure: "[Subscription Status].[Status Category]",
                },
            ],
        },
        SPOCPPSummaryAzureCustomerTPIDSub: {
            gridid: "SPOCPPSummaryAzureCustomerTPIDSub",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "IsFMClose", Value: [["Yes"]], IsFilterPanelParam: "True" },
                { Key: "ReportingArea", Value: [["ALL"]], IsFilterPanelParam: "True" },
                {
                    Key: "ReportingRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubRegion",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                {
                    Key: "ReportingSubsidiary",
                    Value: [["ALL"]],
                    IsFilterPanelParam: "True",
                },
                { Key: "PartnerVORG", Value: [["ALL"]] },
                { Key: "PartnerOneKey", Value: [["ALL"]] },
                { Key: "TimePeriod", Value: [[3]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "SubscriptionStatus", Value: [["ALL"]] },
                { Key: "SubscriptionRenewal", Value: [["ALL"]] },
                { Key: "IsAutoRenew", Value: [["ALL"]] },
                { Key: "StatusCategory", Value: [[1]] },
                { Key: "PartnerAttachType", Value: [["ALL"]] },
                { Key: "CustomerKey", Value: [["ALL"]] },
            ],
            filter: "CustomerID$#$PartnerAttachType",
            page: "Summary",
            section: "CPPAzure",
        },
        PCPActiveEntitlements: {
            gridid: "PCPActiveEntitlements",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "ReportID", Value: [["0902"]] },
                { Key: "PartnerID", Value: [[null]] },
                { Key: "CompetencyID", Value: [[null]] },
                { Key: "CompetencyType", Value: [[null]] },
                { Key: "pPartnerAssociationKey", Value: [["All"]] },
                { Key: "Workload", Value: [[""]] },
                { Key: "pChannel", Value: [["1"]] },
                { Key: "pSortBy", Value: [["[CustomerID]"]] },
                {
                    Key: "pSortOrder",
                    Value: [["ASC"]],
                } /*, { "Key": "pStart", "Value": [["0"]] }, { "Key": "pMaxRows", "Value": [["10"]] }*/,
            ],
            page: "PCP",
            section: "PCPActiveEntitlementsGrid",
        },
        PCPActivations: {
            gridid: "PCPActivations",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "ReportID", Value: [["0902"]] },
                { Key: "PartnerID", Value: [[null]] },
                { Key: "CompetencyID", Value: [[null]] },
                { Key: "CompetencyType", Value: [[null]] },
                { Key: "pPartnerAssociationKey", Value: [["All"]] },
                { Key: "Workload", Value: [["ProPlus"]] },
                { Key: "pChannel", Value: [["1"]] },
                { Key: "pSortBy", Value: [["[CustomerID]"]] },
                {
                    Key: "pSortOrder",
                    Value: [["ASC"]],
                } /*, { "Key": "pStart", "Value": [["0"]] }, { "Key": "pMaxRows", "Value": [["10"]] }*/,
            ],
            page: "PCP",
            section: "PCPActivationsGrid",
        },
        PCPAzureTTMConsumption: {
            gridid: "PCPAzureTTMConsumption",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "ReportID", Value: [["0903"]] },
                { Key: "PartnerID", Value: [[null]] },
                { Key: "CompetencyID", Value: [[null]] },
                { Key: "CompetencyType", Value: [[null]] },
                { Key: "pPartnerAssociationKey", Value: [["All"]] },
                { Key: "Workload", Value: [["Azure"]] },
                { Key: "pChannel", Value: [["1"]] },
                { Key: "pSortBy", Value: [["[Subscription ID]"]] },
                {
                    Key: "pSortOrder",
                    Value: [["ASC"]],
                } /*, { "Key": "pStart", "Value": [["0"]] }, { "Key": "pMaxRows", "Value": [["10"]] }*/,
            ],
            page: "PCP",
            section: "PCPAzureTTMConsumptionGrid",
        },
        PCPAADPCompetencyPerformanceCredit: {
            gridid: "PCPAADPCompetencyPerformanceCredit",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "ReportID", Value: [["0903"]] },
                { Key: "PartnerID", Value: [[null]] },
                { Key: "CompetencyID", Value: [[null]] },
                { Key: "CompetencyType", Value: [[null]] },
                { Key: "pPartnerAssociationKey", Value: [["All"]] },
                { Key: "Workload", Value: [["AADP"]] },
                { Key: "pChannel", Value: [["1"]] },
                { Key: "pSortBy", Value: [["[Subscription ID]"]] },
                {
                    Key: "pSortOrder",
                    Value: [["ASC"]],
                } /*, { "Key": "pStart", "Value": [["0"]] }, { "Key": "pMaxRows", "Value": [["10"]] }*/,
            ],
            page: "PCP",
            section: "PCPAADPCompetencyPerformanceCreditGrid",
        },
        PCPTotalO365Customers: {
            gridid: "PCPTotalO365Customers",
            type: "multi",
            subgrid: ["PCPTotalO365CustomersSub"],
            parameters: [
                { Key: "ReportID", Value: [["0904"]] },
                { Key: "PartnerID", Value: [[null]] },
                { Key: "CompetencyID", Value: [[null]] },
                { Key: "CompetencyType", Value: [[null]] },
                { Key: "pPartnerAssociationKey", Value: [["All"]] },
                { Key: "Workload", Value: [[null]] },
                { Key: "pChannel", Value: [["1"]] },
                { Key: "pSortBy", Value: [["[Customer Name]"]] },
                { Key: "pSortOrder", Value: [["ASC"]] },
                { Key: "HasDeploymentOppty", Value: [["All"]] },
                { Key: "RenewalsDue", Value: [["All"]] },
                { Key: "SubscriptionStatus", Value: [["All"]] },
                {
                    Key: "ChannelName",
                    Value: [["All"]],
                } /*, { "Key": "pStart", "Value": [["0"]] }, { "Key": "pMaxRows", "Value": [["10"]] }*/,
            ],
            page: "PCP",
            section: "PCPTotalO365CustomersGrid",
        },
        PCPTotalO365CustomersSub: {
            gridid: "PCPTotalO365CustomersSub",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "ReportID", Value: [["0905"]] },
                { Key: "PartnerID", Value: [[null]] },
                { Key: "CompetencyID", Value: [[null]] },
                { Key: "CompetencyType", Value: [[null]] },
                { Key: "Workload", Value: [[null]] },
                { Key: "pChannel", Value: [["1"]] },
                { Key: "pSortBy", Value: [["[Subscription ID]"]] },
                { Key: "pSortOrder", Value: [["ASC"]] },
                { Key: "pPartnerAssociationKey", Value: [["All"]] },
                {
                    Key: "TenantID",
                    Value: [[null]],
                } /*, { "Key": "pStart", "Value": [["0"]] }, { "Key": "pMaxRows", "Value": [["10"]] }*/,
            ],
            page: "PCP",
            section: "PCPTotalO365CustomersSubGrid",
            filter: "TenantID$#$Partner Association Type",
        },
        SPOTrainingIndividualGrid: {
            gridid: "SPOTrainingIndividualGrid",
            type: "multi",
            subgrid: ["SPOTrainingIndividualGridSub"],
            parameters: [
                { Key: "IsMCP", Value: [["ALL"]] },
                { Key: "pPartnerID", Value: [["ALL"]] },
                { Key: "IsAssociatedMPN", Value: [["ALL"]] },
                { Key: "PartnerKey", Value: [["ALL"]] },
                { Key: "MaxRows", Value: [["15"]] },
                { Key: "Start", Value: [["0"]] },
                { Key: "SortBy", Value: [["IndividualID"]] },
                { Key: "SortOrder", Value: [["ASC"]] },
                { Key: "IndividualID", Value: [["ALL"]] },
            ],
            page: "Training",
            exportParameters: [
                {
                    Placeholder: "IsMCP",
                    Key: "Is MCP",
                    CubeMeasure: "[Individual].[Is MCP]",
                },
                {
                    Placeholder: "pPartnerID",
                    Key: "VOrg Partner ID",
                    CubeMeasure: "[Partner].[VOrg Partner ID]",
                },
                {
                    Placeholder: "PartnerKey",
                    Key: "Partner ID",
                    CubeMeasure: "[Partner].[Partner ID]",
                },
            ],
        },
        SPOTrainingIndividualGridSub: {
            gridid: "SPOTrainingIndividualGridSub",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "IsMCP", Value: [["ALL"]] },
                { Key: "pPartnerID", Value: [["ALL"]] },
                { Key: "IsAssociatedMPN", Value: [["ALL"]] },
                { Key: "MaxRows", Value: [["15"]] },
                { Key: "Start", Value: [["0"]] },
                { Key: "SortBy", Value: [[""]] },
                { Key: "SortOrder", Value: [["ASC"]] },
                { Key: "IndividualID", Value: [["ALL"]] },
            ],
            page: "Training",
            filter: "Individual ID",
        },
        SPOPartnerLearningGrid: {
            title: "Details",
            gridid: "SPOPartnerLearningGrid",
            type: "learninghierarchy",
            subgrid: ["SPOPartnerLearningUserGrid"],
            parameters: [
                {
                    Key: "PartnerID",
                    Value: [[parseInt(window.location.search.split("&")[0].split("=")[1])]],
                },
                { Key: "SortBy", Value: [[""]] },
                { Key: "SortOrder", Value: [[""]] },
                { Key: "Roles", Value: [[""]] },
                { Key: "Products", Value: [[""]] },
                { Key: "Levels", Value: [[""]] },
                { Key: "LearningPath", Value: [[""]] },
            ],
            filter: "PartnerID",
            page: "Learning",
            sortable: true,
            exportParameters: [
                {
                    Placeholder: "Roles",
                    Key: "Role Name",
                    CubeMeasure: "[MS Learn Role].[Role Name]",
                },
                {
                    Placeholder: "PartnerID",
                    Key: "Partner ID",
                    CubeMeasure: "[MS Learn Progress].[Partner ID]",
                },
                {
                    Placeholder: "Levels",
                    Key: "Level Name",
                    CubeMeasure: "[MS Learn Level].[Level Name]",
                },
                {
                    Placeholder: "Products",
                    Key: "Sub Product",
                    CubeMeasure: "[MS Learn Product].[Sub Product]",
                },
            ],
        },
        SPOPartnerModuleGrid: {
            title: "Details",
            gridid: "SPOPartnerModuleGrid",
            type: "learninghierarchy",
            subgrid: ["SPOPartnerModuleUserGrid"],
            parameters: [
                {
                    Key: "PartnerID",
                    Value: [[parseInt(window.location.search.split("&")[0].split("=")[1])]],
                },
                { Key: "SortBy", Value: [[""]] },
                { Key: "SortOrder", Value: [[""]] },
                { Key: "Roles", Value: [[""]] },
                { Key: "Products", Value: [[""]] },
                { Key: "Levels", Value: [[""]] },
                { Key: "Module", Value: [[""]] },
            ],
            filter: "PartnerID",
            page: "Learning",
            sortable: true,
            exportParameters: [
                {
                    Placeholder: "Roles",
                    Key: "Role Name",
                    CubeMeasure: "[MS Learn Role].[Role Name]",
                },
                {
                    Placeholder: "PartnerID",
                    Key: "Partner ID",
                    CubeMeasure: "[MS Learn Progress].[Partner ID]",
                },
                {
                    Placeholder: "Levels",
                    Key: "Level Name",
                    CubeMeasure: "[MS Learn Level].[Level Name]",
                },
                {
                    Placeholder: "Products",
                    Key: "Sub Product",
                    CubeMeasure: "[MS Learn Product].[Sub Product]",
                },
            ],
        },
        SPOPartnerModuleUserGrid: {
            gridid: "SPOPartnerModuleUserGrid",
            type: "learningmulti",
            title: "",
            subgrid: [],
            parameters: [
                {
                    Key: "PartnerID",
                    Value: [[parseInt(window.location.search.split("&")[0].split("=")[1])]],
                },
                { Key: "SortBy", Value: [[""]] },
                { Key: "SortOrder", Value: [[""]] },
                { Key: "Roles", Value: [[""]] },
                { Key: "Products", Value: [[""]] },
                { Key: "Levels", Value: [[""]] },
                { Key: "Module", Value: [[""]] },
            ],
            filter: "Module",
            page: "Learning",
            sortable: true,
            exportParameters: [],
        },
        SPOPartnerLearningUserGrid: {
            gridid: "SPOPartnerLearningUserGrid",
            type: "learningmulti",
            title: "",
            subgrid: [],
            parameters: [
                { Key: "PartnerID", Value: [[""]] },
                { Key: "SortBy", Value: [[""]] },
                { Key: "SortOrder", Value: [[""]] },
                { Key: "Roles", Value: [[""]] },
                { Key: "Products", Value: [[""]] },
                { Key: "Levels", Value: [[""]] },
                { Key: "LearningPath", Value: [[""]] },
            ],
            filter: "LearningPath",
            page: "Learning",
            sortable: true,
            exportParameters: [],
        },
        //Planning Tool
        PlanningToolPINExport: {
            gridid: "PlanningToolPINExport",
            paramNo: 2,
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "CreditedArea", Value: [["ALL"]] },
                { Key: "CreditedSubregion", Value: [["ALL"]] },
            ],
            page: "ACR",
            exportParameters: [
                {
                    Placeholder: "CreditedArea",
                    Key: "Credited Area",
                    CubeMeasure: "[Credited Geography].[Credited Area]",
                },
                {
                    Placeholder: "CreditedSubregion",
                    Key: "Credited SubRegion",
                    CubeMeasure: "[Credited Geography].[Credited SubRegion]",
                },
            ],
        },
        PlanningToolPINExportACR: {
            gridid: "PlanningToolPINExport",
            paramNo: 2,
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "CreditedArea", Value: [["ALL"]] },
                { Key: "CreditedSubregion", Value: [["ALL"]] },
            ],
            page: "ACR",
            section: "Azure",
            metric: "ACR",
            exportParameters: [
                {
                    Placeholder: "CreditedArea",
                    Key: "Credited Area",
                    CubeMeasure: "[Credited Geography].[Credited Area]",
                },
                {
                    Placeholder: "CreditedSubregion",
                    Key: "Credited SubRegion",
                    CubeMeasure: "[Credited Geography].[Credited SubRegion]",
                },
            ],
        },
        PlanningToolPartnerPivotACR: {
            gridid: "PlanningToolPartnerPivotACR",
            paramNo: 6,
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "CreditedArea", Value: [["ALL"]] },
                { Key: "CreditedSubregion", Value: [["ALL"]] },
                { Key: "PartnerGeography", Value: [["ALL"]] },
                { Key: "PartnerSubsidiary", Value: [["ALL"]] },
                { Key: "IsManaged", Value: [["ALL"]] },
                { Key: "Metric", Value: [["0"]] },
            ],
            page: "ACR",
            section: "Azure",
            metric: "ACR",
            exportParameters: [
                {
                    Placeholder: "CreditedArea",
                    Key: "Credited Area",
                    CubeMeasure: "[Credited Geography].[Credited Area]",
                },
                {
                    Placeholder: "CreditedSubregion",
                    Key: "Credited SubRegion",
                    CubeMeasure: "[Credited Geography].[Credited SubRegion]",
                },
                {
                    Placeholder: "PartnerGeography",
                    Key: "Partner Area",
                    CubeMeasure: "[PartnerOne Sub].[Partner Area]",
                },
                {
                    Placeholder: "PartnerSubsidiary",
                    Key: "Partner Subsidiary",
                    CubeMeasure: "[PartnerOne Sub].[Partner Subsidiary]",
                },
                {
                    Placeholder: "IsManaged",
                    Key: "Is Managed",
                    CubeMeasure: "[Managed].[IsManaged]",
                },
            ],
        },
        PlanningToolPartnerPivotACRDB: {
            gridid: "PlanningToolPartnerPivotACRDB",
            paramNo: 1,
            type: "single",
            subgrid: [],
            parameters: [{ Key: "MetricID", Value: [["95"]] }],
            page: "ACR",
            section: "Azure",
            metric: "ACR",
            exportParameters: [
                {
                    Placeholder: "CreditedArea",
                    Key: "Credited Area",
                    CubeMeasure: "[Credited Geography].[Credited Area]",
                },
                {
                    Placeholder: "CreditedSubregion",
                    Key: "Credited SubRegion",
                    CubeMeasure: "[Credited Geography].[Credited SubRegion]",
                },
                {
                    Placeholder: "PartnerGeography",
                    Key: "Partner Area",
                    CubeMeasure: "[PartnerOne Sub].[Partner Area]",
                },
                {
                    Placeholder: "PartnerSubsidiary",
                    Key: "Partner Subsidiary",
                    CubeMeasure: "[PartnerOne Sub].[Partner Subsidiary]",
                },
                {
                    Placeholder: "IsManaged",
                    Key: "Is Managed",
                    CubeMeasure: "[Managed].[IsManaged]",
                },
            ],
        },
        PlanningToolCustomerPivotACR: {
            gridid: "PlanningToolCustomerPivotACR",
            paramNo: 8,
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "CreditedArea", Value: [["ALL"]] },
                { Key: "CreditedSubregion", Value: [["ALL"]] },
                { Key: "CreditedSubsidiary", Value: [["ALL"]] },
                { Key: "PartnerGeography2", Value: [["ALL"]] },
                { Key: "Segment", Value: [["ALL"]] },
                { Key: "Industry", Value: [["ALL"]] },
                { Key: "Vertical", Value: [["ALL"]] },
                { Key: "Metric", Value: [["0"]] },
            ],
            page: "ACR2",
            section: "Azure",
            metric: "ACR",
            exportParameters: [
                {
                    Placeholder: "CreditedArea",
                    Key: "Credited Area",
                    CubeMeasure: "[Credited Geography].[Credited Area]",
                },
                {
                    Placeholder: "CreditedSubregion",
                    Key: "Credited SubRegion",
                    CubeMeasure: "[Credited Geography].[Credited SubRegion]",
                },
                {
                    Placeholder: "CreditedSubsidiary",
                    Key: "Credited Subsidiary",
                    CubeMeasure: "[Credited Geography].[Credited Subsidiary]",
                },
                {
                    Placeholder: "PartnerGeography2",
                    Key: "Partner Area",
                    CubeMeasure: "[PartnerOne Sub].[Partner Area]",
                },
                {
                    Placeholder: "Segment",
                    Key: "Reporting Segment",
                    CubeMeasure: "[Segment].[Reporting Segment]",
                },
                {
                    Placeholder: "Industry",
                    Key: "Industry",
                    CubeMeasure: "[Customer].[Industry]",
                },
                {
                    Placeholder: "Vertical",
                    Key: "Vertical",
                    CubeMeasure: "[Customer].[Vertical]",
                },
            ],
        },
        PlanningToolPINExportAzureSkills: {
            gridid: "PlanningToolPINExport",
            paramNo: 2,
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "CreditedArea", Value: [["ALL"]] },
                { Key: "CreditedSubregion", Value: [["ALL"]] },
            ],
            page: "ACR",
            section: "Azure",
            metric: "Azure Partner Certified Individuals",
            exportParameters: [
                {
                    Placeholder: "CreditedArea",
                    Key: "Credited Area",
                    CubeMeasure: "[Credited Geography].[Credited Area]",
                },
                {
                    Placeholder: "CreditedSubregion",
                    Key: "Credited SubRegion",
                    CubeMeasure: "[Credited Geography].[Credited SubRegion]",
                },
            ],
        },
        PlanningToolPartnerPivotAzureSkills: {
            gridid: "PlanningToolPartnerPivotAzureSkills",
            paramNo: 6,
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "CreditedArea", Value: [["ALL"]] },
                { Key: "CreditedSubregion", Value: [["ALL"]] },
                { Key: "PartnerGeography", Value: [["ALL"]] },
                { Key: "PartnerSubsidiary", Value: [["ALL"]] },
                { Key: "IsManaged", Value: [["ALL"]] },
                { Key: "Metric", Value: [["0"]] },
            ],
            page: "ACR",
            section: "Azure",
            metric: "Azure Partner Certified Individuals",
            exportParameters: [
                {
                    Placeholder: "CreditedArea",
                    Key: "Credited Area",
                    CubeMeasure: "[Credited Geography].[Credited Area]",
                },
                {
                    Placeholder: "CreditedSubregion",
                    Key: "Credited SubRegion",
                    CubeMeasure: "[Credited Geography].[Credited SubRegion]",
                },
                {
                    Placeholder: "PartnerGeography",
                    Key: "Partner Area",
                    CubeMeasure: "[PartnerOne Sub].[Partner Area]",
                },
                {
                    Placeholder: "PartnerSubsidiary",
                    Key: "Partner Subsidiary",
                    CubeMeasure: "[PartnerOne Sub].[Partner Subsidiary]",
                },
                {
                    Placeholder: "IsManaged",
                    Key: "Is Managed",
                    CubeMeasure: "[Managed].[IsManaged]",
                },
            ],
        },
        PlanningToolPartnerPivotAzureSkillsDB: {
            gridid: "PlanningToolPartnerPivotAzureSkillsDB",
            paramNo: 1,
            type: "single",
            subgrid: [],
            parameters: [{ Key: "MetricID", Value: [["61"]] }],
            page: "ACR",
            section: "Azure",
            metric: "Azure Partner Certified Individuals",
            exportParameters: [
                {
                    Placeholder: "CreditedArea",
                    Key: "Credited Area",
                    CubeMeasure: "[Credited Geography].[Credited Area]",
                },
                {
                    Placeholder: "CreditedSubregion",
                    Key: "Credited SubRegion",
                    CubeMeasure: "[Credited Geography].[Credited SubRegion]",
                },
                {
                    Placeholder: "PartnerGeography",
                    Key: "Partner Area",
                    CubeMeasure: "[PartnerOne Sub].[Partner Area]",
                },
                {
                    Placeholder: "PartnerSubsidiary",
                    Key: "Partner Subsidiary",
                    CubeMeasure: "[PartnerOne Sub].[Partner Subsidiary]",
                },
                {
                    Placeholder: "IsManaged",
                    Key: "Is Managed",
                    CubeMeasure: "[Managed].[IsManaged]",
                },
            ],
        },
        PlanningToolCustomerPivotAzureSkills: {
            gridid: "PlanningToolCustomerPivotAzureSkills",
            paramNo: 8,
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "CreditedArea", Value: [["ALL"]] },
                { Key: "CreditedSubregion", Value: [["ALL"]] },
                { Key: "CreditedSubsidiary", Value: [["ALL"]] },
                { Key: "PartnerGeography2", Value: [["ALL"]] },
                { Key: "Segment", Value: [["ALL"]] },
                { Key: "Industry", Value: [["ALL"]] },
                { Key: "Vertical", Value: [["ALL"]] },
                { Key: "Metric", Value: [["0"]] },
            ],
            page: "ACR2",
            section: "Azure",
            metric: "Azure Partner Certified Individuals",
            exportParameters: [
                {
                    Placeholder: "CreditedArea",
                    Key: "Credited Area",
                    CubeMeasure: "[Credited Geography].[Credited Area]",
                },
                {
                    Placeholder: "CreditedSubregion",
                    Key: "Credited SubRegion",
                    CubeMeasure: "[Credited Geography].[Credited SubRegion]",
                },
                {
                    Placeholder: "CreditedSubsidiary",
                    Key: "Credited Subsidiary",
                    CubeMeasure: "[Credited Geography].[Credited Subsidiary]",
                },
                {
                    Placeholder: "PartnerGeography2",
                    Key: "Partner Area",
                    CubeMeasure: "[PartnerOne Sub].[Partner Area]",
                },
                {
                    Placeholder: "Segment",
                    Key: "Reporting Segment",
                    CubeMeasure: "[Segment].[Reporting Segment]",
                },
                {
                    Placeholder: "Industry",
                    Key: "Industry",
                    CubeMeasure: "[Customer].[Industry]",
                },
                {
                    Placeholder: "Vertical",
                    Key: "Vertical",
                    CubeMeasure: "[Customer].[Vertical]",
                },
            ],
        },
        PlanningToolPINExportD365BR: {
            gridid: "PlanningToolPINExport",
            paramNo: 2,
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "CreditedArea", Value: [["ALL"]] },
                { Key: "CreditedSubregion", Value: [["ALL"]] },
            ],
            page: "ACR",
            section: "BusinessApplications",
            metric: "D365 Billed Revenue",
            exportParameters: [
                {
                    Placeholder: "CreditedArea",
                    Key: "Credited Area",
                    CubeMeasure: "[Credited Geography].[Credited Area]",
                },
                {
                    Placeholder: "CreditedSubregion",
                    Key: "Credited SubRegion",
                    CubeMeasure: "[Credited Geography].[Credited SubRegion]",
                },
            ],
        },
        PlanningToolCustomerPivotD365BR: {
            gridid: "PlanningToolCustomerPivotD365BR",
            paramNo: 8,
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "CreditedArea", Value: [["ALL"]] },
                { Key: "CreditedSubregion", Value: [["ALL"]] },
                { Key: "CreditedSubsidiary", Value: [["ALL"]] },
                { Key: "PartnerGeography2", Value: [["ALL"]] },
                { Key: "Segment", Value: [["ALL"]] },
                { Key: "Industry", Value: [["ALL"]] },
                { Key: "Vertical", Value: [["ALL"]] },
                { Key: "Metric", Value: [["0"]] },
            ],
            page: "ACR2",
            section: "BusinessApplications",
            metric: "D365 Billed Revenue",
            exportParameters: [
                {
                    Placeholder: "CreditedArea",
                    Key: "Credited Area",
                    CubeMeasure: "[Credited Geography].[Credited Area]",
                },
                {
                    Placeholder: "CreditedSubregion",
                    Key: "Credited SubRegion",
                    CubeMeasure: "[Credited Geography].[Credited SubRegion]",
                },
                {
                    Placeholder: "CreditedSubsidiary",
                    Key: "Credited Subsidiary",
                    CubeMeasure: "[Credited Geography].[Credited Subsidiary]",
                },
                {
                    Placeholder: "PartnerGeography2",
                    Key: "Partner Area",
                    CubeMeasure: "[PartnerOne Sub].[Partner Area]",
                },
                {
                    Placeholder: "Segment",
                    Key: "Reporting Segment",
                    CubeMeasure: "[Segment].[Reporting Segment]",
                },
                {
                    Placeholder: "Industry",
                    Key: "Industry",
                    CubeMeasure: "[Customer].[Industry]",
                },
                {
                    Placeholder: "Vertical",
                    Key: "Vertical",
                    CubeMeasure: "[Customer].[Vertical]",
                },
            ],
        },
        PlanningToolPartnerPivotD365BR: {
            gridid: "PlanningToolPartnerPivotD365BR",
            paramNo: 6,
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "CreditedArea", Value: [["ALL"]] },
                { Key: "CreditedSubregion", Value: [["ALL"]] },
                { Key: "PartnerGeography", Value: [["ALL"]] },
                { Key: "PartnerSubsidiary", Value: [["ALL"]] },
                { Key: "IsManaged", Value: [["ALL"]] },
                { Key: "Metric", Value: [["0"]] },
            ],
            page: "ACR",
            section: "BusinessApplications",
            metric: "D365 Billed Revenue",
            exportParameters: [
                {
                    Placeholder: "CreditedArea",
                    Key: "Credited Area",
                    CubeMeasure: "[Credited Geography].[Credited Area]",
                },
                {
                    Placeholder: "CreditedSubregion",
                    Key: "Credited SubRegion",
                    CubeMeasure: "[Credited Geography].[Credited SubRegion]",
                },
                {
                    Placeholder: "PartnerGeography",
                    Key: "Partner Area",
                    CubeMeasure: "[PartnerOne Sub].[Partner Area]",
                },
                {
                    Placeholder: "PartnerSubsidiary",
                    Key: "Partner Subsidiary",
                    CubeMeasure: "[PartnerOne Sub].[Partner Subsidiary]",
                },
                {
                    Placeholder: "IsManaged",
                    Key: "Is Managed",
                    CubeMeasure: "[Managed].[IsManaged]",
                },
            ],
        },
        PlanningToolPartnerPivotD365BRDB: {
            gridid: "PlanningToolPartnerPivotD365BRDB",
            paramNo: 1,
            type: "single",
            subgrid: [],
            parameters: [{ Key: "MetricID", Value: [["90"]] }],
            page: "ACR",
            section: "BusinessApplications",
            metric: "D365 Billed Revenue",
            exportParameters: [
                {
                    Placeholder: "CreditedArea",
                    Key: "Credited Area",
                    CubeMeasure: "[Credited Geography].[Credited Area]",
                },
                {
                    Placeholder: "CreditedSubregion",
                    Key: "Credited SubRegion",
                    CubeMeasure: "[Credited Geography].[Credited SubRegion]",
                },
                {
                    Placeholder: "PartnerGeography",
                    Key: "Partner Area",
                    CubeMeasure: "[PartnerOne Sub].[Partner Area]",
                },
                {
                    Placeholder: "PartnerSubsidiary",
                    Key: "Partner Subsidiary",
                    CubeMeasure: "[PartnerOne Sub].[Partner Subsidiary]",
                },
                {
                    Placeholder: "IsManaged",
                    Key: "Is Managed",
                    CubeMeasure: "[Managed].[IsManaged]",
                },
            ],
        },
        PlanningToolPINExportDAICRCR: {
            gridid: "PlanningToolPINExport",
            paramNo: 2,
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "CreditedArea", Value: [["ALL"]] },
                { Key: "CreditedSubregion", Value: [["ALL"]] },
            ],
            page: "ACR",
            section: "Azure",
            metric: "Data & AI Consumed Revenue",
            exportParameters: [
                {
                    Placeholder: "CreditedArea",
                    Key: "Credited Area",
                    CubeMeasure: "[Credited Geography].[Credited Area]",
                },
                {
                    Placeholder: "CreditedSubregion",
                    Key: "Credited SubRegion",
                    CubeMeasure: "[Credited Geography].[Credited SubRegion]",
                },
            ],
        },
        PlanningToolCustomerPivotDAICRCR: {
            gridid: "PlanningToolCustomerPivotDAICRCR",
            paramNo: 8,
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "CreditedArea", Value: [["ALL"]] },
                { Key: "CreditedSubregion", Value: [["ALL"]] },
                { Key: "CreditedSubsidiary", Value: [["ALL"]] },
                { Key: "PartnerGeography2", Value: [["ALL"]] },
                { Key: "Segment", Value: [["ALL"]] },
                { Key: "Industry", Value: [["ALL"]] },
                { Key: "Vertical", Value: [["ALL"]] },
                { Key: "Metric", Value: [["0"]] },
            ],
            page: "ACR2",
            section: "Azure",
            metric: "Data & AI Consumed Revenue",
            exportParameters: [
                {
                    Placeholder: "CreditedArea",
                    Key: "Credited Area",
                    CubeMeasure: "[Credited Geography].[Credited Area]",
                },
                {
                    Placeholder: "CreditedSubregion",
                    Key: "Credited SubRegion",
                    CubeMeasure: "[Credited Geography].[Credited SubRegion]",
                },
                {
                    Placeholder: "CreditedSubsidiary",
                    Key: "Credited Subsidiary",
                    CubeMeasure: "[Credited Geography].[Credited Subsidiary]",
                },
                {
                    Placeholder: "PartnerGeography2",
                    Key: "Partner Area",
                    CubeMeasure: "[PartnerOne Sub].[Partner Area]",
                },
                {
                    Placeholder: "Segment",
                    Key: "Reporting Segment",
                    CubeMeasure: "[Segment].[Reporting Segment]",
                },
                {
                    Placeholder: "Industry",
                    Key: "Industry",
                    CubeMeasure: "[Customer].[Industry]",
                },
                {
                    Placeholder: "Vertical",
                    Key: "Vertical",
                    CubeMeasure: "[Customer].[Vertical]",
                },
            ],
        },
        PlanningToolPartnerPivotDAICRCR: {
            gridid: "PlanningToolPartnerPivotDAICRCR",
            paramNo: 6,
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "CreditedArea", Value: [["ALL"]] },
                { Key: "CreditedSubregion", Value: [["ALL"]] },
                { Key: "PartnerGeography", Value: [["ALL"]] },
                { Key: "PartnerSubsidiary", Value: [["ALL"]] },
                { Key: "IsManaged", Value: [["ALL"]] },
                { Key: "Metric", Value: [["0"]] },
            ],
            page: "ACR",
            section: "Azure",
            metric: "Data & AI Consumed Revenue",
            exportParameters: [
                {
                    Placeholder: "CreditedArea",
                    Key: "Credited Area",
                    CubeMeasure: "[Credited Geography].[Credited Area]",
                },
                {
                    Placeholder: "CreditedSubregion",
                    Key: "Credited SubRegion",
                    CubeMeasure: "[Credited Geography].[Credited SubRegion]",
                },
                {
                    Placeholder: "PartnerGeography",
                    Key: "Partner Area",
                    CubeMeasure: "[PartnerOne Sub].[Partner Area]",
                },
                {
                    Placeholder: "PartnerSubsidiary",
                    Key: "Partner Subsidiary",
                    CubeMeasure: "[PartnerOne Sub].[Partner Subsidiary]",
                },
                {
                    Placeholder: "IsManaged",
                    Key: "Is Managed",
                    CubeMeasure: "[Managed].[IsManaged]",
                },
            ],
        },
        PlanningToolPartnerPivotDAICRCRDB: {
            gridid: "PlanningToolPartnerPivotDAICRCRDB",
            paramNo: 1,
            type: "single",
            subgrid: [],
            parameters: [{ Key: "MetricID", Value: [["100"]] }],
            page: "ACR",
            section: "Azure",
            metric: "Data & AI Consumed Revenue",
            exportParameters: [
                {
                    Placeholder: "CreditedArea",
                    Key: "Credited Area",
                    CubeMeasure: "[Credited Geography].[Credited Area]",
                },
                {
                    Placeholder: "CreditedSubregion",
                    Key: "Credited SubRegion",
                    CubeMeasure: "[Credited Geography].[Credited SubRegion]",
                },
                {
                    Placeholder: "PartnerGeography",
                    Key: "Partner Area",
                    CubeMeasure: "[PartnerOne Sub].[Partner Area]",
                },
                {
                    Placeholder: "PartnerSubsidiary",
                    Key: "Partner Subsidiary",
                    CubeMeasure: "[PartnerOne Sub].[Partner Subsidiary]",
                },
                {
                    Placeholder: "IsManaged",
                    Key: "Is Managed",
                    CubeMeasure: "[Managed].[IsManaged]",
                },
            ],
        },
        PlanningToolPINExportM365BR: {
            gridid: "PlanningToolPINExport",
            paramNo: 2,
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "CreditedArea", Value: [["ALL"]] },
                { Key: "CreditedSubregion", Value: [["ALL"]] },
            ],
            page: "ACR",
            section: "ModernWorkplace",
            metric: "M365 E5 Billed Revenue",
            exportParameters: [
                {
                    Placeholder: "CreditedArea",
                    Key: "Credited Area",
                    CubeMeasure: "[Credited Geography].[Credited Area]",
                },
                {
                    Placeholder: "CreditedSubregion",
                    Key: "Credited SubRegion",
                    CubeMeasure: "[Credited Geography].[Credited SubRegion]",
                },
            ],
        },
        PlanningToolCustomerPivotM365BR: {
            gridid: "PlanningToolCustomerPivotM365BR",
            paramNo: 8,
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "CreditedArea", Value: [["ALL"]] },
                { Key: "CreditedSubregion", Value: [["ALL"]] },
                { Key: "CreditedSubsidiary", Value: [["ALL"]] },
                { Key: "PartnerGeography2", Value: [["ALL"]] },
                { Key: "Segment", Value: [["ALL"]] },
                { Key: "Industry", Value: [["ALL"]] },
                { Key: "Vertical", Value: [["ALL"]] },
                { Key: "Metric", Value: [["0"]] },
            ],
            page: "ACR2",
            section: "ModernWorkplace",
            metric: "M365 E5 Billed Revenue",
            exportParameters: [
                {
                    Placeholder: "CreditedArea",
                    Key: "Credited Area",
                    CubeMeasure: "[Credited Geography].[Credited Area]",
                },
                {
                    Placeholder: "CreditedSubregion",
                    Key: "Credited SubRegion",
                    CubeMeasure: "[Credited Geography].[Credited SubRegion]",
                },
                {
                    Placeholder: "CreditedSubsidiary",
                    Key: "Credited Subsidiary",
                    CubeMeasure: "[Credited Geography].[Credited Subsidiary]",
                },
                {
                    Placeholder: "PartnerGeography2",
                    Key: "Partner Area",
                    CubeMeasure: "[PartnerOne Sub].[Partner Area]",
                },
                {
                    Placeholder: "Segment",
                    Key: "Reporting Segment",
                    CubeMeasure: "[Segment].[Reporting Segment]",
                },
                {
                    Placeholder: "Industry",
                    Key: "Industry",
                    CubeMeasure: "[Customer].[Industry]",
                },
                {
                    Placeholder: "Vertical",
                    Key: "Vertical",
                    CubeMeasure: "[Customer].[Vertical]",
                },
            ],
        },
        PlanningToolPartnerPivotM365BR: {
            gridid: "PlanningToolPartnerPivotM365BR",
            paramNo: 6,
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "CreditedArea", Value: [["ALL"]] },
                { Key: "CreditedSubregion", Value: [["ALL"]] },
                { Key: "PartnerGeography", Value: [["ALL"]] },
                { Key: "PartnerSubsidiary", Value: [["ALL"]] },
                { Key: "IsManaged", Value: [["ALL"]] },
                { Key: "Metric", Value: [["0"]] },
            ],
            page: "ACR",
            section: "ModernWorkplace",
            metric: "M365 E5 Billed Revenue",
            exportParameters: [
                {
                    Placeholder: "CreditedArea",
                    Key: "Credited Area",
                    CubeMeasure: "[Credited Geography].[Credited Area]",
                },
                {
                    Placeholder: "CreditedSubregion",
                    Key: "Credited SubRegion",
                    CubeMeasure: "[Credited Geography].[Credited SubRegion]",
                },
                {
                    Placeholder: "PartnerGeography",
                    Key: "Partner Area",
                    CubeMeasure: "[PartnerOne Sub].[Partner Area]",
                },
                {
                    Placeholder: "PartnerSubsidiary",
                    Key: "Partner Subsidiary",
                    CubeMeasure: "[PartnerOne Sub].[Partner Subsidiary]",
                },
                {
                    Placeholder: "IsManaged",
                    Key: "Is Managed",
                    CubeMeasure: "[Managed].[IsManaged]",
                },
            ],
        },
        PlanningToolPartnerPivotM365BRDB: {
            gridid: "PlanningToolPartnerPivotM365BRDB",
            paramNo: 1,
            type: "single",
            subgrid: [],
            parameters: [{ Key: "MetricID", Value: [["62"]] }],
            page: "ACR",
            section: "ModernWorkplace",
            metric: "M365 E5 Billed Revenue",
            exportParameters: [
                {
                    Placeholder: "CreditedArea",
                    Key: "Credited Area",
                    CubeMeasure: "[Credited Geography].[Credited Area]",
                },
                {
                    Placeholder: "CreditedSubregion",
                    Key: "Credited SubRegion",
                    CubeMeasure: "[Credited Geography].[Credited SubRegion]",
                },
                {
                    Placeholder: "PartnerGeography",
                    Key: "Partner Area",
                    CubeMeasure: "[PartnerOne Sub].[Partner Area]",
                },
                {
                    Placeholder: "PartnerSubsidiary",
                    Key: "Partner Subsidiary",
                    CubeMeasure: "[PartnerOne Sub].[Partner Subsidiary]",
                },
                {
                    Placeholder: "IsManaged",
                    Key: "Is Managed",
                    CubeMeasure: "[Managed].[IsManaged]",
                },
            ],
        },
        PlanningToolPINExportTeamsAU: {
            gridid: "PlanningToolPINExport",
            paramNo: 2,
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "CreditedArea", Value: [["ALL"]] },
                { Key: "CreditedSubregion", Value: [["ALL"]] },
            ],
            page: "ACR",
            section: "ModernWorkplace",
            metric: "Teams Active Usage",
            exportParameters: [
                {
                    Placeholder: "CreditedArea",
                    Key: "Credited Area",
                    CubeMeasure: "[Credited Geography].[Credited Area]",
                },
                {
                    Placeholder: "CreditedSubregion",
                    Key: "Credited SubRegion",
                    CubeMeasure: "[Credited Geography].[Credited SubRegion]",
                },
            ],
        },
        PlanningToolCustomerPivotTeamsAU: {
            gridid: "PlanningToolCustomerPivotTeamsAU",
            paramNo: 8,
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "CreditedArea", Value: [["ALL"]] },
                { Key: "CreditedSubregion", Value: [["ALL"]] },
                { Key: "CreditedSubsidiary", Value: [["ALL"]] },
                { Key: "PartnerGeography2", Value: [["ALL"]] },
                { Key: "Segment", Value: [["ALL"]] },
                { Key: "Industry", Value: [["ALL"]] },
                { Key: "Vertical", Value: [["ALL"]] },
                { Key: "Metric", Value: [["0"]] },
            ],
            page: "ACR2",
            section: "ModernWorkplace",
            metric: "Teams Active Usage",
            exportParameters: [
                {
                    Placeholder: "CreditedArea",
                    Key: "Credited Area",
                    CubeMeasure: "[Credited Geography].[Credited Area]",
                },
                {
                    Placeholder: "CreditedSubregion",
                    Key: "Credited SubRegion",
                    CubeMeasure: "[Credited Geography].[Credited SubRegion]",
                },
                {
                    Placeholder: "CreditedSubsidiary",
                    Key: "Credited Subsidiary",
                    CubeMeasure: "[Credited Geography].[Credited Subsidiary]",
                },
                {
                    Placeholder: "PartnerGeography2",
                    Key: "Partner Area",
                    CubeMeasure: "[PartnerOne Sub].[Partner Area]",
                },
                {
                    Placeholder: "Segment",
                    Key: "Reporting Segment",
                    CubeMeasure: "[Segment].[Reporting Segment]",
                },
                {
                    Placeholder: "Industry",
                    Key: "Industry",
                    CubeMeasure: "[Customer].[Industry]",
                },
                {
                    Placeholder: "Vertical",
                    Key: "Vertical",
                    CubeMeasure: "[Customer].[Vertical]",
                },
            ],
        },
        PlanningToolPartnerPivotTeamsAU: {
            gridid: "PlanningToolPartnerPivotTeamsAU",
            paramNo: 6,
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "CreditedArea", Value: [["ALL"]] },
                { Key: "CreditedSubregion", Value: [["ALL"]] },
                { Key: "PartnerGeography", Value: [["ALL"]] },
                { Key: "PartnerSubsidiary", Value: [["ALL"]] },
                { Key: "IsManaged", Value: [["ALL"]] },
                { Key: "Metric", Value: [["0"]] },
            ],
            page: "ACR",
            section: "ModernWorkplace",
            metric: "Teams Active Usage",
            exportParameters: [
                {
                    Placeholder: "CreditedArea",
                    Key: "Credited Area",
                    CubeMeasure: "[Credited Geography].[Credited Area]",
                },
                {
                    Placeholder: "CreditedSubregion",
                    Key: "Credited SubRegion",
                    CubeMeasure: "[Credited Geography].[Credited SubRegion]",
                },
                {
                    Placeholder: "PartnerGeography",
                    Key: "Partner Area",
                    CubeMeasure: "[PartnerOne Sub].[Partner Area]",
                },
                {
                    Placeholder: "PartnerSubsidiary",
                    Key: "Partner Subsidiary",
                    CubeMeasure: "[PartnerOne Sub].[Partner Subsidiary]",
                },
                {
                    Placeholder: "IsManaged",
                    Key: "Is Managed",
                    CubeMeasure: "[Managed].[IsManaged]",
                },
            ],
        },
        PlanningToolPartnerPivotTeamsAUDB: {
            gridid: "PlanningToolPartnerPivotTeamsAUDB",
            paramNo: 1,
            type: "single",
            subgrid: [],
            parameters: [{ Key: "MetricID", Value: [["66"]] }],
            page: "ACR",
            section: "ModernWorkplace",
            metric: "Teams Active Usage",
            exportParameters: [
                {
                    Placeholder: "CreditedArea",
                    Key: "Credited Area",
                    CubeMeasure: "[Credited Geography].[Credited Area]",
                },
                {
                    Placeholder: "CreditedSubregion",
                    Key: "Credited SubRegion",
                    CubeMeasure: "[Credited Geography].[Credited SubRegion]",
                },
                {
                    Placeholder: "PartnerGeography",
                    Key: "Partner Area",
                    CubeMeasure: "[PartnerOne Sub].[Partner Area]",
                },
                {
                    Placeholder: "PartnerSubsidiary",
                    Key: "Partner Subsidiary",
                    CubeMeasure: "[PartnerOne Sub].[Partner Subsidiary]",
                },
                {
                    Placeholder: "IsManaged",
                    Key: "Is Managed",
                    CubeMeasure: "[Managed].[IsManaged]",
                },
            ],
        },
        PlanningToolPINExportIPCosellAzure: {
            gridid: "PlanningToolPINExport",
            paramNo: 2,
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "CreditedArea", Value: [["ALL"]] },
                { Key: "CreditedSubregion", Value: [["ALL"]] },
            ],
            page: "ACR",
            section: "Azure",
            metric: "IP Co-Sell for Azure",
            exportParameters: [
                {
                    Placeholder: "CreditedArea",
                    Key: "Credited Area",
                    CubeMeasure: "[Credited Geography].[Credited Area]",
                },
                {
                    Placeholder: "CreditedSubregion",
                    Key: "Credited SubRegion",
                    CubeMeasure: "[Credited Geography].[Credited SubRegion]",
                },
            ],
        },
        PlanningToolPINExportIPCosellTeams: {
            gridid: "PlanningToolPINExport",
            paramNo: 2,
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "CreditedArea", Value: [["ALL"]] },
                { Key: "CreditedSubregion", Value: [["ALL"]] },
            ],
            page: "ACR",
            section: "Azure",
            metric: "IP Co-Sell for Azure",
            exportParameters: [
                {
                    Placeholder: "CreditedArea",
                    Key: "Credited Area",
                    CubeMeasure: "[Credited Geography].[Credited Area]",
                },
                {
                    Placeholder: "CreditedSubregion",
                    Key: "Credited SubRegion",
                    CubeMeasure: "[Credited Geography].[Credited SubRegion]",
                },
            ],
        },
        PlanningToolPINExportIPCosellD365: {
            gridid: "PlanningToolPINExport",
            paramNo: 2,
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "CreditedArea", Value: [["ALL"]] },
                { Key: "CreditedSubregion", Value: [["ALL"]] },
            ],
            page: "ACR",
            section: "Azure",
            metric: "IP Co-Sell for Azure",
            exportParameters: [
                {
                    Placeholder: "CreditedArea",
                    Key: "Credited Area",
                    CubeMeasure: "[Credited Geography].[Credited Area]",
                },
                {
                    Placeholder: "CreditedSubregion",
                    Key: "Credited SubRegion",
                    CubeMeasure: "[Credited Geography].[Credited SubRegion]",
                },
            ],
        },
        PlanningToolPartnerPivotIPCosellAzure: {
            gridid: "PlanningToolPartnerPivotIPCosellAzure",
            paramNo: 6,
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "CreditedArea", Value: [["ALL"]] },
                { Key: "CreditedSubregion", Value: [["ALL"]] },
                { Key: "PartnerGeography", Value: [["ALL"]] },
                { Key: "PartnerSubsidiary", Value: [["ALL"]] },
                { Key: "IsManaged", Value: [["ALL"]] },
                { Key: "Metric", Value: [["0"]] },
            ],
            page: "ACR",
            section: "Azure",
            metric: "IP Co-Sell for Azure",
            exportParameters: [
                {
                    Placeholder: "CreditedArea",
                    Key: "Credited Area",
                    CubeMeasure: "[Credited Geography].[Credited Area]",
                },
                {
                    Placeholder: "CreditedSubregion",
                    Key: "Credited SubRegion",
                    CubeMeasure: "[Credited Geography].[Credited SubRegion]",
                },
                {
                    Placeholder: "PartnerGeography",
                    Key: "Partner Area",
                    CubeMeasure: "[PartnerOne Sub].[Partner Area]",
                },
                {
                    Placeholder: "PartnerSubRegion",
                    Key: "Partner Subsidiary",
                    CubeMeasure: "[PartnerOne Sub].[Partner Subsidiary]",
                },
                {
                    Placeholder: "IsManaged",
                    Key: "Is Managed",
                    CubeMeasure: "[Managed].[IsManaged]",
                },
            ],
        },
        PlanningToolPartnerPivotIPCosellAzureDB: {
            gridid: "PlanningToolPartnerPivotIPCosellAzureDB",
            paramNo: 1,
            type: "single",
            subgrid: [],
            parameters: [{ Key: "MetricID", Value: [["139"]] }],
            page: "ACR",
            section: "Azure",
            metric: "IP Co-Sell for Azure",
            exportParameters: [
                {
                    Placeholder: "CreditedArea",
                    Key: "Credited Area",
                    CubeMeasure: "[Credited Geography].[Credited Area]",
                },
                {
                    Placeholder: "CreditedSubregion",
                    Key: "Credited SubRegion",
                    CubeMeasure: "[Credited Geography].[Credited SubRegion]",
                },
                {
                    Placeholder: "PartnerGeography",
                    Key: "Partner Area",
                    CubeMeasure: "[PartnerOne Sub].[Partner Area]",
                },
                {
                    Placeholder: "PartnerSubRegion",
                    Key: "Partner Subsidiary",
                    CubeMeasure: "[PartnerOne Sub].[Partner Subsidiary]",
                },
                {
                    Placeholder: "IsManaged",
                    Key: "Is Managed",
                    CubeMeasure: "[Managed].[IsManaged]",
                },
            ],
        },
        PlanningToolPINExportBizAppsCP: {
            gridid: "PlanningToolPINExport",
            paramNo: 2,
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "CreditedArea", Value: [["ALL"]] },
                { Key: "CreditedSubregion", Value: [["ALL"]] },
            ],
            page: "ACR",
            section: "BusinessApplications",
            metric: "Biz Apps Certified Individuals",
            exportParameters: [
                {
                    Placeholder: "CreditedArea",
                    Key: "Credited Area",
                    CubeMeasure: "[Credited Geography].[Credited Area]",
                },
                {
                    Placeholder: "CreditedSubregion",
                    Key: "Credited SubRegion",
                    CubeMeasure: "[Credited Geography].[Credited SubRegion]",
                },
            ],
        },
        PlanningToolPartnerPivotBizAppsCP: {
            gridid: "PlanningToolPartnerPivotBizAppsCP",
            paramNo: 6,
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "CreditedArea", Value: [["ALL"]] },
                { Key: "CreditedSubregion", Value: [["ALL"]] },
                { Key: "PartnerGeography", Value: [["ALL"]] },
                { Key: "PartnerSubsidiary", Value: [["ALL"]] },
                { Key: "IsManaged", Value: [["ALL"]] },
                { Key: "Metric", Value: [["0"]] },
            ],
            page: "ACR",
            section: "BusinessApplications",
            metric: "Biz Apps Certified Individuals",
            exportParameters: [
                {
                    Placeholder: "CreditedArea",
                    Key: "Credited Area",
                    CubeMeasure: "[Credited Geography].[Credited Area]",
                },
                {
                    Placeholder: "CreditedSubregion",
                    Key: "Credited SubRegion",
                    CubeMeasure: "[Credited Geography].[Credited SubRegion]",
                },
                {
                    Placeholder: "PartnerGeography",
                    Key: "Partner Area",
                    CubeMeasure: "[PartnerOne Sub].[Partner Area]",
                },
                {
                    Placeholder: "PartnerSubsidiary",
                    Key: "Partner Subsidiary",
                    CubeMeasure: "[PartnerOne Sub].[Partner Subsidiary]",
                },
                {
                    Placeholder: "IsManaged",
                    Key: "Is Managed",
                    CubeMeasure: "[Managed].[IsManaged]",
                },
            ],
        },
        PlanningToolPartnerPivotBizAppsCPDB: {
            gridid: "PlanningToolPartnerPivotBizAppsCPDB",
            paramNo: 1,
            type: "single",
            subgrid: [],
            parameters: [{ Key: "MetricID", Value: [["61"]] }],
            page: "ACR",
            section: "BusinessApplications",
            metric: "Biz Apps Certified Individuals",
            exportParameters: [
                {
                    Placeholder: "CreditedArea",
                    Key: "Credited Area",
                    CubeMeasure: "[Credited Geography].[Credited Area]",
                },
                {
                    Placeholder: "CreditedSubregion",
                    Key: "Credited SubRegion",
                    CubeMeasure: "[Credited Geography].[Credited SubRegion]",
                },
                {
                    Placeholder: "PartnerGeography",
                    Key: "Partner Area",
                    CubeMeasure: "[PartnerOne Sub].[Partner Area]",
                },
                {
                    Placeholder: "PartnerSubsidiary",
                    Key: "Partner Subsidiary",
                    CubeMeasure: "[PartnerOne Sub].[Partner Subsidiary]",
                },
                {
                    Placeholder: "IsManaged",
                    Key: "Is Managed",
                    CubeMeasure: "[Managed].[IsManaged]",
                },
            ],
        },
        //CED Report HTML grid
        "CEDReport&Grid": {
            gridid: "CEDReport&Grid",
            type: "single",
            subgrid: [],
            parameters: [
                { Key: "UserAlias", Value: [["All"]] },
                { Key: "MaxRows", Value: [["30"]] },
                { Key: "Start", Value: [["0"]] },
                { Key: "SortBy", Value: "[Country]" },
                { Key: "Country", Value: "" },
                { Key: "FilterSearch", Value: "" },
            ],
            page: "CED",
        },
    };
    static PCP_TILES_COMPETENCIES = [
        "cloud productivity",
        "cloud platform",
        "small and midmarket cloud solutions",
    ];
    static PCP_TILES_COMPETENCY_TYPES = ["silver", "gold"];
    static PCP_O365_ASSOCIATION_TYPES = [
        "All",
        "Partner of Record",
        "CSP Tier1",
        "CSP Tier2",
        "Transacting Partner of Record",
        "Delegated Admin Privilege",
        "FastTrack",
        "FastTrack Ready",
        "Syndication",
    ];
    static PCP_Azure_ASSOCIATION_TYPES = [
        "All",
        "Partner of Record",
        "CSP Tier1",
        "CSP Tier2",
        "Transacting Partner of Record",
        "Manual Attribution",
        "Partner Admin Link",
        "Marketplace",
        "Customer Usage Attribution",
        "Partner As End Customer",
        "Deal Registration",
        "Dual Credit",
    ];
    static PCP_SUBSCRIPTION_STATUS = [
        "All",
        "Active",
        "Disabled",
        "In grace period",
        "Deprovisioned",
    ];
    static PCP_CHANNEL = [
        "All",
        "Advisor",
        "Direct",
        "EA",
        "FPP",
        "Open",
        "Syndication",
    ];
    static PCP_RENEWALS_DUE_IN_DAYS = ["All", "30", "60", "90", ">90"];
    static PCP_HAS_DEPLOYMENT_OPPORTUNITY = ["All", "Yes", "No"];
    static CURRENT_VIEW_ACTIVE_PARTNERS = 0; //Get the count of active partner on PartnerSearch page
    static CURRENT_ACTIVE_HTML_VIEW = '';
    static CURRENT_ACTIVE_IUR_TAB_VIEW = null;
    static CURRENT_ACTIVE_PT_METRIC_VIEW = null;
    static PARTNER_SEARCH_FILTER_MODEL: any = {
        "[IsEngaged]": null,
        "[IsAttainedInPartnerCenter]": null,
        "[ProgramID]": null,
        "[AreaID]": null,
        "[SubsidiaryID]": null,
        "[PartnerOne ID]": null,
        "[PDMKey]": null,
        "[PartnerSpecialization]": null,
        "[PartnerSubSpecialization]": null,
        "[IsPrimaryPDM]": null,
        "[ManagementLevel]": null,
        "[RegionID]": null,
        "[SubRegionID]": null,
        "[CountryID]": null,
        "[OrganizationTypeID]": null,
        "[PartnerTypeID_1]": null,
        "[PartnerSubTypeID_1]": null,
        "[MarketingTypeID]": null,
        "[IndustryID_1]": null,
        "[VerticalID_1]": null,
        "[HasMCP]": null,
        "[HasActiveCloudSubscriptionSale]": null,
        "[CloudSubscriptionSaleProduct]": null,
        "[HasCloudConsumptionorActiveUse]": null,
        "[CloudConsumptionorActiveUseProduct]": null,
        "[PartnerOneQuickSearch]": null,
        "[PartnerQuickSearch]": null,
        "[PartnerWebAddressQuickSearch]": null,
        "[PartnerPrimaryContactQuickSearch]": null,
        "[PartnerPhysicalAddressQuickSearch]": null,
        "[PartnerPhoneNumberQuickSearch]": null,
        "@pProgramTier": null,
        "@pProgramStatus": null,
        "@pSubProgramTier": null,
        "@pResourceCenter": null,
        "@pPriorityCompetency": null,
        "@pCompetency": null,
        "@pPartnerCenterMigrationDate": null,
        "@pCompetencyLevel": null,
        "@pCompetencyStatus": null,
        "@pCompetencyStatusDetail": null,
    };
    static PARTNER_SEARCH_FILTER_MODEL_MAPPING = {
        IsEngaged: "[IsEngaged]",
        IsAttainedInPartnerCenter: "[IsAttainedInPartnerCenter]",
        ProgramTier: "[ProgramID]",
        Area: "[AreaID]",
        Subsidiary: "[SubsidiaryID]",
        PartnerOneName: "[PartnerOne ID]",
        PODName: "[PDMKey]",
        PartnerSpecialization: "[PartnerSpecialization]",
        PartnerSubSpecialization: "[PartnerSubSpecialization]",
        IsPrimaryPDM: "[IsPrimaryPDM]",
        ManagementLevel: "[ManagementLevel]",
        Region: "[RegionID]",
        SubRegion: "[SubRegionID]",
        Country: "[CountryID]",
        Hierarchylevel: "[OrganizationTypeID]",
        PartnerType: "[PartnerTypeID_1]",
        PartnerSubType: "[PartnerSubTypeID_1]",
        MatketType: "[MarketingTypeID]",
        Industry: "[IndustryID_1]",
        Vertical: "[VerticalID_1]",
        HasMCP: "[HasMCP]",
        HasActiveCloudSubscriptionSale: "[HasActiveCloudSubscriptionSale]",
        CloudSubscriptionSaleProduct: "[CloudSubscriptionSaleProduct]",
        HasCloudConsumptionorActiveUse: "[HasCloudConsumptionorActiveUse]",
        CloudConsumptionorActiveUseProduct: "[CloudConsumptionorActiveUseProduct]",
        PartnerOneQuickSearch: "[PartnerOneQuickSearch]",
        PartnerQuickSearch: "[PartnerQuickSearch]",
        PartnerWebAddressQuickSearch: "[PartnerWebAddressQuickSearch]",
        PartnerPrimaryContactQuickSearch: "[PartnerPrimaryContactQuickSearch]",
        PartnerPhysicalAddressQuickSearch: "[PartnerPhysicalAddressQuickSearch]",
        PartnerPhoneNumberQuickSearch: "[PartnerPhoneNumberQuickSearch]",
        mpnProgramTier: "@pProgramTier",
        ProgramStatus: "@pProgramStatus",
        SubProgram: "@pSubProgramTier",
        PartnerCenterMigrationDate: "@pPartnerCenterMigrationDate",
        ResourceCenter: "@pResourceCenter",
        CloudCompetency: "@pPriorityCompetency",
        Competency: "@pCompetency",
        CompetencyLevel: "@pCompetencyLevel",
        CompetencyStatus: "@pCompetencyStatus",
        ActiveCompetencySubStatus: "@pCompetencyStatusDetail",
    };
    static PARTNER_ONE_SUB_ID_QUERY_KEY: string = "PartnerOneSubID";
    static PARTNER_ONE_ID_QUERY_KEY: string = "PartnerOneID";
    static PARTNER_ID_QUERY_KEY: string = "PartnerID";
    static SUBSCRIPTION_KEY_STATIC_HEADER = "[Measures].[SubscriptionKey]";
    static TENANT_KEY_STATIC_HEADER = "[Measures].[TenantKey]";
    static DETAILS = "Details";
    static MPN_PARTNER_REVENUE = "MPNPartnerRevenue";
    static MPN_SUBSCRIPTION_ADOPTION = "MPNSubscription_Adoption";
    static ADVANCED_SEARCH_ETE_MAX_ROWS = "20000"; //Max rows to be exported from Advance Search ETE
    static NOTCH_DEFAULT_POSITION = 58;
    static NOT_YET_BILLED_ICE_SUBSCRIPTION =
        "SPOCPPAzureNotYetBilledSubscription&Data";
    static REFERRAL_GRID = "SPOReferral&Grid";
    static SHOW_HUB_TEXT = false;
    // Dynamics 365 Constants
    static D365_DROPDOWN_DATA_ID = "65";
    static D365_BULLET_CONFIG_ID = "200";
    static D365_VORGID_COUNT = "37";
    static D365_FILTER_DATA_GET_ROUTE = "d365service/GetGridData/";
    static MSLEARN_LEARNING_DROPDOWN_DATA =
        "reportfilter/GetLearningDropdownData";
    static MSLEARN_CONFIG_ID = "100";
    static D365_FILTER_DATA_REQUEST_HANDLER =
        "d365service/GetDataWithConfigParam";
    static D365_FILTER_DATA_REQUEST_HANDLER_NOACCESS =
        "d365service/GetDataWithConfigParamNoAccess";
    static D365_CURRENT_TIME_SPAN = "";
    static D365_TILE_COUNT = {
        "SPOCPPDynamics365PartnerOneID&Grid": 0,
        "SPOCPPDynamics365Customer&Grid": 0,
    };
    static DYNAMICS_365_HTML_SLICER_DATA_CONFIG: any = {
        Workload: {
            id: [],
            name: [],
            default: "",
        },
        AreaName: {
            id: [],
            name: [],
            default: "",
        },
        ScoreRange: {
            id: [],
            name: [],
            default: "",
        },
        WorkloadAreaMap: {},
    };

    static DYNAMICS_365_HTML_QUARTILE_SLICER_DATA_CONFIG = {
        QuartileRevenue: {
            id: [],
            name: [],
            selectedKey: "ALL",
        },
        QuartileNPSA: {
            id: [],
            name: [],
            selectedKey: "ALL",
        },
        QuartileNCA: {
            id: [],
            name: [],
            selectedKey: "ALL",
        },
        QuartileLiveProdEnv: {
            id: [],
            name: [],
            selectedKey: "ALL",
        },
        QuartileCertifications: {
            id: [],
            name: [],
            selectedKey: "ALL",
        },
        QuartileMAU: {
            id: [],
            name: [],
            selectedKey: "ALL",
        },
        QuartileMAS: {
            id: [],
            name: [],
            selectedKey: "ALL",
        },
        QuartileNPS: {
            id: [],
            name: [],
            selectedKey: "ALL",
        },
        TenantCountry: {
            name: [],
            selectedKey: "ALL",
        },
        CustomerIndustry: {
            name: [],
            selectedKey: "ALL",
        },
        CustomerSegment: {
            name: [],
            selectedKey: "ALL",
        },
        CustomerVertical: {
            name: [],
            selectedKey: "ALL",
        },
    };

    static DYNAMICS_365_DROPDOWN_SLICER_CONFIG = {
        PartnerShortlist: {
            Workload: "",
            AreaName: "",
            TenantCountry: "",
            CustomerIndustry: "",
            CustomerSegment: "",
            CustomerVertical: "",
            PartnerID: "",
            QuartileRevenue: "",
            QuartileNPSA: "",
            QuartileNCA: "",
            QuartileLiveProdEnv: "",
            QuartileCertifications: "",
            QuartileMAU: "",
            QuartileMAS: "",
            QuartileNPS: "",
            TimeSpan: "",
        },
    };

    static DYNAMICS_365_VORG_COUNT_CONFIG = {
        page: "PartnerShortlist",
        parameters: [
            { Key: "Workload", Value: "" },
            { Key: "ScoreRange", Value: "" },
            { Key: "TimeSpan", Value: "" },
            { Key: "Area", Value: "" },
            { Key: "Role", Value: "" },
            { Key: "Contact", Value: "" },
            { Key: "Competency", Value: "" },
            { Key: "PartnerNameID", Value: "" },
        ],
    };

    static DYNAMICS_365_HTML_GRID_CONFIG: any = {
        "SPOCPPDynamics365Customer&Grid": {
            title: "Details",
            gridid: "SPOCPPDynamics365Customer&Grid",
            type: "hierarchy",
            subgrid: ["SPOCPPDynamicsTenant&Grid"],
            parameters: [
                { Key: "SortBy", Value: [[""]] },
                { Key: "SortOrder", Value: [[""]] },
                { Key: "Workload", Value: [[]] },
                { Key: "AreaName", Value: [[]] },
                {
                    Key: "PartnerID",
                    Value: [[parseInt(window.location.search.split("&")[0].split("=")[1])]],
                },
                {
                    Key: "PartnerType",
                    Value: [
                        [
                            window.location.search
                                .toLocaleLowerCase()
                                .split("&")[0]
                                .replace("?", "")
                                .split("=")
                                .indexOf("partneroneid") > -1
                                ? "PartnerOneID"
                                : "MPNVorgID",
                        ],
                    ],
                },
                { Key: "TimeSpan", Value: [[""]] },
                { Key: "SearchString", Value: [[""]] },
            ],
            filter: "Workload$#$AreaName",
            page: "PVI",
            sortable: true,
            exportParameters: [],
        },
        "SPOCPPDynamicsTenant&Grid": {
            gridid: "SPOCPPDynamicsTenant&Grid",
            type: "pvimulti",
            title: "",
            subgrid: ["SPOCPPDynamicsSubscription&Grid"],
            parameters: [
                { Key: "SortBy", Value: [[""]] },
                { Key: "SortOrder", Value: [[""]] },
                { Key: "Workload", Value: [[2]] },
                { Key: "AreaName", Value: [[17]] },
                { Key: "PartnerID", Value: [[]] },
                { Key: "TPID", Value: [[]] },
                {
                    Key: "PartnerType",
                    Value: [
                        [
                            window.location.search
                                .toLocaleLowerCase()
                                .split("&")[0]
                                .replace("?", "")
                                .split("=")
                                .indexOf("partneroneid") > -1
                                ? "PartnerOneID"
                                : "MPNVorgID",
                        ],
                    ],
                },
                { Key: "TimeSpan", Value: [[""]] },
            ],
            filter: "TPID$#$PartnerID$#$AreaName$#$Workload$#$SortBy$#$SortOrder",
            page: "PVI",
            sortable: true,
            exportParameters: [],
        },
        "SPOCPPDynamicsSubscription&Grid": {
            gridid: "SPOCPPDynamicsSubscription&Grid",
            type: "single",
            title: "",
            parameters: [
                { Key: "SortBy", Value: [[""]] },
                { Key: "SortOrder", Value: [[""]] },
                { Key: "Workload", Value: [[2]] },
                { Key: "AreaName", Value: [[17]] },
                { Key: "PartnerID", Value: [[]] },
                { Key: "TPID", Value: [[]] },
                { Key: "TenantID", Value: [[]] },
                {
                    Key: "PartnerType",
                    Value: [
                        [
                            window.location.search
                                .toLocaleLowerCase()
                                .split("&")[0]
                                .replace("?", "")
                                .split("=")
                                .indexOf("partneroneid") > -1
                                ? "PartnerOneID"
                                : "MPNVorgID",
                        ],
                    ],
                },
                { Key: "TimeSpan", Value: [[""]] },
            ],
            filter:
                "TenantID$#$TPID$#$PartnerID$#$AreaName$#$Workload$#$SortBy$#$SortOrder",
            page: "PVI",
            sortable: true,
            exportParameters: [],
        },
        "SPOCPPDynamics365Customer&CSGrid": {
            title: "Details",
            gridid: "SPOCPPDynamics365Customer&CSGrid",
            type: "hierarchy",
            subgrid: ["SPOCPPDynamicsTenant&CSGrid"],
            parameters: [
                { Key: "SortBy", Value: [[""]] },
                { Key: "SortOrder", Value: [[""]] },
                { Key: "Workload", Value: [[]] },
                { Key: "AreaName", Value: [[]] },
                {
                    Key: "PartnerID",
                    Value: [[parseInt(window.location.search.split("&")[0].split("=")[1])]],
                },
                {
                    Key: "PartnerType",
                    Value: [
                        [
                            window.location.search
                                .toLocaleLowerCase()
                                .split("&")[0]
                                .replace("?", "")
                                .split("=")
                                .indexOf("partneroneid") > -1
                                ? "PartnerOneID"
                                : "MPNVorgID",
                        ],
                    ],
                },
                { Key: "TimeSpan", Value: [[""]] },
                { Key: "SearchString", Value: [[""]] },
            ],
            filter: "Workload$#$AreaName",
            page: "PVI",
            sortable: true,
            exportParameters: [],
        },
        "SPOCPPDynamicsTenant&CSGrid": {
            gridid: "SPOCPPDynamicsTenant&CSGrid",
            type: "single",
            title: "",
            parameters: [
                { Key: "SortBy", Value: [[""]] },
                { Key: "SortOrder", Value: [[""]] },
                { Key: "Workload", Value: [[2]] },
                { Key: "AreaName", Value: [[17]] },
                { Key: "PartnerID", Value: [[]] },
                { Key: "TPID", Value: [[]] },
                {
                    Key: "PartnerType",
                    Value: [
                        [
                            window.location.search
                                .toLocaleLowerCase()
                                .split("&")[0]
                                .replace("?", "")
                                .split("=")
                                .indexOf("partneroneid") > -1
                                ? "PartnerOneID"
                                : "MPNVorgID",
                        ],
                    ],
                },
                { Key: "TimeSpan", Value: [[""]] },
            ],
            filter: "TPID$#$PartnerID$#$AreaName$#$Workload$#$SortBy$#$SortOrder",
            page: "PVI",
            sortable: true,
            exportParameters: [],
        },
        "SPOCPPDynamics365PartnerOne&Grid": {
            title: "Partner Details",
            gridid: "SPOCPPDynamics365PartnerOne&Grid",
            type: "pvimulti",
            subgrid: ["SPOCPPDynamics365VOrg&Grid"],
            parameters: [
                { Key: "SortBy", Value: [[""]] },
                { Key: "SortOrder", Value: [[""]] },
                { Key: "PartnerArea", Value: [[""]] },
                { Key: "Workload", Value: [[2]] },
                { Key: "ScoreRange", Value: [[0]] },
                { Key: "TimeSpan", Value: [[""]] },
                { Key: "Area", Value: [[""]] },
                { Key: "Role", Value: [[""]] },
                { Key: "Contact", Value: [[""]] },
                { Key: "Competency", Value: [[""]] },
                { Key: "PartnerNameID", Value: [[""]] },
            ],
            filter: "PartnerOneID$#$Workload$#$AreaName",
            page: "PartnerShortlist",
            sortable: true,
            exportParameters: [],
        },
        "SPOCPPDynamics365VOrg&Grid": {
            title: "",
            gridid: "SPOCPPDynamics365VOrg&Grid",
            type: "single",
            parameters: [
                { Key: "SortBy", Value: [[""]] },
                { Key: "SortOrder", Value: [[""]] },
                { Key: "PartnerArea", Value: [[""]] },
                { Key: "Workload", Value: [[2]] },
                { Key: "AreaName", Value: [[3]] },
                { Key: "ScoreRange", Value: [[0]] },
                { Key: "TimeSpan", Value: [[""]] },
                { Key: "Area", Value: [[""]] },
                { Key: "Role", Value: [[""]] },
                { Key: "Contact", Value: [[""]] },
                { Key: "Competency", Value: [[""]] },
                { Key: "PartnerNameID", Value: [[""]] },
                { Key: "PartnerOneID", Value: [[""]] },
            ],
            filter: "Workload$#$AreaName",
            page: "PartnerShortlist",
            sortable: true,
            exportParameters: [],
        },
    };

    static MSLEARN_SUMMARY_PARTNER_HTML_GRIDS_CONFIG = {
        MSLearnPartnerGrid: {
            title: "Details",
            gridid: "MSLearnPartnerGrid",
            type: "learninghierarchy",
            subgrid: ["MSLearnPartnerPathGrid"],
            hfpEnabled: true,
            parameters: [
                { Key: "PartnerID", Value: [[""]] },
                { Key: "SortBy", Value: [[""]] },
                { Key: "SortOrder", Value: [[""]] },
                { Key: "Roles", Value: [[""]] },
                { Key: "Products", Value: [[""]] },
                { Key: "Levels", Value: [[""]] },
                { Key: "SubProducts", Value: [[""]] },
                { Key: "FiscalYear", Value: [[""]] },
                { Key: "FiscalMonth", Value: [[""]] },
                { Key: "PartnerNameID", Value: [[""]] },
                { Key: "AreaID", Value: [[""]] },
                { Key: "SubsidiaryID", Value: [[""]] },
                { Key: "LearningPath", Value: [[""]] },
            ],
            filter: "",
            page: "MSLearn",
            sortable: true,
            exportParameters: [
                {
                    Placeholder: "Roles",
                    Key: "Role Name",
                    CubeMeasure: "[MS Learn Role].[Role Name]",
                },
                {
                    Placeholder: "PartnerNameID",
                    Key: "Partner Name ID",
                    CubeMeasure: "[MS Learn Progress].[Partner Name ID]",
                },
                {
                    Placeholder: "Levels",
                    Key: "Level Name",
                    CubeMeasure: "[MS Learn Level].[Level Name]",
                },
                {
                    Placeholder: "Products",
                    Key: "Product",
                    CubeMeasure: "[MS Learn Product].[Product]",
                },
                {
                    Placeholder: "SubProducts",
                    Key: "Sub Product",
                    CubeMeasure: "[MS Learn Product].[Sub Product]",
                },
                {
                    Placeholder: "FiscalYear",
                    Key: "Fiscal Year",
                    CubeMeasure: "[Time].[Fiscal Year]",
                },
                {
                    Placeholder: "FiscalMonth",
                    Key: "FiscalYearMonthName",
                    CubeMeasure: "[Time].[FiscalYearMonthName]",
                },
                {
                    Placeholder: "AreaID",
                    Key: "Area",
                    CubeMeasure: "[MS Learn Progress].[Area]",
                },
                {
                    Placeholder: "SubsidiaryID",
                    Key: "Subsidiary",
                    CubeMeasure: "[MS Learn Progress].[Subsidiary]",
                },
                {
                    Placeholder: "LearningPath",
                    Key: "Title",
                    CubeMeasure: "[MS Learn Progress].[Title]",
                },
            ],
        },
        MSLearnPartnerPathGrid: {
            title: "Details",
            gridid: "MSLearnPartnerPathGrid",
            type: "learningmulti",
            subgrid: [],
            hfpEnabled: true,
            parameters: [
                { Key: "SortBy", Value: [[""]] },
                { Key: "SortOrder", Value: [[""]] },
                { Key: "Roles", Value: [[""]] },
                { Key: "Products", Value: [[""]] },
                { Key: "Levels", Value: [[""]] },
                { Key: "LearningPath", Value: [[""]] },
                { Key: "SubProducts", Value: [[""]] },
                { Key: "FiscalYear", Value: [[""]] },
                { Key: "FiscalMonth", Value: [[""]] },
                { Key: "PartnerNameID", Value: [[""]] },
                { Key: "AreaID", Value: [[""]] },
                { Key: "SubsidiaryID", Value: [[""]] },
                { Key: "PartnerID", Value: [[""]] },
            ],
            filter: "PartnerID",
            page: "MSLearn",
            sortable: true,
            exportParameters: [],
        },
        MSLearnPathGrid: {
            title: "Details",
            gridid: "MSLearnPathGrid",
            type: "learninghierarchy",
            subgrid: ["MSLearnPathPartnerGrid"],
            hfpEnabled: true,
            parameters: [
                { Key: "PartnerID", Value: [[""]] },
                { Key: "SortBy", Value: [[""]] },
                { Key: "SortOrder", Value: [[""]] },
                { Key: "Roles", Value: [[""]] },
                { Key: "Products", Value: [[""]] },
                { Key: "Levels", Value: [[""]] },
                { Key: "LearningPath", Value: [[""]] },
                { Key: "SingleLearningPath", Value: [[""]] },
                { Key: "SubProducts", Value: [[""]] },
                { Key: "FiscalYear", Value: [[""]] },
                { Key: "FiscalMonth", Value: [[""]] },
                { Key: "PartnerNameID", Value: [[""]] },
                { Key: "AreaID", Value: [[""]] },
                { Key: "SubsidiaryID", Value: [[""]] },
            ],
            filter: "",
            page: "MSLearn",
            sortable: true,
            exportParameters: [
                {
                    Placeholder: "Roles",
                    Key: "Role Name",
                    CubeMeasure: "[MS Learn Role].[Role Name]",
                },
                {
                    Placeholder: "PartnerNameID",
                    Key: "Partner Name ID",
                    CubeMeasure: "[MS Learn Progress].[Partner Name ID]",
                },
                {
                    Placeholder: "Levels",
                    Key: "Level Name",
                    CubeMeasure: "[MS Learn Level].[Level Name]",
                },
                {
                    Placeholder: "Products",
                    Key: "Product",
                    CubeMeasure: "[MS Learn Product].[Product]",
                },
                {
                    Placeholder: "SubProducts",
                    Key: "Sub Product",
                    CubeMeasure: "[MS Learn Product].[Sub Product]",
                },
                {
                    Placeholder: "FiscalYear",
                    Key: "Fiscal Year",
                    CubeMeasure: "[Time].[Fiscal Year]",
                },
                {
                    Placeholder: "FiscalMonth",
                    Key: "FiscalYearMonthName",
                    CubeMeasure: "[Time].[FiscalYearMonthName]",
                },
                {
                    Placeholder: "AreaID",
                    Key: "Area",
                    CubeMeasure: "[MS Learn Progress].[Area]",
                },
                {
                    Placeholder: "SubsidiaryID",
                    Key: "Subsidiary",
                    CubeMeasure: "[MS Learn Progress].[Subsidiary]",
                },
                {
                    Placeholder: "LearningPath",
                    Key: "Title",
                    CubeMeasure: "[MS Learn Progress].[Title]",
                },
            ],
        },
        MSLearnPathPartnerGrid: {
            title: "Details",
            gridid: "MSLearnPathPartnerGrid",
            type: "learningmulti",
            subgrid: [],
            hfpEnabled: true,
            parameters: [
                { Key: "SortBy", Value: [[""]] },
                { Key: "SortOrder", Value: [[""]] },
                { Key: "Roles", Value: [[""]] },
                { Key: "Products", Value: [[""]] },
                { Key: "Levels", Value: [[""]] },
                { Key: "PartnerID", Value: [[""]] },
                { Key: "SubProducts", Value: [[""]] },
                { Key: "FiscalYear", Value: [[""]] },
                { Key: "FiscalMonth", Value: [[""]] },
                { Key: "PartnerNameID", Value: [[""]] },
                { Key: "AreaID", Value: [[""]] },
                { Key: "SubsidiaryID", Value: [[""]] },
                { Key: "LearningPath", Value: [[""]] },
                { Key: "SingleLearningPath", Value: [[""]] },
            ],
            filter: "SingleLearningPath",
            page: "MSLearn",
            sortable: true,
            exportParameters: [],
        },
        MSLearnPartnerModuleGrid: {
            title: "Details",
            gridid: "MSLearnPartnerModuleGrid",
            type: "learninghierarchy",
            subgrid: ["MSLearnPartnerSingleModuleGrid"],
            hfpEnabled: true,
            parameters: [
                { Key: "PartnerID", Value: [[""]] },
                { Key: "SortBy", Value: [[""]] },
                { Key: "SortOrder", Value: [[""]] },
                { Key: "Roles", Value: [[""]] },
                { Key: "Products", Value: [[""]] },
                { Key: "Levels", Value: [[""]] },
                { Key: "Module", Value: [[""]] },
                { Key: "SubProducts", Value: [[""]] },
                { Key: "FiscalYear", Value: [[""]] },
                { Key: "FiscalMonth", Value: [[""]] },
                { Key: "PartnerNameID", Value: [[""]] },
                { Key: "AreaID", Value: [[""]] },
                { Key: "SubsidiaryID", Value: [[""]] },
            ],
            filter: "",
            page: "MSLearn_Modules",
            sortable: true,
            exportParameters: [
                {
                    Placeholder: "Roles",
                    Key: "Role Name",
                    CubeMeasure: "[MS Learn Role].[Role Name]",
                },
                {
                    Placeholder: "PartnerNameID",
                    Key: "Partner Name ID",
                    CubeMeasure: "[MS Learn Progress].[Partner Name ID]",
                },
                {
                    Placeholder: "Levels",
                    Key: "Level Name",
                    CubeMeasure: "[MS Learn Level].[Level Name]",
                },
                {
                    Placeholder: "Products",
                    Key: "Product",
                    CubeMeasure: "[MS Learn Product].[Product]",
                },
                {
                    Placeholder: "SubProducts",
                    Key: "Sub Product",
                    CubeMeasure: "[MS Learn Product].[Sub Product]",
                },
                {
                    Placeholder: "FiscalYear",
                    Key: "Fiscal Year",
                    CubeMeasure: "[Time].[Fiscal Year]",
                },
                {
                    Placeholder: "FiscalMonth",
                    Key: "FiscalYearMonthName",
                    CubeMeasure: "[Time].[FiscalYearMonthName]",
                },
                {
                    Placeholder: "AreaID",
                    Key: "Area",
                    CubeMeasure: "[MS Learn Progress].[Area]",
                },
                {
                    Placeholder: "SubsidiaryID",
                    Key: "Subsidiary",
                    CubeMeasure: "[MS Learn Progress].[Subsidiary]",
                },
                {
                    Placeholder: "Module",
                    Key: "Title",
                    CubeMeasure: "[MS Learn Progress].[Title]",
                },
            ],
        },
        MSLearnPartnerSingleModuleGrid: {
            title: "Details",
            gridid: "MSLearnPartnerSingleModuleGrid",
            type: "learningmulti",
            subgrid: [],
            hfpEnabled: true,
            parameters: [
                { Key: "SortBy", Value: [[""]] },
                { Key: "SortOrder", Value: [[""]] },
                { Key: "Roles", Value: [[""]] },
                { Key: "Products", Value: [[""]] },
                { Key: "Levels", Value: [[""]] },
                { Key: "Module", Value: [[""]] },
                { Key: "SubProducts", Value: [[""]] },
                { Key: "FiscalYear", Value: [[""]] },
                { Key: "FiscalMonth", Value: [[""]] },
                { Key: "PartnerNameID", Value: [[""]] },
                { Key: "AreaID", Value: [[""]] },
                { Key: "SubsidiaryID", Value: [[""]] },
                { Key: "PartnerID", Value: [[""]] },
            ],
            filter: "PartnerID",
            page: "MSLearn_Modules",
            sortable: true,
            exportParameters: [],
        },
        MSLearnSingleModuleGrid: {
            title: "Details",
            gridid: "MSLearnSingleModuleGrid",
            type: "learninghierarchy",
            subgrid: ["MSLearnSingleModulePartnerGrid"],
            hfpEnabled: true,
            parameters: [
                { Key: "PartnerID", Value: [[""]] },
                { Key: "SortBy", Value: [[""]] },
                { Key: "SortOrder", Value: [[""]] },
                { Key: "Roles", Value: [[""]] },
                { Key: "Products", Value: [[""]] },
                { Key: "Levels", Value: [[""]] },
                { Key: "Module", Value: [[""]] },
                { Key: "SubProducts", Value: [[""]] },
                { Key: "FiscalYear", Value: [[""]] },
                { Key: "FiscalMonth", Value: [[""]] },
                { Key: "PartnerNameID", Value: [[""]] },
                { Key: "AreaID", Value: [[""]] },
                { Key: "SubsidiaryID", Value: [[""]] },
                { Key: "SingleModule", Value: [[""]] },
            ],
            filter: "",
            page: "MSLearn_Modules",
            sortable: true,
            exportParameters: [
                {
                    Placeholder: "Roles",
                    Key: "Role Name",
                    CubeMeasure: "[MS Learn Role].[Role Name]",
                },
                {
                    Placeholder: "PartnerNameID",
                    Key: "Partner Name ID",
                    CubeMeasure: "[MS Learn Progress].[Partner Name ID]",
                },
                {
                    Placeholder: "Levels",
                    Key: "Level Name",
                    CubeMeasure: "[MS Learn Level].[Level Name]",
                },
                {
                    Placeholder: "Products",
                    Key: "Product",
                    CubeMeasure: "[MS Learn Product].[Product]",
                },
                {
                    Placeholder: "SubProducts",
                    Key: "Sub Product",
                    CubeMeasure: "[MS Learn Product].[Sub Product]",
                },
                {
                    Placeholder: "FiscalYear",
                    Key: "Fiscal Year",
                    CubeMeasure: "[Time].[Fiscal Year]",
                },
                {
                    Placeholder: "FiscalMonth",
                    Key: "FiscalYearMonthName",
                    CubeMeasure: "[Time].[FiscalYearMonthName]",
                },
                {
                    Placeholder: "AreaID",
                    Key: "Area",
                    CubeMeasure: "[MS Learn Progress].[Area]",
                },
                {
                    Placeholder: "SubsidiaryID",
                    Key: "Subsidiary",
                    CubeMeasure: "[MS Learn Progress].[Subsidiary]",
                },
                {
                    Placeholder: "Module",
                    Key: "Title",
                    CubeMeasure: "[MS Learn Progress].[Title]",
                },
            ],
        },
        MSLearnSingleModulePartnerGrid: {
            title: "Details",
            gridid: "MSLearnSingleModulePartnerGrid",
            type: "learningmulti",
            subgrid: [],
            hfpEnabled: true,
            parameters: [
                { Key: "SortBy", Value: [[""]] },
                { Key: "SortOrder", Value: [[""]] },
                { Key: "Roles", Value: [[""]] },
                { Key: "Products", Value: [[""]] },
                { Key: "Levels", Value: [[""]] },
                { Key: "PartnerID", Value: [[""]] },
                { Key: "Module", Value: [[""]] },
                { Key: "SubProducts", Value: [[""]] },
                { Key: "FiscalYear", Value: [[""]] },
                { Key: "FiscalMonth", Value: [[""]] },
                { Key: "PartnerNameID", Value: [[""]] },
                { Key: "AreaID", Value: [[""]] },
                { Key: "SubsidiaryID", Value: [[""]] },
                { Key: "SingleModule", Value: [[""]] },
            ],
            filter: "SingleModule",
            page: "MSLearn_Modules",
            sortable: true,
            exportParameters: [],
        },
    };
}
