import React, { useState } from "react";
import CardComponent from "./CardComponent";
import Searchreporticon from "../Images/img/Searchreporticon.png";
import "./RightPanel.css";
import FastTrackOKR from '../Images/FastTrackOKR.png'
import onboarding from '../Images/Onboarding.png'
import { Constants } from './helpers/Constants';

interface IModule {
  imageUrl: string;
  heading: string;
  bullets: Array<{ tileText: string; URL: string }>;
  footerTags: string[];
}

const RightPanel = () => {
  const modules: IModule[] = [
    {
      imageUrl: onboarding,
      heading: "How to get access to Power BI reports?",
      bullets: [
        {
          tileText: "Join required SGs",
          URL: Constants.JOIN_SG_URL,
        },
        {
          tileText: "Join Power BI Workspace",
          URL: Constants.WORKSPACE_URL,
        },
      ],
      footerTags: ["Power BI", "Access", "Requesting Access"],
    },
    {
      imageUrl: FastTrackOKR,
      heading: "FastTrack OKR",
      bullets: [
        {
          tileText: "Funnel Definition",
          URL: Constants.FUNNEL_SHAEPOINT_URL,
        },
        {
          tileText: "Lynx vs FastTrack DataPlatform",
          URL: Constants.DATA_PLATFORM_URL,
        },
      ],
      footerTags: ["FastTrack", "OKR", "Lynx", "Funnel"],
    },
    // {
    //   imageUrl: onboarding,
    //   heading: "Introduction to Azure",
    //   bullets: [
    //     {
    //       tileText: "Azure Fundamentals",
    //       URL: "https://docs.microsoft.com/en-us/learn/azure/",
    //     },
    //     {
    //       tileText: "Azure Services Overview",
    //       URL: "https://docs.microsoft.com/en-us/learn/azure/",
    //     },
    //   ],
    //   footerTags: ["Azure", "Cloud", "Introduction"],
    // },
    // {
    //   imageUrl: FastTrackOKR,
    //   heading: "Advanced Power BI Techniques",
    //   bullets: [
    //     {
    //       tileText: "DAX Functions",
    //       URL: "https://docs.microsoft.com/en-us/learn/azure/",
    //     },
    //     {
    //       tileText: "Power Query",
    //       URL: "https://docs.microsoft.com/en-us/learn/azure/",
    //     },
    //   ],
    //   footerTags: ["Power BI", "Advanced", "DAX", "Power Query"],
    // },
    // {
    //   imageUrl: FastTrackOKR,
    //   heading: "DevOps Best Practices",
    //   bullets: [
    //     {
    //       tileText: "CI/CD Pipelines",
    //       URL: "https://docs.microsoft.com/en-us/learn/azure/",
    //     },
    //     {
    //       tileText: "Infrastructure as Code",
    //       URL: "https://docs.microsoft.com/en-us/learn/azure/",
    //     },
    //   ],
    //   footerTags: ["DevOps", "CI/CD", "Infrastructure"],
    // },
    // {
    //   imageUrl: FastTrackOKR,
    //   heading: "Machine Learning Basics",
    //   bullets: [
    //     {
    //       tileText: "Supervised Learning",
    //       URL: "https://docs.microsoft.com/en-us/learn/azure/",
    //     },
    //     {
    //       tileText: "Unsupervised Learning",
    //       URL: "https://docs.microsoft.com/en-us/learn/azure/",
    //     },
    //   ],
    //   footerTags: ["Machine Learning", "AI", "Basics"],
    // },
  ];

  const [filteredModules, setFilteredModules] = useState<IModule[]>(modules);

  const localSearch = (searchKey: string) => {
    if (searchKey === '') {
      setFilteredModules(modules);
      return;
    }
    const filteredModules = modules.filter((module) => {
      return module.heading.toLowerCase().includes(searchKey.toLowerCase()) ||
             module.footerTags.join(',').toLowerCase().includes(searchKey.toLowerCase()) ||
             module.bullets.some((bullet) => bullet.tileText.toLowerCase().includes(searchKey.toLowerCase()));
    });
    setFilteredModules(filteredModules);
  }

  return (
    <div className="trainingsModule">
      <h2 className="module-title h4">Training Modules</h2>

      <div className="input-container d-flex align-items-center">
        <input
          type="text"
          className="form-control search-input"
          placeholder="Search Training Modules by Tags, Lesson Name, or Modules"
          onChange={(e) => localSearch(e.target.value)}
        />
        <span
          className="ms-Icon ms-Icon--Search searchMagnifierIcon search-icon"
          title="Search Reports"
        ></span>
      </div>

      <CardComponent filteredModules={filteredModules} />
    </div>
  );
};

export default RightPanel;