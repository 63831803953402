import React from 'react'
import { PowerBIEmbed } from 'powerbi-client-react';
import '../Styles/LandingPage.css'


 const RecentNotification = () => {
  const RecentNotifications="RecentNotifications";





  return (
    <div >
      <div className="tileHeaderExclusive"><div><i className="ms-Icon ms-Icon--News tileHeaderIcon" aria-hidden="true"></i></div><span className="tileHeaderText">Recent Notifications</span></div>

<PowerBIEmbed
embedConfig = {
    {
        type: 'report', 
        id: 'ff57ea8f-d2fc-4618-a5ad-60819effd602',
        embedUrl: 'https://msit.powerbi.com/reportEmbed?reportId=ff57ea8f-d2fc-4618-a5ad-60819effd602&groupId=c7300506-4e4b-47d2-8d0f-bfdfd6897ec1&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9ERi1NU0lULVNDVVMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7InVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
        accessToken: localStorage.getItem('powerbi_access_token') || "",
        settings: {
            panes: {
                filters: {
                    expanded: false,
                    visible: false
                },
            },
            
            navContentPaneEnabled: false,
            filterPaneEnabled: false,
            persistentFiltersEnabled: false,
            personalBookmarksEnabled: false,
            bars: {
                actionBar: {
                    visible: false
                },
            }
        }
    }
}



cssClassName={RecentNotifications}


/>

</div>
  )
}
export default RecentNotification;
