import { CopilotMessage as CopilotMessage, UserMessageV2 as UserMessage, SystemMessage, OutputCard } from "@fluentui-copilot/react-copilot";
import { Avatar } from "@fluentui/react-components";
import { Open16Regular } from "@fluentui/react-icons";
import parse from "html-react-parser";
import { MessageData, transform } from "./Common";


export function renderChatMessage(messageData: MessageData, index: number) {
  const d = new Date();
  const month = d.toLocaleDateString("default", { month: "numeric" });
  const userMsgTime = month +
    "/" +
    d.getDate() +
    ", " +
    d.toLocaleTimeString("default", { hour: "2-digit", minute: "2-digit" });

  switch (messageData.type) {
    case "copilot": {
      return (
        <CopilotMessage
          key={`CopilotMessage-${index}`}
          // disclaimer=""
          // avatar={<Avatar
          //   size={24}
          //   image={{
          //     src: "https://res-2-sdf.cdn.office.net/files/fabric-cdn-prod_20240411.001/assets/brand-icons/product/svg/copilot_24x1.svg",
          //   }} />}
          // name="Copilot"
        >
          {parse(messageData.message, { replace: transform, trim: true })}
        </CopilotMessage>
      );
    }
    case "user": {
      return (
        <UserMessage key={`UserMessage-${index}`} timestamp={userMsgTime}>
          {messageData.message}
        </UserMessage>
      );
    }
    case "system": {
      return (
        <SystemMessage key={`SystemMessage-${index}`} icon={<Open16Regular />}>
          {messageData.message}
        </SystemMessage>
      );
    }
    default:
      return null;
  }
}
