import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, ListGroup } from "react-bootstrap";
import Searchreporticon from "../../Images/img/Searchreporticon.png";
import playSmallbtnicn from "../../Images/img/playSmallbtnicn.png";
import { IRecentReports } from "../Interfaces/Interfaces";
import { Constants } from "../helpers/Constants";
import Loading from "../../Images/Loading.gif";
import axios from "axios";
import SearchBar from "./SearchBar";
import Searchicon from "../../Images/img/Searchicon.png";
import protectedAxiosInstance from "../helpers/api";
import { useNavigate } from "react-router-dom";
import trainImg from "../../Images/img/train.png";
import trainingIcon from "../../Images/img/trainingicon.png";
import "./OCPLanding.css";
 
 
const env: string = (window.location.host.includes("dev") || window.location.host.includes("localhost")) ? "dev" : "non-dev";
 
interface props {
  recentReports: IRecentReports[];
}
 
interface report {
  TileText: string;
  Description: string;
  ReportURL: string;
  IsPowerBI: string;
  ContactEmail: string;
  Persona: string;
}
 
const UnifiedComponent: React.FC<props> = ({ recentReports }) => {
  const [reports, setReports] = useState<report[]>([]);
 
  const navigate = useNavigate();
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await protectedAxiosInstance.get(
          `${window.location.origin}/api/Service/GetReportLibraryConfiguration`,
          {
            withCredentials: true,
          }
        );
        setReports(response.data.Table);
      } catch (error) {
        console.error(error);
      }
    };
 
    fetchData();
  }, []);
 
  return (
    <Container fluid className="unified-component">
      <Row className="align-items-start">
        {/* Search Reports Section */}
        <Col md={6} className="search-reports" style={{ marginLeft: "-2px" }}>
          <div
            style={{
              height: "315px",
              display: "flex",
              flexDirection: "column",
            }}
          >
          <h5 style={{ marginBottom: "0.6rem" }}>
            <img
              src={Searchreporticon}
              alt="Bar Chart Icon"
              className="icon"
              style={{ height: "25px", width: "25px" }}
            />{" "}
            Search Reports
          </h5>
          <div style={{ position: "relative" }}>
            <SearchBar reportCatalog={reports} />
            <img
              src={Searchicon}
              alt="Search Icon"
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                pointerEvents: "none",
              }}
            />
          </div>
            <h6 style={env !== "dev" ? { marginTop: "0rem" } : { marginTop: "1rem" }}>
              Recently Viewed Reports
            </h6>
            <div className="grid-container">
              {recentReports.length > 0 ? (
                recentReports.map((view, index) =>
                  view.tileText !== "" ? (
                    <div key={index} className="grid-item">
                      <a
                        onClick={() => {
                          navigate(`/view?parent=${view.tileID}`);
                        }}
                        className="no-color-change"
                      >
                        {view.tileText}
                      </a>
                    </div>
                  ) : null
                )
              ) : (
                <p>This list is empty. Use the left menu to open a report.</p>
              )}
            </div>
 
            {env === "dev" && (
              <a
                className="view-report-catalog"
                onClick={() => {
                  navigate("/view?parent=ReportCatalog_SearchReport");
                }}
                title="View report catalog"
              >
                <b>View report catalog &gt;</b>
              </a>
            )}
          </div>
        </Col>
 
        {/* Training Section */}
        <Col md={6} className="demo" style={{ height: "314px" }}>
          <Col md={8}>
            <h5 className="d-flex align-items-center" style={{ marginLeft: '-15px', marginTop: '-2.5px' }}>
              <img src={trainingIcon} alt="Training Icon" className="icon" style={{ height: '32px', width: '36px' }} />
              Training Spotlight
            </h5>
            <div style={{width:"300px", marginTop:'23px'}}>
            <p style={{ fontSize: '16px',width:'297px' }}>
              <b>How to get access to Power BI Reports?</b>
            </p>
            </div>
 
 
            <ul style={{
              marginTop: '40px',
              listStyleType: 'none',
            }} className="bullet">
              <li className="litag"
                title="Join required SGs"
              >
                <a
                  href={Constants.JOIN_SG_URL}
                  target="_blank"
                  className="text-link "
                >
                  Join required SGs
                </a>
              </li>
 
              <li className="litag"
                title="Join Power BI Workspace"
              >
                <a
                  href={Constants.WORKSPACE_URL}
                  target="_blank"
                  className="text-link"
                >
                  Join Power BI Workspace
                </a>
              </li>
 
              <li className="litag"
                title="View all training modules"
              >
                <a
                 onClick={() => { navigate("../Pages/Training") }}
                  target="_blank"
                  className="text-link"
                >
                  View all training modules
                </a>
              </li>
            </ul>
 
{/*
            <div style={{ marginTop: '12px',marginLeft:'3px', width: '220px' }}>
              <a onClick={() => { navigate("../Pages/Training") }} className="view-all-link" title="View all training modules">
                <b style={{ fontSize: '16px' }}>View all training modules &gt;</b>
              </a>
            </div> */}
 
            <div className="reportdiv">
              <a onClick={() => { navigate("/view?parent=ReportCatalog_SearchReport") }} className="view-all-link" title="Visit the Report Catalog to view report videos">
                <b style={{ fontSize: '16px' }}>Visit the Report Catalog to view report videos &gt;</b>
              </a>
            </div>
 
          </Col>
          {/* <Col md={4} className="training-image-wrapper">
            <div className="training-image-container">
              <img src={trainImg} alt="Training" className="training-image" />
            </div>
          </Col> */}
        </Col>
      </Row>
    </Container>
  );
};
 
export default UnifiedComponent;