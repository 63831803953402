import React, { useState } from 'react';
import './CardComponent.css'; 
import { Container } from 'react-bootstrap';

interface IModule {
    imageUrl: string;
    heading: string;
    bullets: Array<{ tileText: string; URL: string }>;
    footerTags: string[];
  }
interface props {
    filteredModules: IModule[];
}

function CardComponent({ filteredModules }: props) {

    return (
        <>
            <Container fluid className='trainingCardsContainer'>
                {filteredModules.map((card, i) => (
                    <div className="card mb-3" style={{ display: 'flex', flexDirection: 'column' }} key={i}>
                        <div className="row g-0" style={{height: '100%', marginBottom: '10px'}}>
                            <div className="bg-light imageCon col-md-4">
                                <img src={card.imageUrl} className="img-fluid" alt="Card" />
                            </div>
                            <div className="col-md-8">
                                <div className="card-body">
                                    <h5 className="card-title" style={{ color: 'black', fontWeight: '600', fontSize: '16px', marginRight: '20%', textAlign: 'left' }}>{card.heading}</h5>
                                    <ul className="card-text trainingcard" style={{marginRight: '3rem'}}>
                                        {card.bullets.map((bullet, index) => (
                                            <li className="bullet"  style={{  fontSize:'14px', marginBottom:'0.5rem', listStylePosition:'inside',textAlign:'left' }} key={index}><a href={bullet.URL} target='_self'>{bullet.tileText}</a></li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <p className="card-text"><small style={{color: 'black', fontWeight: '600' }}>{card.footerTags.join(', ')}</small></p>
                    </div>
                ))}
            </Container>
        </>
    );
}

export default CardComponent;