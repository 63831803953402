import React, { useState } from 'react'
{/* <link href="/Content/Styles/filterpanel.css" rel="stylesheet" />
<link href="https://static2.sharepointonline.com/files/fabric/office-ui-fabric-core/11.0.0/css/fabric.min.css" rel="stylesheet" /> */}
import LeftNav from './LeftNav'; 
import '../Styles/filterpanel.css'
import '../Styles/LandingPage.css'
import video from '../Images/VideoImage.jpg'
import { Constants } from './helpers/Constants';




 const WhatsNew = () => {
  return (
    <div>


<div className="NotificationSection">
        
            <div className="tileHeaderExclusive">
                <div><i className="ms-Icon ms-Icon--News tileHeaderIcon" aria-hidden="true"></i></div>
                <span className="tileHeaderText">What's New?</span>
            </div>
            <div className="totalNewItems">
                Total new introductions: 1
            </div>
           <div className="newItemsExclusive">
                <div className="newLinksContainer WhatsTextConatiner">
                  <div className="tileText newLink">
                        <a className="newlink" href={Constants.DEMO_VIDEO_NEW_URL} target="_blank"><span className="cursorPointer" title="New FastTrack BI Portal Demo">New FastTrack BI Portal Demo</span></a>
                        <span className="cursorPointer newlink normalHeadingLink" title="New FastTrack BI Portal Demo">New FastTrack BI Portal Demo</span>
                    </div>
                    <div className="descListContainer">
                      <div className="descriptionContainer">
                            <div>New Portal is launched to host all the FastTrack BI reports at one location.</div>
                        </div>
                    </div>
                </div>
                <div className="newImageSection">
                    <img className="newsImageExclusive" src={video} />
                </div>
            </div>
        {/* <div className="NotificationExclusive ng-hide" data-ng-show="OCPLandingCtrl.newLinks.length == 0">No New Report Available</div> */}
        </div>


    </div>
  )
}
export default WhatsNew;